import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"

export default function SaveDraftProposalDialog({
  open,
  onSave,
  onCancel,
  isSavingDraft,
}) {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          pb: 2,
        }}
      >
        <Box>
          <HaloCircularIcon Icon={SaveOutlinedIcon} />
        </Box>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Do you want to save proposal as a draft?
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          You could find it in the Drafts section later.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isSavingDraft}
          onClick={onSave}
          sx={{ ml: 2 }}
        >
          Save as draft
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
