import Typography from "@mui/material/Typography"

import Row from "./Row"

export default function DetailedHeader({ title, subtitle }) {
  return (
    <Row sx={{ alignItems: "center", display: "inline-flex" }}>
      <Typography variant="h6">{title}</Typography>
      {subtitle && (
        <Typography variant="h6" color="text.secondary2" sx={{ ml: 1 }}>
          {subtitle}
        </Typography>
      )}
    </Row>
  )
}
