import Autocomplete from "@mui/material/Autocomplete"
import Avatar from "@mui/material/Avatar"
import Chip from "@mui/material/Chip"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import Row from "../../../common/components/Row"

export default function ExcludeBrandsSelector({
  brand,
  onBrandChanged,
  brandInputValue,
  onBrandInputValueChanged,
  options,
  excludedBrands,
  handleSelectedBrandDeletedWrapper,
}) {
  const excludedBrandIDS = excludedBrands.map(({ id }) => id)
  return (
    <>
      <Autocomplete
        value={brand}
        onChange={onBrandChanged}
        inputValue={brandInputValue}
        onInputChange={onBrandInputValueChanged}
        options={options.filter(
          (option) => excludedBrandIDS.indexOf(option.id) === -1
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} placeholder="Brokerage names" fullWidth />
        )}
        renderOption={(props, option) => (
          <Row
            component="li"
            {...props}
            sx={{
              alignItems: "center",
            }}
          >
            <Avatar src={option.icon} sx={{ width: "32px", height: "32px" }} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              {option.name}
            </Typography>
          </Row>
        )}
        sx={{ maxWidth: "30em" }}
      />
      <Row
        sx={{
          mt: 1,
          flexWrap: "wrap",
        }}
      >
        {excludedBrands.map((brand, index) => (
          <Chip
            key={brand.id}
            size="medium"
            label={brand.name}
            onDelete={handleSelectedBrandDeletedWrapper(index)}
            sx={{
              ml: 1,
              mt: 1,
            }}
          />
        ))}
      </Row>
    </>
  )
}

export function useExcludeBrandsSelectorState() {
  const [brandInputValue, setBrandInputValue] = useState("")
  const [brand, setBrand] = useState(null)
  const [excludedBrands, setExcludedBrands] = useState([])

  function handleBrandInputValueChanged(_, newValue) {
    setBrandInputValue(newValue)
  }

  function handleBrandChanged(_, newValue) {
    setBrand(null)
    setBrandInputValue("")
    setExcludedBrands([...excludedBrands, newValue])
  }

  function handleSelectedBrandDeletedWrapper(index) {
    return function () {
      setExcludedBrands(excludedBrands.filter((_, i) => i !== index))
    }
  }

  return {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
  }
}
