import CircleIcon from "@mui/icons-material/Circle"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useTheme } from "@mui/material/styles"

import AnimatedProgressBar from "../../pages/brokerage/components/AnimatedProgressBar"
import {
  AgentVerticalLinearProgress,
  MARKET_PROGRESSBAR_COLOR,
  MarketVerticalLinearProgress,
} from "../../pages/brokerage/components/VerticalProgressBars"
import MoneyBagIcon from "../resources/icons/money-bag.png"
import Column from "./Column"
import IconTitleValue from "./IconTitleValue"
import Row from "./Row"

export default function CompensationColumns({ sentProposal }) {
  const agentCommission = 100 - sentProposal.proposal.brokerage_commission_ratio
  const theme = useTheme()

  return (
    <Grid container spacing={4}>
      <Grid xs={12} sm={6}>
        <IconTitleValue
          Icon={() => (
            <img
              src={MoneyBagIcon}
              alt="signing bonus"
              style={{ width: "24px", height: "24px" }}
            />
          )}
          title="Signing Bonus"
          value={`$${sentProposal.proposal.signing_bonus}`}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Row
          sx={{
            alignItems: "center",
            height: "40px",
          }}
        >
          <AnimatedProgressBar
            Component={AgentVerticalLinearProgress}
            variant="determinate"
            color="secondary"
            value={agentCommission}
          />

          <Box sx={{ ml: 1, height: "100%" }}>
            <AnimatedProgressBar
              Component={MarketVerticalLinearProgress}
              variant="determinate"
              color="secondary"
              value={sentProposal.proposal.brokerage_commission_ratio}
            />
          </Box>
          <Row
            sx={{
              ml: 2,
              "& > .MuiBox-root:nth-of-type(n + 2)": { ml: 2 },
            }}
          >
            <RatioIndicator
              title="Agent Commission"
              value={agentCommission}
              circleColor={theme.palette.secondary.main}
            />
            <RatioIndicator
              title="Brokerage Commission"
              value={sentProposal.proposal.brokerage_commission_ratio}
              circleColor={MARKET_PROGRESSBAR_COLOR}
            />
          </Row>
        </Row>
      </Grid>
    </Grid>
  )
}

function RatioIndicator({ title, value, circleColor }) {
  return (
    <Column>
      <Row sx={{ alignItems: "center" }}>
        <Typography variant="subtitle1">{title}</Typography>
        <CircleIcon htmlColor={circleColor} sx={{ ml: 1, fontSize: "10px" }} />
      </Row>
      <Typography variant="h6">{value}</Typography>
    </Column>
  )
}
