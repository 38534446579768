import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutline"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { useDebounce } from "usehooks-ts"

import { axiosClient, validateZipcode } from "../../../common/utils"

export default function ZipcodeTextField({
  zipcode,
  setZipcode,
  zipcodeError,
  setZipcodeError,
  sx,
}) {
  const debouncedZipcode = useDebounce(zipcode)
  const ZIPCODE_STATUS = {
    empty: 1,
    validating: 2,
    valid: 3,
    invalid: 4,
  }
  const [zipcodeStatus, setZipcodeStatus] = useState(ZIPCODE_STATUS.empty)

  function handleZipcodeChanged(e) {
    setZipcode(e.target.value)
    setZipcodeError(null)
  }

  useEffect(
    () => {
      if (debouncedZipcode) {
        if (validateZipcode(debouncedZipcode)) {
          setZipcodeStatus(ZIPCODE_STATUS.validating)
          axiosClient
            .post("/customers/api/validate-zipcode/", { zip_code: zipcode })
            .then(() => setZipcodeStatus(ZIPCODE_STATUS.valid))
            .catch(() => {
              setZipcodeStatus(ZIPCODE_STATUS.invalid)
              setZipcodeError("Invalid zipcode")
            })
        }
      } else {
        setZipcodeStatus(ZIPCODE_STATUS.empty)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedZipcode]
  )

  return (
    <TextField
      label="Zipcode"
      value={zipcode}
      onChange={handleZipcodeChanged}
      error={!!zipcodeError}
      helperText={zipcodeError}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {zipcodeStatus === ZIPCODE_STATUS.validating ? (
              <CircularProgress
                variant="indeterminate"
                size={24}
                color="primary"
              />
            ) : zipcodeStatus === ZIPCODE_STATUS.valid ? (
              <CheckCircleOutlinedIcon color="primary" />
            ) : zipcodeStatus === ZIPCODE_STATUS.invalid ? (
              <ErrorOutlinedIcon color="error" />
            ) : null}
          </InputAdornment>
        ),
      }}
      fullWidth
      sx={sx}
    />
  )
}

export function useZipcodeFieldState() {
  const [zipcode, setZipcode] = useState("")
  const [zipcodeError, setZipcodeError] = useState(null)

  return {
    zipcode,
    setZipcode,
    zipcodeError,
    setZipcodeError,
  }
}
