import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router"

import Row from "../../common/components/Row"
import { useNavigateWithNext } from "../../common/utils"
import { selectToken, selectUserType } from "./slice"
import { getUserHomeURL } from "./utils"

export default function UsersLayout() {
  const token = useSelector(selectToken)
  const userType = useSelector(selectUserType)
  const location = useLocation()
  const navigateWithNext = useNavigateWithNext()

  useEffect(
    () => {
      const stopNavigatePatterns = [
        /* Allow switching tokens without needing to log out */ "_impersonate",
        /* Allow agent to stay on the last step of signup wizard */ "signup/agent",
      ]
      if (
        !token ||
        !userType ||
        stopNavigatePatterns.some(
          (pattern) => location.pathname.indexOf(pattern) !== -1
        )
      ) {
        return
      }
      navigateWithNext(getUserHomeURL(userType))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, userType]
  )

  return (
    <>
      <Row>
        <Outlet />
      </Row>
    </>
  )
}
