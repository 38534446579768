import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import InboxActiveIcon from "../../resources/icons/inbox-active.svg"
import Column from "../Column"

export default function CenteredLoadingAndNoConversationsIndicator({
  isLoadingConversations,
  noConversationsTitle,
  noConversationsSubtitle,
}) {
  return (
    <Column
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "100%",
      }}
    >
      {isLoadingConversations ? (
        <CircularProgress variant="indeterminate" color="primary" size={96} />
      ) : (
        <>
          <img
            src={InboxActiveIcon}
            alt="chat bubble"
            style={{ width: "128px", height: "128px" }}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            {noConversationsTitle}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }} color="text.secondary2">
            {noConversationsSubtitle}
          </Typography>
        </>
      )}
    </Column>
  )
}

export function shouldShowIndicator(conversationsLength, hasAnyConversations) {
  return conversationsLength === 0 && !hasAnyConversations
}
