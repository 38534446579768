import Typography from "@mui/material/Typography"

import { getIconForPerkCategory } from "../../../common/components/PerksColumns"
import Row from "../../../common/components/Row"

export default function Perk({ perk }) {
  const Icon = getIconForPerkCategory(perk.category)

  return (
    <Row sx={{ alignItems: "center" }}>
      <Icon />
      <Typography variant="body1" sx={{ ml: 1 }}>
        {perk.title}
      </Typography>
    </Row>
  )
}
