import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutline"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useCallback, useContext, useEffect, useState } from "react"
import { unstable_useBlocker } from "react-router-dom"
import { useWizard } from "react-use-wizard"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import { useIsMobile } from "../../../common/utils"
import {
  UnfinishedDataContext,
  useTrackUserQuitUnfinishedSignup,
} from "../utils"

export default function ConfirmLeaveSignupWizardDialog({
  blockingStepIndex = 1,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { isTrackingQuitUnfinished, trackQuitUnfinshedSignup } =
    useTrackUserQuitUnfinishedSignup()
  const unfinishedData = useContext(UnfinishedDataContext)

  const { activeStep } = useWizard()
  const isMobile = useIsMobile()
  const shouldBlockNavigation = useCallback(
    ({ currentLocation, nextLocation }) => {
      const allowedLeaveURLs = ["phone-confirmation-sent", "/agent/home"]
      const nextPathname = nextLocation.pathname
      return (
        activeStep >= blockingStepIndex &&
        currentLocation.pathname !== nextPathname &&
        allowedLeaveURLs.every(
          (allowed) => nextPathname.indexOf(allowed) === -1
        )
      )
    },
    [activeStep, blockingStepIndex]
  )
  const navigationBlocker = unstable_useBlocker(shouldBlockNavigation)

  function handleDialogClosed() {
    navigationBlocker.reset?.()
    setIsOpen(false)
  }

  function handleImSureButtonClicked() {
    trackQuitUnfinshedSignup(unfinishedData).finally(() =>
      navigationBlocker.proceed?.()
    )
  }

  useEffect(() => {
    if (
      navigationBlocker.state === "blocked" &&
      activeStep < blockingStepIndex
    ) {
      navigationBlocker.reset()
    }
  }, [navigationBlocker, activeStep, blockingStepIndex])

  useEffect(() => {
    if (navigationBlocker.state === "blocked") {
      setIsOpen(true)
    }
  }, [navigationBlocker])

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleDialogClosed}
      maxWidth="xs"
      fullScreen={isMobile}
    >
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <HaloCircularIcon Icon={ErrorOutlinedIcon} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Are you sure you don't want to continue signing up?
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            All unsaved progress will be lost
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          onClick={handleImSureButtonClicked}
          isLoading={isTrackingQuitUnfinished}
        >
          Yes I'm sure
        </LoadingButton>
        <Button variant="contained" onClick={handleDialogClosed}>
          Continue signup
        </Button>
      </DialogActions>
    </Dialog>
  )
}
