import { useSelector } from "react-redux"

import { useAuthorizedAxiosClient } from "../../../common/utils"
import { selectCurrentBrokerage } from "../slice"

export function useUpdateCandidate() {
  const axios = useAuthorizedAxiosClient()
  const currentBrokerage = useSelector(selectCurrentBrokerage)

  return (candidateParam, data) => {
    return axios.put(
      `/customers/api/candidates/${currentBrokerage.id}/update/${candidateParam.id}/?type=${candidateParam.type}`,
      data
    )
  }
}
