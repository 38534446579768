import Snackbar from "@mui/material/Snackbar"

export default function SuccessSnackbar({
  message = "Changes saved",
  open,
  onClose,
  horizontalAnchor = "right",
}) {
  return (
    <Snackbar
      message={message}
      color="primary"
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: horizontalAnchor,
        vertical: "bottom",
      }}
    />
  )
}
