// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import * as turf from "@turf/turf"
import "mapbox-gl/dist/mapbox-gl.css"
import { useEffect, useRef, useState } from "react"

import BrokerageIcon from "../common/resources/icons/brokerage.png"
import PinIcon from "./resources/icons/pin.png"
import { fixElasticSearchPoint } from "./utils"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export function useMapLoader(mapContainer) {
  const map = useRef()
  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    if (map.current) {
      return
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-98.02979920000001, 43.7094283],
      zoom: 4.3,
    })
    map.current.on("load", () => {
      setMapLoaded(true)
    })
  })

  return { map, mapLoaded }
}

export function addImageLayerToMap(
  map,
  imageURL,
  imageName,
  sourceName,
  layerID,
  iconSize
) {
  map.current.loadImage(imageURL, (error, image) => {
    if (error) {
      throw error
    }
    map.current.addImage(imageName, image)
    map.current.addLayer({
      id: layerID,
      type: "symbol",
      source: sourceName,
      layout: {
        "icon-image": imageName,
        "icon-size": iconSize,
      },
    })
  })
}

export function addZipcodeCircleToMap(
  map,
  centerPoint,
  radius,
  radiusUnits,
  sourceID,
  theme
) {
  const zipcodeCircleAreaCenter = fixElasticSearchPoint(centerPoint)
  const zipcodeAreaCircle = turf.circle(zipcodeCircleAreaCenter, radius, {
    steps: 80,
    units: radiusUnits === "mi" ? "miles" : "kilometers",
  })

  map.current.addSource(sourceID, {
    type: "geojson",
    data: zipcodeAreaCircle,
  })

  map.current.addLayer({
    id: sourceID,
    type: "fill",
    source: sourceID,
    paint: {
      "fill-color": theme.palette.otherwise.mapPointOutline,
      "fill-opacity": 0.3,
    },
  })
}

export function addZipcodeCircleOutlineToMap(map, layerID, sourceID, theme) {
  map.current.addLayer({
    id: layerID,
    type: "line",
    source: sourceID,
    paint: {
      "line-color": theme.palette.otherwise.mapPoint,
      "line-opacity": 0.5,
      "line-width": 1,
    },
    layout: {},
  })
}

export function addCircleLayerToMap(map, layerID, data) {
  map.current.addSource(layerID, {
    type: "geojson",
    data: data,
  })

  map.current.addLayer({
    id: layerID,
    type: "circle",
    source: layerID,
    paint: {
      "circle-color": ["get", "color"],
      "circle-radius": 16,
      "circle-opacity": 1,
      "circle-stroke-width": ["get", "outlineWidth"],
      "circle-stroke-color": ["get", "outlineColor"],
      "circle-stroke-opacity": 0.5,
    },
  })

  addPopupToLayer(
    map,
    layerID,
    (feature) => feature.properties.label,
    (feature) => feature.properties.coordinates
  )
}

export function addPopupToLayer(
  map,
  layerID,
  getLabelFromFeature,
  getLocationFromFeature
) {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  })

  map.current.on("mousemove", layerID, (e) => {
    map.current.getCanvas().style.cursor = "pointer"

    const feature = e.features[0]

    const coordinates = JSON.parse(getLocationFromFeature(feature)).coordinates

    popup
      .setLngLat(coordinates)
      .setHTML(getLabelFromFeature(feature))
      .addTo(map.current)
  })

  map.current.on("mouseleave", layerID, () => {
    map.current.getCanvas().style.cursor = ""
    popup.remove()
  })
}

export function addBrokerageMarkerToMap(
  map,
  { image, company, outlineColor, location },
  addPopup = true
) {
  let imageURL = image || BrokerageIcon

  let brokerageImageMarkerElem
  if (image) {
    brokerageImageMarkerElem = document.createElement("img")
    brokerageImageMarkerElem.src = imageURL
    brokerageImageMarkerElem.className = "brokerage-image"
  } else {
    brokerageImageMarkerElem = document.createElement("div")
    brokerageImageMarkerElem.className = "brokerage-image no-brand"
    brokerageImageMarkerElem.style["backgroundImage"] = `url(${imageURL})`
  }
  if (outlineColor) {
    brokerageImageMarkerElem.className += " other-brokerage"
  }

  let popup = undefined
  if (addPopup) {
    popup = new mapboxgl.Popup({ closeButton: false }).setText(company)
  }

  return new mapboxgl.Marker(brokerageImageMarkerElem)
    .setLngLat(location.coordinates)
    .setPopup(popup)
    .addTo(map.current)
}

export function useAddBrokerageMarkersToMap(
  map,
  mapLoaded,
  brokerages,
  isLoadingBrokerages
) {
  const [brokerageIconMarkers, setBrokerageIconMarkers] = useState([])

  useEffect(
    () => {
      if (!mapLoaded || isLoadingBrokerages) {
        return
      }
      if (brokerages.length > 0) {
        const markers = []
        brokerages
          .slice()
          .sort((brokerage1, brokerage2) => {
            // bring brokerages with icons on top in the map
            if (brokerage1.brand) {
              return 1
            } else if (brokerage2.brand) {
              return -1
            }
            return 0
          })
          .forEach((brokerage) => {
            // use markers for brokerage icons instead of image layers
            // because we'll need a layer per image (brand)
            // also can't style icon layers (to add borders)
            markers.push(addBrokerageMarkerToMap(map, brokerage))
          })
        setBrokerageIconMarkers(markers)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapLoaded, isLoadingBrokerages, brokerages]
  )

  useEffect(() => {
    return () => {
      brokerageIconMarkers.forEach((marker) => marker.remove())
    }
  }, [brokerageIconMarkers])
}

export function addPinIconToMap(
  map,
  centerCoordinates,
  theme,
  sourceID,
  imageName,
  imageLayerID
) {
  map.current.addSource(sourceID, {
    type: "geojson",
    data: createAgentHomeLocationSource(centerCoordinates, theme),
  })
  addImageLayerToMap(map, PinIcon, imageName, sourceID, imageLayerID, 0.25)
}

export function fitMapToCircle(
  map,
  mapCenter,
  distanceFromCenter,
  distanceUnits,
  zoomOut
) {
  const zipcodeCircleAreaCenter = fixElasticSearchPoint(mapCenter)
  const zipcodeAreaCircle = turf.circle(
    zipcodeCircleAreaCenter,
    distanceFromCenter,
    { steps: 80, units: getTurfDistanceUnits(distanceUnits) }
  )

  let fitBoundsTarget = zipcodeAreaCircle
  if (zoomOut) {
    fitBoundsTarget = turf.buffer(zipcodeAreaCircle, distanceFromCenter / 5, {
      units: getTurfDistanceUnits(distanceUnits),
    })
  }
  map.current.fitBounds(turf.bbox(fitBoundsTarget))
}

export function getTurfDistanceUnits(distanceUnits) {
  return distanceUnits === "mi" ? "miles" : "kilometers"
}

export function createAgentHomeLocationSource(centerCoordinates, theme) {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: centerCoordinates,
        },
        properties: {
          label: "Home",
          coordinates: centerCoordinates,
          color: theme.palette.otherwise.mapPoint,
          outlineColor: theme.palette.otherwise.mapPointOutline,
          outlineWidth: 8,
        },
      },
    ],
  }
}

export function removeLayer(mapRef, layerName) {
  if (mapRef.getLayer(layerName)) {
    mapRef.removeLayer(layerName)
  }
}

export function removeSource(mapRef, sourceID) {
  if (mapRef.getSource(sourceID)) {
    mapRef.removeSource(sourceID)
  }
}

export function removeImage(mapRef, imageName) {
  if (mapRef.hasImage(imageName)) {
    mapRef.removeImage(imageName)
  }
}
