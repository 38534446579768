import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { styled } from "@mui/material/styles"
import { useState } from "react"

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const BoldTableCell = styled(TableCell)(() => ({
  fontWeight: 500,
}))

export function SortableHeaderTableCell({
  children,
  sortTableBy,
  sortDirection,
  columnName,
  sortByColumnWrapper,
}) {
  return (
    <HeaderTableCell
      sortDirection={sortTableBy === columnName ? sortDirection : false}
    >
      <TableSortLabel
        active={sortTableBy === columnName}
        direction={sortTableBy === columnName ? sortDirection : "asc"}
        onClick={sortByColumnWrapper(columnName)}
      >
        {children}
      </TableSortLabel>
    </HeaderTableCell>
  )
}

export function NoResultsTableRow({ columnCount, children }) {
  return (
    <TableRow>
      <TableCell colSpan={columnCount} sx={{ textAlign: "center" }}>
        {children}
      </TableCell>
    </TableRow>
  )
}

export function useTableSort(defaultSortColumn = "created") {
  const [sortTableBy, setSortTableBy] = useState(defaultSortColumn)
  const [sortDirection, setSortDirection] = useState("desc")

  function sortByColumnWrapper(columnName) {
    return () => {
      setSortTableBy(columnName)
      let newSortDirection
      if (columnName === sortTableBy) {
        newSortDirection = sortDirection === "asc" ? "desc" : "asc"
      } else {
        newSortDirection = "asc"
      }
      setSortDirection(newSortDirection)
    }
  }
  return {
    sortTableBy,
    setSortTableBy,
    sortDirection,
    setSortDirection,
    sortByColumnWrapper,
  }
}

export function getOrderingParam(sortDirection, sortTableBy) {
  return `${sortDirection === "desc" ? "-" : ""}${sortTableBy}`
}

export function useTablePagination() {
  const [pageIndex, setPageIndex] = useState(1)
  const [totalResultCount, setTotalResultCount] = useState(0)

  function handlePageChanged(e, newValue) {
    setPageIndex(newValue + 1)
  }
  return { pageIndex, handlePageChanged, totalResultCount, setTotalResultCount }
}
