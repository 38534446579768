import Avatar from "@mui/material/Avatar"

import { AGENT_FIELD_HIDDEN_MARKER } from "../../pages/brokerage/utils"
import DefaultAgentAvatar from "../resources/icons/agent-default.jpg"
import GlassesIcon from "../resources/icons/glasses-active.svg"
import CircularIcon from "./CircularIcon"

export default function UserAvatar({
  profileData,
  isProfileLoading,
  size,
  avatarSX,
  defaultAvatar,
}) {
  return (
    <Avatar
      alt="profile image"
      src={profileData.profile_image || defaultAvatar}
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        bgcolor: (theme) => theme.palette.secondary.main,
        fontSize: `${size / 2}px`,
        ...avatarSX,
      }}
    >
      {!isProfileLoading &&
        !profileData.profile_image &&
        profileData.first_name[0].toUpperCase()}
    </Avatar>
  )
}

export function AgentAvatar({ agent, size = 64 }) {
  return agent.profile_image === AGENT_FIELD_HIDDEN_MARKER ? (
    <CircularIcon
      DirectIcon={
        <img
          src={GlassesIcon}
          alt="anonymous profile"
          style={{ width: size / 2, height: size / 2 }}
        />
      }
      size={size}
    />
  ) : (
    <UserAvatar
      profileData={agent}
      defaultAvatar={DefaultAgentAvatar}
      size={size}
    />
  )
}
