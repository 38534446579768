import BrokerageVerticalSplitLayout from "../../components/BrokerageAgentPreview"
import PhoneConfirmationSent from "../Page"

export default function BrokeragePhoneConfirmationSent() {
  return (
    <BrokerageVerticalSplitLayout>
      <PhoneConfirmationSent />
    </BrokerageVerticalSplitLayout>
  )
}
