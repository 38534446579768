import { useShowBottomNav } from "../../utils"
import Row from "../Row"

export default function MainContainer({ children }) {
  const showBottomNav = useShowBottomNav()

  return (
    <Row
      sx={{
        position: "relative",
        "& > .MuiBox-root": {
          height: showBottomNav ? "calc(100vh - 100px)" : "calc(100vh - 75px)",
        },
      }}
    >
      {children}
    </Row>
  )
}
