import CircleIcon from "@mui/icons-material/Circle"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import * as toSorted from "array.prototype.tosorted"
import { formatDistance } from "date-fns"
import { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "usehooks-ts"

import GoldDiamondIcon from "../../resources/icons/gold-diamond.png"
import { trimLongString, useAuthorizedAxiosClient } from "../../utils"
import BrandAvatar from "../BrandAvatar"
import Row from "../Row"
import { AgentAvatar } from "../UserAvatar"
import ConversationChip from "./ConversationChip"
import ConversationMenuNoItems, {
  CommonConversationMenuItems,
  handleConversationChangedWrapper,
} from "./ConversationMenu"
import IdentityRevealedChip from "./IdentityRevealedChip"
import InboxContext from "./InboxContext"
import { getConversationBrokerageFullName } from "./utils"

export function AgentConversation({
  conversation,
  onConversationChanged,
  onConversationDeleted,
  selectedConversation,
  onClick,
}) {
  const isSelectedConversation =
    selectedConversation && conversation.id === selectedConversation.id

  return (
    <ConversationLayout
      conversation={conversation}
      selectedConversation={selectedConversation}
      name={getConversationBrokerageFullName(conversation)}
      supertitle={conversation.brokerage.company}
      Image={
        <BrandAvatar brand={{ icon: conversation.brokerage.image }} size={48} />
      }
      BottomChip={
        <IdentityRevealedChip
          identityRevealed={conversation.identity_revealed}
          darkBackground={isSelectedConversation}
        />
      }
      onClick={onClick}
      onConversationChanged={onConversationChanged}
      onConversationDeleted={onConversationDeleted}
    />
  )
}

export function CustomerConversation({
  conversation,
  onConversationChanged,
  onConversationDeleted,
  selectedConversation,
  onClick,
}) {
  const isSelectedConversation =
    selectedConversation && conversation.id === selectedConversation.id
  return (
    <ConversationLayout
      conversation={conversation}
      selectedConversation={selectedConversation}
      name={conversation.agent_request.final_name}
      Image={
        <AgentAvatar
          agent={{
            first_name: conversation.agent_request.final_name,
            profile_image: conversation.agent_request.profile_image,
          }}
          size={48}
        />
      }
      BottomChip={
        conversation.has_proposal && (
          <ConversationChip
            icon={
              <img
                src={GoldDiamondIcon}
                style={{ width: "32px", height: "24px" }}
                alt="diamond"
              />
            }
            label="Proposal sent"
            darkBackground={isSelectedConversation}
          />
        )
      }
      onClick={onClick}
      onConversationChanged={onConversationChanged}
      onConversationDeleted={onConversationDeleted}
    />
  )
}

function ConversationLayout({
  conversation,
  name,
  supertitle,
  Image,
  BottomChip,
  onConversationChanged,
  onConversationDeleted,
  selectedConversation,
  onClick,
}) {
  const [isHovered, setIsHovered] = useState(false)
  const [menuAnchorElem, setMenuAnchorElem] = useState(null)

  const inboxContext = useContext(InboxContext)

  const isSelectedConversation =
    selectedConversation && conversation.id === selectedConversation.id

  function handleMenuButtonClicked(e) {
    setMenuAnchorElem(e.currentTarget)
  }

  function handleMenuClosed() {
    setMenuAnchorElem(null)
    setIsHovered(false)
  }

  return (
    <SelectableConversationContainer
      isSelected={isSelectedConversation}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => !menuAnchorElem && setIsHovered(false)}
      onClick={() => onClick(conversation)}
    >
      <Row sx={{ position: "relative" }}>
        {conversation.has_unread_messages && (
          <CircleIcon
            color="secondary"
            sx={{
              position: "absolute",
              top: "20px",
              left: "-12px",
              width: "8px",
              height: "8px",
            }}
          />
        )}
        {Image}
      </Row>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 2,
          ml: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {supertitle && (
              <Typography variant="subtitle2">{supertitle}</Typography>
            )}
            <Typography
              variant="h6"
              fontWeight={
                selectedConversation
                  ? selectedConversation.has_unread_messages
                    ? 500
                    : 400
                  : 400
              }
            >
              {name}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {conversation.last_message_text &&
                trimLongString(conversation.last_message_text, 40)}
            </Typography>
            {BottomChip && (
              <Box sx={{ mt: 1, alignSelf: "flex-start" }}>{BottomChip}</Box>
            )}
          </Box>
          {isHovered ? (
            <IconButton onClick={handleMenuButtonClicked}>
              <MoreVertIcon />
            </IconButton>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="subtitle1">
                {conversation.last_message_date &&
                  formatDistance(
                    new Date(conversation.last_message_date),
                    new Date()
                  )}
              </Typography>
              {conversation[inboxContext.conversationMutedPropertyName] && (
                <NotificationsOffOutlinedIcon
                  fontSize="small"
                  sx={{ color: "text.secondary2", mt: 1 }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <ConversationMenuNoItems
        anchorEl={menuAnchorElem}
        onClose={handleMenuClosed}
      >
        <CommonConversationMenuItems
          conversation={conversation}
          name={name}
          onMuted={handleConversationChangedWrapper(
            conversation,
            onConversationChanged
          )}
          onReported={handleConversationChangedWrapper(
            conversation,
            onConversationChanged
          )}
          onDeleted={onConversationDeleted}
          onClose={handleMenuClosed}
        />
      </ConversationMenuNoItems>
    </SelectableConversationContainer>
  )
}

const ConversationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
  borderRadius: theme.units.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.otherwise.lightBackground,
    cursor: "pointer",
  },
}))

export function SelectableConversationContainer({
  isSelected,
  children,
  ...props
}) {
  return (
    <ConversationContainer
      sx={{
        backgroundColor: (theme) =>
          isSelected ? theme.palette.otherwise.lightBackground : "inherit",
      }}
      {...props}
    >
      {children}
    </ConversationContainer>
  )
}

export function useCommonConversationHandlers({
  conversations,
  setConversations,
  decrementUnreadConversationsCount,
}) {
  const [selectedConversationIndex, setSelectedConversationIndex] =
    useState(null)
  const dispatch = useDispatch()

  function handleConversationSelected(conversationIndex) {
    if (
      selectedConversationIndex &&
      conversationIndex === selectedConversationIndex
    ) {
      return
    }
    setSelectedConversationIndex(conversationIndex)
  }

  function handleConversationClosed() {
    setSelectedConversationIndex(null)
  }

  function handleConversationSelectedWrapper(conversationIndex) {
    return () => handleConversationSelected(conversationIndex)
  }

  function handleConversationUpdated(newConversationData) {
    setConversations(
      conversations.map((conversation) =>
        conversation.id === newConversationData.id
          ? newConversationData
          : conversation
      )
    )
  }

  function handleConversationDeletedWrapper(index) {
    return () => {
      setConversations(conversations.filter((_, i) => i !== index))
    }
  }

  useEffect(
    () => {
      const selectedConversation = conversations[selectedConversationIndex]
      if (!selectedConversation) {
        return
      }
      if (selectedConversation.has_unread_messages) {
        setConversations(
          conversations.map((conversation) =>
            conversation.id === selectedConversation.id
              ? { ...conversation, has_unread_messages: false }
              : conversation
          )
        )
        dispatch(decrementUnreadConversationsCount())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversations, selectedConversationIndex]
  )

  return {
    selectedConversationIndex,
    setSelectedConversationIndex,
    handleConversationSelected,
    handleConversationClosed,
    handleConversationSelectedWrapper,
    handleConversationUpdated,
    handleConversationDeletedWrapper,
  }
}

export function useConversationsLoader(
  url,
  searchQuery,
  selectedConversationType
) {
  const [conversations, setConversations] = useState([])
  const [isLoadingConversations, setIsLoadingConversations] = useState(true)
  const [pageIndex, setPageIndex] = useState(1)
  const [totalConversationCount, setTotalConversationCount] = useState(0)
  const [hasAnyConversations, setHasAnyConversations] = useState(false)
  const debouncedSearchQuery = useDebounce(searchQuery)
  const axios = useAuthorizedAxiosClient()
  const searchParams = useSearchParams()[0]

  function loadConversations(handleConversationSelected) {
    const urlSearchParams = new URLSearchParams()
    urlSearchParams.append("page", pageIndex)
    if (searchQuery) {
      urlSearchParams.append("q", searchQuery)
    }
    if (selectedConversationType) {
      urlSearchParams.append("ordering", selectedConversationType)
    }
    return axios
      .get(url + `?${urlSearchParams.toString()}`)
      .then(({ data }) => {
        const sortedConversations = toSorted(data.results, (c1, c2) =>
          c1.is_concierge_conversation
            ? -1
            : c2.is_concierge_conversation
            ? 1
            : 0
        )
        setConversations(sortedConversations)
        setTotalConversationCount(data.count)
        if (handleConversationSelected) {
          const conversationID = searchParams.get("cid")
          if (conversationID) {
            const conversationIndex = sortedConversations.findIndex(
              (conversation) => conversation.id === parseInt(conversationID)
            )
            if (conversationIndex > -1) {
              handleConversationSelected(conversationIndex)
            }
          }
        }
        return data
      })
      .finally(() => setIsLoadingConversations(false))
  }

  useEffect(
    () => {
      if (!url) {
        return
      }
      loadConversations()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, debouncedSearchQuery, selectedConversationType]
  )

  return {
    conversations,
    setConversations,
    isLoadingConversations,
    loadConversations,
    pageIndex,
    setPageIndex,
    totalConversationCount,
    hasAnyConversations,
    setHasAnyConversations,
  }
}
