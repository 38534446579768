import TextField from "@mui/material/TextField"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"

import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import { handleChangedPositiveOnlyTextField } from "../../../common/utils"
import { VERBOSE_DISTANCE_UNITS } from "./utils"

const MAX_DISTANCE = 100

export default function DistanceUnitsSelector({
  distance,
  onDistanceChanged,
  units,
  onUnitsChanged,
  onError,
}) {
  const distanceError =
    parseInt(distance) > MAX_DISTANCE
      ? `Distance cannot be larger than ${MAX_DISTANCE} ${VERBOSE_DISTANCE_UNITS[units]}`
      : null

  useEffect(
    () => {
      if (distanceError) {
        onError(distanceError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [distanceError]
  )

  return (
    <Column>
      <Row>
        <TextField
          type="number"
          placeholder={units}
          value={distance}
          onChange={onDistanceChanged}
          fullWidth
          sx={{ minWidth: "3em" }}
        />
        <ToggleButtonGroup
          value={units}
          onChange={onUnitsChanged}
          exclusive
          color="primary"
          sx={{
            ml: 2,
          }}
        >
          <ToggleButton value="km">Km</ToggleButton>
          <ToggleButton value="mi">Mi</ToggleButton>
        </ToggleButtonGroup>
      </Row>
      {distanceError && (
        <Typography
          variant="body2"
          color="error.main"
          sx={{ mt: 1, pl: 0.5, fontSize: "0.8rem" }}
        >
          {distanceError}
        </Typography>
      )}
    </Column>
  )
}

export function useDistanceUnitsSelectorState(
  initialDistance = 0,
  initialUnits = "mi"
) {
  const [distance, setDistance] = useState(initialDistance)
  const [units, setUnits] = useState(initialUnits)
  const [distanceError, setDistanceError] = useState(null)

  let distanceFilter
  if (distance && units) {
    distanceFilter = { distance, units }
  }

  function handleDistanceChanged(e) {
    handleChangedPositiveOnlyTextField(e, (newValue) => {
      if (newValue === 0) {
        e.preventDefault()
      } else {
        setDistance(newValue)
      }
    })
  }

  function handleUnitsToggleChanged(_, newUnits) {
    setUnits(newUnits)
  }

  return {
    distance,
    setDistance,
    handleDistanceChanged,
    units,
    setUnits,
    handleUnitsToggleChanged,
    distanceError,
    setDistanceError,
    distanceFilter,
  }
}
