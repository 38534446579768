import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import Column from "../../../common/components/Column"
import { profileLoading, selectProfile } from "../slice"
import FilterTitleDescription from "./FilterTitleDescription"
import ExcludeBrandsSelector, {
  useExcludeBrandsSelectorState,
} from "./exclude-brands-selector"
import { ANCHOR_TOP } from "./zipcode-filter-dialog"

export default function ExcludeBrokeragesDialog({
  open,
  onClose,
  filteredBrokerages,
}) {
  const {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
  } = useExcludeBrandsSelectorState()

  useFillExcludedBrandsFromProfile(setExcludedBrands)

  let processedBrandOptions = processBrandOptions(
    filteredBrokerages,
    excludedBrands
  )

  function handleApplyButtonClicked() {
    onClose(excludedBrands)
  }

  return (
    <Popover
      open={open}
      onClose={() => onClose()}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: ANCHOR_TOP,
        left: 800,
      }}
    >
      <Column
        sx={{
          p: 2,
          width: "350px",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <ExcludeBrandsSelector
            brand={brand}
            onBrandChanged={handleBrandChanged}
            brandInputValue={brandInputValue}
            onBrandInputValueChanged={handleBrandInputValueChanged}
            excludedBrands={excludedBrands}
            handleSelectedBrandDeletedWrapper={
              handleSelectedBrandDeletedWrapper
            }
            options={processedBrandOptions}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 1 }}
          onClick={handleApplyButtonClicked}
        >
          Apply
        </Button>
      </Column>
    </Popover>
  )
}

export function ExcludeBrokeragesFilterTitleDescription() {
  return (
    <FilterTitleDescription
      title="Exclude these brokerages"
      description="These brokerages won't appear in your results"
    />
  )
}

export function processBrandOptions(filteredBrokerages, excludedBrands) {
  const excludedBrandIDS = excludedBrands.map(({ id }) => id)

  return (
    filteredBrokerages
      .map(({ brand }) => brand)
      .filter((brand) => !!brand)
      // remove duplicate brands
      .reduce((acc, brand) => {
        if (!acc.some((innerBrand) => innerBrand.id === brand.id)) {
          acc.push(brand)
        }
        return acc
      }, [])
      .filter((brand) => excludedBrandIDS.indexOf(brand.id) === -1)
      .sort(({ name: name1 }, { name: name2 }) => {
        if (name1 > name2) {
          return 1
        } else if (name2 > name1) {
          return -1
        }
        return 0
      })
  )
}

export function useFillExcludedBrandsFromProfile(setExcludedBrands) {
  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setExcludedBrands(profile.excluded_brands)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, profile]
  )
}
