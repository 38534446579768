import Popover from "@mui/material/Popover"
import { useRef, useState } from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"

export default function CropProfileImagePopover({
  open,
  anchorElem,
  onClosed,
  imgData,
  onCropFinished,
}) {
  const [imageCrop, setImageCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  })
  const imageElemRef = useRef()
  const canvasRef = useRef()
  const ASPECT_RATIO = 1

  function handleCropFinished(crop) {
    const canvas2dContext = canvasRef.current.getContext("2d")
    const image = imageElemRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = window.devicePixelRatio
    canvasRef.current.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvasRef.current.height = Math.floor(crop.height * scaleY * pixelRatio)
    canvas2dContext.imageSmoothingQuality = "high"
    canvas2dContext.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    canvas2dContext.save()
    canvas2dContext.drawImage(
      imageElemRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
    canvas2dContext.restore()
    canvasRef.current.toBlob((blob) => {
      if (!blob) return
      onCropFinished(canvasRef.current.toDataURL(), blob)
    })
  }

  return (
    <Popover
      open={open}
      onClose={onClosed}
      anchorEl={anchorElem}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      sx={{ "& .MuiPopover-paper": { width: "400px" } }}
    >
      <ReactCrop
        crop={imageCrop}
        onChange={(crop) => setImageCrop(crop)}
        onComplete={handleCropFinished}
        aspect={ASPECT_RATIO}
        circularCrop
      >
        <img
          alt="crop"
          ref={imageElemRef}
          src={imgData}
          style={{ maxWidth: "100%" }}
        />
      </ReactCrop>
      <canvas
        ref={canvasRef}
        style={{
          display: "none",
        }}
      />
    </Popover>
  )
}
