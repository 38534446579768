import { useTheme } from "@mui/material/styles"
import mapboxgl from "mapbox-gl"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useCenterMap } from "../../../common/components/CenterMapContainer"
import {
  addBrokerageMarkerToMap,
  addImageLayerToMap,
  addZipcodeCircleOutlineToMap,
  addZipcodeCircleToMap,
  fitMapToCircle,
  removeImage,
  removeLayer,
  removeSource,
  useMapLoader,
} from "../../../common/map-utils"
import CarIcon from "../../../common/resources/icons/car.png"
import PinIcon from "../../../common/resources/icons/pin.png"
import { axiosClient } from "../../../common/utils"
import { selectIsAgent } from "../../users/slice"
import { profileLoading, selectProfile } from "../slice"
import "./styles.css"

export default function PathFromHomeToBrokerageMap({
  brokerage,
  isLoading,
  mapContainer,
  parentCenterMap,
  onMapCentered,
}) {
  const [routeData, setRouteData] = useState(null)
  const profile = useSelector(selectProfile)
  const { map, mapLoaded } = useMapLoader(mapContainer)
  const userIsAgent = useSelector(selectIsAgent)
  const isProfileLoading = useSelector(profileLoading) && userIsAgent
  const theme = useTheme()

  function getBrokerageCoordinates() {
    return brokerage.location?.coordinates
  }

  function getAgentLocation() {
    let agentCoordinates
    const brokerageCoordinates = getBrokerageCoordinates()
    if (userIsAgent) {
      agentCoordinates = profile.location.coordinates
    } else {
      agentCoordinates = [
        brokerageCoordinates[0] + 0.05,
        brokerageCoordinates[1] - 0.1,
      ]
    }
    return { type: "Point", coordinates: agentCoordinates }
  }

  function getDistanceFromWork() {
    if (userIsAgent) {
      return profile.distance_from_work
    }
    return 20
  }

  function getDistanceUnits() {
    if (userIsAgent) {
      return profile.distance_units
    }
    return "mi"
  }

  function centerMap() {
    fitMapToCircle(
      map,
      getAgentLocation(),
      getDistanceFromWork(),
      getDistanceUnits(),
      false
    )
  }

  useEffect(
    () => {
      if (isLoading || isProfileLoading || !getBrokerageCoordinates()) return

      const agentCoordindates = getAgentLocation().coordinates
      const brokerageCoordinates = getBrokerageCoordinates()

      axiosClient
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${agentCoordindates[0]},${agentCoordindates[1]};${brokerageCoordinates[0]},${brokerageCoordinates[1]}`,
          {
            params: {
              geometries: "geojson",
              access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
            },
          }
        )
        .then(({ data }) => {
          setRouteData(data)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brokerage, isProfileLoading]
  )

  useEffect(
    () => {
      if (!mapLoaded || isProfileLoading || isLoading || !routeData) return

      const ZIPCODE_CIRCLE_LAYER_ID = "zipcode-circle"
      const ZIPCODE_CIRCLE_OUTLINE_LAYER_ID = "zipcode-circle-outline"

      addZipcodeCircleToMap(
        map,
        getAgentLocation(),
        getDistanceFromWork(),
        getDistanceUnits(),
        ZIPCODE_CIRCLE_LAYER_ID,
        theme
      )

      addZipcodeCircleOutlineToMap(
        map,
        ZIPCODE_CIRCLE_OUTLINE_LAYER_ID,
        ZIPCODE_CIRCLE_LAYER_ID,
        theme
      )

      const PIN_IMAGE_NAME = "pin-icon"
      const PIN_ICON_LAYER_ID = "pin-icon"
      map.current.addSource(PIN_ICON_LAYER_ID, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: getAgentLocation(),
              properties: {},
            },
          ],
        },
      })
      addImageLayerToMap(
        map,
        PinIcon,
        PIN_IMAGE_NAME,
        PIN_ICON_LAYER_ID,
        PIN_ICON_LAYER_ID,
        0.25
      )

      addBrokerageMarkerToMap(map, brokerage, false)

      const ROUTE_LINE_LAYER_ID = "route-line"
      const route = routeData.routes[0]
      const ROUTE_COORDS = route.geometry.coordinates
      map.current.addSource(ROUTE_LINE_LAYER_ID, {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: ROUTE_COORDS,
          },
        },
      })
      map.current.addLayer({
        id: ROUTE_LINE_LAYER_ID,
        type: "line",
        source: ROUTE_LINE_LAYER_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": theme.palette.otherwise.mapPoint,
          "line-width": 5,
          "line-opacity": 0.75,
        },
      })

      const distanceMarkerElem = document.createElement("div")
      const carImageElem = document.createElement("img")
      carImageElem.src = CarIcon
      const distanceTextElem = document.createElement("span")
      const travelTimeMins = route.duration / 60
      distanceTextElem.appendChild(
        document.createTextNode(`${Math.round(travelTimeMins)} mins`)
      )
      distanceMarkerElem.className = "distance-marker"
      distanceMarkerElem.appendChild(carImageElem)
      distanceMarkerElem.append(distanceTextElem)

      const markerRoutePointIndex = Math.round(ROUTE_COORDS.length / 2)
      new mapboxgl.Marker(distanceMarkerElem)
        .setLngLat(ROUTE_COORDS[markerRoutePointIndex])
        .addTo(map.current)

      centerMap()

      const mapRef = map.current

      return () => {
        removeLayer(mapRef, ZIPCODE_CIRCLE_LAYER_ID)
        removeLayer(mapRef, ZIPCODE_CIRCLE_OUTLINE_LAYER_ID)
        removeLayer(mapRef, PIN_ICON_LAYER_ID)
        removeLayer(mapRef, ROUTE_LINE_LAYER_ID)
        removeImage(mapRef, PIN_IMAGE_NAME)
        removeSource(mapRef, ZIPCODE_CIRCLE_LAYER_ID)
        removeSource(mapRef, PIN_ICON_LAYER_ID)
        removeSource(mapRef, ROUTE_LINE_LAYER_ID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapLoaded, isLoading, isProfileLoading, routeData]
  )

  useCenterMap(parentCenterMap, centerMap, onMapCentered)
}
