import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

export default function LabeledArrowToggleButton({
  label,
  emLabel,
  toggled,
  onClick,
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2">
        {label}
        {emLabel && <strong>{emLabel}</strong>}
      </Typography>
      <IconButton size="small" sx={{ ml: 1 }} onClick={onClick}>
        {toggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
  )
}
