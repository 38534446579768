import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"

import { BorderedIconTitleSubtitleButton } from "../../../common/components/BorderedIconTitleSubtitle"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"
import BrokerageIcon from "../../../common/resources/icons/brokerage-purple.png"
import PersonPrimaryIcon from "../../../common/resources/icons/person-primary.png"

export default function SignupUserTypeChoice() {
  const USER_TYPE_BROKERAGE = 0
  const USER_TYPE_AGENT = 1
  const navigate = useNavigate()

  function handleSignUpButtonClickedWrapper(userType) {
    return () => {
      navigate(
        userType === USER_TYPE_AGENT
          ? "/users/signup/agent/"
          : "/users/signup/brokerage/"
      )
    }
  }

  return (
    <BuildingVerticalSplitLayout verticalCenterContent>
      <Typography variant="h4">Welcome</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Select the type of account you want to sign up with
      </Typography>
      <Box
        sx={{
          mt: 4,
          "& .MuiPaper-root:nth-of-type(n + 2)": {
            mt: 2,
          },
        }}
      >
        <BorderedIconTitleSubtitleButton
          title="Sign up as a Brokerage"
          subtitle="Find amazing agents and grow your company"
          onClick={handleSignUpButtonClickedWrapper(USER_TYPE_BROKERAGE)}
          Icon={() => (
            <img
              src={BrokerageIcon}
              alt="building"
              style={{ width: "20px", height: "20px" }}
            />
          )}
          showActiveCheckmark={false}
        />
        <BorderedIconTitleSubtitleButton
          title="Sign up as an Agent"
          subtitle="Find your best-fitting brokerage and build your career"
          onClick={handleSignUpButtonClickedWrapper(USER_TYPE_AGENT)}
          Icon={() => (
            <img
              src={PersonPrimaryIcon}
              style={{ width: "20px", height: "20px" }}
              alt="agent"
            />
          )}
          showActiveCheckmark={false}
        />
      </Box>
    </BuildingVerticalSplitLayout>
  )
}
