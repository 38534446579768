import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { Wizard, useWizard } from "react-use-wizard"

import ActionButtonsContainer from "../../../common/components/ActionButtonsContainer"
import BrokerageVerticalSplitLayout from "../../users/components/BrokerageAgentPreview"
import { selectIsAnonymous } from "../../users/slice"
import { useTrackSignupWizards } from "../utils"
import {
  ActionButtons as BasicInfoActionButtons,
  ContentSection as BasicInfoContentSection,
  HeaderSection as BasicInfoHeaderSection,
  useCommonBasicInfoState,
} from "./basic-info/Common"
import {
  ActionButtons as ContactInfoActionButtons,
  ContentSection as ContactInfoContentSection,
  HeaderSection as ContactInfoHeaderSection,
} from "./contact-info/Common"
import {
  ActionButtons as DescribeActionButtons,
  ContentSection as DescribeContentSection,
  HeaderSection as DescribeHeaderSection,
} from "./describe/Common"
import {
  ActionButtons as MediaActionButtons,
  ContentSection as MediaContentSection,
  HeaderSection as MediaHeaderSection,
} from "./media/Common"
import {
  ActionButtons as PerksActionButtons,
  ContentSection as PerksContentSection,
  HeaderSection as PerksHeaderSection,
} from "./perks/Common"

export default function CreateBrokerageWizard({
  onReturn,
  onBrokerageCreated,
}) {
  const {
    company,
    setCompany,
    address,
    setAddress,
    addressInput,
    setAddressInput,
    addressOptions,
    setAddressOptions,
    geoLocation,
    setGeoLocation,
    isQueryingPlaceDetails,
    setIsQueryingPlaceDetails,
    images,
    setImages,
    selectedPerks,
    setSelectedPerks,
    yearFounded,
    setYearFounded,
    description,
    setDescription,
    website,
    setWebsite,
    email,
    setEmail,
    phone,
    setPhone,
    zipcode,
    setZipcode,
    zipcodeError,
    setZipcodeError,
    imageName,
    onImageNameChanged,
    imageData,
    onImageDataChanged,
    debouncedSearchQuery,
  } = useCommonBasicInfoState()

  return (
    <Wizard>
      <BasicInfoStep
        address={address}
        setAddress={setAddress}
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        addressOptions={addressOptions}
        setAddressOptions={setAddressOptions}
        company={company}
        setCompany={setCompany}
        zipcode={zipcode}
        setZipcode={setZipcode}
        zipcodeError={zipcodeError}
        setZipcodeError={setZipcodeError}
        debouncedSearchQuery={debouncedSearchQuery}
        imageData={imageData}
        onImageDataChanged={onImageDataChanged}
        imageName={imageName}
        onImageNameChanged={onImageNameChanged}
        isQueryingPlaceDetails={isQueryingPlaceDetails}
        setIsQueryingPlaceDetails={setIsQueryingPlaceDetails}
        setGeoLocation={setGeoLocation}
        onReturn={onReturn}
      />
      <AddMediaStep images={images} setImages={setImages} />
      <PerksStep
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
      <DescribeStep
        description={description}
        setDescription={setDescription}
        yearFounded={yearFounded}
        setYearFounded={setYearFounded}
      />
      <ContactInfoStep
        addressInput={addressInput}
        company={company}
        zipcode={zipcode}
        description={description}
        email={email}
        setEmail={setEmail}
        geoLocation={geoLocation}
        image={imageData}
        imageName={imageName}
        images={images}
        phone={phone}
        setPhone={setPhone}
        website={website}
        setWebsite={setWebsite}
        selectedPerks={selectedPerks}
        yearFounded={yearFounded}
        onBrokerageCreated={onBrokerageCreated}
      />
    </Wizard>
  )
}

function BasicInfoStep({
  imageName,
  onImageNameChanged,
  imageData,
  onImageDataChanged,
  address,
  setAddress,
  company,
  setCompany,
  zipcode,
  setZipcode,
  zipcodeError,
  setZipcodeError,
  addressInput,
  setAddressInput,
  addressOptions,
  setAddressOptions,
  isQueryingPlaceDetails,
  setIsQueryingPlaceDetails,
  setGeoLocation,
  debouncedSearchQuery,
  onReturn,
}) {
  const { nextStep, activeStep } = useWizard()
  useTrackSignupWizards(
    activeStep + 1,
    "What is your brokerage name and address?"
  )

  return (
    <BrokerageVerticalSplitLayout>
      <BasicInfoHeaderSection
        imageName={imageName}
        onImageNameChanged={onImageNameChanged}
        imageData={imageData}
        onImageDataChanged={onImageDataChanged}
      />
      <BasicInfoContentSection
        address={address}
        setAddress={setAddress}
        company={company}
        setCompany={setCompany}
        zipcode={zipcode}
        setZipcode={setZipcode}
        zipcodeError={zipcodeError}
        setZipcodeError={setZipcodeError}
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        addressOptions={addressOptions}
        setAddressOptions={setAddressOptions}
        debouncedSearchQuery={debouncedSearchQuery}
      />
      <ActionButtonsContainer>
        <BasicInfoActionButtons
          address={address}
          company={company}
          imageData={imageData}
          isQueryingPlaceDetails={isQueryingPlaceDetails}
          setIsQueryingPlaceDetails={setIsQueryingPlaceDetails}
          setGeoLocation={setGeoLocation}
          onPreviousButtonClicked={onReturn}
          onGoogleQueryFinished={() => nextStep()}
        />
      </ActionButtonsContainer>
    </BrokerageVerticalSplitLayout>
  )
}

function AddMediaStep({ images, setImages }) {
  const { previousStep, nextStep, activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Add media to your brokerage")

  return (
    <BrokerageVerticalSplitLayout>
      <MediaHeaderSection />
      <MediaContentSection images={images} setImages={setImages} />
      <ActionButtonsContainer>
        <MediaActionButtons
          images={images}
          onPreviousButtonClicked={() => previousStep()}
          onNextButtonClicked={() => nextStep()}
        />
      </ActionButtonsContainer>
    </BrokerageVerticalSplitLayout>
  )
}

function PerksStep({ selectedPerks, setSelectedPerks }) {
  const { previousStep, nextStep, activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Add perks to your brokerage")

  return (
    <BrokerageVerticalSplitLayout verticalCenter={false}>
      <PerksHeaderSection />
      <PerksContentSection
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
      <ActionButtonsContainer>
        <PerksActionButtons
          perks={selectedPerks}
          onPreviousButtonClicked={() => previousStep()}
          onNextButtonClicked={() => nextStep()}
        />
      </ActionButtonsContainer>
    </BrokerageVerticalSplitLayout>
  )
}

function DescribeStep({
  description,
  setDescription,
  yearFounded,
  setYearFounded,
}) {
  const { nextStep, previousStep, activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Describe your brokerage")

  return (
    <BrokerageVerticalSplitLayout>
      <DescribeHeaderSection />
      <DescribeContentSection
        description={description}
        setDescription={setDescription}
        yearFounded={yearFounded}
        setYearFounded={setYearFounded}
      />
      <ActionButtonsContainer>
        <DescribeActionButtons
          description={description}
          yearFounded={yearFounded}
          onPreviousButtonClicked={() => previousStep()}
          onNextButtonClicked={() => nextStep()}
        />
      </ActionButtonsContainer>
    </BrokerageVerticalSplitLayout>
  )
}

function ContactInfoStep({
  addressInput,
  company,
  zipcode,
  description,
  email,
  setEmail,
  geoLocation,
  image,
  imageName,
  images,
  selectedPerks,
  phone,
  setPhone,
  website,
  setWebsite,
  yearFounded,
  onBrokerageCreated,
}) {
  const { previousStep, activeStep } = useWizard()
  const navigate = useNavigate()
  const isAnonymous = useSelector(selectIsAnonymous)
  useTrackSignupWizards(
    activeStep + 1,
    "Add contact information to your brokerage"
  )

  function handleCreatingBrokerageFinished(brokerage) {
    if (isAnonymous) {
      onBrokerageCreated(brokerage)
    } else {
      navigate("/brokerage/dashboard")
    }
  }

  return (
    <BrokerageVerticalSplitLayout>
      <ContactInfoHeaderSection />
      <ContactInfoContentSection
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        website={website}
        setWebsite={setWebsite}
      />
      <ActionButtonsContainer>
        <ContactInfoActionButtons
          addressInput={addressInput}
          company={company}
          zipcode={zipcode}
          description={description}
          email={email}
          geoLocation={geoLocation}
          image={image}
          imageName={imageName}
          images={images}
          phone={phone}
          selectedPerks={selectedPerks}
          website={website}
          yearFounded={yearFounded}
          onPreviousButtonClicked={() => previousStep()}
          onCreatingBrokerageFinished={handleCreatingBrokerageFinished}
        />
      </ActionButtonsContainer>
    </BrokerageVerticalSplitLayout>
  )
}
