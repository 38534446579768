import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { alpha, useTheme } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useEffect, useRef, useState } from "react"

import { useHammerSwipeHandler } from "../utils"
import Column from "./Column"
import Row from "./Row"

export default function BrokerageMediaGallery({ videos = [], images }) {
  const [clickedMediaIndex, setClickedMediaIndex] = useState(null)
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"))

  const mediaShownInitially = isMediumUp ? 4 : 3
  const allMedia = videos.concat(images)
  const totalMediaLength = allMedia.length
  const remainingMedia = totalMediaLength - mediaShownInitially
  const mediaElemStyles = {
    width: "100%",
    aspectRatio: "1/1",
    borderRadius: theme.units.borderRadius,
    cursor: "pointer",
  }

  function handleMediaClickedWrapper(index) {
    return () => {
      setClickedMediaIndex(index)
    }
  }

  return (
    <Grid container spacing={2}>
      {totalMediaLength === 0 ? (
        <Grid xs={12}>
          <Typography variant="body1" color="text.secondary2">
            No media found for this brokerage.
          </Typography>
        </Grid>
      ) : (
        allMedia.slice(0, mediaShownInitially).map(({ image, file }, index) => (
          <MediaPreview
            key={image || file}
            remainingMedia={index === mediaShownInitially - 1 && remainingMedia}
            onClick={handleMediaClickedWrapper(index)}
          >
            {image ? (
              <img
                src={image}
                style={{ ...mediaElemStyles }}
                alt="brokerage media"
              />
            ) : (
              <Box sx={{ position: "relative" }}>
                <VideoPlayIcon />
                <StyledVideo src={file} />
              </Box>
            )}
          </MediaPreview>
        ))
      )}
      <MediaNavOverlay
        open={clickedMediaIndex !== null}
        onClose={() => setClickedMediaIndex(null)}
        media={allMedia}
        clickedImageIndex={clickedMediaIndex}
      />
    </Grid>
  )
}

function MediaPreview({ children, remainingMedia, onClick }) {
  const theme = useTheme()

  return (
    <Grid xs={4} md={3}>
      <Row
        sx={{
          position: "relative",
          "& img": {
            "&:hover": {
              outline: `4px solid ${theme.palette.otherwise.darkBackground}`,
            },
          },
        }}
        onClick={onClick}
      >
        {remainingMedia ? (
          <DarkFilm
            sx={{
              "&:hover": { backgroundColor: alpha("#000000", 0.4) },
            }}
          >
            <Row
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" color="white">
                +{remainingMedia}
              </Typography>
            </Row>
          </DarkFilm>
        ) : null}
        {children}
      </Row>
    </Grid>
  )
}

function MediaNavOverlay({ open, onClose, media, clickedImageIndex }) {
  const [mediaIndex, setMediaIndex] = useState(0)
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"))
  const mediaContainerRef = useRef()

  const currentMedia = media[mediaIndex]
  const mediaElemStyles = {
    width: isMediumUp ? "800px" : "100vw",
  }

  useHammerSwipeHandler(
    mediaContainerRef,
    mediaIndex,
    handleNavigateRight,
    false,
    handleNavigateLeft,
    false
  )

  function handleCloseButtonClicked() {
    setMediaIndex(0)
    onClose()
  }

  function handleNavigateLeft() {
    setMediaIndex(mediaIndex === 0 ? media.length - 1 : mediaIndex - 1)
  }

  function handleNavigateRight() {
    setMediaIndex(mediaIndex === media.length - 1 ? 0 : mediaIndex + 1)
  }

  useEffect(() => {
    if (clickedImageIndex !== null) {
      setMediaIndex(clickedImageIndex)
    }
  }, [clickedImageIndex])

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1300,
        backgroundColor: "rgba(23, 20, 31, 0.9)",
        display: open ? "block" : "none",
      }}
    >
      <Column
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <Column sx={{ color: "white", width: "100%", height: "100%" }}>
          <Row
            sx={{
              alignItems: "center",
              p: 2,
              borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
            }}
          >
            <IconButton onClick={handleCloseButtonClicked}>
              <CloseOutlinedIcon htmlColor="white" />
            </IconButton>
            <Divider
              orientation="vertical"
              sx={{ height: "100%", ml: 1, borderColor: "white" }}
            />
            <Column sx={{ ml: 2 }}>
              <Typography variant="h6">Media</Typography>
              <Typography variant="body1" color="text.secondary2">
                {mediaIndex + 1}/{media.length}
              </Typography>
            </Column>
          </Row>
          <Row
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
            ref={mediaContainerRef}
          >
            {isMediumUp && (
              <WhiteBorderIconButton
                sx={{ ml: 8 }}
                onClick={handleNavigateLeft}
              >
                <KeyboardArrowLeftIcon htmlColor="white" />
              </WhiteBorderIconButton>
            )}
            {currentMedia ? (
              currentMedia.image ? (
                <img
                  src={currentMedia.image}
                  alt="brokerage media"
                  style={mediaElemStyles}
                />
              ) : (
                <video
                  controls
                  src={currentMedia.file}
                  style={mediaElemStyles}
                />
              )
            ) : null}
            {isMediumUp && (
              <WhiteBorderIconButton
                sx={{ mr: 8 }}
                onClick={handleNavigateRight}
              >
                <KeyboardArrowRightIcon htmlColor="white" />
              </WhiteBorderIconButton>
            )}
          </Row>
        </Column>
      </Column>
    </Box>
  )
}

const WhiteBorderIconButton = styled(IconButton)(() => ({
  border: "1px solid white",
}))

export function VideoPlayIcon() {
  return (
    <Row
      sx={{
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <PlayCircleFilledIcon fontSize="large" sx={{ color: "white" }} />
    </Row>
  )
}

export function StyledVideo({ src }) {
  const theme = useTheme()
  return (
    <video
      src={src}
      style={{
        width: "100%",
        aspectRatio: "1/1",
        borderRadius: theme.units.borderRadius,
        cursor: "pointer",
        objectFit: "cover",
      }}
    />
  )
}

export const DarkFilm = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  backgroundColor: alpha("#000000", 0.5),
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  borderRadius: theme.units.borderRadius,
  cursor: "pointer",
}))
