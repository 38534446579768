import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import { styled } from "@mui/material/styles"
import { useSelector } from "react-redux"

import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import Row from "../../../common/components/Row"
import { selectProfile } from "../slice"
import { DistanceFilterTitleDescription } from "./distance-filter-dialog"
import DistanceUnitsSelector, {
  useDistanceUnitsSelectorState,
} from "./distance-units-selector"
import ExcludeBrandsSelector, {
  useExcludeBrandsSelectorState,
} from "./exclude-brands-selector"
import {
  ExcludeBrokeragesFilterTitleDescription,
  processBrandOptions,
  useFillExcludedBrandsFromProfile,
} from "./exclude-brokerages-dialog"
import {
  ZipcodeFilterTitleDescription,
  ZipcodeTextField,
  useZipcodeFilterState,
} from "./zipcode-filter-dialog"

export default function FiltersMobileDialog({
  open,
  onClose,
  filteredBrokerages,
}) {
  const profile = useSelector(selectProfile)
  const {
    zipcode,
    zipcodeError,
    handleZipcodeChanged,
    isValidatingZipcode,
    validateZipcode,
  } = useZipcodeFilterState(profile.zip_code, handleZipcodeValidated)
  const {
    distance,
    handleDistanceChanged,
    units,
    handleUnitsToggleChanged,
    distanceError,
    setDistanceError,
    distanceFilter,
  } = useDistanceUnitsSelectorState(
    profile.distance_from_work,
    profile.distance_units
  )
  const {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
  } = useExcludeBrandsSelectorState()

  useFillExcludedBrandsFromProfile(setExcludedBrands)

  const processedBrandOptions = processBrandOptions(
    filteredBrokerages,
    excludedBrands
  )

  function handleZipcodeValidated(zipcodeFilter) {
    onClose(zipcodeFilter, distanceFilter, excludedBrands)
  }

  function handleApplyButtonClicked() {
    validateZipcode()
  }

  return (
    <Dialog open={open} onClose={() => onClose()} fullScreen>
      <DialogContent>
        <InputSection>
          <Column>
            <ZipcodeFilterTitleDescription />
          </Column>
          <Spacer />
          <ZipcodeTextField
            zipcode={zipcode}
            zipcodeError={zipcodeError}
            handleZipcodeChanged={handleZipcodeChanged}
            sx={{ minWidth: "8em", maxWidth: "8em" }}
          />
        </InputSection>
        <Divider />
        <InputSection>
          <Column>
            <DistanceFilterTitleDescription />
          </Column>
          <Spacer />
          <DistanceUnitsSelector
            distance={distance}
            onDistanceChanged={handleDistanceChanged}
            units={units}
            onUnitsChanged={handleUnitsToggleChanged}
            onError={setDistanceError}
          />
        </InputSection>
        <Divider />
        <InputSection sx={{ flexDirection: "column" }}>
          <ExcludeBrokeragesFilterTitleDescription />
          <Box sx={{ mt: 2 }}>
            <ExcludeBrandsSelector
              brand={brand}
              onBrandChanged={handleBrandChanged}
              brandInputValue={brandInputValue}
              onBrandInputValueChanged={handleBrandInputValueChanged}
              excludedBrands={excludedBrands}
              handleSelectedBrandDeletedWrapper={
                handleSelectedBrandDeletedWrapper
              }
              options={processedBrandOptions}
            />
          </Box>
        </InputSection>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isValidatingZipcode}
          disabled={distanceError}
          onClick={handleApplyButtonClicked}
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const InputSection = styled(Row)(({ theme }) => ({
  justifyContent: "space-between",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}))

function Spacer() {
  return <Box sx={{ width: (theme) => theme.spacing(4) }} />
}
