import AllInclusiveIcon from "@mui/icons-material/AllInclusive"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"

import { PurpleBorderedChip } from "../../../common/components/BorderedChips"
import BrandAvatar from "../../../common/components/BrandAvatar"
import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import { trimLongString, useIsMobile } from "../../../common/utils"
import { FREE_PROSPECTS, formatDate } from "../utils"
import CancelSubscriptionReasonDialog from "./CancelSubscriptionReasonDialog"

export default function CancelSubscriptionDialog({ open, onClose, brokerage }) {
  const [isCancelReasonDialogOpen, setIsCancelReasonDialogOpen] =
    useState(false)

  const subscription = brokerage.subscription
  const isMobile = useIsMobile()
  const theme = useTheme()

  function handleDowngradeButtonClicked() {
    onClose()
    setIsCancelReasonDialogOpen(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      keepMounted
    >
      {subscription && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Column
            sx={{
              alignItems: "center",
              textAlign: "center",
              px: 4,
            }}
          >
            <Typography variant="h4">
              Really go back to the Basic plan?
            </Typography>
            <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
              If you downgrade your subscription, you will{" "}
              {brokerage.free_agents_remaining === 0
                ? "no longer have access to new agents"
                : `have access only to ${brokerage.free_agents_remaining} new agents`}
              . Your Pro subscription will expire{" "}
              {formatDate(subscription.valid_until)} if you continue.
            </Typography>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                p: (theme) => `${theme.spacing(2)}!important`,
                mt: 4,
                backdropFilter: "blur(5px)",
                display: "flex",
                alignItems: "center",
                width: { xs: "320px", sm: "400px" },
              }}
            >
              <BrandAvatar brand={{ icon: brokerage.image }} />
              <Column
                sx={{
                  ml: 2,
                }}
              >
                <Typography variant="caption">
                  {trimLongString(brokerage.company, isMobile ? 20 : 35)}{" "}
                  <PurpleBorderedChip label="Pro" sx={{ ml: 1 }} size="small" />
                </Typography>
                <Row sx={{ alignItems: "center", mt: 1 }}>
                  <LocationOnOutlinedIcon
                    sx={{ color: "text.secondary2" }}
                    fontSize="small"
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary2"
                    sx={{ ml: 0.5 }}
                  >
                    {brokerage.address && trimLongString(brokerage.address, 35)}
                  </Typography>
                </Row>
              </Column>
            </Paper>
          </Column>
          <RowButColumnOnMobile
            sx={{
              alignItems: "center",
              mt: 4,
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="error"
              fullWidth
              size="large"
              onClick={handleDowngradeButtonClicked}
            >
              Downgrade to free
            </Button>
            <CancelSubscriptionReasonDialog
              open={isCancelReasonDialogOpen}
              onClose={() => setIsCancelReasonDialogOpen(false)}
              brokerage={brokerage}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
              size="large"
              onClick={() => onClose()}
            >
              Stay Pro
            </Button>
          </RowButColumnOnMobile>
          <Divider sx={{ mt: 4 }} />
          <RowButColumnOnMobile
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
              [theme.breakpoints.only("xs")]: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
                "& > .MuiBox-root:nth-of-type(n + 2)": {
                  mt: 2,
                },
              },
            }}
          >
            <PlanHighlight
              title="Basic Subscription"
              subtitle={`${FREE_PROSPECTS} free trial prospects`}
              iconText={FREE_PROSPECTS}
              iconBackgroundColor="otherwise.lightBackground"
              iconTextColor="inherit"
            />
            <PlanHighlight
              title="Pro Subscription"
              subtitle="Unlimited prospects"
              iconText={<AllInclusiveIcon color="primary" />}
              iconBackgroundColor="primary.light"
            />
          </RowButColumnOnMobile>
        </DialogContent>
      )}
    </Dialog>
  )
}

function PlanHighlight({
  title,
  subtitle,
  iconText,
  iconBackgroundColor,
  iconTextColor,
}) {
  return (
    <Row
      sx={{
        alignItems: "center",
      }}
    >
      <Avatar
        sx={{
          backgroundColor: iconBackgroundColor,
          fontWeight: 500,
          width: "48px",
          height: "48px",
          color: iconTextColor,
        }}
      >
        {iconText}
      </Avatar>
      <Column
        sx={{
          ml: 2,
        }}
      >
        <Typography variant="overline" lineHeight={1}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {subtitle}
        </Typography>
      </Column>
    </Row>
  )
}
