import KeyOffOutlinedIcon from "@mui/icons-material/KeyOffOutlined"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router-dom"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"

export default function PhoneConfirmationInvalid() {
  return (
    <BuildingVerticalSplitLayout>
      <HaloCircularIcon Icon={KeyOffOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 4 }}>
        Phone confirmation link is invalid
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        You may have double-clicked the phone confirmation link which is only
        for one-time use.{" "}
        <Link
          color="primary"
          component={RouterLink}
          to="/users/signup/resend-phone-confirmation/"
          underline="always"
        >
          Click here
        </Link>{" "}
        to get another confirmation text message.
      </Typography>
    </BuildingVerticalSplitLayout>
  )
}
