import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useCallback } from "react"
import { unstable_useBlocker } from "react-router-dom"

import { useIsMobile } from "../utils"
import { DangerHaloCircularIcon } from "./CircularIcon"
import Column from "./Column"

export default function UnsavedChangesPreventLeaveDialog({ dataHasChanged }) {
  const isMobile = useIsMobile()

  function handleClosed() {
    navigationBlocker.reset?.()
  }

  const shouldBlockNavigation = useCallback(
    ({ currentLocation, nextLocation }) => {
      return (
        dataHasChanged && currentLocation.pathname !== nextLocation.pathname
      )
    },
    [dataHasChanged]
  )

  const navigationBlocker = unstable_useBlocker(shouldBlockNavigation)

  return (
    <Dialog
      open={navigationBlocker.state === "blocked"}
      onClose={handleClosed}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <DangerHaloCircularIcon Icon={SaveAsOutlinedIcon} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Are you sure you want to leave?
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            Any unsaved changes will be lost.
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => navigationBlocker.proceed?.()}
        >
          Yes I'm sure
        </Button>
        <Button variant="contained" color="primary" onClick={handleClosed}>
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  )
}
