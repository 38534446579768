import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined"
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Tab from "@mui/material/Tab"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { lighten, useTheme } from "@mui/material/styles"
import { formatDistance } from "date-fns"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import BorderlessSearchTextField, {
  InputProps,
} from "../../../common/components/BorderlessSearchTextField"
import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TabContainer from "../../../common/components/TabContainer"
import InboxWhiteIcon from "../../../common/resources/icons/inbox-active.svg"
import {
  formatCurrency,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import AgentStatusChip from "../components/AgentStatusChip"
import { buildStatTitle } from "../components/AgentToMarketStatComparison"
import {
  BoldTableCell,
  HeaderTableCell,
  NoResultsTableRow,
  SortableHeaderTableCell,
  getOrderingParam,
  useTablePagination,
  useTableSort,
} from "../components/Tables"
import TopProducerAgentAvatar from "../components/TopProducerAgentAvatar"
import { WhiteButton, WhiteHeader, WhiteSubtitle } from "../components/White"
import SubscribeIconBackground from "../resources/images/subscribe-icon-bg.png"
import SubscribeRowBackgroundImage from "../resources/images/subscribe-table-row-bg.png"
import {
  profileLoading,
  selectCurrentBrokerage,
  selectHasCampaigns,
} from "../slice"
import { getAgentName, useStartAgentConversation } from "../utils"
import {
  PRODUCT_TYPES,
  formatDate,
  useGoSubscribe,
  useIsBrokerageSubscribed,
} from "../utils"
import AirtableCandidateDrawer from "./AirtableCandidateDrawer"
import BaseballCardDrawer, { useBaseballCardState } from "./BaseballCardDrawer"
import FiltersModal from "./FIltersModal"
import { useUpdateCandidate } from "./utils"

const CANDIDATE_TYPES = {
  agentRequest: "agent_request",
  bookingLog: "booking_log",
}

export default function Candidates() {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false)
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false)
  const [candidates, setCandidates] = useState([])
  const { highlightedCandidate, setHighlightedCandidate } =
    useBaseballCardState()
  const { sortTableBy, sortDirection, sortByColumnWrapper } = useTableSort()
  const {
    pageIndex,
    handlePageChanged,
    setTotalResultCount,
    totalResultCount,
  } = useTablePagination()
  const [isLoadingHistoricalCandidates, setIsLoadingHistoricalCandidates] =
    useState(false)

  const [historicalCandidates, setHistoricalCandidates] = useState([])
  const {
    sortTableBy: sortTableByHistoricalCandidates,
    sortDirection: sortDirectionHistoricalCandidates,
    sortByColumnWrapper: sortByColumnWrapperHistoricalCandidates,
  } = useTableSort("appointment_date")
  const {
    pageIndex: historicalPageIndex,
    handlePageChanged: historicalHandlePageChanged,
    totalResultCount: historicalTotalResultCount,
    setTotalResultCount: historicalSetTotalResultCount,
  } = useTablePagination()
  const [searchQuery, setSearchQuery] = useState("")
  const [modalFilters, setModalFilters] = useState({})

  const [highlightedAirtableCandidate, setHighlightedAirtableCandidate] =
    useState(null)
  const TABS = {
    highrise: 0,
    historical: 1,
  }
  const [activeTab, setActiveTab] = useState(TABS.highrise)

  const searchParams = useSearchParams()[0]
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const hasCampaigns = useSelector(selectHasCampaigns)
  const { requestStartConversation } = useStartAgentConversation()
  const axios = useAuthorizedAxiosClient()
  const goSubscribe = useGoSubscribe("Candidates table CTA")
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()
  const updateCandidate = useUpdateCandidate()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const lightPurple = lighten(theme.palette.primary.light, 0.6)
  const lightPink = lighten(theme.palette.secondary.light, 0.92)
  const CANDIDATES_PAGE_SIZE = 20

  function handleSearchFieldKeyDownEvent(e) {
    if (e.code === "Enter") {
      queryCandidates()
    }
  }

  function handleFiltersButtonClicked() {
    setFiltersModalOpen(true)
  }

  function handleFiltersModalClosed(filters) {
    setFiltersModalOpen(false)
    if (filters) {
      setModalFilters(filters)
    }
  }

  function handleChatIconButtonClickedWrapper(candidate) {
    return (e) => {
      e.stopPropagation()
      requestStartConversation(candidate)
    }
  }

  function handleRowClickedWrapper(candidate) {
    return () => {
      if (candidate.type === CANDIDATE_TYPES.agentRequest) {
        setHighlightedCandidate(candidate)
        segmentAnalytics.track("Broker viewed candidate baseball card", {
          "Brokerage ID": currentBrokerage.id,
          "Brokerage name": currentBrokerage.company,
          "Agent request ID": candidate.id,
          "Candidate name": getAgentName(candidate),
        })
      } else {
        setHighlightedAirtableCandidate(candidate)
      }
    }
  }

  function handleRatingChangedWrapper(candidateParam) {
    return (e) => {
      updateCandidate(candidateParam, { rating: e.target.value }).then(
        ({ data }) =>
          setCandidates(
            candidates.map((candidate) => {
              if (
                candidate.id === candidateParam.id &&
                candidate.type === candidateParam.type
              ) {
                return { ...candidate, ...data }
              }
              return candidate
            })
          )
      )
    }
  }

  function handleHighlightedCandidateUpdated(updatedCandidate) {
    setCandidates(
      candidates.map((candidate) =>
        candidate.id === updatedCandidate.id ? updatedCandidate : candidate
      )
    )
  }

  function queryCandidates() {
    if (isProfileLoading) {
      return
    }
    setIsLoadingCandidates(true)
    const orderingParam = getOrderingParam(sortDirection, sortTableBy)
    const searchParams = new URLSearchParams()
    searchParams.append("q", searchQuery)
    searchParams.append("ordering", orderingParam)
    searchParams.append("page", pageIndex)
    searchParams.append("page_size", CANDIDATES_PAGE_SIZE)
    for (const [filter, value] of Object.entries(modalFilters)) {
      if (filter === "area_of_focus") {
        for (const areaOfFocus of value) {
          searchParams.append(filter, areaOfFocus.id)
        }
      } else {
        searchParams.append(filter, value)
      }
    }
    axios
      .get(
        `/customers/api/candidates/${
          currentBrokerage.id
        }/?${searchParams.toString()}`
      )
      .then(({ data }) => {
        setCandidates(data.results)
        setTotalResultCount(data.count)
      })
      .finally(() => setIsLoadingCandidates(false))
  }

  useEffect(
    () => {
      queryCandidates()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isProfileLoading,
      sortTableBy,
      sortDirection,
      modalFilters,
      currentBrokerage,
      pageIndex,
    ]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsLoadingHistoricalCandidates(true)
      axios
        .get(`/customers/api/historical-candidates/${currentBrokerage.id}/`, {
          params: {
            ordering: getOrderingParam(
              sortDirectionHistoricalCandidates,
              sortTableByHistoricalCandidates
            ),
            q: searchQuery,
            page: historicalPageIndex,
          },
        })
        .then(({ data }) => {
          setHistoricalCandidates(data.results)
          historicalSetTotalResultCount(data.count)
        })
        .finally(() => setIsLoadingHistoricalCandidates(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isProfileLoading,
      sortTableByHistoricalCandidates,
      sortDirectionHistoricalCandidates,
      searchQuery,
      historicalPageIndex,
      currentBrokerage,
    ]
  )

  useEffect(
    () => {
      if (isLoadingCandidates) {
        return
      }

      const agentRequestID = searchParams.get("arid")
      if (agentRequestID) {
        setHighlightedCandidate(
          candidates.find(
            (candidate) => candidate.id === parseInt(agentRequestID)
          )
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingCandidates]
  )

  const AVATAR_SIZE = 48
  const COLUMN_COUNT = 10
  const filtersApplied = Object.values(modalFilters).some((value) => !!value)
  const filterButtonProps = {
    color: filtersApplied ? "primary" : "inherit",
    onClick: handleFiltersButtonClicked,
    disabled: activeTab !== TABS.highrise,
  }

  return (
    <Column
      sx={{
        px: { xs: 2, sm: 8 },
        py: 4,
      }}
    >
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BorderlessSearchTextField
          placeholder="Search for candidate"
          InputProps={InputProps}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleSearchFieldKeyDownEvent}
        />
        <Row
          sx={{
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <IconButton {...filterButtonProps}>
              <TuneOutlinedIcon />
            </IconButton>
          ) : (
            <Button
              variant="text"
              startIcon={<TuneOutlinedIcon />}
              {...filterButtonProps}
            >
              Filters
            </Button>
          )}
          <FiltersModal
            open={filtersModalOpen}
            onClose={handleFiltersModalClosed}
          />
        </Row>
      </Row>
      <Row
        sx={{ justifyContent: "space-between", alignItems: "center", mt: 4 }}
      >
        <Tabs
          value={activeTab}
          onChange={(e, currentTab) => setActiveTab(currentTab)}
        >
          <Tab label="Highrise" />
          <Tab label="History" />
        </Tabs>
        {hasCampaigns && activeTab === TABS.highrise ? (
          <Row>
            <ColorIndicator
              name={PRODUCT_TYPES.highrise}
              color={theme.palette.primary.light}
            />
            <Box sx={{ ml: 2 }}>
              <ColorIndicator
                name={PRODUCT_TYPES.campaigns}
                color={theme.palette.secondary.light}
              />
            </Box>
          </Row>
        ) : null}
      </Row>
      <Box sx={{ mt: 4 }}>
        <TabContainer index={TABS.highrise} value={activeTab}>
          <TableContainer sx={{ maxWidth: "calc(100vw - 32px)" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderTableCell
                    sx={{ width: `${AVATAR_SIZE}px`, textAlign: "center" }}
                  >
                    {isLoadingCandidates && (
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        size={32}
                      />
                    )}
                  </HeaderTableCell>
                  <HeaderTableCell />
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="name"
                  >
                    Name
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="status"
                  >
                    Status
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="created"
                  >
                    Date
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="years_in_business"
                  >
                    Experience
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="sales_volume_last_year"
                  >
                    Total Sales
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="avg_sale_price"
                  >
                    Avg. Price
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="homes_sold_last_year"
                  >
                    Sales #
                  </SortableHeaderTableCell>
                  <HeaderTableCell>Area of focus</HeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                    columnName="rating"
                  >
                    Rating
                  </SortableHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidates.length === 0 ? (
                  <NoResultsTableRow columnCount={COLUMN_COUNT}>
                    No candidates found for the selected brokerage and/or
                    filters
                  </NoResultsTableRow>
                ) : (
                  <>
                    {candidates.map((candidate) => {
                      const candidateWrapper = new CandidateWrapper(candidate)
                      return (
                        <TableRow
                          key={candidate.id}
                          hover
                          sx={{
                            cursor: "pointer",
                            backgroundColor: (theme) =>
                              highlightedCandidate
                                ? candidate.id === highlightedCandidate.id
                                  ? theme.palette.otherwise.lightBackground
                                  : "inherit"
                                : hasCampaigns
                                ? candidateWrapper.isAgentRequest
                                  ? lightPurple
                                  : lightPink
                                : undefined,
                          }}
                          onClick={handleRowClickedWrapper(candidate)}
                          on
                        >
                          <TableCell>
                            {candidateWrapper.isAgentRequest ? (
                              <TopProducerAgentAvatar
                                agentRequest={candidate}
                                avatarSize={AVATAR_SIZE}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {candidateWrapper.isAgentRequest ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={
                                  <img
                                    src={InboxWhiteIcon}
                                    style={{ width: "12px", height: "12px" }}
                                    alt="Chat with agent"
                                  />
                                }
                                sx={{
                                  padding: "4px 12px",
                                }}
                                onClick={handleChatIconButtonClickedWrapper(
                                  candidate
                                )}
                              >
                                Chat
                              </Button>
                            ) : null}
                          </TableCell>
                          <BoldTableCell>{candidateWrapper.name}</BoldTableCell>
                          <TableCell>
                            {candidateWrapper.isAgentRequest ? (
                              <AgentStatusChip agent={candidate} />
                            ) : (
                              candidate.status
                            )}
                          </TableCell>
                          <BoldTableCell>
                            {formatDistance(
                              new Date(candidateWrapper.date),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </BoldTableCell>
                          <BoldTableCell>
                            {buildStatTitle(
                              candidate.years_in_business,
                              (yearsInBusiness) => `${yearsInBusiness} years`,
                              ""
                            )}
                          </BoldTableCell>
                          <BoldTableCell>
                            {buildStatTitle(
                              candidate.sales_volume_last_year,
                              (salesVolume) => formatCurrency(salesVolume),
                              ""
                            )}
                          </BoldTableCell>
                          <BoldTableCell>
                            {buildStatTitle(
                              candidate.avg_sale_price,
                              (avgPrice) => formatCurrency(avgPrice),
                              ""
                            )}
                          </BoldTableCell>
                          <BoldTableCell>
                            {buildStatTitle(
                              candidate.homes_sold_last_year,
                              undefined,
                              ""
                            )}
                          </BoldTableCell>
                          <BoldTableCell
                            sx={{
                              "& .MuiChip-root:nth-of-type(n + 2)": { ml: 1 },
                            }}
                          >
                            {candidateWrapper.isAgentRequest ? (
                              <Row component="span">
                                {candidate.agent.areas_of_focus.length > 0 && (
                                  <Chip
                                    label={
                                      candidate.agent.areas_of_focus[0].name
                                    }
                                  />
                                )}
                                {candidate.agent.areas_of_focus.length > 1 && (
                                  <Chip
                                    label={`+${
                                      candidate.agent.areas_of_focus.length - 1
                                    }`}
                                  />
                                )}
                              </Row>
                            ) : null}
                          </BoldTableCell>
                          <TableCell>
                            <Select
                              label="Rating"
                              value={candidate.rating || ""}
                              onChange={handleRatingChangedWrapper(candidate)}
                              variant="standard"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {[1, 2, 3, 4, 5].map((rating) => (
                                <MenuItem key={rating} value={rating}>
                                  {rating}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                )}
                <TableRow>
                  <TablePagination
                    page={pageIndex - 1}
                    onPageChange={handlePageChanged}
                    count={totalResultCount}
                    rowsPerPage={CANDIDATES_PAGE_SIZE}
                    rowsPerPageOptions={[]}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {!isBrokerageSubscribed(currentBrokerage) && (
            <Row
              sx={{
                width: "100%",
                backgroundImage: `url(${SubscribeRowBackgroundImage})`,
                backgroundSize: "100% 100%",
                p: 2,
                borderRadius: (theme) => theme.units.borderRadius,
              }}
            >
              <Row
                sx={{
                  alignItems: { xs: "flex-start", sm: "center" },
                  width: "100%",
                }}
              >
                <Avatar
                  sx={{
                    background: `url(${SubscribeIconBackground})`,
                    backgroundSize: "100% 100%",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <WorkspacePremiumOutlinedIcon color="white" />
                </Avatar>
                <RowButColumnOnMobile
                  sx={{
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-start", sm: "center" },
                    width: "100%",
                    ml: 2,
                  }}
                >
                  <Column>
                    <WhiteHeader variant="h6">
                      Reveal all of your candidates instantly with Pro
                      Subscription
                    </WhiteHeader>
                    <WhiteSubtitle variant="body2">
                      Monthly and annual plans available
                    </WhiteSubtitle>
                  </Column>
                  <WhiteButton
                    size="small"
                    onClick={goSubscribe}
                    sx={{ mt: { xs: 2, sm: 0 } }}
                  >
                    Subscribe
                  </WhiteButton>
                </RowButColumnOnMobile>
              </Row>
            </Row>
          )}
          <BaseballCardDrawer
            highlightedCandidate={highlightedCandidate}
            setHighlightedCandidate={setHighlightedCandidate}
            onCandidateUpdated={handleHighlightedCandidateUpdated}
          />
          <AirtableCandidateDrawer
            highlightedCandidate={highlightedAirtableCandidate}
            setHighlightedCandidate={setHighlightedAirtableCandidate}
            onCandidateUpdated={handleHighlightedCandidateUpdated}
          />
        </TabContainer>
        <TabContainer index={TABS.historical} value={activeTab}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableByHistoricalCandidates}
                    sortDirection={sortDirectionHistoricalCandidates}
                    sortByColumnWrapper={
                      sortByColumnWrapperHistoricalCandidates
                    }
                    columnName="name"
                  >
                    Name
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableByHistoricalCandidates}
                    sortDirection={sortDirectionHistoricalCandidates}
                    sortByColumnWrapper={
                      sortByColumnWrapperHistoricalCandidates
                    }
                    columnName="email"
                  >
                    Email
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    sortTableBy={sortTableByHistoricalCandidates}
                    sortDirection={sortDirectionHistoricalCandidates}
                    sortByColumnWrapper={
                      sortByColumnWrapperHistoricalCandidates
                    }
                    columnName="appointment_date"
                  >
                    Appointment date
                  </SortableHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historicalCandidates.length === 0 ? (
                  <NoResultsTableRow columnCount={3}>
                    No candidates found for the selected brokerage and/or
                    filters
                  </NoResultsTableRow>
                ) : (
                  historicalCandidates.map((candidate) => (
                    <TableRow key={candidate.id}>
                      <BoldTableCell>{candidate.name}</BoldTableCell>
                      <BoldTableCell>{candidate.email}</BoldTableCell>
                      <BoldTableCell>
                        {formatDate(candidate.appointment_date)}
                      </BoldTableCell>
                    </TableRow>
                  ))
                )}
                <TableRow>
                  <TablePagination
                    page={historicalPageIndex - 1}
                    onPageChange={historicalHandlePageChanged}
                    count={historicalTotalResultCount}
                    rowsPerPage={10}
                    rowsPerPageOptions={[]}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Row sx={{ mt: 2, justifyContent: "flex-end" }}></Row>
        </TabContainer>
      </Box>
    </Column>
  )
}

class CandidateWrapper {
  constructor(candidate) {
    this.candidate = candidate
  }
  get name() {
    if (this.isAgentRequest) {
      return getAgentName(this.candidate)
    }
    return this.candidate.candidate_name
  }
  get isAgentRequest() {
    return this.candidate.type === CANDIDATE_TYPES.agentRequest
  }
  get date() {
    if (this.isAgentRequest) {
      return this.candidate.created
    }
    return this.candidate.stamp
  }
}

function ColorIndicator({ name, color }) {
  return (
    <Row sx={{ alignItems: "center" }}>
      <Typography variant="caption">{name}</Typography>
      <Box
        sx={{
          width: "16px",
          height: "16px",
          backgroundColor: color,
          ml: 1,
          borderRadius: "4px",
        }}
      />
    </Row>
  )
}
