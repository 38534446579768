import Skeleton from "@mui/material/Skeleton"

export function TextSkeleton({ width = 6, height = 1 }) {
  return (
    <Skeleton
      variant="text"
      sx={{
        display: "inline-block",
        width: `${width}em`,
        height: `${height}em`,
      }}
    />
  )
}

export function CircularSkeleton({ size }) {
  return (
    <Skeleton variant="circular" width={`${size}px`} height={`${size}px`} />
  )
}

export default function LoadingWrapper({
  isLoading,
  skeleton = <TextSkeleton />,
  children,
}) {
  if (isLoading) {
    return <>{skeleton}</>
  } else {
    return <>{children}</>
  }
}
