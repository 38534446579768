import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { useDebounce, useScript } from "usehooks-ts"

export const googleServices = {
  autoComplete: null,
  placeDetails: null,
}

export default function AddressAutocomplete({
  address,
  setAddress,
  debouncedSearchQuery,
  addressInput,
  setAddressInput,
  addressOptions,
  setAddressOptions,
}) {
  const googleScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
  )

  useEffect(() => {
    if (window.google !== undefined) {
      googleServices.autoComplete =
        new window.google.maps.places.AutocompleteService()
      googleServices.placeDetails = new window.google.maps.places.PlacesService(
        document.createElement("div")
      )
    }
  }, [googleScriptStatus])

  useEffect(
    () => {
      if (googleServices.autoComplete && debouncedSearchQuery.length > 0) {
        googleServices.autoComplete.getQueryPredictions(
          {
            input: debouncedSearchQuery,
            componentRestrictions: { country: ["us", "ca"] },
          },
          (results, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              results
            ) {
              setAddressOptions(results)
            }
          }
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchQuery]
  )

  return (
    <Autocomplete
      label="Location"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={addressOptions}
      isOptionEqualToValue={(option, value) =>
        option.place_id === value.place_id
      }
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={address}
      onChange={(e, newValue) => setAddress(newValue)}
      inputValue={addressInput}
      onInputChange={(e, newValue) => setAddressInput(newValue)}
      renderInput={(params) => (
        <TextField {...params} label="Location" fullWidth />
      )}
      fullWidth
      sx={{ mt: 4 }}
    />
  )
}

export function useAddressAutocompleteState() {
  const [address, setAddress] = useState(null)
  const [addressInput, setAddressInput] = useState("")
  const debouncedSearchQuery = useDebounce(addressInput, 1000)
  const [addressOptions, setAddressOptions] = useState([])
  return {
    address,
    setAddress,
    addressInput,
    setAddressInput,
    debouncedSearchQuery,
    addressOptions,
    setAddressOptions,
  }
}
