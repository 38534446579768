import CircleIcon from "@mui/icons-material/Circle"
import StarIcon from "@mui/icons-material/Star"
import Box from "@mui/material/Box"
import Rating from "@mui/material/Rating"
import Snackbar from "@mui/material/Snackbar"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  hideNotification,
  selectNotificationByType,
} from "../../notifications/slice"
import { NOTIFICATION_TYPES } from "../utils"

export default function ExperienceRatingStars() {
  const rateExperienceNotification = useSelector(
    selectNotificationByType(NOTIFICATION_TYPES.experienceRating)
  )
  const [rating, setRating] = useState(rateExperienceNotification?.rating)
  const [showThanksSnackbar, setShowThanksSnackbar] = useState(false)

  const dispatch = useDispatch()

  function handleRatingChanged(_, newRating) {
    dispatch(
      hideNotification({
        id: rateExperienceNotification.id,
        data: { rating: newRating },
      })
    )
    setRating(newRating)
    setShowThanksSnackbar(true)
  }

  function handleThanksSnackbarClosed() {
    setShowThanksSnackbar(false)
  }

  useEffect(() => {
    if (!rateExperienceNotification) {
      return
    }
    setRating(rateExperienceNotification.rating)
  }, [rateExperienceNotification])

  return (
    <>
      <Rating
        value={rating}
        onChange={handleRatingChanged}
        IconContainerComponent={StarContainer}
        sx={{ width: "180px", justifyContent: "space-between" }}
      />
      <Snackbar
        open={showThanksSnackbar}
        autoHideDuration={5000}
        onClose={handleThanksSnackbarClosed}
        message="Thanks for your feedback!"
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </>
  )
}

function StarContainer(props) {
  return (
    <Box
      {...props}
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "36px",
        height: "36px",
      }}
    >
      <CircleIcon
        sx={{
          color: (theme) => theme.palette.otherwise.lightBackground,
          position: "absolute",
          top: 0,
          left: 0,
          width: "36px",
          height: "36px",
        }}
      />
      <StarIcon sx={{ width: 24, height: 24, zIndex: 10 }} />
    </Box>
  )
}
