import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined"
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnOutlined"
import TableRestaurantOutlinedIcon from "@mui/icons-material/TableRestaurantOutlined"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"

import Row from "./Row"

export default function PerksColumns({ perks }) {
  const sortedPerks = perks.slice()
  sortedPerks.sort(({ category: category1 }, { category: category2 }) =>
    category1 > category2 ? 1 : category1 < category2 ? -1 : 0
  )
  return (
    <Grid container rowSpacing={2}>
      {sortedPerks.length === 0 ? (
        <Grid xs={12}>
          <Typography variant="body1" color="text.secondary2">
            No perks selected.
          </Typography>
        </Grid>
      ) : (
        sortedPerks.map((perk, index) => (
          <Grid key={perk.id} xs={12} md={6}>
            <PerkRow perk={perk} />
          </Grid>
        ))
      )}
    </Grid>
  )
}

function PerkRow({ perk }) {
  const Icon = getIconForPerkCategory(perk.category)
  return (
    <Row
      sx={{
        alignItems: "center",
      }}
    >
      <Icon />
      <Typography variant="body1" sx={{ ml: 2 }}>
        {perk.title}
      </Typography>
    </Row>
  )
}

export const PERKS_CATEGORIES = {
  compensation: "compensation",
  admin: "admin",
  office: "office",
  marketing: "marketing",
}

export function getIconForPerkCategory(category) {
  switch (category) {
    case PERKS_CATEGORIES.compensation:
      return MonetizationOnIcon
    case PERKS_CATEGORIES.admin:
      return ContactSupportOutlinedIcon
    case PERKS_CATEGORIES.office:
      return TableRestaurantOutlinedIcon
    default:
      return CampaignOutlinedIcon
  }
}
