import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import CircularIcon from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"
import { axiosClient } from "../../../common/utils"

export default function ResendEmailConfirmation() {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const theme = useTheme()

  function handleEmailChanged(e) {
    setEmail(e.target.value)
    setEmailError(null)
  }

  function handleResendButtonClicked() {
    setIsLoading(true)
    axiosClient
      .post("/users/api/resend-confirmation-email/", { email })
      .then(() => {
        navigate("/users/signup/success/")
      })
      .catch(({ response: { data } }) => {
        setEmailError(data.email)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <BuildingVerticalSplitLayout>
      <Box
        sx={{
          [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
          },
        }}
      >
        <CircularIcon Icon={EmailOutlinedIcon} />
      </Box>
      <Typography variant="h4" sx={{ mt: 2 }}>
        Resend Confirmation Email
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Enter your email below to get another confirmation email delivered to
        you.
      </Typography>
      <TextField
        type="email"
        value={email}
        onChange={handleEmailChanged}
        error={!!emailError}
        helperText={emailError}
        sx={{ mt: 2 }}
        autoFocus
      />
      <LoadingButton
        isLoading={isLoading}
        disabled={!email || emailError}
        sx={{
          mt: 2,
          alignSelf: "flex-start",
          minWidth: "10em",
          [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
            width: "100%",
          },
        }}
        onClick={handleResendButtonClicked}
      >
        Get confirmation email
      </LoadingButton>
    </BuildingVerticalSplitLayout>
  )
}
