import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import { useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Row from "./Row"

export default function SidebarLink({
  Icon,
  leftLabel,
  rightLabel,
  to,
  addMarginTop = true,
  id,
}) {
  const navigate = useNavigate()
  const isActive = useIsLocationActive(to)

  return (
    <Row
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        mt: addMarginTop ? 1 : undefined,
        p: 2.5,
        background: isActive ? "#E8E3F7" : undefined,
        borderRadius: (theme) => theme.units.borderRadius,
        "&:hover": {
          background: isActive ? undefined : "rgba(232, 227, 247, 0.5)",
        },
      }}
      onClick={() => navigate(to)}
      id={id}
    >
      <Row sx={{ alignItems: "center" }}>
        {Icon}
        <Typography
          variant="caption"
          sx={{
            ml: 2,
            fontSize: "1rem",
            fontWeight: isActive ? 500 : 400,
            color: isActive ? "inherit" : "text.secondary",
          }}
        >
          {leftLabel}
        </Typography>
      </Row>
      {rightLabel && (
        <Chip
          label={rightLabel}
          color="secondary"
          size="small"
          sx={{ borderRadius: "25px" }}
        />
      )}
    </Row>
  )
}

export function ImageSidebarLink({
  leftLabel,
  rightLabel,
  to,
  normalIcon,
  activeIcon,
  id,
}) {
  const isActive = useIsLocationActive(to)
  return (
    <SidebarLink
      leftLabel={leftLabel}
      rightLabel={rightLabel}
      to={to}
      Icon={<img src={isActive ? activeIcon : normalIcon} alt="link icon" />}
      id={id}
    />
  )
}

export function MuiIconSidebarLink({ Icon, leftLabel, rightLabel, to, id }) {
  const isActive = useIsLocationActive(to)
  const iconRef = useRef()

  return (
    <SidebarLink
      leftLabel={leftLabel}
      rightLabel={rightLabel}
      to={to}
      Icon={
        <Icon
          sx={{
            color: isActive ? "primary.main" : "text.secondary2",
            width: "24px",
            height: "24px",
          }}
          ref={iconRef}
        />
      }
      id={id}
    />
  )
}

function useIsLocationActive(to) {
  const location = useLocation()
  return location.pathname.startsWith(to)
}

class SidebarLinkData {
  constructor(leftLabel, rightLabel, to, id) {
    this.leftLabel = leftLabel
    this.rightLabel = rightLabel
    this.to = to
    this.id = `${id}-nav-link`
  }
}

export class ImageSidebarLinkData extends SidebarLinkData {
  constructor(leftLabel, rightLabel, to, normalIcon, activeIcon, id) {
    super(leftLabel, rightLabel, to, id)
    this.normalIcon = normalIcon
    this.activeIcon = activeIcon
  }
}

export class MuiIconSidebarLinkData extends SidebarLinkData {
  constructor(leftLabel, rightLabel, to, Icon, id) {
    super(leftLabel, rightLabel, to, id)
    this.Icon = Icon
  }
}
