import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import Link from "@mui/material/Link"
import { useState } from "react"

export default function PhoneAgreementCheckbox() {
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] = useState(false)

  function handleLearnMoreLinkClicked(e) {
    e.preventDefault()
    setLearnMoreDialogOpen(true)
  }

  return (
    <>
      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label={
          <>
            I agree to receive text messages at the phone number provided.{" "}
            <Link
              href="#"
              color="primary.main"
              onClick={handleLearnMoreLinkClicked}
            >
              Learn more
            </Link>
            .
          </>
        }
        sx={{ mt: 2 }}
      />
      <LearnMoreDialog
        open={learnMoreDialogOpen}
        onClose={() => setLearnMoreDialogOpen(false)}
      />
    </>
  )
}

function LearnMoreDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
      <DialogContent>
        I agree to receive text messages at the phone number provided. Consent
        is not a condition to purchase. Msg & data rates may apply. Msg
        frequency varies. Reply HELP for help and STOP to cancel. View our{" "}
        <Link
          href="https://www.highrisecloud.com/terms-of-use"
          target="_blank"
          color="primary.main"
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          href="https://smartsetter.io/policy/"
          target="_blank"
          color="primary.main"
        >
          Privacy Policy
        </Link>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onClose()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
