import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import { formatDistance } from "date-fns"
import { useContext } from "react"

import HighriseLogo from "../../resources/icons/highrise-logo.png"
import Column from "../Column"
import Row from "../Row"
import { LeftChatMessageLayout, MessageContainer } from "./ChatMessage"
import InboxContext from "./InboxContext"
import { MESSAGE_SOURCES } from "./InboxContext"

export default function IdentityRevealedMessage({ message }) {
  const inboxContext = useContext(InboxContext)
  if (inboxContext.messageSource === MESSAGE_SOURCES.agent) {
    return <HorizontalSplitter />
  } else {
    return (
      <Column>
        <HorizontalSplitter />
        <Box sx={{ mt: 4 }}>
          <LeftChatMessageLayout
            Avatar={
              <Avatar
                src={HighriseLogo}
                sx={{ width: "32px", height: "32px" }}
              />
            }
          >
            <MessageContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: (theme) =>
                  theme.palette.otherwise.lightBackground,
                p: 2,
              }}
            >
              <Row sx={{ alignItems: "center" }}>
                <Typography variant="h6">HighRise</Typography>
                <Chip
                  label="App"
                  size="small"
                  sx={{ ml: 1, color: "text.secondary2" }}
                />
              </Row>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Agent has revealed their identity to you. You can now see their
                revealed profile information.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mt: 1, alignSelf: "flex-end" }}
              >
                {formatDistance(new Date(message.created), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </MessageContainer>
          </LeftChatMessageLayout>
        </Box>
      </Column>
    )
  }
}

function HorizontalSplitter() {
  const inboxContext = useContext(InboxContext)
  return (
    <Row
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          borderBottom: (theme) =>
            `2px dashed ${theme.palette.otherwise.border}`,
          width: "100%",
        }}
      />
      <Row
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          p: 1,
          zIndex: 2,
        }}
      >
        <VisibilityOutlinedIcon sx={{ color: "text.secondary2" }} />
        <Typography
          variant="body1"
          color="text.secondary2"
          sx={{ ml: 1 }}
          lineHeight={1}
        >
          {inboxContext.messageSource === MESSAGE_SOURCES.agent
            ? "You revealed your identity"
            : "Agent revealed their identity"}
        </Typography>
      </Row>
    </Row>
  )
}
