import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"

export default function LoadingButton({
  disabled,
  isLoading,
  onClick,
  sx,
  children,
  ...props
}) {
  return (
    <Button
      variant="contained"
      disabled={disabled || isLoading}
      startIcon={isLoading ? <CircularProgress size={16} /> : props.startIcon}
      onClick={onClick}
      sx={sx}
      {...props}
    >
      {children}
    </Button>
  )
}
