import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircleOutlined"
import TableRestaurantOutlinedIcon from "@mui/icons-material/TableRestaurantOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useEffect, useState } from "react"
import ConfettiExplosion from "react-confetti-explosion"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import BrandAvatar from "../../../common/components/BrandAvatar"
import BrokerageMediaGallery from "../../../common/components/BrokerageMediaGallery"
import CenterMapContainer from "../../../common/components/CenterMapContainer"
import Column from "../../../common/components/Column"
import CompensationColumns from "../../../common/components/CompensationColumns"
import IconTitleValue from "../../../common/components/IconTitleValue"
import LoadingButton from "../../../common/components/LoadingButton"
import LoadingWrapper, {
  CircularSkeleton,
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import PerksColumns from "../../../common/components/PerksColumns"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TextExpandCollapse from "../../../common/components/TextExpandCollapse"
import TitledSection from "../../../common/components/TitledSection"
import {
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import PathFromHomeToBrokerageMap from "../brokerage-detail/PathFromHomeToBrokerageMap"
import ProposalStatusChip, {
  PROPOSAL_STATUSES,
} from "../components/ProposalStatusChip"
import ProposalDiamondIcon from "../resources/icons/proposal-diamond.png"
import ProposalHeaderImage from "../resources/images/proposal-header.svg"

export default function Proposal() {
  const { id } = useParams()
  const [isEditingPersonalNote, setIsEditingPersonalNote] = useState(false)
  const [personalNote, setPersonalNote] = useState("")
  const [showConfetti, setShowConfetti] = useState(false)
  const CONFETTI_DURATION = 3000
  const [proposal, setProposal] = useState({
    proposal: { perks: [] },
    agent_request: { brokerage: { images: [] } },
  })
  const [isLoadingProposal, setIsLoadingProposal] = useState(true)
  const [isAcceptingProposal, setIsAcceptingProposal] = useState(false)
  const [isRejectingProposal, setIsRejectingProposal] = useState(false)
  const [isUpdatingPersonalNote, setIsUpdatingPersonalNote] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const AVATAR_SIZE = isMobile ? 96 : 160

  const brokerage = proposal.agent_request.brokerage
  const brand = brokerage.brand
  const brandName = brand ? brand.name : brokerage.company
  const proposalName = proposal.proposal.name
  const verticalSpacing = { xs: 2, sm: 4 }

  function handleProposalStatusButtonClicked(status, setIsLoadingFlag) {
    return () => {
      updateProposalProperty({ status }, setIsLoadingFlag)
    }
  }

  function handleEditPersoanlNoteButtonClicked() {
    if (isEditingPersonalNote) {
      updateProposalProperty(
        { agent_note: personalNote },
        setIsUpdatingPersonalNote
      ).then(() => setIsEditingPersonalNote(false))
    } else {
      setIsEditingPersonalNote(true)
    }
  }

  function updateProposalProperty(data, setIsLoadingFlag) {
    setIsLoadingFlag(true)
    return axios
      .put(`/agents/api/proposals/${id}/`, data)
      .then(({ data }) => {
        setProposal(data)
        if (data.status === PROPOSAL_STATUSES.accepted) {
          segmentAnalytics.track("Agent accepted proposal", {
            "Proposal ID": proposal.id,
            "Proposal name": proposalName,
          })
        }
      })
      .finally(() => setIsLoadingFlag(false))
  }

  useEffect(
    () => {
      setIsLoadingProposal(true)
      axios
        .get(`/agents/api/proposals/${id}/`)
        .then(({ data }) => {
          setProposal(data)
          setPersonalNote(data.agent_note || "")
          setShowConfetti(true)
        })
        .finally(() => setIsLoadingProposal(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    let timeoutID
    if (showConfetti) {
      timeoutID = setTimeout(() => {
        setShowConfetti(false)
      }, CONFETTI_DURATION)
    }
    return () => timeoutID && clearTimeout(timeoutID)
  }, [showConfetti])

  useEffect(
    () => {
      if (isLoadingProposal) {
        return
      }
      segmentAnalytics.track("Agent viewed proposal", {
        "Proposal ID": proposal.id,
        "Proposal name": proposalName,
        "Proposal status": proposal.status,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingProposal]
  )

  return (
    <Column sx={{ pb: verticalSpacing }}>
      <Box
        sx={{
          position: "fixed",
          top: "144px",
          left: "55%",
        }}
      >
        {showConfetti && (
          <ConfettiExplosion
            duration={CONFETTI_DURATION}
            height="200vh"
            width={1500}
            colors={["#f28128", "#6a398a", "#6bb1b0", "white"]}
            force={0.6}
            particleCount={100}
          />
        )}
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${ProposalHeaderImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: { xs: "120px", sm: "192px" },
          width: "100%",
        }}
      />
      <Column
        sx={{
          p: { xs: 2, sm: 8 },
          pt: 2,
          "& .MuiPaper-root": {
            p: verticalSpacing,
            mt: verticalSpacing,
          },
        }}
      >
        <Row
          sx={{
            position: "relative",
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              position: { xs: "static", sm: "absolute" },
              top: "-36px",
            }}
          >
            <LoadingWrapper
              isLoading={isLoadingProposal}
              skeleton={<CircularSkeleton size={AVATAR_SIZE} />}
            >
              <BrandAvatar
                brand={{ icon: brokerage.image }}
                size={AVATAR_SIZE}
                brokerageIconPadding={5}
                border="2px solid white"
              />
            </LoadingWrapper>
          </Box>
          <Column
            sx={{
              ml: (theme) =>
                isMobile ? 2 : `calc(${AVATAR_SIZE}px + ${theme.spacing(2)})`,
              alignItems: "flex-start",
            }}
          >
            <LoadingWrapper
              isLoading={isLoadingProposal}
              skeleton={<TextSkeleton width={20} height={2} />}
            >
              <ProposalStatusChip proposal={proposal} />
              <Typography variant="h4" sx={{ mt: 1 }}>
                {proposalName}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                {brandName}
              </Typography>
            </LoadingWrapper>
          </Column>
        </Row>
        <Collapse in={proposal.status === PROPOSAL_STATUSES.sent}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "none",
              backgroundColor: (theme) =>
                theme.palette.otherwise.lightBackground,
              mt: { xs: 2, sm: "72px!important" },
            }}
          >
            <img src={ProposalDiamondIcon} alt="proposal" />
            <RowButColumnOnMobile
              sx={{
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", sm: "center" },
                flexGrow: 2,
                ml: 2,
              }}
            >
              <Column>
                <Typography variant="h6">
                  Did you accept {brandName} proposal?
                </Typography>
                <Typography variant="subtitle1">{proposalName}</Typography>
              </Column>
              <Row sx={{ mt: { xs: 2, sm: 0 } }}>
                <LoadingButton
                  variant="outlined"
                  isLoading={isRejectingProposal}
                  disabled={isAcceptingProposal}
                  onClick={handleProposalStatusButtonClicked(
                    PROPOSAL_STATUSES.rejected,
                    setIsRejectingProposal
                  )}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  isLoading={isAcceptingProposal}
                  disabled={isRejectingProposal}
                  onClick={handleProposalStatusButtonClicked(
                    PROPOSAL_STATUSES.accepted,
                    setIsAcceptingProposal
                  )}
                  sx={{ ml: 2 }}
                >
                  Yes
                </LoadingButton>
              </Row>
            </RowButColumnOnMobile>
          </Paper>
        </Collapse>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Row
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Column>
              <Typography variant="h6">Personal note</Typography>
              <Typography variant="subtitle1">
                These notes are private and not visible to the brokerage
              </Typography>
            </Column>
            <LoadingButton
              variant="text"
              onClick={handleEditPersoanlNoteButtonClicked}
              isLoading={isUpdatingPersonalNote}
            >
              {isEditingPersonalNote ? "Save" : "Edit"}
            </LoadingButton>
          </Row>
          <Box
            sx={{
              mt: verticalSpacing,
            }}
          >
            {isEditingPersonalNote ? (
              <TextField
                value={personalNote}
                onChange={(e) => setPersonalNote(e.target.value)}
                multiline
                fullWidth
                rows={3}
              />
            ) : (
              <Typography variant="body1" whiteSpace="break-spaces">
                {personalNote || "No personal note provided."}
              </Typography>
            )}
          </Box>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TitledSection title="Introduction">
            <Typography variant="body1">
              {proposal.proposal.introduction}
            </Typography>
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Compensation" addMarginTop>
            <CompensationColumns sentProposal={proposal} />
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Fees" addMarginTop>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <IconTitleValue
                  Icon={SwapVerticalCircleIcon}
                  title="Per Transaction Fee"
                  value={`$${proposal.proposal.per_transaction_fee}`}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <IconTitleValue
                  Icon={TableRestaurantOutlinedIcon}
                  title="Monthly Fees"
                  value={`$${proposal.proposal.monthly_desk_fee}`}
                />
              </Grid>
            </Grid>
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Perks" addMarginTop>
            <PerksColumns perks={proposal.proposal.perks} />
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Notes" addMarginTop>
            <Typography variant="body1">
              {proposal.proposal.note || "No notes provided by brokerage."}
            </Typography>
          </TitledSection>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <RowButColumnOnMobile
            sx={{
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
            }}
          >
            <Row>
              <BrandAvatar brand={{ icon: brokerage.image }} size={64} />
              <Column
                sx={{
                  ml: 2,
                }}
              >
                <Typography variant="h6">
                  {proposal.agent_request.brokerage.company}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary2">
                  Brokerage
                </Typography>
              </Column>
            </Row>
            <Button
              variant="outlined"
              size="large"
              onClick={() => navigate(`/agent/brokerages/${brokerage.id}/`)}
              sx={{ mt: { xs: 2, sm: 0 } }}
              fullWidth={isMobile}
            >
              View brokerage details
            </Button>
          </RowButColumnOnMobile>
          <SectionDivider />
          <Row
            sx={{
              alignItems: "center",
              mt: verticalSpacing,
              "& .MuiBox-root:nth-of-type(n + 2)": { ml: 4 },
            }}
          >
            <IconTitleValue
              Icon={LocationOnOutlined}
              title="Location"
              value={brokerage.address}
            />
            <IconTitleValue
              Icon={PersonOutlinedIcon}
              title="Size"
              value={
                brokerage.head_count === null
                  ? "Unknown"
                  : `${brokerage.head_count} agents`
              }
            />
          </Row>
          <Box sx={{ mt: verticalSpacing, width: "100%" }}>
            <TextExpandCollapse
              text={proposal.agent_request.brokerage.description}
            />
          </Box>
          <CenterMapContainer
            Map={PathFromHomeToBrokerageMap}
            mapProps={{
              brokerage: proposal.agent_request.brokerage,
              isLoading: isLoadingProposal,
            }}
            sx={{
              mt: verticalSpacing,
              borderRadius: (theme) => theme.units.borderRadius,
              height: "320px",
            }}
          />
          <SectionDivider />
          <TitledSection title="Media" addMarginTop>
            <BrokerageMediaGallery
              videos={brokerage.videos}
              images={brokerage.images}
            />
          </TitledSection>
        </Paper>
      </Column>
    </Column>
  )
}

function SectionDivider() {
  return <Divider sx={{ mt: { xs: 2, sm: 4 } }} />
}
