import { BuildingVerticalSplitLayout } from "../../../../common/components/VerticalSplitLayout"
import PhoneConfirmationSent from "../Page"

export default function AgentPhoneConfirmationSent() {
  return (
    <BuildingVerticalSplitLayout verticalCenterContent>
      <PhoneConfirmationSent />
    </BuildingVerticalSplitLayout>
  )
}
