import CloseIcon from "@mui/icons-material/Close"
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import CollapseAnimation from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import Fade from "@mui/material/Fade"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Snackbar from "@mui/material/Snackbar"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import BrandAvatar from "../../../common/components/BrandAvatar"
import BrokeragesAroundZipcodeMap from "../../../common/components/BrokeragesAroundZipcodeMap"
import BrokeragesInAgentBoardMap from "../../../common/components/BrokeragesInAgentBoardMap"
import CenterMapContainer from "../../../common/components/CenterMapContainer"
import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import SectionHeader, {
  SubtitleSectionHeader,
} from "../../../common/components/SectionHeader"
import StartTourDialog from "../../../common/components/StartTourDialog"
import { getConversationBrokerageFullName } from "../../../common/components/inbox/utils"
import {
  trimLongString,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import {
  hideNotification,
  removeNotification,
  selectIsHidingNotification,
  selectNotificationByType,
  selectNotificationsCount,
} from "../../notifications/slice"
import { VERBOSE_DISTANCE_UNITS } from "../brokerages/utils"
import ExperienceCheckIcon from "../resources/icons/experience-check.svg"
import {
  profileLoading,
  selectHasBoard,
  selectProfile,
  selectProfileProgress,
  selectShowWelcomeTour,
} from "../slice"
import {
  NOTIFICATION_TYPES,
  useNavigateToConversation,
  useProfileNotificationRequirements,
  useRateExperienceNotificationRequirements,
  useUpdateAgentTourStatus,
} from "../utils"
import MaximumOffersTourDialog from "./MaximumOffersTourDialog"
import SelectSignupReasonDialog from "./SelectSignupReasonDialog"
import ExperienceRatingStars from "./experience-rating-stars"
import ProfileCircularProgress from "./profile-circular-progress"

const NOTIFICATION_ICON_SIZE = 70

export default function Home() {
  const [homeData, setHomeData] = useState({
    map: {
      agent_location: null,
      brokerages: [],
    },
  })
  const [unreadConversations, setUnreadConversations] = useState([])
  const [replyingToConversationID, setReplyingToConversationID] = useState(null)
  const [replyText, setReplyText] = useState("")
  const [snackbarConversationID, setSnackbarConversationID] = useState(null)
  const [isMapDataLoading, setIsMapDataLoading] = useState(true)
  const [widerAreaRecommendation, setWiderAreaRecommendation] = useState({
    increase_by: 0,
    new_brokerage_count: 0,
  })

  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const profileProgress = useSelector(selectProfileProgress)
  const completeProfileNotification = useSelector(
    selectNotificationByType(NOTIFICATION_TYPES.completeProfile)
  )

  const experienceRatingNotification = useSelector(
    selectNotificationByType(NOTIFICATION_TYPES.experienceRating)
  )
  const notificationsCount = useSelector(selectNotificationsCount)
  const hasBoard = useSelector(selectHasBoard)
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const navigate = useNavigate()
  const navigateToConversation = useNavigateToConversation()

  function handleReplyToMessageButtonClickedWrapper(conversationID) {
    return () => {
      if (conversationID === replyingToConversationID) {
        axios
          .post(`/agents/api/home/conversations/reply/?cid=${conversationID}`, {
            text: replyText,
          })
          .then(() => {
            setReplyingToConversationID(null)
            setReplyText("")
            setSnackbarConversationID(conversationID)
            setUnreadConversations(
              unreadConversations.filter(
                (conversation) => conversation.id !== conversationID
              )
            )
          })
      } else {
        setReplyingToConversationID(conversationID)
      }
    }
  }

  useEffect(
    () => {
      setIsLoading(true)
      axios
        .get("/agents/api/home/")
        .then(({ data }) => {
          setHomeData(data)
          setIsMapDataLoading(false)
        })
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      axios
        .get("/agents/api/home/conversations/")
        .then(({ data }) => setUnreadConversations(data))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      axios.get("/agents/api/wider-area-recommender/").then(({ data }) => {
        setWiderAreaRecommendation(data)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Column id="home" sx={{ p: { xs: 2, sm: 4 } }}>
      <CollapseAnimation in={unreadConversations.length > 0}>
        <SubtitleSectionHeader
          title="Unread Messages"
          subtitle={unreadConversations.length}
          linkTitle="View all"
          linkTarget="/agent/conversations"
        />
        <Column
          sx={{
            mt: 2,
            width: {
              xs: "100%",
              md: "60%",
            },
            "& .MuiPaper-root:nth-of-type(n + 1)": { mt: 2 },
          }}
        >
          {unreadConversations.map((conversation) => (
            <Paper
              elevation={0}
              sx={{
                backgroundColor:
                  replyingToConversationID === conversation.id
                    ? theme.palette.primary.light
                    : "inherit",
                transition: theme.transitions.create("background-color"),
              }}
            >
              <Column>
                <Row sx={{ alignItems: "center" }}>
                  <BrandAvatar
                    brand={{
                      name: conversation.brokerage.company,
                      icon: conversation.brokerage.image,
                    }}
                    size={40}
                    brokerageIconPadding={1.2}
                  />
                  <Row
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexBasis: "100%",
                      ml: 2,
                    }}
                  >
                    <Column>
                      <Typography variant="caption" color="text.secondary2">
                        {conversation.brokerage.company}
                      </Typography>
                      <Typography variant="h6" fontSize="1.1em">
                        {getConversationBrokerageFullName(conversation)}
                      </Typography>
                    </Column>
                    <Typography
                      variant="caption"
                      fontSize="0.8em"
                      color="text.secondary2"
                      sx={{ visibility: { xs: "hidden", sm: "initial" } }}
                    >
                      {format(
                        new Date(conversation.last_message_date),
                        "hh:mmaa"
                      )}
                    </Typography>
                  </Row>
                </Row>
                <Column sx={{ mt: 1, ml: "56px" }}>
                  <Typography variant="body1" fontSize="0.9em">
                    {trimLongString(conversation.last_message_text)}
                  </Typography>
                  <CollapseAnimation
                    in={replyingToConversationID === conversation.id}
                  >
                    <TextField
                      value={replyText}
                      onChange={(e) => setReplyText(e.target.value)}
                      placeholder="Type your reply here"
                      fullWidth
                      autoFocus
                      multiline
                      rows={3}
                      sx={{
                        mt: 2,
                        "& .MuiInputBase-root": { backgroundColor: "white" },
                      }}
                    />
                  </CollapseAnimation>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2, alignSelf: "flex-start" }}
                    onClick={handleReplyToMessageButtonClickedWrapper(
                      conversation.id
                    )}
                    disabled={
                      replyingToConversationID === conversation.id &&
                      replyText.length === 0
                    }
                  >
                    {replyingToConversationID === conversation.id
                      ? "Send Message"
                      : "Reply to Message"}
                  </Button>
                </Column>
              </Column>
            </Paper>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/agent/conversations")}
            sx={{ mt: 4, alignSelf: "center" }}
          >
            View all {unreadConversations.length} messages
          </Button>
        </Column>
      </CollapseAnimation>
      <Snackbar
        open={!!snackbarConversationID}
        onClose={() => setSnackbarConversationID(null)}
        message="Reply sent successfully"
        color="primary"
        autoHideDuration={5000}
        action={
          <Button
            variant="text"
            color="secondary"
            sx={{ color: "primary.contrastText" }}
            onClick={() => navigateToConversation(snackbarConversationID)}
          >
            View
          </Button>
        }
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      />
      <Column
        sx={{ mt: unreadConversations.length > 0 ? 4 : 0, width: "100%" }}
      >
        <SubtitleSectionHeader
          title="Recent Notifications"
          subtitle={notificationsCount}
          linkTitle="View all"
          linkTarget="/agent/profile"
        />
      </Column>
      <CollapseAnimation in={isProfileLoading}>
        <Paper
          elevation={0}
          sx={{ display: "flex", flexDirection: "column", mt: 2 }}
        >
          <NotificationSkeleton />
        </Paper>
      </CollapseAnimation>

      <CollapseAnimation
        in={!!completeProfileNotification || !!experienceRatingNotification}
        timeout={100}
      >
        <Paper
          elevation={0}
          sx={{ display: "flex", flexDirection: "column", mt: 2 }}
        >
          <CollapseAnimation
            in={!!completeProfileNotification && profileProgress !== 100}
            timeout={250}
          >
            <CompleteProfileNotification />
          </CollapseAnimation>
          <CollapseAnimation
            in={!!completeProfileNotification && !!experienceRatingNotification}
          >
            <Divider sx={{ my: 2 }} />
          </CollapseAnimation>
          <CollapseAnimation
            in={!!experienceRatingNotification}
            timeout={250}
            unmountOnExit
          >
            <RateExperienceNotification />
          </CollapseAnimation>
        </Paper>
      </CollapseAnimation>
      <Column sx={{ mt: 4 }}>
        <SectionHeader
          title="Maps"
          linkTitle="Discover brokerages"
          linkTarget="/agent/brokerages"
        />
        <CenterMapContainer
          id="home-map"
          sx={{
            mt: 2,
            height: "450px",
            borderRadius: (theme) => theme.units.borderRadius,
            position: "relative",
          }}
          Map={
            hasBoard ? BrokeragesInAgentBoardMap : BrokeragesAroundZipcodeMap
          }
          mapProps={{
            brokerages: homeData.map.brokerages,
            isBrokeragesLoading: isMapDataLoading || isProfileLoading,
            distanceFromHome: profile.distance_from_work,
            distanceUnits: profile.distance_units,
            mapCenter: homeData.map.agent_location,
            zoomOut: true,
            addBrokerageLinks: true,
          }}
        >
          <Fade
            in={!hasBoard && widerAreaRecommendation.increase_by > 0}
            timeout={2000}
          >
            <Row
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                p: 1,
                pl: 2,
                background: (theme) =>
                  theme.palette.otherwise.gradientBackground,
                opacity: "0.9",
                zIndex: 10,
              }}
            >
              <Typography variant="body2" color="white">
                Increase your search distance by{" "}
                {widerAreaRecommendation.increase_by}{" "}
                {VERBOSE_DISTANCE_UNITS[profile.distance_units]} and{" "}
                <strong>
                  discover {widerAreaRecommendation.new_brokerage_count} more
                  brokers
                </strong>
              </Typography>
              <IconButton onClick={() => navigate("/agent/settings/")}>
                <ExpandCircleDownIcon
                  sx={{ color: "white", transform: "rotate(-90deg)" }}
                />
              </IconButton>
            </Row>
          </Fade>
        </CenterMapContainer>
      </Column>
      <StartTourDialog
        showTourSelector={selectShowWelcomeTour}
        useUpdateTourStatus={useUpdateAgentTourStatus}
      />
      <MaximumOffersTourDialog />
      <SelectSignupReasonDialog />
    </Column>
  )
}

function CompleteProfileNotification() {
  const { title, subtitle, buttonHandler } =
    useProfileNotificationRequirements()

  const dispatch = useDispatch()

  function handleNotificationClosed() {
    dispatch(removeNotification(NOTIFICATION_TYPES.completeProfile))
  }

  return (
    <CloseableNotification
      leftIcon={<ProfileCircularProgress size={NOTIFICATION_ICON_SIZE} />}
      title={<NotificationTitleSubtitle title={title} subtitle={subtitle} />}
      actionButton={
        <Button variant="outlined" size="small" onClick={buttonHandler}>
          View profile
        </Button>
      }
      onClose={handleNotificationClosed}
      isClosing={false}
    />
  )
}

function RateExperienceNotification() {
  const { title, subtitle } = useRateExperienceNotificationRequirements()
  const isHidingNotification = useSelector(selectIsHidingNotification)
  const dispatch = useDispatch()
  const rateExperienceNotification = useSelector(
    selectNotificationByType(NOTIFICATION_TYPES.experienceRating)
  )

  function handleClosed() {
    dispatch(hideNotification({ id: rateExperienceNotification.id }))
  }

  return (
    <>
      <CloseableNotification
        leftIcon={<img src={ExperienceCheckIcon} alt="check icon" />}
        title={<NotificationTitleSubtitle title={title} subtitle={subtitle} />}
        actionButton={<ExperienceRatingStars />}
        isClosing={isHidingNotification}
        onClose={handleClosed}
      />
    </>
  )
}

function CloseableNotification({
  leftIcon,
  title,
  actionButton,
  onClose,
  isClosing,
}) {
  function CloseButton() {
    return (
      <IconButton size="small" onClick={onClose}>
        <CloseIcon sx={{ color: "text.secondary" }} />
      </IconButton>
    )
  }

  const isMobile = useIsMobile()

  return (
    <RowButColumnOnMobile
      sx={{
        justifyContent: "space-between",
        alignItems: { xs: "flex-start", sm: "center" },
        position: "relative",
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        {leftIcon}
        <Box sx={{ ml: 2 }}>{title}</Box>
      </Row>
      <Row
        sx={{ alignSelf: "center", alignItems: "center", mt: { xs: 4, sm: 0 } }}
      >
        {actionButton}
        <Box sx={{ position: "relative", ml: 2 }}>
          {isClosing && (
            <CircularProgress
              variant="indeterminate"
              size="28px"
              sx={{
                position: "absolute",
                top: "3px",
                left: "3px",
                right: 0,
                bottom: 0,
                color: "text.secondary",
              }}
            />
          )}
          {!isMobile && <CloseButton />}
        </Box>
      </Row>
      {isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseButton />
        </Box>
      )}
    </RowButColumnOnMobile>
  )
}

function NotificationTitleSubtitle({ title, subtitle }) {
  return (
    <Column>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1" color="text.secondary">
        {subtitle}
      </Typography>
    </Column>
  )
}

function NotificationSkeleton() {
  return (
    <Row
      sx={{
        alignItems: "center",
      }}
    >
      <Skeleton
        variant="circular"
        width={NOTIFICATION_ICON_SIZE}
        height={NOTIFICATION_ICON_SIZE}
      />
      <Skeleton variant="text" sx={{ ml: 2 }} width="15em" />
    </Row>
  )
}
