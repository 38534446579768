import KeyOffOutlinedIcon from "@mui/icons-material/KeyOffOutlined"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"

export default function EmailConfirmationInvalid() {
  const navigate = useNavigate()
  return (
    <BuildingVerticalSplitLayout>
      <HaloCircularIcon Icon={KeyOffOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 4 }}>
        Let's try that one more time!
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        The link you clicked seems to of expired, but it's ok! Simply click the
        button below and we'll resend you a confirmation email.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/users/resend-confirmation-email/")}
        size="large"
        sx={{ mt: 4, alignSelf: "flex-start" }}
      >
        Resend Confirmation Email
      </Button>
    </BuildingVerticalSplitLayout>
  )
}
