// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import { useTheme } from "@mui/material/styles"
import { alpha } from "@mui/material/styles"
import * as turf from "@turf/turf"
import { useEffect, useRef } from "react"

import {
  removeImage,
  removeLayer,
  removeSource,
  useMapLoader,
} from "../map-utils"
import BrokerageIcon from "../resources/icons/brokerage.png"
import HomePinIcon from "../resources/icons/home-pin.png"
import { useCenterMap } from "./CenterMapContainer"

export default function BrokeragesInAgentBoardMap({
  mapContainer,
  brokerages,
  isLoadingBrokerages,
  mapCenter,
  onMapMovementFinished,
  parentCenterMap,
  onMapCentered,
  addBrokerageLinks = false,
}) {
  const { map, mapLoaded } = useMapLoader(mapContainer)
  const brokeragesEnvelope = useRef()
  const brandCount = useRef(0)
  const canvasRef = useRef()
  const theme = useTheme()

  const LAYERS = {
    brokerages: "brokerages",
    brokerageIcons: "brokerage-icons-",
    brokerageIconClusters: "brokerage-icon-clusters",
    brokerageClusterCount: "brokerage-cluster-count",
  }
  const brokerageIcon = "brokerage-icon-"

  function centerMap() {
    map.current.fitBounds(
      turf.bbox(turf.buffer(brokeragesEnvelope.current, 3, { units: "miles" }))
    )
  }

  useEffect(
    () => {
      if (isLoadingBrokerages || !mapLoaded || !mapCenter) {
        return
      }

      if (brokerages.length > 0) {
        const brokeragesFeatureCollection = turf.featureCollection(
          brokerages.map(({ location }) => turf.point(location.coordinates))
        )
        brokeragesEnvelope.current = turf.polygonSmooth(
          turf.buffer(turf.envelope(brokeragesFeatureCollection), 2.5, {
            units: "miles",
          }),
          { iterations: 1 }
        )

        map.current.addSource(LAYERS.brokerages, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: brokerages.map((brokerage) => ({
              type: "Feature",
              geometry: brokerage.location,
            })),
          },
          cluster: true,
          clusterRadius: 50,
        })

        map.current.addLayer({
          id: LAYERS.brokerageIconClusters,
          type: "circle",
          source: LAYERS.brokerages,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": alpha(theme.palette.primary.main, 0.75),
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              10,
              30,
              20,
              35,
              30,
              40,
            ],
          },
        })

        map.current.on("click", LAYERS.brokerageIconClusters, (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: [LAYERS.brokerageIconClusters],
          })
          const clusterId = features[0].properties.cluster_id
          map.current
            .getSource(LAYERS.brokerages)
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return

              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              })
            })
        })

        map.current.addLayer({
          id: LAYERS.brokerageClusterCount,
          type: "symbol",
          source: LAYERS.brokerages,
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        })

        centerMap()
      }

      const brands = {}
      brokerages.forEach((brokerage) => {
        const brandID = brokerage.brand?.id
        const brandData = brands[brandID]
        if (!brandData) {
          brands[brandID] = { ...brokerage.brand, brokerages: [] }
        }
        brands[brandID].brokerages.push(brokerage)
      })

      brandCount.current = Object.keys(brands).length
      let brandIndex = 0
      for (let brandID in brands) {
        const sourceName = LAYERS.brokerageIcons + brandIndex.toString()
        const brand = brands[brandID]
        map.current.addSource(sourceName, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: brand.brokerages.map((brokerage) => ({
              type: "Feature",
              geometry: brokerage.location,
              properties: brokerage,
            })),
          },
        })

        const image = new Image()
        image.crossOrigin = "Anonymous"
        image.src = (brandID ? brand.icon_circular : BrokerageIcon) + "?nocache"
        image.addEventListener(
          "load",
          ((brandIndex) => () => {
            const canvas = canvasRef.current
            const context = canvas.getContext("2d")
            context.imageSmoothingEnabled = true
            const canvasWidth = canvas.offsetWidth
            context.clearRect(0, 0, canvasWidth, canvasWidth)
            const imageWidth = 32
            const borderWidth = 2
            context.drawImage(
              image,
              borderWidth,
              borderWidth,
              imageWidth,
              imageWidth
            )
            context.beginPath()
            context.strokeStyle = "rgba(220, 52, 149, 0.5)"
            context.lineWidth = borderWidth
            const midCanvas = canvasWidth / 2
            context.arc(
              midCanvas,
              midCanvas,
              imageWidth / 2,
              0,
              Math.PI * 2,
              true
            )
            context.stroke()
            const imageName = brokerageIcon + brandIndex.toString()

            map.current.addImage(
              imageName,
              context.getImageData(0, 0, canvasWidth, canvasWidth)
            )

            map.current.addLayer({
              id: sourceName,
              type: "symbol",
              source: sourceName,
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": imageName,
                "icon-size": 1,
              },
            })

            map.current.on("click", sourceName, (e) => {
              const coordinates = e.features[0].geometry.coordinates.slice()
              const properties = e.features[0].properties

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
              }

              new mapboxgl.Popup({ closeButton: false })
                .setLngLat(coordinates)
                .setHTML(
                  addBrokerageLinks
                    ? `<a href="/agent/brokerages/${properties.id}">${properties.company}</a>`
                    : `<strong>${properties.company}`
                )
                .addTo(map.current)
            })
          })(brandIndex),
          false
        )

        brandIndex += 1
      }

      const homePinIconMarkerHTMLElement = document.createElement("div")
      homePinIconMarkerHTMLElement.id = "home-pin-marker"
      homePinIconMarkerHTMLElement.style.backgroundImage = `url(${HomePinIcon})`
      new mapboxgl.Marker(homePinIconMarkerHTMLElement)
        .setLngLat(mapCenter.coordinates)
        .addTo(map.current)

      const mapRef = map.current

      return () => {
        for (let i = 0; i < brandCount.current; ++i) {
          removeImage(mapRef, `${brokerageIcon}${i}`)
          removeLayer(mapRef, `${LAYERS.brokerageIcons}${i}`)
          removeSource(mapRef, `${LAYERS.brokerageIcons}${i}`)
        }
        removeLayer(mapRef, LAYERS.brokerageIconClusters)
        removeLayer(mapRef, LAYERS.brokerageClusterCount)
        removeSource(mapRef, LAYERS.brokerages)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingBrokerages, mapLoaded, mapCenter]
  )

  useCenterMap(parentCenterMap, centerMap, onMapCentered)
  return <canvas width="36" height="36" ref={canvasRef}></canvas>
}
