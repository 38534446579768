import SendRoundedIcon from "@mui/icons-material/SendRounded"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import LoadingButton from "../../../common/components/LoadingButton"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"
import { axiosClient } from "../../../common/utils"

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const theme = useTheme()

  function handleEmailChanged(e) {
    setEmail(e.target.value)
    setEmailError(null)
  }

  function handleGetLinkButtonClicked() {
    setIsLoading(true)
    axiosClient
      .post("/users/api/request-forgot-password-email/", { email })
      .then(() => {
        navigate("/users/forgot-password-email-sent/")
      })
      .catch(({ response: { data } }) => {
        setEmailError(data.email)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <BuildingVerticalSplitLayout>
      <Typography variant="h4">Recover your account</Typography>
      <Typography variant="body1" sx={{ color: "text.secondary2", mt: 1 }}>
        Start your account recovery process by entering your email below. You'll
        receive an email to confirm.
      </Typography>
      <TextField
        label="Email"
        fullWidth
        value={email}
        onChange={handleEmailChanged}
        error={!!emailError}
        helperText={emailError}
        type="email"
        sx={{ mt: 2 }}
        autoFocus
      />
      <LoadingButton
        variant="contained"
        color="primary"
        endIcon={<SendRoundedIcon />}
        disabled={!email}
        isLoading={isLoading}
        onClick={handleGetLinkButtonClicked}
        sx={{
          alignSelf: "flex-start",
          minWidth: "10em",
          mt: 4,
          [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
            width: "100%",
          },
        }}
        size="large"
      >
        Get reset link
      </LoadingButton>
    </BuildingVerticalSplitLayout>
  )
}
