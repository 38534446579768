import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import CircleIcon from "@mui/icons-material/Circle"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined"
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import RefreshIcon from "@mui/icons-material/RefreshOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { styled, useTheme } from "@mui/material/styles"
import { lighten } from "@mui/material/styles"
import { useTour } from "@reactour/tour"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

import CandidateCardCommon, {
  CANDIDATE_CARD_HEIGHT,
  CANDIDATE_CARD_WIDTH,
  CandidateCardContainer,
} from "../../../common/components/CandidateCard"
import CenterEverythingBox from "../../../common/components/CenterEverythingBox"
import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column, { ColumnButRowOnMobile } from "../../../common/components/Column"
import DetailedHeader from "../../../common/components/DetailedHeader"
import LoadingButton from "../../../common/components/LoadingButton"
import PercentageCircularProgress from "../../../common/components/PercentageCircularProgress"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import { SubtitleSectionHeader } from "../../../common/components/SectionHeader"
import StartTourDialog from "../../../common/components/StartTourDialog"
import InboxWhiteIcon from "../../../common/resources/icons/inbox-white.png"
import {
  AGENT_REQUEST_STATUS_OPTIONS,
  arrayContains,
  openLinkInNewTab,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useHammerSwipeHandler,
  useIsMobile,
  useShowBottomNav,
} from "../../../common/utils"
import { BACKEND_AGENT_LEVELS_OF_INTEREST } from "../../agent/utils"
import RyanBrownAvatar from "../../users/resources/images/ryan-brown.png"
import BaseballCardDrawer, {
  useBaseballCardState,
} from "../candidates/BaseballCardDrawer"
import AgentNameBlueCheck from "../components/AgentNameBlueCheck"
import AnimatedProgressBar from "../components/AnimatedProgressBar"
import TopProducerAgentAvatar from "../components/TopProducerAgentAvatar"
import { WhiteButton, WhiteHeader, WhiteSubtitle } from "../components/White"
import CampaignBackground from "../resources/images/campaigns-bg-desktop.png"
import CampaignMobileBackground from "../resources/images/campaigns-bg-mobile.png"
import CampaignsForeground from "../resources/images/campaigns-fg.png"
import People1Avatar from "../resources/images/people/people1.png"
import People2Avatar from "../resources/images/people/people2.png"
import People3Avatar from "../resources/images/people/people3.png"
import People4Avatar from "../resources/images/people/people4.png"
import People5Avatar from "../resources/images/people/people5.png"
import People6Avatar from "../resources/images/people/people6.png"
import SubscribeCardBackgroundImage from "../resources/images/subscribe-card-bg.png"
import TrainingVideoThumbnail from "../resources/images/video-thumb.jpg"
import {
  NOTIFICATIONS,
  hideNotification,
  profileLoading,
  selectCurrentBrokerage,
  selectHasCampaigns,
  selectShowDashboardInfoNotification,
  selectShowDashboardTour,
  showProDialogForCurrentBrokerage,
} from "../slice"
import {
  AGENT_FIELD_HIDDEN_MARKER,
  TOUR_STEPS,
  getCandidateProfileURL,
  useBrokerageProfileCompleteness,
  useGoSubscribe,
  useIsBrokerageSubscribed,
  useStartAgentConversation,
  useUpdateBrokerageTourStatus,
} from "../utils"
import BuildPageDialog from "./BuildPageDialog"
import UpdateAddressDialog from "./UpdateAddressDialog"

const CARD_TYPES = {
  candidate: 1,
  upgrade: 2,
}

export default function Dashboard() {
  const [isCandidatesLoading, setIsCandidatesLoading] = useState(false)
  const [cards, setCards] = useState([])
  // includes not displayed candidates
  const [totalCandidateCount, setTotalCandidateCount] = useState(0)
  const [runNopeAnimation, setRunNopeAnimation] = useState(false)
  const cardContainerRef = useRef()
  const [stats, setStats] = useState({})
  const { highlightedCandidate, setHighlightedCandidate } =
    useBaseballCardState()
  const [isHiringPipelineStatsLoading, setIsHiringPipelineStatsLoading] =
    useState(false)
  const [hiringPipelineStats, setHiringPipelineStats] = useState({
    total: 1,
    in_conversation: 1,
    proposal_sent: 1,
    proposals_reviewed: 1,
  })
  const videos = [
    {
      title: "The Key to Success at Scale",
      url: "https://player.vimeo.com/video/824784960?h=c036890216",
    },
    {
      title: "You Business Sticking Point",
      url: "https://player.vimeo.com/video/813615605?h=3aed904dc8",
    },
    {
      title: "The Importance of Follow-Up",
      url: "https://player.vimeo.com/video/805992571?h=174a09c667",
    },
  ]
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const [middleCardIndex, setMiddleCardIndex] = useState(0)
  const [openedTrainingVideo, setOpenedTrainingVideo] = useState(null)
  const candidateCardCount = cards.filter(
    (card) => card.card_type === CARD_TYPES.candidate
  ).length
  const shownCards = filterShownCards(cards)
  const moveLeftButtonDisabled =
    candidateCardCount === 0 || middleCardIndex === 0
  const moveRightButtonDisabled = candidateCardCount === 0
  const { profileCompleteness, calculateProfileCompleteness } =
    useBrokerageProfileCompleteness()

  const theme = useTheme()
  const axios = useAuthorizedAxiosClient()
  const navigate = useNavigate()
  const { currentStep: currentTourStep } = useTour()
  const showBottomNav = useShowBottomNav()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const showDashboardInfoNotification = useSelector(
    selectShowDashboardInfoNotification
  )
  const hasCampaigns = useSelector(selectHasCampaigns)
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()

  const CARD_MARGIN = theme.spacing(2)
  const inConversationPercentage = getCandidateStatPercentage(
    hiringPipelineStats.in_conversation
  )
  const proposalSentPercentage = getCandidateStatPercentage(
    hiringPipelineStats.proposal_sent
  )
  const proposalReviewedPercentage = getCandidateStatPercentage(
    hiringPipelineStats.proposals_reviewed
  )

  const profileCompletenessPointSum =
    profileCompleteness.nameComplete +
    profileCompleteness.logoComplete +
    profileCompleteness.descriptionComplete +
    profileCompleteness.mediaComplete
  const PROFILE_COMPLETENESS_POINT_COUNT = 4

  const HIDDEN_AGENT_DATA = [
    {
      firstName: "Ryan",
      lastName: "Brown",
      profileImage: People1Avatar,
    },
    {
      firstName: "Jonathan",
      lastName: "Garner",
      profileImage: People2Avatar,
    },
    {
      firstName: "Kennedy",
      lastName: "Black",
      profileImage: People3Avatar,
    },
    {
      firstName: "Norman",
      lastName: "Blue",
      profileImage: People4Avatar,
    },
    {
      firstName: "Lily",
      lastName: "Geller",
      profileImage: People5Avatar,
    },
    {
      firstName: "Zara",
      lastName: "Anderson",
      profileImage: People6Avatar,
    },
  ]

  function getCandidateStatPercentage(stat) {
    if (stat === 0) {
      return 0
    }
    return Math.round((100 * stat) / hiringPipelineStats.total)
  }

  function handleOnNextCardButtonClicked() {
    const upgradePlanCardIndex = cards.findIndex(
      (card) => card.card_type === CARD_TYPES.upgrade
    )
    if (middleCardIndex === upgradePlanCardIndex) {
      setRunNopeAnimation(true)
      dispatch(showProDialogForCurrentBrokerage("Next button"))
      return
    }
    if (middleCardIndex === cards.length - 1) {
      return
    }
    setMiddleCardIndex(middleCardIndex + 1)
  }

  function handleOnPreviosCardButtonClicked() {
    if (middleCardIndex === 0) {
      return
    }
    setMiddleCardIndex(middleCardIndex - 1)
  }

  function updateMiddleCardIndex(cards) {
    const firstPendingCardIndex = shownCards.findIndex(
      (card) => card.status === AGENT_REQUEST_STATUS_OPTIONS.pending
    )
    if (firstPendingCardIndex > -1) {
      setMiddleCardIndex(firstPendingCardIndex)
    } else {
      setMiddleCardIndex(
        shownCards.length < 3 ? 0 : Math.round(shownCards.length / 2) - 1
      )
    }
  }

  function handleCardUpdated(card) {
    card.card_type = CARD_TYPES.candidate
    setCards(cards.map((c) => (c.id === card.id ? card : c)))
  }

  function handleWatchTrainingVideoButtonClickedWrapper(video) {
    return () => {
      setOpenedTrainingVideo(video)
    }
  }

  function filterShownCards(cards) {
    return cards.filter((card) => !card.hidden)
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsCandidatesLoading(true)
      axios
        .get(`/customers/api/dashboard/${currentBrokerage.id}/`)
        .then(({ data }) => {
          let cards = data.recommendations.map((recommendation) => ({
            ...recommendation,
            card_type: CARD_TYPES.candidate,
          }))
          if (!isBrokerageSubscribed(currentBrokerage)) {
            if (cards.length === 0) {
              dispatch(
                showProDialogForCurrentBrokerage("Auto dashboard no candidates")
              )
            }
          }
          setTotalCandidateCount(
            cards.length + data.hidden_recommendations_count
          )
          if (!isBrokerageSubscribed(currentBrokerage)) {
            cards.push({ card_type: CARD_TYPES.upgrade })
          }
          cards = cards.concat(
            [...Array(Math.min(5, 10))].map((_, i) => {
              const agentData = HIDDEN_AGENT_DATA[i % HIDDEN_AGENT_DATA.length]
              return {
                ...makeCandidateCard(
                  i,
                  AGENT_REQUEST_STATUS_OPTIONS.pending,
                  BACKEND_AGENT_LEVELS_OF_INTEREST.active,
                  agentData.firstName,
                  agentData.lastName,
                  "",
                  agentData.profileImage,
                  10,
                  1.6e6,
                  6e3
                ),
                hidden: true,
              }
            })
          )
          setCards(cards)
          updateMiddleCardIndex(cards)
          setStats({
            yearsInBusiness: data.average_years_in_business,
            salesVolume: data.average_sales_volume,
          })
        })
        .finally(() => setIsCandidatesLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useHammerSwipeHandler(
    cardContainerRef,
    middleCardIndex,
    handleOnNextCardButtonClicked,
    moveLeftButtonDisabled,
    handleOnPreviosCardButtonClicked,
    moveRightButtonDisabled
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsHiringPipelineStatsLoading(true)
      axios
        .get(
          `/customers/api/dashboard/hiring-pipeline-stats/${currentBrokerage.id}/`
        )
        .then(({ data }) => setHiringPipelineStats(data))
        .finally(() => setIsHiringPipelineStatsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      calculateProfileCompleteness(currentBrokerage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useEffect(
    () => {
      if (
        currentTourStep === TOUR_STEPS.candidateCards2 &&
        cards.length === 1
      ) {
        setCards([
          makeCandidateCard(
            2,
            AGENT_REQUEST_STATUS_OPTIONS.pending,
            "undecided",
            AGENT_FIELD_HIDDEN_MARKER,
            AGENT_FIELD_HIDDEN_MARKER,
            "Anonymous Agent #123",
            AGENT_FIELD_HIDDEN_MARKER,
            5,
            9e5,
            CANDIDATE_CARD_SOURCES.tour
          ),
          makeCandidateCard(
            1,
            AGENT_REQUEST_STATUS_OPTIONS.pending,
            "active",
            "Ryan",
            "Brown",
            null,
            RyanBrownAvatar,
            10,
            1.6e6,
            CANDIDATE_CARD_SOURCES.tour
          ),
          ...cards,
        ])
        setMiddleCardIndex(1)
      } else {
        const cards_ = cards.filter(
          (card) => card.source !== CANDIDATE_CARD_SOURCES.tour
        )
        setCards(cards_)
        updateMiddleCardIndex(cards_)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTourStep]
  )

  useEffect(() => {
    if (runNopeAnimation) {
      setTimeout(() => setRunNopeAnimation(false), 500)
    }
  }, [runNopeAnimation])

  return (
    <Column
      sx={{
        py: { xs: 2, sm: 4 },
      }}
      id="dashboard"
    >
      {showBottomNav && (
        <DashboardSection>
          <Column sx={{ mb: 4 }}>
            <Typography variant="h6">
              Welcome to your recruiting dashboard!
            </Typography>
            <Typography variant="body1" color="text.secondary2">
              We're happy your're here, now let's get growing!
            </Typography>
          </Column>
        </DashboardSection>
      )}
      <DashboardSection>
        <Collapse
          in={
            candidateCardCount > 0 &&
            currentTourStep === 0 &&
            showDashboardInfoNotification
          }
        >
          <Paper sx={{ mt: 4, p: { xs: 2, sm: 4 } }} elevation={0}>
            <RowButColumnOnMobile
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", sm: "space-between" },
                alignItems: "center",
              }}
            >
              <RowButColumnOnMobile
                sx={{
                  alignItems: "center",
                }}
              >
                <HaloCircularIcon Icon={InfoOutlinedIcon} size={48} />
                <Column
                  sx={{
                    ml: { xs: 0, sm: 2 },
                    mt: { xs: 2, sm: 0 },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography variant="h6" fontSize="1.1em">
                    These are your interested requests
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary2"
                    sx={{ mt: 1, maxWidth: "40em" }}
                  >
                    They have requested to learn more about your brokerage and
                    may be interested in receiving a hiring proposal from you.
                    Accept their request to begin messaging.
                  </Typography>
                </Column>
              </RowButColumnOnMobile>
              <Button
                variant="outlined"
                sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 4, sm: 0 } }}
                onClick={() =>
                  dispatch(hideNotification(NOTIFICATIONS.dashboardCardInfo))
                }
              >
                Got it
              </Button>
            </RowButColumnOnMobile>
          </Paper>
        </Collapse>
      </DashboardSection>
      <Column
        sx={{
          py: 2,
          mt: 4,
          background: "#9C79DB",
        }}
      >
        <Row
          sx={{
            px: { xs: 2, sm: 4 },
            color: "white",
            "& .MuiTypography-root": { color: "white" },
          }}
        >
          <SubtitleSectionHeader
            title="New requests"
            subtitle={
              <Box sx={{ display: "inline-flex" }}>
                <Row sx={{ alignItems: "center" }}>
                  <Typography variant="h6" display="inline">
                    {totalCandidateCount}
                  </Typography>
                  {candidateCardCount > 0 && (
                    <Tooltip
                      placement="right-start"
                      title="By choosing connect you'll be able message and provide them with proposals. If you choose to ignore them instead, the agent will not be notified of your decision."
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ ml: 1, color: "white", cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </Row>
              </Box>
            }
            linkTitle="View all"
            linkTarget="/brokerage/candidates"
          />
        </Row>
        {isCandidatesLoading ? (
          <CardContainer
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              variant="indeterminate"
              size={64}
              color="secondary"
            />
          </CardContainer>
        ) : (
          <CardContainer
            sx={{
              position: "relative",
              overflowX: "clip",
            }}
            ref={cardContainerRef}
            id="candidate-cards"
          >
            {!isMobile && (
              <BorderedIconButton
                color="primary"
                sx={{
                  position: "absolute",
                  left: theme.spacing(2),
                  top: "45%",
                  zIndex: 2,
                }}
                onClick={handleOnPreviosCardButtonClicked}
                disabled={moveLeftButtonDisabled}
              >
                <NavigateBeforeOutlinedIcon color="primary" />
              </BorderedIconButton>
            )}
            {cards.map((card, index) => {
              let leftPos
              const halfCardWidth = CANDIDATE_CARD_WIDTH / 2
              const nopeAnimationDistance = `${halfCardWidth}em`
              const nopeAnimationDistanceStyle = runNopeAnimation
                ? " - " + nopeAnimationDistance
                : ""
              if (index === middleCardIndex) {
                leftPos = `calc(50% - ${halfCardWidth}em${nopeAnimationDistanceStyle})`
              } else if (index < middleCardIndex) {
                const cardCountFromMiddle = middleCardIndex - index
                leftPos = `calc(50% - ${
                  halfCardWidth + cardCountFromMiddle * CANDIDATE_CARD_WIDTH
                }em - ${CARD_MARGIN} * ${cardCountFromMiddle}${nopeAnimationDistanceStyle})`
              } else {
                const cardCountFromMiddle = index - middleCardIndex
                leftPos = `calc(50% + ${
                  halfCardWidth +
                  CANDIDATE_CARD_WIDTH * (cardCountFromMiddle - 1)
                }em + (${CARD_MARGIN} * ${cardCountFromMiddle})${nopeAnimationDistanceStyle})`
              }
              const position = { left: leftPos, top: theme.spacing(2) }
              const cardOffsetFromMiddle = Math.abs(index - middleCardIndex)
              let scaleFactor = cardOffsetFromMiddle === 0 ? 1 : 0.9
              let opacity = cardOffsetFromMiddle > 1 && !card.hidden ? 0.5 : 1
              const styles = {
                ...position,
                transform: `scale(${scaleFactor})`,
                opacity,
              }
              return card.card_type === CARD_TYPES.candidate ? (
                <CandidateCard
                  key={index}
                  recommendation={card}
                  stats={stats}
                  styles={styles}
                  onShowCandidateDetails={() => setHighlightedCandidate(card)}
                  onCardUpdated={handleCardUpdated}
                />
              ) : (
                <UpgradePlanCard key={index} styles={styles} />
              )
            })}
            <BaseballCardDrawer
              highlightedCandidate={highlightedCandidate}
              setHighlightedCandidate={setHighlightedCandidate}
              onCandidateUpdated={handleCardUpdated}
            />
            {!isMobile && (
              <BorderedIconButton
                color="primary"
                sx={{
                  position: "absolute",
                  right: theme.spacing(2),
                  top: "45%",
                  zIndex: 2,
                }}
                onClick={handleOnNextCardButtonClicked}
                disabled={moveRightButtonDisabled}
              >
                <NavigateNextOutlinedIcon color="primary" />
              </BorderedIconButton>
            )}
          </CardContainer>
        )}
      </Column>
      <DashboardSection>
        <Collapse
          in={profileCompletenessPointSum < PROFILE_COMPLETENESS_POINT_COUNT}
        >
          <TitledSection title="Next steps">
            <Paper
              elevation={0}
              sx={{
                p: { xs: 2, sm: 4 },
              }}
            >
              <RowButColumnOnMobile
                sx={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Row sx={{ alignItems: "flex-start" }}>
                  <PercentageCircularProgress
                    progress={
                      100 *
                      (profileCompletenessPointSum /
                        PROFILE_COMPLETENESS_POINT_COUNT)
                    }
                    size={48}
                    fontSize="1em"
                  />
                  <Column
                    sx={{
                      ml: 2,
                      "& > .MuiBox-root": {
                        mt: 2,
                      },
                    }}
                  >
                    <Typography variant="h6">
                      Complete your brokerage page
                    </Typography>
                    <CheckedProfileCompletenessRow
                      title="Brokerage name"
                      completed={profileCompleteness.nameComplete}
                    />
                    <CheckedProfileCompletenessRow
                      title="Upload your logo"
                      completed={profileCompleteness.logoComplete}
                    />
                    <CheckedProfileCompletenessRow
                      title="Complete your brokerage description"
                      completed={profileCompleteness.descriptionComplete}
                    />
                    <CheckedProfileCompletenessRow
                      title="Add pictures or media"
                      completed={profileCompleteness.mediaComplete}
                    />
                  </Column>
                </Row>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    navigate(
                      `/brokerage/${currentBrokerage.id}/edit/?source=complete-callout`
                    )
                  }
                  sx={{
                    alignSelf: { xs: "center", sm: "flex-start" },
                    mt: { xs: 2, sm: 0 },
                  }}
                >
                  View brokerage page
                </Button>
              </RowButColumnOnMobile>
            </Paper>
          </TitledSection>
        </Collapse>
      </DashboardSection>
      <DashboardSection>
        <TitledSection
          title={
            <DetailedHeader title="Hiring pipeline" subtitle="Last 30 days" />
          }
        >
          <Box
            sx={{
              height: { xs: "fit-content", sm: "250px" },
              "& > *": {
                height: "100%",
              },
            }}
          >
            {isHiringPipelineStatsLoading ? (
              <CenterEverythingBox>
                <CircularProgress color="primary" size={64} />
              </CenterEverythingBox>
            ) : (
              <Paper
                elevation={0}
                sx={{
                  p: { xs: 0, sm: 4 },
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <RowButColumnOnMobile sx={{ height: "100%" }}>
                  <PerformanceReviewTextProgress
                    progress={100}
                    title={`${hiringPipelineStats.total}`}
                    titleVariant="h5"
                    subtitle="Active candidates"
                    ProgressBar={PerformanceReviewBar1}
                  />
                  <PerformanceReviewMobileDivider />
                  <PerformanceReviewTextProgress
                    progress={inConversationPercentage}
                    title={hiringPipelineStats.in_conversation}
                    secondaryTitle={`(${inConversationPercentage}%)`}
                    subtitle="In conversation"
                    ProgressBar={PerformanceReviewBar2}
                  />
                  <PerformanceReviewMobileDivider />
                  <PerformanceReviewTextProgress
                    progress={proposalSentPercentage}
                    title={hiringPipelineStats.proposal_sent}
                    secondaryTitle={`(${proposalSentPercentage}%)`}
                    subtitle="Proposals sent"
                    ProgressBar={PerformanceReviewBar3}
                  />
                  <PerformanceReviewMobileDivider />
                  <PerformanceReviewTextProgress
                    progress={proposalReviewedPercentage}
                    title={hiringPipelineStats.proposals_reviewed}
                    secondaryTitle={`(${proposalReviewedPercentage}%)`}
                    subtitle="Proposals reviewed"
                    ProgressBar={PerformanceReviewBar4}
                    addBorderRight
                  />
                </RowButColumnOnMobile>
              </Paper>
            )}
          </Box>
        </TitledSection>
      </DashboardSection>
      <DashboardSection>
        <Collapse in={!hasCampaigns}>
          <TitledSection title="My Campaigns">
            <RowButColumnOnMobile
              sx={{
                borderRadius: (theme) => theme.units.borderRadius,
                justifyContent: "space-between",
                backgroundImage: `url(${
                  isMobile ? CampaignMobileBackground : CampaignBackground
                })`,
                backgroundSize: "100% 100%",
                p: { xs: 2, sm: 4 },
              }}
            >
              <Column
                sx={{
                  justifyContent: "center",
                  flexBasis: "50%",
                  mt: { xs: 4, sm: 0 },
                  order: { xs: 1, sm: 0 },
                }}
              >
                <WhiteHeader variant={isMobile ? "h5" : "h4"}>
                  Turbocharge your growth with a targeted recruiting campaign
                </WhiteHeader>
                <WhiteSubtitle sx={{ mt: 1 }}>
                  Want more candidates? Begin an active campaign to drive
                  exclusive prospects to your brokerage.
                </WhiteSubtitle>
                <WhiteButton
                  variant="contained"
                  color="primary"
                  sx={{ mt: 4, alignSelf: { xs: "center", sm: "flex-start" } }}
                  size="large"
                  fullWidth={isMobile}
                  onClick={() => openLinkInNewTab("https://smartsetter.io")}
                >
                  Learn more
                </WhiteButton>
              </Column>
              <Box
                sx={{
                  background: `url(${CampaignsForeground})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  alignSelf: "center",
                  width: { xs: "220px", sm: "480px" },
                  height: { xs: "160px", sm: "320px" },
                  marginLeft: { xs: 0, sm: 4 },
                  order: { xs: 0, sm: 1 },
                }}
              />
            </RowButColumnOnMobile>
          </TitledSection>
        </Collapse>
      </DashboardSection>
      <DashboardSection>
        <TitledSection title="Recruiting Academy">
          <Grid container spacing={2}>
            {videos.map((video) => (
              <Grid key={video.url} xs={12} sm={4}>
                <TrainingCard
                  key={video.url}
                  video={video}
                  onWatchButtonClicked={handleWatchTrainingVideoButtonClickedWrapper(
                    video
                  )}
                />
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={!!openedTrainingVideo}
            onClose={() => setOpenedTrainingVideo(null)}
            sx={{
              "& .MuiPaper-root": {
                width: { xs: "100%", sm: "640px" },
                height: "360px",
                maxWidth: "min-content",
                padding: 0,
              },
            }}
            fullScreen={isMobile}
          >
            <iframe
              title={openedTrainingVideo?.title}
              src={openedTrainingVideo?.url}
              width="640"
              height="564"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </Dialog>
        </TitledSection>
      </DashboardSection>
      <UpdateAddressDialog />
      <StartTourDialog
        showTourSelector={selectShowDashboardTour}
        useUpdateTourStatus={useUpdateBrokerageTourStatus}
      />
      <BuildPageDialog />
    </Column>
  )
}

const DashboardSection = styled(Column)(({ theme }) => ({
  paddingInline: theme.spacing(4),
  [theme.breakpoints.only("xs")]: {
    paddingInline: theme.spacing(2),
  },
}))

function TitledSection({ title, children }) {
  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box
        sx={{
          mt: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function CheckedProfileCompletenessRow({ title, completed }) {
  return (
    <Row sx={{ alignItems: "center" }}>
      {completed ? (
        <CheckOutlinedIcon color="primary" />
      ) : (
        <CircleIcon
          sx={{
            color: "text.secondary2 ",
            width: "4px",
            height: "4px",
            mx: 1.2,
          }}
        />
      )}
      <Typography
        variant="body1"
        color="text.secondary2"
        sx={{ ml: 1, textDecoration: completed ? "line-through" : "none" }}
      >
        {title}
      </Typography>
    </Row>
  )
}

const PerformanceReviewVerticalLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    width: "100%",
    height: "100%",
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "white",
      [theme.breakpoints.only("xs")]: {
        borderBottom: "none!important",
      },
    },
  })
)

const PerformanceReviewBar1Border = `2px solid #a38ed0`
const PerformanceReviewBar1 = styled(PerformanceReviewVerticalLinearProgress)(
  ({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderBottom: PerformanceReviewBar1Border,
      [theme.breakpoints.only("xs")]: {
        borderRight: PerformanceReviewBar1Border,
      },
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#c9b1f0",
    },
    "&:hover": {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: lighten("#c9b1f0", 0.15),
      },
    },
  })
)

const PerformanceReviewBar2Border = `2px solid #ad95d9`
const PerformanceReviewBar2 = styled(PerformanceReviewVerticalLinearProgress)(
  ({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderBottom: PerformanceReviewBar2Border,
      [theme.breakpoints.only("xs")]: {
        borderRight: PerformanceReviewBar2Border,
      },
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#af89e8",
    },
    "&:hover": {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: lighten("#af89e8", 0.15),
      },
    },
  })
)

const PerformanceReviewBar3Border = `2px solid #8155ca`
const PerformanceReviewBar3 = styled(PerformanceReviewVerticalLinearProgress)(
  ({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderBottom: PerformanceReviewBar3Border,
      [theme.breakpoints.only("xs")]: {
        borderRight: PerformanceReviewBar3Border,
      },
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#9462e1",
    },
    "&:hover": {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: lighten("#9462e1", 0.15),
      },
    },
  })
)

const PerformanceReviewBar4Border = `2px solid #6d3bc3`
const PerformanceReviewBar4 = styled(PerformanceReviewVerticalLinearProgress)(
  ({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderBottom: PerformanceReviewBar4Border,
      [theme.breakpoints.only("xs")]: {
        borderRight: PerformanceReviewBar4Border,
      },
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#793bd9",
    },
    "&:hover": {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: lighten("#793bd9", 0.15),
      },
    },
  })
)

function PerformanceReviewTextProgress({
  progress,
  title,
  titleVariant = "h6",
  secondaryTitle,
  subtitle,
  ProgressBar,
  addBorderRight,
}) {
  const isMobile = useIsMobile()

  return (
    <ColumnButRowOnMobile
      sx={{
        justifyContent: "space-between",
        flexBasis: { xs: 1, sm: "25%" },
        height: "100%",
        borderLeft: (theme) =>
          isMobile ? "none" : `1px solid ${theme.palette.otherwise.border}`,
        borderRight: (theme) =>
          addBorderRight &&
          !isMobile &&
          `1px solid ${theme.palette.otherwise.border}`,
      }}
    >
      <Column
        sx={{
          p: 2,
          maxWidth: { xs: "128px", sm: "fit-content" },
        }}
      >
        <Row sx={{ alignItems: "flex-end" }}>
          <Typography variant={titleVariant}>{title}</Typography>
          {secondaryTitle && (
            <Typography
              variant="h6"
              color="text.secondary2"
              sx={{ ml: "4px", mb: "-1px" }}
            >
              {secondaryTitle}
            </Typography>
          )}
        </Row>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Column>
      <AnimatedProgressBar
        Component={ProgressBar}
        variant="determinate"
        color="primary"
        value={progress}
        horizontalOnMobile
      />
    </ColumnButRowOnMobile>
  )
}

function PerformanceReviewMobileDivider() {
  const isMobile = useIsMobile()
  return isMobile ? <Divider sx={{ mx: 2 }} /> : null
}

const CardContainer = styled(Box)(({ theme }) => ({
  height: `${CANDIDATE_CARD_HEIGHT}em`,
}))

const BorderedIconButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  background: "#E3D6F7",
  "&.Mui-disabled": {
    borderColor: theme.palette.text.disabled,
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.disabled,
    },
  },
}))

function CandidateCard({
  recommendation,
  stats,
  styles,
  onShowCandidateDetails,
  onCardUpdated,
}) {
  const agent = recommendation.agent

  const [isAcceptingRequest, setIsAcceptingRequest] = useState(false)
  const [isRejectingRequest, setIsRejectingRequest] = useState(false)
  const [isResettingRequest, setIsResettingRequest] = useState(false)

  const navigate = useNavigate()
  const axios = useAuthorizedAxiosClient()
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const { requestStartConversation } = useStartAgentConversation()

  function handleUpdateRequestStatusButtonClickedWrapper(status, setIsLoading) {
    return () => {
      setIsLoading(true)
      axios
        .put(
          `/customers/api/dashboard/${currentBrokerage.id}/update-request-status/${recommendation.id}/`,
          { status }
        )
        .then(({ data }) => {
          onCardUpdated(data)
          segmentAnalytics.track(`Broker ${status} agent`, {
            "Brokerage ID": currentBrokerage.id,
            "Brokerage name": currentBrokerage.company,
            "Agent request ID": recommendation.id,
          })
          if (data.status === AGENT_REQUEST_STATUS_OPTIONS.accepted) {
            requestStartConversation(data)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  function handleAgentProfileButtonClickedWrapper(agentRequest) {
    return () => {
      navigate(getCandidateProfileURL(agentRequest))
    }
  }

  return (
    <CandidateCardCommon
      isHot={agent.replied_to_concierge_message}
      requestStatus={recommendation.status}
      agentName={
        <Link href="#" onClick={onShowCandidateDetails} color="text.primary">
          <AgentNameBlueCheck agentRequest={recommendation} />
        </Link>
      }
      levelOfInterest={agent.level_of_interest}
      yearInBusiness={recommendation.years_in_business}
      salesVolumeLastYear={recommendation.sales_volume_last_year}
      signingBonus={recommendation.signing_bonus}
      stats={stats}
      styles={{
        ...styles,
        filter: recommendation.hidden ? "blur(4px)" : "none",
      }}
      Avatar={
        <IconButton color="secondary" onClick={onShowCandidateDetails}>
          <TopProducerAgentAvatar
            agentRequest={recommendation}
            avatarSize={64}
          />
        </IconButton>
      }
      ActionsRow={
        <Row
          sx={{
            mt: 2,
            justifyContent: "center",
            "& > .MuiButton-root:nth-of-type(n + 2)": {
              ml: 1,
            },
          }}
        >
          {arrayContains(
            [
              AGENT_REQUEST_STATUS_OPTIONS.accepted,
              AGENT_REQUEST_STATUS_OPTIONS.inContact,
            ],
            recommendation.status
          ) ? (
            <>
              <Button
                variant="outlined"
                startIcon={<PersonOutlinedIcon />}
                sx={{ color: "inherit!important" }}
                onClick={handleAgentProfileButtonClickedWrapper(recommendation)}
                fullWidth
              >
                Profile
              </Button>
              <ChatWithAgentButton recommendation={recommendation} />
            </>
          ) : recommendation.status ===
            AGENT_REQUEST_STATUS_OPTIONS.proposalReceived ? (
            <>
              <Button
                variant="outlined"
                startIcon={<BusinessCenterOutlinedIcon />}
                sx={{ color: "inherit!important" }}
                onClick={() =>
                  navigate(
                    `/brokerage/proposals/${recommendation.sent_proposal_id}`
                  )
                }
                fullWidth
              >
                Proposal
              </Button>
              <ChatWithAgentButton recommendation={recommendation} />
            </>
          ) : recommendation.status ===
            AGENT_REQUEST_STATUS_OPTIONS.rejected ? (
            <>
              <Button
                variant="outlined"
                startIcon={<CloseOutlinedIcon />}
                disabled
                sx={{ color: "inherit!important" }}
              >
                Rejected
              </Button>
              <LoadingButton
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={handleUpdateRequestStatusButtonClickedWrapper(
                  AGENT_REQUEST_STATUS_OPTIONS.pending,
                  setIsResettingRequest
                )}
                isLoading={isResettingRequest}
              >
                Reset
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                variant="outlined"
                color="inherit"
                startIcon={<CloseOutlinedIcon />}
                isLoading={isRejectingRequest}
                disabled={recommendation.hidden || isAcceptingRequest}
                onClick={handleUpdateRequestStatusButtonClickedWrapper(
                  AGENT_REQUEST_STATUS_OPTIONS.rejected,
                  setIsRejectingRequest
                )}
                fullWidth
              >
                Decline
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<CheckOutlinedIcon />}
                onClick={handleUpdateRequestStatusButtonClickedWrapper(
                  AGENT_REQUEST_STATUS_OPTIONS.accepted,
                  setIsAcceptingRequest
                )}
                isLoading={isAcceptingRequest}
                disabled={recommendation.hidden || isRejectingRequest}
                fullWidth
              >
                Connect
              </LoadingButton>
            </>
          )}
        </Row>
      }
    />
  )
}

function UpgradePlanCard({ styles }) {
  const goSubscribe = useGoSubscribe("Dashboard card")

  return (
    <CandidateCardContainer
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        background: `url(${SubscribeCardBackgroundImage})`,
        backgroundSize: "cover",
        height: "26.25em",
        borderRadius: "18px",
        ...styles,
      }}
    >
      <Column>
        <WhiteHeader variant="h6">Get unlimited access</WhiteHeader>
        <WhiteSubtitle variant="subtitle1">
          Reveal all of your candidates instantly with a PRO subscription
        </WhiteSubtitle>
        <WhiteButton
          variant="contained"
          color="primary"
          sx={{
            mt: 4,
            alignSelf: "flex-start",
          }}
          onClick={goSubscribe}
        >
          Start PRO Now
        </WhiteButton>
      </Column>
    </CandidateCardContainer>
  )
}

const TrainingCardChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.units.borderRadius,
  backgroundColor: theme.palette.otherwise.lightBackground,
}))

function TrainingCard({ video, onWatchButtonClicked }) {
  return (
    <Card elevation={0} sx={{ p: 0 }}>
      <CardMedia sx={{ height: 140 }} image={TrainingVideoThumbnail} />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 4,
        }}
      >
        <TrainingCardChip label="Remote work" />
        <Typography variant="caption" sx={{ mt: 1 }}>
          {video.title}
        </Typography>
      </CardContent>
      <CardActions sx={{ p: 4, pt: 0 }}>
        <Button variant="outlined" onClick={onWatchButtonClicked}>
          Watch
        </Button>
      </CardActions>
    </Card>
  )
}

function ChatWithAgentButton({ recommendation }) {
  const { isCreatingConversation, requestStartConversation } =
    useStartAgentConversation()
  return (
    <LoadingButton
      isLoading={isCreatingConversation}
      startIcon={
        <img
          src={InboxWhiteIcon}
          style={{ width: "20px", height: "20px" }}
          alt="Inbox"
        />
      }
      onClick={() => requestStartConversation(recommendation)}
      fullWidth
    >
      Chat
    </LoadingButton>
  )
}

const CANDIDATE_CARD_SOURCES = {
  tour: "tour",
  hidden: "hidden",
}

function makeCandidateCard(
  id,
  status,
  levelOfInterest,
  firstName,
  lastName,
  anonymousName,
  profileImage,
  yearsInBusiness,
  salesVolumeLastYear,
  signingBonus,
  source
) {
  return {
    id: id,
    status: status,
    created: "2023-05-24T12:29:47.204081Z",
    agent: {
      id: id,
      level_of_interest: levelOfInterest,
    },
    first_name: firstName,
    last_name: lastName,
    anonymous_name: anonymousName,
    profile_image: profileImage,
    years_in_business: yearsInBusiness,
    sales_volume_last_year: salesVolumeLastYear,
    signing_bonus: signingBonus,
    card_type: CARD_TYPES.candidate,
    source,
  }
}
