import { styled } from "@mui/material/styles"

import { RowButColumnOnMobile } from "./Row"

export default styled(RowButColumnOnMobile)(({ theme }) => ({
  marginTop: theme.spacing(4),
  "& .MuiButton-root:nth-of-type(2)": {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}))
