import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"

import { useIsMobile } from "../utils"
import BlurredBackgroundRow from "./BlurredBackgroundRow"

export default function BrokerageLocationUnknownWarning({ subtitle }) {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const noLocationNotificationHorizontalPosition = isMobile ? "10%" : "25%"

  return (
    <BlurredBackgroundRow
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
        textAlign: "center",
        position: "absolute",
        top: { xs: "80px", sm: "110px" },
        left: noLocationNotificationHorizontalPosition,
        right: noLocationNotificationHorizontalPosition,
        borderRadius: theme.units.borderRadius,
      }}
    >
      <WarningAmberOutlinedIcon color="warning" fontSize="large" />
      <Typography variant="h6">
        Your brokerage's geographical location is unknown!
      </Typography>
      <Typography variant="body1" color="text.secondary2">
        {subtitle}
      </Typography>
    </BlurredBackgroundRow>
  )
}
