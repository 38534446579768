import AddIcon from "@mui/icons-material/Add"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Fade from "@mui/material/Fade"
import IconButton from "@mui/material/IconButton"
import LinearProgress from "@mui/material/LinearProgress"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useSelector } from "react-redux"
import useWebSocket from "react-use-websocket"
import { useElementSize } from "usehooks-ts"

import { selectToken } from "../../../pages/users/slice"
import { useAuthorizedAxiosClient } from "../../utils"
import {
  buildAbsoluteURL,
  handleAxiosUploadProgress,
  useShowBottomNav,
} from "../../utils"
import Column from "../Column"
import MobileFullScreenDialog from "../MobileFullScreenDialog"
import Row from "../Row"
import ChatMessage, { LeftChatMessage, MESSAGE_TYPES } from "./ChatMessage"
import ConversationMenuNoItems, {
  CloseConversationMenuItem,
  CommonConversationMenuItems,
  handleConversationChangedWrapper,
} from "./ConversationMenu"
import IdentityRevealedMessage from "./IdentityRevealedMessage"
import InboxContext from "./InboxContext"
import MessageContextMenu from "./MessageContextMenu"
import ProposalMessage from "./ProposalMessage"
import ProposalRequestMessage from "./ProposalRequestMessage"

export default function ConversationDetailsWrapper({
  onConversationClosed,
  ...props
}) {
  return (
    <MobileFullScreenDialog onClosed={onConversationClosed}>
      <ConversationDetails
        onConversationClosed={onConversationClosed}
        {...props}
      />
    </MobileFullScreenDialog>
  )
}

function ConversationDetails({
  selectedConversation,
  conversationDetails,
  name,
  isLoading,
  onConversationChanged,
  onConversationClosed,
  onConversationDeleted,
  onMessagesUpdated,
  onIdentityRevealed,
  onProposalReceived,
  HeaderContents,
  BelowHeaderContents,
  MessagesFooter,
  profileDataSelector,
  textFieldEnabled = true,
}) {
  const [typingUser, setTypingUser] = useState(null)
  const [clearUserIsTypingTimeoutID, setClearUserIsTypingTimeoutID] =
    useState(null)
  const [conversationMenuAnchorElem, setConversationMenuAnchorElem] =
    useState(null)
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0)
  const [messageContextMenu, setMessageContextMenu] = useState(null)
  const [chatMessage, setChatMessage] = useState("")
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [messageUnderContextMenu, setMessageUnderContextMenu] = useState(null)
  const [isEditingMessage, setIsEditingMessage] = useState(false)
  const [tabFocused, setTabFocused] = useState(true)
  const [messagesContainerHeight, setMessagesContainerHeight] = useState(0)

  const chatMessageInputRef = useRef()
  const [messagesContainer, setMessagesContainer] = useState(null)
  const selectAttachmentInputRef = useRef()
  const token = useSelector(selectToken)
  const profileData = useSelector(profileDataSelector)
  const axios = useAuthorizedAxiosClient()
  const showBottomNav = useShowBottomNav()
  const [headerRef, { height: headerHeight }] = useElementSize()
  const [textFieldRef, { height: textFieldHeight }] = useElementSize()
  const [belowHeaderContentRef, { height: belowHeaderContentHeight }] =
    useElementSize()

  const websocketQueryParams = { token, cid: selectedConversation.id }

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    buildAbsoluteURL("/ws/chat/", "wss"),
    { queryParams: websocketQueryParams }
  )
  const {
    sendJsonMessage: sendUserIsTypingJsonMessage,
    lastJsonMessage: lastUserIsTypingJsonMessage,
  } = useWebSocket(buildAbsoluteURL("/ws/user-is-typing/", "wss"), {
    queryParams: websocketQueryParams,
  })

  const inboxContext = useContext(InboxContext)

  function openConversationMenu(e) {
    setConversationMenuAnchorElem(e.currentTarget)
  }

  function handleConversationMenuClosed() {
    setConversationMenuAnchorElem(null)
  }

  function handleMessageContextMenu(e, message) {
    e.preventDefault()
    const contextMenuPosition =
      messageContextMenu === null
        ? { mouseX: e.clientX - 2, mouseY: e.clientY + 6 }
        : null
    setMessageContextMenu(contextMenuPosition)
    setMessageUnderContextMenu(message)
  }

  function handleMessageContextMenuClosed() {
    setMessageContextMenu(null)
  }

  function handleMessageContextMenuEditClicked() {
    setChatMessage(messageUnderContextMenu.text)
    setMessageContextMenu(null)
    setIsEditingMessage(true)
    chatMessageInputRef.current.focus()
  }

  function handleMessageContextMenuDeleteClicked() {
    sendJsonMessage({ id: messageUnderContextMenu.id, event: "deleted" })
    setMessageContextMenu(null)
  }

  function handleSendMessage() {
    if (chatMessage.length > 0) {
      if (isEditingMessage) {
        sendJsonMessage({
          id: messageUnderContextMenu.id,
          event: "edited",
          text: chatMessage,
        })
      } else {
        sendJsonMessage({ text: chatMessage })
      }
      setIsSendingMessage(true)
    }
  }

  function handleChatOnKeyDownEvent(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    } else {
      sendUserIsTypingJsonMessage({})
    }
  }

  function handleAddAttachmentButtonClicked() {
    selectAttachmentInputRef.current.click()
  }

  function handleAttachmentFileSelected(e) {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append("file", file)
    setIsUploadingAttachment(true)
    axios
      .post(
        `/inbox/api/upload-attachment/?cid=${conversationDetails.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) =>
            handleAxiosUploadProgress(
              progressEvent,
              setAttachmentUploadProgress
            ),
        }
      )
      .finally(() => {
        setIsUploadingAttachment(false)
        e.target.value = null
      })
  }

  function shouldUpdateFavicon(jsonMessage) {
    return jsonMessage.source !== inboxContext.messageSource && !tabFocused
  }

  const messagesContainerRef = useCallback(
    (node) => node !== null && setMessagesContainer(node),
    []
  )

  useEffect(() => {
    window.onblur = () => {
      setTabFocused(false)
    }
    window.onfocus = () => {
      setTabFocused(true)
      setFavicon(favicons.normal)
    }
    return () => {
      window.onblur = undefined
      window.onfocus = undefined
    }
  }, [])

  useEffect(
    () => {
      if (lastJsonMessage !== null) {
        let newMessages
        if (lastJsonMessage.event === "edited") {
          newMessages = conversationDetails.messages.map((message) =>
            message.id === lastJsonMessage.message.id
              ? { ...message, text: lastJsonMessage.message.text, edited: true }
              : message
          )
          setIsEditingMessage(false)
          setChatMessage("")
          setMessageUnderContextMenu(null)
        } else if (lastJsonMessage.event === "deleted") {
          newMessages = conversationDetails.messages.filter(
            (message) => message.id !== lastJsonMessage.message.id
          )
          setMessageUnderContextMenu(null)
        } else {
          newMessages = [
            ...conversationDetails.messages,
            lastJsonMessage.message,
          ]
          let favicon = favicons.normal
          if (shouldUpdateFavicon(lastJsonMessage)) {
            favicon = favicons.notification
          }
          setFavicon(favicon)
        }
        switch (lastJsonMessage.message.type) {
          case MESSAGE_TYPES.text:
            setChatMessage("")
            break
          case MESSAGE_TYPES.identityRevealed:
            onIdentityRevealed(lastJsonMessage.agent_request)
            break
          case MESSAGE_TYPES.proposal:
            onProposalReceived(lastJsonMessage.message)
            break
          default:
            break
        }
        onMessagesUpdated(newMessages)
      }
      setIsSendingMessage(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastJsonMessage]
  )

  useEffect(
    () => {
      if (
        lastUserIsTypingJsonMessage !== null &&
        lastUserIsTypingJsonMessage.user.id !== profileData.id
      ) {
        setTypingUser(lastUserIsTypingJsonMessage.user)
        if (clearUserIsTypingTimeoutID) {
          clearTimeout(clearUserIsTypingTimeoutID)
        }
      }
      return () => {
        setClearUserIsTypingTimeoutID(
          setTimeout(() => setTypingUser(null), 1500)
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastUserIsTypingJsonMessage]
  )

  useLayoutEffect(() => {
    if (messagesContainer) {
      const height = messagesContainer.scrollHeight
      setMessagesContainerHeight(height)
      messagesContainer.scroll({
        top: height + 100,
        behavior: "smooth",
      })
    }
  }, [messagesContainer, conversationDetails])

  // refocus input after being enabled
  useEffect(() => {
    if (!chatMessageInputRef.current) {
      return
    }
    if (!isSendingMessage) {
      chatMessageInputRef.current.focus()
    }
  }, [chatMessageInputRef, isSendingMessage])

  return (
    <Column
      sx={{
        justifyContent: isLoading ? "center" : "space-between",
        alignItems: isLoading ? "center" : "initial",
        flexBasis: showBottomNav ? "100%" : "60%",
      }}
    >
      {isLoading ? (
        <CircularProgress variant="indeterminate" color="primary" size={64} />
      ) : (
        <>
          <HeaderContainer ref={headerRef}>
            {HeaderContents}
            <IconButton onClick={openConversationMenu}>
              <MoreVertIcon />
            </IconButton>
            <ConversationMenuNoItems
              anchorEl={conversationMenuAnchorElem}
              onClose={handleConversationMenuClosed}
            >
              <CommonConversationMenuItems
                conversation={selectedConversation}
                name={name}
                onMuted={handleConversationChangedWrapper(
                  selectedConversation,
                  onConversationChanged
                )}
                onReported={handleConversationChangedWrapper(
                  selectedConversation,
                  onConversationChanged
                )}
                onDeleted={onConversationDeleted}
                onClose={handleConversationMenuClosed}
              />
              <CloseConversationMenuItem
                onClick={onConversationClosed}
                onCloseMenu={handleConversationMenuClosed}
              />
            </ConversationMenuNoItems>
          </HeaderContainer>
          {BelowHeaderContents && (
            <BelowHeaderContents ref={belowHeaderContentRef} />
          )}
          <MessagesColumn
            sx={{
              maxHeight: showBottomNav
                ? `calc(100vh - ${
                    headerHeight + textFieldHeight + belowHeaderContentHeight
                  }px)`
                : "initial",
            }}
            ref={messagesContainerRef}
          >
            {conversationDetails.messages.map((message, index) =>
              message.type === MESSAGE_TYPES.identityRevealed ? (
                <IdentityRevealedMessage key={index} message={message} />
              ) : message.type === MESSAGE_TYPES.proposal ? (
                <ProposalMessage key={index} message={message} />
              ) : message.type === MESSAGE_TYPES.proposalRequest ? (
                <ProposalRequestMessage
                  key={index}
                  conversationDetails={conversationDetails}
                  message={message}
                />
              ) : message.source === inboxContext.messageSource ? (
                <ChatMessage
                  key={index}
                  message={message}
                  conversationDetails={conversationDetails}
                  index={index}
                  onContextMenu={handleMessageContextMenu}
                />
              ) : (
                <LeftChatMessage
                  key={index}
                  message={message}
                  conversationDetails={conversationDetails}
                  index={index}
                />
              )
            )}
            <MessageContextMenu
              contextMenu={messageContextMenu}
              onClose={handleMessageContextMenuClosed}
              onEditClicked={handleMessageContextMenuEditClicked}
              onDeleteClicked={handleMessageContextMenuDeleteClicked}
            />

            {typingUser && (
              <UserIsTypingText
                sx={{
                  top: `${messagesContainerHeight - 32}px`,
                }}
              >
                {typingUser.full_name} is typing...
              </UserIsTypingText>
            )}
            <Fade in={isUploadingAttachment} timeout={1000}>
              <UploadAttachmentRow
                sx={{
                  top: `${messagesContainerHeight - 100}px`,
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={attachmentUploadProgress}
                  color="primary"
                  sx={{ flexBasis: "100%" }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary2"
                  sx={{ ml: 1 }}
                >
                  {attachmentUploadProgress}%
                </Typography>
              </UploadAttachmentRow>
            </Fade>
          </MessagesColumn>
          {MessagesFooter}
          <ChatTextField
            placeholder="Type a message here..."
            variant="standard"
            multiline
            fullWidth
            autoFocus
            value={chatMessage}
            disabled={!textFieldEnabled || isSendingMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyDown={handleChatOnKeyDownEvent}
            InputProps={{
              startAdornment: (
                <IconButton
                  onClick={handleAddAttachmentButtonClicked}
                  disabled={isUploadingAttachment}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.otherwise.lightBackground,
                  }}
                >
                  <AddIcon />
                </IconButton>
              ),
              endAdornment: (
                <Button
                  variant="contained"
                  size="small"
                  disabled={chatMessage.length === 0}
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
              ),
            }}
            inputRef={chatMessageInputRef}
            ref={textFieldRef}
          />
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleAttachmentFileSelected}
            accept="*/*"
            ref={selectAttachmentInputRef}
          />
        </>
      )}
    </Column>
  )
}

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  [theme.breakpoints.only("xs")]: {
    padding: theme.spacing(2),
  },
  borderBottom: `1px solid ${theme.palette.otherwise.border}`,
}))

const MessagesColumn = styled(Column)(({ theme }) => ({
  // set justifyContent to flex-end if message count is small
  justifyContent: "flex-start",
  flexGrow: 10,
  paddingInline: theme.spacing(4),
  [theme.breakpoints.only("xs")]: {
    paddingInline: theme.spacing(2),
  },
  paddingBottom: theme.spacing(2),
  overflowY: "auto",
  gap: theme.spacing(4),
  position: "relative",
}))

const UserIsTypingText = styled((props) => (
  <Typography variant="body2" color="text.secondary2" {...props} />
))(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(2),
  backgroundColor: theme.palette.otherwise.lightBackground,
  borderTopRightRadius: "4px",
}))

const UploadAttachmentRow = styled(Row)(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(1),
  width: "10em",
  position: "absolute",
  left: "40%",
  borderRadius: "25px",
  backgroundColor: theme.palette.otherwise.lightBackground,
}))

const ChatTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2),
    border: "none",
    borderTop: `1px solid ${theme.palette.otherwise.border}`,
  },
  "& .MuiInputBase-input": {
    marginLeft: theme.spacing(2),
  },
  "& .MuiInput-root:before": {
    borderBottom: "none!important",
  },
  "& .MuiInput-root:after": {
    borderBottom: "none",
  },
}))

const favicons = {
  normal: "/highrise-logo.png",
  notification: "/ss-logo-notification.png",
}

function setFavicon(iconURL) {
  document.querySelector("link[rel~='icon']").href = iconURL
}
