import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"
import VisiblityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Link from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"
import GlassesIcon from "../../../common/resources/icons/glasses-active.svg"
import DialogLayout from "../brokerage-detail/DialogLayout"
import { VISIBILITY_TAB_NAME } from "../settings"

export default function RevealIdentityDialog({
  open,
  onClose,
  onRevealButtonClicked,
  isLoading,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogLayout
        Icon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <HaloCircularIcon
              DirectIcon={
                <img
                  src={GlassesIcon}
                  alt="glasses"
                  style={{ width: "1.4em", height: "1.4em" }}
                />
              }
            />
            <Chip
              icon={<ArrowForwardOutlinedIcon />}
              color="primary"
              sx={{
                position: "absolute",
                top: "34%",
                left: "40%",
                zIndex: 2,
                "& .MuiChip-label": { pl: 0 },
                backgroundColor: "primary.main",
                borderRadius: "25px",
              }}
            />
            <HaloCircularIcon Icon={VisiblityOutlinedIcon} />
          </Box>
        }
        title="Make myself visible to this brokerage only?"
        description={`This brokerage will have access to view your name and profile. This will not affect your status with other brokerages.`}
        footer={
          <Link
            variant="body1"
            component={RouterLink}
            to={`/agent/settings?tab=${VISIBILITY_TAB_NAME}`}
            color="primary.main"
            sx={{ mt: 1 }}
          >
            Review visibility settings
          </Link>
        }
      />
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton isLoading={isLoading} onClick={onRevealButtonClicked}>
          Reveal identity
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
