import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router-dom"

import Row from "./Row"

export default function SectionHeader({ title, linkTitle, linkTarget }) {
  return (
    <Row
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Link to={linkTarget} component={RouterLink}>
        {linkTitle}
      </Link>
    </Row>
  )
}

export function SubtitleSectionHeader({
  title,
  subtitle,
  linkTitle,
  linkTarget,
}) {
  return (
    <SectionHeader
      title={
        <>
          {title}{" "}
          <Typography
            variant="h6"
            component="span"
            sx={{ ml: 1, color: "text.secondary", display: "inline" }}
          >
            {subtitle}
          </Typography>
        </>
      }
      linkTitle={linkTitle}
      linkTarget={linkTarget}
    />
  )
}
