import Pagination from "@mui/material/Pagination"

export default function ConversationListPagination({
  pageIndex,
  setPageIndex,
  totalConversationsCount,
}) {
  const CONVERSATIONS_PER_PAGE = 10
  return (
    <Pagination
      page={pageIndex}
      onChange={(_, page) => setPageIndex(page)}
      count={Math.ceil(totalConversationsCount / CONVERSATIONS_PER_PAGE)}
      sx={{ mt: 2, alignSelf: "flex-end" }}
    />
  )
}
