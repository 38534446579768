import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Wizard, useWizard } from "react-use-wizard"

import ActionButtonsContainer from "../../../../common/components/ActionButtonsContainer"
import CircularProgressBackdrop from "../../../../common/components/CircularProgressBackdrop"
import LoadingButton from "../../../../common/components/LoadingButton"
import BrokerageVerticalSplitLayout from "../../../users/components/BrokerageAgentPreview"
import { selectIsAnonymous } from "../../../users/slice"
import { useGetAgentRequestData } from "../../../users/utils"
import { useTrackSignupWizards } from "../../utils"
import {
  useConfirmClaimBrokerage,
  useGetConfirmBrokerageDetails,
} from "../confirm-brokerage/Common"
import {
  ContentSection as ConfirmBrokerageContentSection,
  HeaderSection as ConfirmBrokerageHeaderSection,
} from "../confirm-brokerage/Common"
import SelectBrokerageCommon from "./Common"

export default function SelectBrokerageWizard({
  selectedBrokerage,
  setSelectedBrokerage,
  onReportClaimedBrokerage,
  onCreateNewBrokerage,
  onAnonymousPreviousButtonClicked,
  onBrokerageSelected,
}) {
  return (
    <Wizard>
      <SelectBrokerageStep
        onBrokerageSelected={setSelectedBrokerage}
        onReportClaimedBrokerage={onReportClaimedBrokerage}
        onCreateNewBrokerage={onCreateNewBrokerage}
        onAnonymousPreviousButtonClicked={onAnonymousPreviousButtonClicked}
      />
      <ConfirmAddBrokerageStep
        brokerage={selectedBrokerage}
        onBrokerageSelected={onBrokerageSelected}
      />
    </Wizard>
  )
}

function SelectBrokerageStep({
  onBrokerageSelected,
  onReportClaimedBrokerage,
  onCreateNewBrokerage,
  onAnonymousPreviousButtonClicked,
}) {
  const { nextStep } = useWizard()
  const { agentRequestData } = useGetAgentRequestData()
  const [searchParams, setSearchParams] = useSearchParams()
  const isAnonymous = useSelector(selectIsAnonymous)
  const stepTitle = "Now let's connect your brokerage"
  useTrackSignupWizards(1, stepTitle)

  function handleBrokerageSelected(brokerage) {
    onBrokerageSelected(brokerage)
    nextStep()
  }

  useEffect(
    () => {
      if (!agentRequestData.brokerage) {
        return
      }

      handleBrokerageSelected(agentRequestData.brokerage)
      const newURLParams = {}
      for (const [param, value] in searchParams.entries()) {
        if (param !== "arid" && param !== "n") {
          newURLParams[param] = value
        }
      }
      setSearchParams(newURLParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agentRequestData]
  )

  return (
    <BrokerageVerticalSplitLayout>
      <Typography variant="h4">{stepTitle}</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Add your office location by searching the list below
      </Typography>
      <SelectBrokerageCommon
        onBrokerageSelected={handleBrokerageSelected}
        onReportClaimedBrokerage={onReportClaimedBrokerage}
        onCreateNewBrokerage={onCreateNewBrokerage}
      />
      {isAnonymous && (
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4, width: "50%" }}
          onClick={onAnonymousPreviousButtonClicked}
        >
          Edit account info
        </Button>
      )}
    </BrokerageVerticalSplitLayout>
  )
}

function ConfirmAddBrokerageStep({ brokerage, onBrokerageSelected }) {
  const { brokerageDetails, isLoadingBrokerageDetails, loadBrokerageDetails } =
    useGetConfirmBrokerageDetails()
  const { isClaimingBrokerage, confirmClaimBrokerage } =
    useConfirmClaimBrokerage()
  const { previousStep } = useWizard()
  const navigate = useNavigate()
  const isAnonymous = useSelector(selectIsAnonymous)
  useTrackSignupWizards(2, "Confirm your brokerage")

  function handleYesButtonClicked() {
    if (isAnonymous) {
      onBrokerageSelected(brokerageDetails)
    } else {
      confirmClaimBrokerage(brokerageDetails).then(() =>
        navigate("/brokerage/dashboard")
      )
    }
  }

  useEffect(
    () => {
      if (!brokerage) {
        return
      }
      loadBrokerageDetails(brokerage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brokerage]
  )

  return (
    <BrokerageVerticalSplitLayout>
      <ConfirmBrokerageHeaderSection brokerage={brokerageDetails} />
      <ConfirmBrokerageContentSection
        brokerageDetails={brokerageDetails}
        showBrokerageStats={false}
      />
      <ActionButtonsContainer>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => previousStep()}
          fullWidth
        >
          Choose a different brokerage
        </Button>
        <LoadingButton
          isLoading={isClaimingBrokerage}
          onClick={handleYesButtonClicked}
          fullWidth
        >
          Yes that's me
        </LoadingButton>
      </ActionButtonsContainer>
      <CircularProgressBackdrop
        open={isLoadingBrokerageDetails}
        backdropSX={{ width: "50vw", right: undefined }}
      />
    </BrokerageVerticalSplitLayout>
  )
}
