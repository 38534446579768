import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useState } from "react"
import { Link as RouterLink, useSearchParams } from "react-router-dom"
import { useWizard } from "react-use-wizard"

import CircularIcon from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import PhoneAgreementCheckbox from "../../../common/components/PhoneAgreementCheckbox"
import BrokerageVerticalSplitLayout from "../components/BrokerageAgentPreview"
import { useHandleStepInputKeyDownEventWrapper } from "../components/SignupWizardSteps"
import {
  useSignupCampaign,
  useTrackSignupStep,
  useValidateSignupData,
} from "../utils"

export function useCommonBrokerageSignupState() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const {
    email,
    setEmail,
    emailError,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
    validateSignupData,
    isValidatingData,
  } = useValidateSignupData({ defaultEmail: "" })
  const [brokerageDetails, setBrokerageDetails] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const searchParams = useSearchParams()[0]
  const { getSignupCampaign } = useSignupCampaign()

  function getSignupData(password) {
    return {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
      brokerage_id: brokerageDetails.id,
      sales_rep: searchParams.get("srid"),
      password,
      signup_campaign: getSignupCampaign(),
      forced_to_pay: !!searchParams.get("ftp"),
    }
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    emailError,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
    validateSignupData,
    isValidatingData,
    brokerageDetails,
    setBrokerageDetails,
    isSubmitting,
    setIsSubmitting,
    getSignupData,
  }
}

export function PersonalInfoStep({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  handlePhoneChanged,
  phoneError,
  email,
  handleEmailChanged,
  emailError,
  validateSignupData,
  isValidatingData,
}) {
  const { nextStep, activeStep } = useWizard()
  const stepTitle = "Tell us about yourself"
  const isNextEnabled = !!(
    firstName &&
    lastName &&
    phone &&
    !phoneError &&
    email &&
    !emailError
  )

  const handleKeyDown = useHandleStepInputKeyDownEventWrapper(isNextEnabled)

  useTrackSignupStep(activeStep, stepTitle)

  function handleNextButtonClicked() {
    validateSignupData().then(() => nextStep())
  }

  return (
    <BrokerageVerticalSplitLayout>
      <Typography variant="h4">{stepTitle}</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Your name will be shown on the brokerage account
      </Typography>
      <Column
        sx={{
          mt: 4,
        }}
      >
        <Grid container xs={12} rowSpacing={4} columnSpacing={2}>
          <TextFieldContainer>
            <TextField
              label="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              required
              autoFocus
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextField
              label="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              required
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextField
              label="Phone number"
              value={phone}
              type="tel"
              onChange={handlePhoneChanged}
              onKeyDown={handleKeyDown}
              error={!!phoneError}
              helperText={phoneError}
              fullWidth
              required
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextField
              label="Email"
              value={email}
              type="email"
              onChange={handleEmailChanged}
              onKeyDown={handleKeyDown}
              error={!!emailError}
              helperText={emailError}
              fullWidth
              required
            />
          </TextFieldContainer>
        </Grid>
        <PhoneAgreementCheckbox />
        <Column sx={{ mt: 4 }}>
          <Grid container columnSpacing={4}>
            <TextFieldContainer>
              <LoadingButton
                disabled={!isNextEnabled}
                onClick={handleNextButtonClicked}
                isLoading={isValidatingData}
                fullWidth
              >
                Next
              </LoadingButton>
            </TextFieldContainer>
          </Grid>
        </Column>
        <Typography
          variant="body1"
          color="text.secondary2"
          sx={{ mt: 4, alignSelf: { xs: "center", sm: "flex-start" } }}
        >
          Already have an account?{" "}
          <Link
            component={RouterLink}
            to="/users/login"
            underline="hover"
            color="primary"
            fontSize="inherit"
          >
            Sign in
          </Link>
        </Typography>
      </Column>
    </BrokerageVerticalSplitLayout>
  )
}

export function StepLayout({ Icon, title, subtitle, children }) {
  return (
    <BrokerageVerticalSplitLayout>
      <CircularIcon Icon={Icon} />
      <Typography variant="h4" sx={{ mt: 4 }}>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        {subtitle}
      </Typography>
      {children}
    </BrokerageVerticalSplitLayout>
  )
}

function TextFieldContainer({ children }) {
  return (
    <Grid xs={12} md={6}>
      {children}
    </Grid>
  )
}
