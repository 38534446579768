import { useNavigate } from "react-router"

import SetPasswordPage from "../components/SetPasswordPage"
import {
  getUserHomeURL,
  useHandlePasswordSet,
  useUserTypeAndSearchParams,
} from "../utils"

export default function Page({ WrapperLayout, onSuccess = () => {} }) {
  const navigate = useNavigate()
  const handlePasswordSet = useHandlePasswordSet()
  const { brokerageSearchParams } = useUserTypeAndSearchParams()
  let urlQueryParams = brokerageSearchParams.toString()

  return (
    <SetPasswordPage
      setPasswordUrl="/users/api/set-password/"
      successCallback={({ data }) => {
        handlePasswordSet(data, onSuccess)
        navigate(
          getUserHomeURL(data.type, data.brokerage_count) +
            (urlQueryParams && `?${urlQueryParams}`)
        )
      }}
      WrapperLayout={WrapperLayout}
    />
  )
}
