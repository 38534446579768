import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

export default function IconTitleValue({ Icon, title, value, defaultValue }) {
  return (
    <IconTitleValueLayout Icon={Icon} title={title}>
      <Typography
        variant="body1"
        color={!!value ? "inherit" : "text.secondary2"}
        fontStyle={!!value ? "normal" : "italic"}
        maxWidth={"20em"}
      >
        {value || defaultValue}
      </Typography>
    </IconTitleValueLayout>
  )
}

export function IconTitleValueLayout({ Icon, title, children }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Icon />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 2,
        }}
      >
        <Typography variant="subtitle1" color="text.secondary2">
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  )
}
