import { styled } from "@mui/material/styles"

import Column from "../Column"

export default styled(Column)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.otherwise.lightBackground,
  borderBottomLeftRadius: theme.units.borderRadius,
  borderBottomRightRadius: theme.units.borderRadius,
}))
