import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined"
import { styled } from "@mui/material/styles"
import { useSelector } from "react-redux"

import BorderedChip, {
  PurpleBorderedChip,
} from "../../../common/components/BorderedChips"
import { selectIsFreeRiding } from "../slice"
import { SUBSCRIPTION_STATUSES } from "../utils"

const BasicSubscriptionChip = styled(BorderedChip)(({ theme }) => ({
  color: theme.palette.text.secondary2,
  borderColor: theme.palette.text.secondary2,
}))

export default function SubscriptionStatusChip({ subscription, addIcon }) {
  const isFreeRiding = useSelector(selectIsFreeRiding)
  if (
    !isFreeRiding &&
    (!subscription || subscription.status !== SUBSCRIPTION_STATUSES.active)
  ) {
    return <BasicSubscriptionChip label="Basic" size="small" />
  }
  return (
    <PurpleBorderedChip
      color="primary"
      label={isFreeRiding ? "Free" : "Pro"}
      size="small"
      icon={addIcon ? <WorkspacePremiumOutlinedIcon /> : undefined}
    />
  )
}
