import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useState } from "react"

import DigitCodeTextFields, {
  useDigitCodeTextFieldsState,
} from "../../../common/components/DigitCodeTextFields"
import LoadingButton from "../../../common/components/LoadingButton"
import { useIsMobile } from "../../../common/utils"
import SMSConfirmedIcon from "../resources/images/phone-confirmed.png"
import SMSReadIcon from "../resources/images/phone-read-sms.png"
import SMSReceivedIcon from "../resources/images/phone-sms-received.png"
import { mixpanelTrackHandleConfirmed, useVerifyPhoneCode } from "../utils"
import ResendActivationCodeLink from "./ResendActivationCodeLink"
import StepContentContainer from "./StepContentContainer"

export default function VerifyPhoneCodeDialog({
  open,
  onClose,
  phone,
  handlePhoneVerified = () => {},
}) {
  const {
    digitCode,
    setDigitCode,
    digitString,
    error: digitCodeError,
    setError: setDigitCodeError,
  } = useDigitCodeTextFieldsState()
  const { isVerifying, verifyPhoneCode } = useVerifyPhoneCode(
    "/agents/api/verify-phone-code/",
    true
  )
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const gtmTrack = useGTMDispatch()
  const isNextEnabled = digitString.length === 6 && !digitCodeError
  const isMobile = useIsMobile()

  function handleNextButtonClicked() {
    verifyPhoneCode(phone, digitString, setDigitCodeError).then(({ data }) => {
      mixpanelTrackHandleConfirmed()
      gtmTrack({ event: "sms_verification_code_submitted" })
      onClose(true)
      handlePhoneVerified(data)
    })
  }

  function handleNeedHelpLinkClicked(e) {
    setHelpDialogOpen(true)
    e.preventDefault()
  }

  function handleHelpDialogClosed() {
    setHelpDialogOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h4">
          We've sent you an activation code via SMS
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          You should be receiving an activation code on your phone SMS messages
          within 2 minutes. Please type it in the field below:
        </Typography>
        <StepContentContainer>
          <DigitCodeTextFields
            digitCode={digitCode}
            setDigitCode={setDigitCode}
            error={digitCodeError}
            setError={setDigitCodeError}
            onFull={handleNextButtonClicked}
            large={!isMobile}
          />
        </StepContentContainer>
        <ResendActivationCodeLink phone={phone} />
        <Link
          href="#"
          color="primary.main"
          underline="always"
          sx={{ display: "flex", alignItems: "center", mt: 2 }}
          onClick={handleNeedHelpLinkClicked}
        >
          <HelpOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} /> Need help
        </Link>
        <Dialog
          open={helpDialogOpen}
          onClose={handleHelpDialogClosed}
          maxWidth="sm"
          fullScreen={isMobile}
        >
          <DialogTitle variant="h5" textAlign="center">
            What do I need to do now?
          </DialogTitle>
          <Grid container spacing={2} padding={0}>
            <HelpStep
              stepIndex={1}
              icon={SMSReceivedIcon}
              description="Open your SMS messaging app on your phone"
            />
            <HelpStep
              stepIndex={2}
              icon={SMSReadIcon}
              description="Find HighRise's text with the activation code"
            />
            <HelpStep
              stepIndex={3}
              icon={SMSConfirmedIcon}
              description="Type the activation code on the indicated area and click Next button"
            />
          </Grid>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpDialogClosed}
              sx={{ width: "18em" }}
            >
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose()}>
          Go back
        </Button>
        <LoadingButton
          isLoading={isVerifying}
          onClick={handleNextButtonClicked}
          disabled={!isNextEnabled}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function HelpStep({ stepIndex, icon, description }) {
  return (
    <Grid xs={12} sm={4}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          p: 2,
          height: "100%",
        }}
      >
        <img
          src={icon}
          style={{ width: "48px", height: "auto" }}
          alt={`Step ${stepIndex}`}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Step {stepIndex}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {description}
        </Typography>
      </Paper>
    </Grid>
  )
}
