import Avatar from "@mui/material/Avatar"

import BrokerageGrayIcon from "../resources/icons/brokerage-gray.png"

export default function BrandAvatar({
  brand,
  size,
  border,
  brokerageIconPadding = "10px",
}) {
  const icon = brand && brand.icon
  const missingIconPadding = icon ? 0 : brokerageIconPadding
  return (
    <Avatar
      src={icon ? icon : BrokerageGrayIcon}
      alt={brand ? brand.name : "Generic brokerage icon"}
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        background: (theme) => theme.palette.otherwise.lightBackground,
        "& img": { padding: missingIconPadding },
        border: border,
      }}
    />
  )
}
