import AddIcCallIcon from "@mui/icons-material/AddIcCall"
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { forwardRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"

import BrandAvatar from "../../../common/components/BrandAvatar"
import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import CenteredLoadingAndNoConversationsIndicator, {
  shouldShowIndicator,
} from "../../../common/components/inbox/CenteredLoadingAndNoConversationsIndicator"
import { MESSAGE_TYPES } from "../../../common/components/inbox/ChatMessage"
import {
  AgentConversation,
  useCommonConversationHandlers,
  useConversationsLoader,
} from "../../../common/components/inbox/Conversation"
import ConversationDetails from "../../../common/components/inbox/ConversationDetails"
import ConversationFilterRow, {
  useConversationFiltersState,
} from "../../../common/components/inbox/ConversationFilterRow"
import ConversationListPagination from "../../../common/components/inbox/ConversationListPagination"
import ConversationsContainer from "../../../common/components/inbox/ConversationsContainer"
import IdentityRevealedChip from "../../../common/components/inbox/IdentityRevealedChip"
import InboxContext, {
  MESSAGE_SOURCES,
} from "../../../common/components/inbox/InboxContext"
import MainContainer from "../../../common/components/inbox/MainContainer"
import {
  getConversationBrokerageFullName,
  useConversationDetailsLoader,
} from "../../../common/components/inbox/utils"
import GlassesIcon from "../../../common/resources/icons/glasses.svg"
import GoldDiamondIcon from "../../../common/resources/icons/gold-diamond.png"
import {
  openLinkInNewTab,
  segmentAnalytics,
  useAuthorizedAxiosClient,
} from "../../../common/utils"
import RevealIdentityDialog from "../components/reveal-identity-dialog"
import {
  decrementUnreadConversationsCount,
  openPhoneDialog,
  selectProfile,
} from "../slice"
import ConfirmRequestProposalDialog from "./ConfirmRequestProposalDialog"

const BROKERAGE_AVATAR_SIZE = 48

export default function Inbox() {
  const {
    searchQuery,
    setSearchQuery,
    selectedConversationType,
    conversationTypeSelectorMenuAnchorElem,
    handleConversationTypeChanged,
    handleConversationTypesSelectorButtonClicked,
    handleConversationTypesSelectorMenuClosed,
  } = useConversationFiltersState()
  const {
    conversations,
    setConversations,
    isLoadingConversations,
    loadConversations,
    pageIndex,
    setPageIndex,
    totalConversationCount,
    hasAnyConversations,
    setHasAnyConversations,
  } = useConversationsLoader(
    "/agents/api/conversations/",
    searchQuery,
    selectedConversationType
  )

  const [isRevealIdentityDialogOpen, setIsRevealIdentityDialogOpen] =
    useState(false)
  const [isRevealingIdentity, setIsRevealingIdentity] = useState(false)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  const inboxContextData = {
    apiURLPrefix: "agents",
    messageSource: MESSAGE_SOURCES.agent,
    conversationMutedPropertyName: "agent_muted",
    conversationReportedPropertyName: "agent_report_reason",
  }
  const inboxContext = useState(inboxContextData)[0]

  const {
    selectedConversationIndex,
    handleConversationSelected,
    handleConversationClosed,
    handleConversationUpdated,
    handleConversationDeletedWrapper,
  } = useCommonConversationHandlers({
    conversations,
    setConversations,
    decrementUnreadConversationsCount,
  })

  const selectedConversation = conversations[selectedConversationIndex]

  const { isLoading, conversationDetails, setConversationDetails } =
    useConversationDetailsLoader(
      inboxContextData.apiURLPrefix,
      selectedConversation
    )

  const axios = useAuthorizedAxiosClient()

  function handleRevealIdentityButtonClicked() {
    setIsRevealingIdentity(true)
    axios
      .post(
        `/agents/api/conversations/${selectedConversation.id}/reveal-identity/`
      )
      .then(({ data }) => {
        setConversationDetails({
          ...conversationDetails,
          ...data,
          messages: [
            ...conversationDetails.messages,
            { type: MESSAGE_TYPES.identityRevealed },
          ],
        })
        setIsRevealIdentityDialogOpen(false)
      })
      .finally(() => setIsRevealingIdentity(false))
  }

  function handleProposalReceived(message) {
    setConversations(
      conversations.map((conversation) =>
        conversation.id === selectedConversation.id
          ? { ...selectedConversation, has_proposal: true }
          : conversation
      )
    )
    setConversationDetails({
      ...conversationDetails,
      proposal: {
        id: message.proposal.id,
        name: message.proposal.proposal.name,
      },
    })
  }

  function handleRevealIdentityDialogClosed() {
    setIsRevealIdentityDialogOpen(false)
  }

  function handleConversationSelectedWrapper(index) {
    return () => {
      handleConversationSelected(index)
    }
  }

  function handleVerifyPhoneLinkClicked(e) {
    e.preventDefault()
    dispatch(openPhoneDialog())
  }

  useEffect(
    () => {
      loadConversations(handleConversationSelected).then((data) =>
        setHasAnyConversations(data.results.length > 0)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <InboxContext.Provider value={inboxContext}>
      <MainContainer>
        {shouldShowIndicator(conversations.length, hasAnyConversations) ? (
          <CenteredLoadingAndNoConversationsIndicator
            isLoadingConversations={isLoadingConversations}
            noConversationsTitle="Start chatting and find your next brokerage"
            noConversationsSubtitle={
              <>
                Go to your{" "}
                <Link
                  component={RouterLink}
                  to="/agent/brokerages/"
                  underline="always"
                  color="primary"
                >
                  brokerages
                </Link>{" "}
                page to find your next brokerage
              </>
            }
          />
        ) : (
          <>
            <ConversationsContainer>
              <ConversationFilterRow
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                conversationTypeSelectorMenuAnchorElem={
                  conversationTypeSelectorMenuAnchorElem
                }
                selectedConversationType={selectedConversationType}
                handleConversationTypeChanged={handleConversationTypeChanged}
                handleConversationTypesSelectorButtonClicked={
                  handleConversationTypesSelectorButtonClicked
                }
                handleConversationTypesSelectorMenuClosed={
                  handleConversationTypesSelectorMenuClosed
                }
                searchPlaceholder="Search for brokerage"
              />
              <Column
                sx={{
                  mt: 2,
                  "& .MuiBox-root:nth-of-type(n + 2)": {
                    mt: 1,
                  },
                }}
              >
                {conversations.map((conversation, index) => (
                  <AgentConversation
                    key={conversation.id}
                    conversation={conversation}
                    onConversationChanged={handleConversationUpdated}
                    onConversationDeleted={handleConversationDeletedWrapper(
                      index
                    )}
                    selectedConversation={selectedConversation}
                    onClick={handleConversationSelectedWrapper(index)}
                  />
                ))}
              </Column>
              <ConversationListPagination
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                totalConversationsCount={totalConversationCount}
              />
            </ConversationsContainer>

            {selectedConversation && (
              <ConversationDetails
                selectedConversation={selectedConversation}
                conversationDetails={conversationDetails}
                name={selectedConversation.brokerage.company}
                isLoading={isLoading}
                onConversationClosed={handleConversationClosed}
                onConversationChanged={handleConversationUpdated}
                onConversationDeleted={handleConversationDeletedWrapper(
                  selectedConversationIndex
                )}
                onMessagesUpdated={(messages) =>
                  setConversationDetails({ ...conversationDetails, messages })
                }
                onIdentityRevealed={() => {}}
                onProposalReceived={handleProposalReceived}
                profileDataSelector={selectProfile}
                HeaderContents={
                  <ConversationDetailsHeaderContents
                    selectedConversation={selectedConversation}
                    conversationDetails={conversationDetails}
                    onCloseConversation={handleConversationClosed}
                  />
                }
                BelowHeaderContents={
                  !profile.phone_verified
                    ? forwardRef((props, ref) => (
                        <BelowHeaderContentsContainer ref={ref}>
                          <Row sx={{ alignItems: "center" }}>
                            <AddIcCallIcon color="primary" fontSize="small" />
                            <Typography
                              variant="body1"
                              fontWeight={450}
                              sx={{ ml: 1 }}
                            >
                              Can't reply until phone is verified
                            </Typography>
                          </Row>
                          <Link
                            color="primary.main"
                            underline="always"
                            onClick={handleVerifyPhoneLinkClicked}
                            href="#"
                          >
                            Verify phone
                          </Link>
                        </BelowHeaderContentsContainer>
                      ))
                    : conversationDetails.proposal &&
                      forwardRef((props, ref) => (
                        <BelowHeaderContentsContainer ref={ref}>
                          <Row
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={GoldDiamondIcon}
                              style={{ width: "32px", height: "24px" }}
                              alt="diamond"
                            />
                            <Typography
                              variant="body1"
                              sx={{ ml: 1 }}
                              fontWeight={450}
                            >
                              {conversationDetails.proposal.name}
                            </Typography>
                          </Row>
                          <Link
                            component={RouterLink}
                            to={`/agent/proposals/${conversationDetails.proposal.id}/`}
                          >
                            View proposal
                          </Link>
                        </BelowHeaderContentsContainer>
                      ))
                }
                MessagesFooter={
                  <>
                    {!conversationDetails.identity_revealed && (
                      <Row
                        sx={{
                          justifyContent: "center",
                          textAlign: "center",
                          py: 2,
                          mt: "0!important",
                        }}
                      >
                        <Column>
                          <Row
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            <img src={GlassesIcon} alt="privacy" />
                            <Typography
                              variant="body1"
                              color="text.secondary2"
                              sx={{ ml: 1 }}
                            >
                              You're anonymous to this broker
                            </Typography>
                          </Row>
                          <Link
                            color="primary"
                            underline="always"
                            sx={{ mt: 1 }}
                            href="#"
                            onClick={() => setIsRevealIdentityDialogOpen(true)}
                          >
                            Reveal identity
                          </Link>
                        </Column>
                      </Row>
                    )}
                    <RevealIdentityDialog
                      open={isRevealIdentityDialogOpen}
                      onClose={handleRevealIdentityDialogClosed}
                      isLoading={isRevealingIdentity}
                      onRevealButtonClicked={handleRevealIdentityButtonClicked}
                    />
                  </>
                }
                textFieldEnabled={profile.phone_verified}
              />
            )}
          </>
        )}
      </MainContainer>
    </InboxContext.Provider>
  )
}

function ConversationDetailsHeaderContents({
  selectedConversation,
  onCloseConversation,
  conversationDetails,
}) {
  const [interactMenuAnchorElem, setInteractMenuAnchorElem] = useState(null)
  const [
    isConfirmRequestProposalDialogOpen,
    setIsConfirmRequestProposalDialogOpen,
  ] = useState(false)
  const meetingLink = conversationDetails.brokerage.claimed_by?.meeting_link

  function handleRequestProposalMenuItemClicked() {
    setIsConfirmRequestProposalDialogOpen(true)
    handleInteractMenuClosed()
  }

  function handleMeetingLinkMenuItemClicked() {
    openLinkInNewTab(meetingLink)
    handleInteractMenuClosed()
    segmentAnalytics.track("Agent visited meeting link", {
      link: meetingLink,
      brokerageName: conversationDetails.brokerage.claimed_by.company,
    })
  }

  function handleInteractMenuClosed() {
    setInteractMenuAnchorElem(null)
  }

  return (
    <Row sx={{ alignItems: "center", width: "100%" }}>
      <BrandAvatar
        brand={{
          name: selectedConversation.brokerage.company,
          icon: selectedConversation.brokerage.image,
        }}
        size={BROKERAGE_AVATAR_SIZE}
        brokerageIconPadding={1.5}
      />
      <RowButColumnOnMobile
        sx={{
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          width: "100%",
          ml: 2,
        }}
      >
        <Column>
          {selectedConversation.is_concierge_conversation ? (
            <Typography variant="subtitle2">
              {selectedConversation.brokerage.company}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              to={`/agent/brokerages/${conversationDetails.brokerage.id}`}
              variant="subtitle2"
              color="primary.main"
              sx={{ alignSelf: "flex-start" }}
            >
              {selectedConversation.brokerage.company}
            </Link>
          )}
          <RowButColumnOnMobile
            sx={{ alignItems: { xs: "flex-start", sm: "center" }, mt: 0.5 }}
          >
            <Typography variant="h6">
              {getConversationBrokerageFullName(selectedConversation)}
            </Typography>
            {!selectedConversation.is_concierge_conversation && (
              <Box sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}>
                <IdentityRevealedChip
                  identityRevealed={selectedConversation.identity_revealed}
                />
              </Box>
            )}
          </RowButColumnOnMobile>
        </Column>
        {!selectedConversation.is_concierge_conversation && (
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => setInteractMenuAnchorElem(e.currentTarget)}
            endIcon={<KeyboardArrowDownOutlinedIcon />}
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            Interact
          </Button>
        )}
      </RowButColumnOnMobile>
      <ConfirmRequestProposalDialog
        open={isConfirmRequestProposalDialogOpen}
        onClose={() => setIsConfirmRequestProposalDialogOpen(false)}
        conversationDetails={conversationDetails}
      />
      <Menu
        anchorEl={interactMenuAnchorElem}
        open={!!interactMenuAnchorElem}
        onClose={handleInteractMenuClosed}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <MenuItem onClick={handleRequestProposalMenuItemClicked}>
          <ListItemIcon>
            <DiamondOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Request proposal</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={!meetingLink}
          onClick={handleMeetingLinkMenuItemClicked}
        >
          <ListItemIcon>
            <OpenInNewOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Meeting link</ListItemText>
        </MenuItem>
      </Menu>
    </Row>
  )
}

const BelowHeaderContentsContainer = styled(Row)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  paddingInline: theme.spacing(4),
  [theme.breakpoints.only("xs")]: {
    paddingInline: theme.spacing(2),
  },
  paddingBlock: theme.spacing(2),
  backgroundColor: theme.palette.otherwise.lightBackground,
  borderBottom: `1px solid ${theme.palette.otherwise.border}`,
}))
