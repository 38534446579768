import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useContext, useState } from "react"

import Column from "../../../../common/components/Column"
import { useIsMobile } from "../../../../common/utils"
import ContactInfoDialog from "../contact-info/Dialog"
import { NewBrokerageContext } from "../utils"
import { ActionButtons, ContentSection, HeaderSection } from "./Common"

export default function DescribeDialog({
  open,
  onClose,
  onPreviousButtonClicked,
  onReopen,
}) {
  const { yearFounded, setYearFounded, description, setDescription } =
    useContext(NewBrokerageContext)
  const [isContactInfoDialogOpen, setIsContactInfoDialogOpen] = useState(false)
  const isMobile = useIsMobile()

  function handleNextButtonClicked() {
    setIsContactInfoDialogOpen(true)
    onClose()
  }

  function handleContactInfoPreviousButtonClicked() {
    setIsContactInfoDialogOpen(false)
    onReopen()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignSelf: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <HeaderSection />
        </Column>
        <ContentSection
          yearFounded={yearFounded}
          setYearFounded={setYearFounded}
          description={description}
          setDescription={setDescription}
        />
      </DialogContent>
      <DialogActions>
        <ActionButtons
          yearFounded={yearFounded}
          description={description}
          onPreviousButtonClicked={onPreviousButtonClicked}
          onNextButtonClicked={handleNextButtonClicked}
        />
        <ContactInfoDialog
          open={isContactInfoDialogOpen}
          onClose={() => setIsContactInfoDialogOpen(false)}
          onPreviousButtonClicked={handleContactInfoPreviousButtonClicked}
        />
      </DialogActions>
    </Dialog>
  )
}
