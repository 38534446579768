import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import { updateBrokerageSubscription } from "../slice"

const CANCELLATION_REASONS = {
  notNeeded: "useless",
  notWorking: "buggy",
  noLeads: "no_leads",
  tooExpensive: "expensive",
  otherwise: "other",
}

export default function CancelSubscriptionReasonDialog({
  open,
  onClose,
  brokerage,
}) {
  const [cancellationReason, setCancellationReason] = useState(
    CANCELLATION_REASONS.notNeeded
  )
  const [note, setNote] = useState("")
  const [isCancelling, setIsCancelling] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()

  function handleCancellationReasonChanged(e) {
    const newReason = e.target.value
    if (newReason !== CANCELLATION_REASONS.otherwise) {
      setNote("")
    }
    setCancellationReason(newReason)
  }

  function handleDowngradeButtonClicked() {
    setIsCancelling(true)
    axios
      .post(`/billing/api/${brokerage.id}/cancel-subscription/`, {
        cancellation_reason: cancellationReason,
        cancellation_reason_note: note,
      })
      .then(({ data }) => {
        dispatch(
          updateBrokerageSubscription({
            brokerageID: brokerage.id,
            subscription: data,
          })
        )
        onClose()
      })
      .finally(() => setIsCancelling(false))
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <HaloCircularIcon Icon={LiveHelpOutlinedIcon} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            We're sorry to see you go!
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            Please let us know what made you cancel the subscription. It'll help
            us improve the product.
          </Typography>
        </Column>
        <Divider sx={{ mt: 4 }} />
        <RadioGroup
          value={cancellationReason}
          onChange={handleCancellationReasonChanged}
          sx={{ mt: 4, pl: 2 }}
        >
          <FormControlLabel
            label="No longer needed / project complete"
            value={CANCELLATION_REASONS.notNeeded}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Not working as expected"
            value={CANCELLATION_REASONS.notWorking}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Not enough leads"
            value={CANCELLATION_REASONS.noLeads}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Too expensive"
            value={CANCELLATION_REASONS.tooExpensive}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Something else"
            value={CANCELLATION_REASONS.otherwise}
            control={<Radio size="small" />}
          />
        </RadioGroup>
        <Collapse in={cancellationReason === CANCELLATION_REASONS.otherwise}>
          <TextField
            label="Notes"
            placeholder="Please explain why you want to cancel the Pro subscription"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            rows={2}
            fullWidth
            sx={{ mt: 2 }}
          />
        </Collapse>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="error"
          size="large"
          isLoading={isCancelling}
          onClick={handleDowngradeButtonClicked}
        >
          Downgrade to free
        </LoadingButton>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => onClose()}
          size="large"
        >
          Stay Pro
        </Button>
      </DialogActions>
    </Dialog>
  )
}
