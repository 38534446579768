import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useParams } from "react-router"

import Column from "../../../common/components/Column"
import CompensationColumns from "../../../common/components/CompensationColumns"
import LoadingButton from "../../../common/components/LoadingButton"
import LoadingWrapper, {
  CircularSkeleton,
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import PerksColumns from "../../../common/components/PerksColumns"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TitledSection, {
  BorderedSection,
} from "../../../common/components/TitledSection"
import { AgentAvatar } from "../../../common/components/UserAvatar"
import ProposalDetailBanner from "../../../common/resources/images/proposal-details-banner.png"
import {
  formatCurrency,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import {
  buildStatTitle,
  getAgentProgressbarCompoent,
  getMarketProgressbarComponent,
  getProgressValues,
  salesVolumeTooltip,
  yearsInBusinessTooltip,
} from "../components/AgentToMarketStatComparison"
import AnimatedProgressBar from "../components/AnimatedProgressBar"
import OnePerkPlus from "../components/OnePerkPlus"
import ProposalStatusChip from "../components/ProposalStatusChip"
import CreateProposalDialog from "../proposals/CreateProposalDialog"
import { useCandidateStatsLoader, useStartAgentConversation } from "../utils"

export default function ProposalDetails() {
  const [sentProposal, setSentProposal] = useState({
    agent_request: { agent: {} },
    proposal: { perks: [] },
  })
  const [isLoading, setIsLoading] = useState(true)
  const { stats: agentStats, isLoading: isLoadingAgentStats } =
    useCandidateStatsLoader()
  const { isCreatingConversation, requestStartConversation } =
    useStartAgentConversation()
  const [isCreateProposalDialogOpen, setIsCreateProposalDialogOpen] =
    useState(false)
  const { id: proposalID } = useParams()
  const axios = useAuthorizedAxiosClient()
  const isMobile = useIsMobile()

  function handleMessageButtonClicked() {
    requestStartConversation(sentProposal.agent_request)
  }

  useEffect(
    () => {
      axios
        .get(`/customers/api/proposals/sent/${proposalID}/`)
        .then(({ data }) => setSentProposal(data))
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const AVATAR_SIZE = isMobile ? 88 : 160
  const agentRequest = sentProposal.agent_request
  const agent = agentRequest.agent

  return (
    <Column>
      <Box
        sx={{
          backgroundImage: `url(${ProposalDetailBanner})`,
          backgroundSize: "100% 100%",
          height: { xs: "80px", sm: "192px" },
        }}
      />
      <Column sx={{ px: { xs: 4, sm: 8 }, py: 4 }}>
        <Row>
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={<CircularSkeleton size={AVATAR_SIZE} />}
          >
            <AgentAvatar
              agent={{
                first_name: agent.full_name,
                profile_image: agent.profile_image,
              }}
              size={AVATAR_SIZE}
            />
          </LoadingWrapper>
          <RowButColumnOnMobile
            sx={{
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              width: "100%",
            }}
          >
            <Column
              sx={{
                justifyContent: "center",
                alignItems: "flex-start",
                ml: 2,
              }}
            >
              <LoadingWrapper
                isLoading={isLoading || isLoadingAgentStats}
                skeleton={<TextSkeleton />}
              >
                <>
                  <ProposalStatusChip proposal={sentProposal} />
                  <Typography variant="h5" sx={{ mt: 1 }}>
                    {agent.full_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {sentProposal.proposal.name}
                  </Typography>
                  <RowButColumnOnMobile
                    sx={{
                      alignItems: { xs: "flex-start", sm: "flex-end" },
                      mt: 1,
                    }}
                  >
                    <Row
                      sx={{
                        "& > .MuiBox-root:last-child": { ml: 2 },
                        mt: 1,
                      }}
                    >
                      <SmallAgentToMarketStatComparison
                        agentValue={agent.years_in_business}
                        marketValue={agentStats.avg_years_in_business}
                        title={buildStatTitle(
                          agent.years_in_business,
                          (yearsInBusiness) => `${yearsInBusiness} years`
                        )}
                        marketTooltip={yearsInBusinessTooltip(
                          agentStats.avg_years_in_business
                        )}
                      />
                      <SmallAgentToMarketStatComparison
                        agentValue={agent.sales_volume_last_year}
                        marketValue={agentStats.avg_sales_volume_last_year}
                        title={buildStatTitle(
                          agent.sales_volume_last_year,
                          (salesVolume) => formatCurrency(salesVolume)
                        )}
                        marketTooltip={salesVolumeTooltip(
                          agentStats.avg_sales_volume_last_year
                        )}
                      />
                    </Row>
                    <Box sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 1, sm: 0 } }}>
                      <OnePerkPlus perks={sentProposal.proposal.perks} />
                    </Box>
                  </RowButColumnOnMobile>
                </>
              </LoadingWrapper>
            </Column>
            <Row
              sx={{
                alignItems: "center",
                mt: { xs: 2, sm: 0 },
                width: { xs: "100%", sm: "fit-content" },
              }}
            >
              <LoadingButton
                isLoading={isLoading || isCreatingConversation}
                size="large"
                onClick={handleMessageButtonClicked}
                fullWidth={isMobile}
              >
                Message
              </LoadingButton>
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => setIsCreateProposalDialogOpen(true)}
              >
                <ContentCopyOutlinedIcon htmlColor="text.secondary2" />
              </IconButton>
              <CreateProposalDialog
                open={isCreateProposalDialogOpen}
                onClose={() => setIsCreateProposalDialogOpen(false)}
                proposalToDuplicate={sentProposal.proposal}
              />
            </Row>
          </RowButColumnOnMobile>
        </Row>
        <BorderedSection>
          <TitledSection title="Introduction">
            <Typography variant="body1" whiteSpace="break-spaces">
              {sentProposal.proposal.introduction ||
                "No introduction provided."}
            </Typography>
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Compensation" addMarginTop>
            <CompensationColumns sentProposal={sentProposal} />
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Perks" addMarginTop>
            <PerksColumns perks={sentProposal.proposal.perks} />
          </TitledSection>
          <SectionDivider />
          <TitledSection title="Note" addMarginTop>
            <Typography variant="body1" whiteSpace="break-spaces">
              {sentProposal.proposal.note || "No note provided."}
            </Typography>
          </TitledSection>
        </BorderedSection>
      </Column>
    </Column>
  )
}

function SmallAgentToMarketStatComparison({
  agentValue,
  marketValue,
  title,
  marketTooltip,
}) {
  const AgentProgressBarComponent = getAgentProgressbarCompoent(agentValue)
  const MarketProgressBarComponent = getMarketProgressbarComponent(marketValue)
  const { agentProgress, marketProgress } = getProgressValues(
    agentValue,
    marketValue
  )
  return (
    <Row sx={{ alignItems: "center", height: "20px" }}>
      <Tooltip title={marketTooltip}>
        <AnimatedProgressBar
          Component={MarketProgressBarComponent}
          variant="determinate"
          color="secondary"
          value={marketProgress}
        />
      </Tooltip>

      <Box sx={{ ml: 0.5, height: "100%" }}>
        <AnimatedProgressBar
          Component={AgentProgressBarComponent}
          variant="determinate"
          color="secondary"
          value={agentProgress}
        />
      </Box>
      <Typography variant="body1" fontWeight={500} sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Row>
  )
}

function SectionDivider() {
  return <Divider sx={{ mt: 4 }} />
}
