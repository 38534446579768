import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import Column from "../../../common/components/Column"
import LeftAlignedDialogActions from "../../../common/components/LeftAlignedDialogActions"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import LoadingButton from "../../../common/components/LoadingButton"
import Row from "../../../common/components/Row"
import SendProposalStatsPerks from "../../../common/components/SendProposalStatsPerks"
import { AgentAvatar } from "../../../common/components/UserAvatar"
import ProposalCardBackgroundImage from "../../../common/resources/images/proposal-card-bg.png"
import {
  segmentAnalytics,
  trimLongString,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import { profileLoading, selectCurrentBrokerage } from "../slice"
import { useSendProposalToConversation } from "../utils"

export default function SendProposalDialog({
  open,
  onCancel,
  proposalIntroduction,
  proposalCommissionSplit,
  proposalSigningBonus,
  proposalPerks,
  isCreatingProposal,
  proposalID,
  onSendProposal,
  onSendingProposalFinished,
  conversation,
}) {
  const [candidates, setCandidates] = useState([])
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [selectedCandidateInputValue, setSelectedCandidateInputValue] =
    useState("")
  const [message, setMessage] = useState("")
  const [isSendingProposal, setIsSendingProposal] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const sendProposalToConversation = useSendProposalToConversation()
  const isMobile = useIsMobile()

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsLoadingCandidates(true)
      axios
        .get(`/customers/api/${currentBrokerage.id}/proposals/candidates/`)
        .then(({ data }) => setCandidates(data))
        .finally(() => setIsLoadingCandidates(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useEffect(
    () => {
      if (proposalID === null) {
        return
      }
      setIsSendingProposal(true)
      let sendProposalPromise
      if (conversation) {
        sendProposalPromise = sendProposalToConversation(
          proposalID,
          conversation.id,
          message
        )
      } else {
        sendProposalPromise = axios.post(
          `/customers/api/${currentBrokerage.id}/proposals/send/`,
          {
            message,
            proposal: proposalID,
            agent_request: selectedCandidate.id,
          }
        )
      }
      sendProposalPromise
        .then(({ data }) => {
          onSendingProposalFinished(data)
          segmentAnalytics.track("Broker sent proposal to agent", {
            "Brokerage ID": currentBrokerage.id,
            "Brokerage name": currentBrokerage.company,
            "Proposal ID": data.id,
            "Proposal name": data.proposal.name,
          })
        })
        .finally(() => setIsSendingProposal(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [proposalID]
  )

  const AVATAR_SIZE = 96

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
        }}
      >
        {isLoadingCandidates ? (
          <Row
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" variant="indeterminate" />
          </Row>
        ) : (
          <>
            <Collapse
              in={!!selectedCandidate}
              sx={{ minHeight: "max-content!important" }}
            >
              <Column
                sx={{
                  borderRadius: (theme) => theme.units.borderRadius,
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${ProposalCardBackgroundImage})`,
                    backgroundSize: "100% 100%",
                    height: "112px",
                    borderTopLeftRadius: (theme) => theme.units.borderRadius,
                    borderTopRightRadius: (theme) => theme.units.borderRadius,
                  }}
                />
                <Column
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.otherwise.lightBackground,
                    p: 2,
                  }}
                >
                  {selectedCandidate && (
                    <Row sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-50px",
                          left: 0,
                          pt: 2,
                        }}
                      >
                        <AgentAvatar
                          agent={{
                            profile_image:
                              selectedCandidate.agent.profile_image,
                            first_name: selectedCandidate.agent.full_name,
                          }}
                          size={AVATAR_SIZE}
                        />
                      </Box>
                      <Column
                        sx={{
                          ml: `calc(${AVATAR_SIZE}px + 16px)`,
                        }}
                      >
                        <Typography variant="h6">
                          {selectedCandidate.agent.full_name}
                        </Typography>
                        <Row
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <LocationOnOutlinedIcon
                            fontSize="small"
                            sx={{ color: "text.secondary2" }}
                          />
                          <Typography
                            variant="body1"
                            color="text.secondary2"
                            sx={{ ml: 1 }}
                          >
                            {selectedCandidate.agent.address &&
                              trimLongString(
                                selectedCandidate.agent.address,
                                50
                              )}
                          </Typography>
                        </Row>
                      </Column>
                    </Row>
                  )}
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {trimLongString(proposalIntroduction, 100)}
                  </Typography>
                  <SendProposalStatsPerks
                    commissionSplit={proposalCommissionSplit}
                    signingBonus={proposalSigningBonus}
                    perks={proposalPerks}
                  />
                </Column>
              </Column>
              <LengthLimitedTextField
                label="Message"
                value={message}
                setValue={setMessage}
                maxLength={300}
                multiline
              />
            </Collapse>
            <Typography variant="h6" sx={{ mt: selectedCandidate ? 4 : 0 }}>
              Recepient
            </Typography>
            <Autocomplete
              value={selectedCandidate}
              onChange={(e, newValue) => setSelectedCandidate(newValue)}
              inputValue={selectedCandidateInputValue}
              onInputChange={(e, newValue) =>
                setSelectedCandidateInputValue(newValue)
              }
              options={candidates}
              getOptionLabel={(option) => option.agent.full_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Name" />}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ display: "flex", alignItems: "center" }}
                  {...props}
                  key={option.id}
                >
                  <AgentAvatar
                    agent={{
                      ...option.agent,
                      first_name: option.agent.full_name,
                    }}
                    size={32}
                  />
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {option.agent.full_name}
                  </Typography>
                </Box>
              )}
              fullWidth
              sx={{
                mt: 2,
              }}
            />
          </>
        )}
      </DialogContent>
      <LeftAlignedDialogActions>
        <LoadingButton
          isLoading={isCreatingProposal || isSendingProposal}
          disabled={!selectedCandidate}
          onClick={onSendProposal}
        >
          Send proposal
        </LoadingButton>
        <Button
          variant="outlined"
          color="primary"
          sx={{ ml: 2 }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </LeftAlignedDialogActions>
    </Dialog>
  )
}
