import DialogActions from "@mui/material/DialogActions"
import { styled } from "@mui/material/styles"

export default styled(DialogActions)(({ theme }) => ({
  justifyContent: "flex-start",
  "& .MuiButton-root": { flexBasis: "initial" },
  [theme.breakpoints.only("xs")]: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      flexBasis: "50%",
      "&:first-of-type": {
        marginLeft: theme.spacing(1),
      },
      "&:last-of-type": {
        marginLeft: 0,
      },
    },
  },
}))
