import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"

export default function StampedAvatar({
  mainAvatar,
  mainSize = "96px",
  stampAvatar,
  stampSize = "48px",
  borderWidth = "4px",
  stampOffset = "-12px",
}) {
  return (
    <StampedAvatarLayout
      MainAvatar={
        <Avatar src={mainAvatar} sx={{ width: mainSize, height: mainSize }} />
      }
      stampAvatar={stampAvatar}
      stampSize={stampSize}
      borderWidth={borderWidth}
      stampOffset={stampOffset}
    />
  )
}

export function StampedAvatarLayout({
  MainAvatar,
  stampAvatar,
  stampSize,
  borderWidth,
  stampOffset,
}) {
  return (
    <Box sx={{ position: "relative" }}>
      {MainAvatar}
      <Avatar
        src={stampAvatar}
        sx={{
          width: stampSize,
          height: stampSize,
          border: `${borderWidth} solid white`,
          position: "absolute",
          bottom: stampOffset,
          right: stampOffset,
        }}
      />
    </Box>
  )
}
