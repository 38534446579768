import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import { darken } from "@mui/material/styles"
import { useRef, useState } from "react"

import { useAuthorizedAxiosClient } from "../utils"
import CropProfileImagePopover from "./crop-profile-image-popover"

export default function AvatarEditorLayout({
  isLoading,
  setIsLoading,
  currentAvatar,
  defaultAvatar,
  firstName,
  updateProfileURL,
  profilePropertyName,
  successCallback,
  size,
  defaultAvatarPadding,
  avatarSX,
}) {
  const { imageName, onImageDataChanged, imageData, onImageNameChanged } =
    useBasicAvatarEditorLayoutState()
  const axios = useAuthorizedAxiosClient()

  function handleCropProfileImagePopoverClosed() {
    const formData = new FormData()
    if (imageData) {
      setIsLoading(true)
      formData.append(profilePropertyName, imageData, imageName)
      axios
        .put(updateProfileURL, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          successCallback(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <BasicAvatarEditorLayout
      currentAvatar={currentAvatar}
      defaultAvatar={defaultAvatar}
      imageFilename={imageName}
      onImageFilenameChanged={onImageNameChanged}
      imageData={imageData}
      onImageDataChanged={onImageDataChanged}
      onCropPopoverClosed={handleCropProfileImagePopoverClosed}
      firstName={firstName}
      size={size}
      defaultAvatarPadding={defaultAvatarPadding}
      avatarSX={avatarSX}
    >
      {isLoading && (
        <CircularProgress
          variant="indeterminate"
          color="secondary"
          size={size + 2}
          thickness={1}
          sx={{
            position: "absolute",
            top: 1,
            left: 1,
          }}
        />
      )}
    </BasicAvatarEditorLayout>
  )
}

/* Use this when you want to handle submitting the new profile image yourself */
export function BasicAvatarEditorLayout({
  currentAvatar,
  defaultAvatar,
  imageFilename,
  onImageFilenameChanged,
  imageData,
  onImageDataChanged,
  onCropPopoverClosed = () => {},
  firstName,
  size,
  defaultAvatarPadding,
  avatarSX,
  children,
}) {
  const [profileImageDataURL, setProfileImageDataURL] = useState("")
  const [croppedProfileImageDataURL, setCroppedProfileImageDataURL] =
    useState("")
  const profileImageInputElemRef = useRef()
  const profileImageBoxRef = useRef()
  const [cropImagePopoverOpen, setCropImagePopoverOpen] = useState(false)

  function handleUpdateProfileImageIconButtonClicked() {
    profileImageInputElemRef.current.click()
  }

  const avatar = croppedProfileImageDataURL || currentAvatar || defaultAvatar

  function handleNewProfileImageSelected(e) {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader()
      fileReader.addEventListener("load", () => {
        if (fileReader.result) {
          onImageFilenameChanged(e.target.files[0].name)
          setProfileImageDataURL(fileReader.result.toString())
          setCropImagePopoverOpen(true)
          e.target.value = null
        }
      })
      fileReader.readAsDataURL(e.target.files[0])
    }
  }

  function handleCropChanged(cropDataURL, cropBlob) {
    setCroppedProfileImageDataURL(cropDataURL)
    onImageDataChanged(cropBlob)
  }

  function handleCropProfileImagePopoverClosed() {
    setCropImagePopoverOpen(false)
    onCropPopoverClosed()
  }

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        p: "2px",
      }}
      ref={profileImageBoxRef}
    >
      <Avatar
        alt="profile image"
        src={avatar}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          fontSize: "3rem",
          padding: avatar === defaultAvatar && defaultAvatarPadding,
          ...avatarSX,
        }}
      >
        {!currentAvatar && firstName && firstName[0].toUpperCase()}
      </Avatar>
      <IconButton
        size="small"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          color: "text.secondary2",
          background: (theme) => theme.palette.otherwise.lightBackground,
          border: "2px solid white",
          "&.MuiIconButton-root:hover": {
            background: (theme) =>
              darken(theme.palette.otherwise.lightBackground, 0.1),
          },
        }}
        onClick={handleUpdateProfileImageIconButtonClicked}
      >
        <EditOutlinedIcon />
      </IconButton>
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleNewProfileImageSelected}
        ref={profileImageInputElemRef}
      />
      <CropProfileImagePopover
        open={cropImagePopoverOpen}
        anchorElem={profileImageBoxRef.current}
        onClosed={handleCropProfileImagePopoverClosed}
        imgData={profileImageDataURL}
        onCropFinished={handleCropChanged}
      />
      {children}
    </Box>
  )
}

export function useBasicAvatarEditorLayoutState() {
  const [imageName, onImageNameChanged] = useState("")
  const [imageData, onImageDataChanged] = useState("")
  return {
    imageName,
    onImageNameChanged,
    imageData,
    onImageDataChanged,
  }
}
