import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import AreasOfFocusEditor from "../../../common/components/AreasOfFocusEditor"
import {
  AGENT_REQUEST_STATUS_OPTIONS,
  optionalParseInt,
  useIsMobile,
} from "../../../common/utils"
import { AGENT_LEVELS_OF_INTEREST } from "../../agent/utils"
import { AGENT_STATUS_DISPLAY_MAP } from "../components/AgentStatusChip"

const STATUS_OPTIONS = [
  { label: "All statuses", value: "" },
  {
    label: AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.pending],
    value: AGENT_REQUEST_STATUS_OPTIONS.pending,
  },
  {
    label: AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.accepted],
    value: AGENT_REQUEST_STATUS_OPTIONS.accepted,
  },
  {
    label: AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.inContact],
    value: AGENT_REQUEST_STATUS_OPTIONS.inContact,
  },
  {
    label:
      AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.proposalReceived],
    value: AGENT_REQUEST_STATUS_OPTIONS.proposalReceived,
  },
  {
    label: AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.rejected],
    value: AGENT_REQUEST_STATUS_OPTIONS.rejected,
  },
]

const AGENT_LEVELS_OF_INTEREST_OPTIONS = {
  "": "All levels",
  ...AGENT_LEVELS_OF_INTEREST,
}

export default function FiltersModal({ open, onClose }) {
  const [status, setStatus] = useState(STATUS_OPTIONS[0])
  const [statusInputValue, setStatusInputValue] = useState("")
  const [minYears, setMinYears] = useState("")
  const [maxYears, setMaxYears] = useState("")
  const [minSalesCount, setMinSalesCount] = useState("")
  const [maxSalesCount, setMaxSalesCount] = useState("")
  const [minAvgSalePrice, setMinAvgSalePrice] = useState("")
  const [maxAvgSalePrice, setMaxAvgSalePrice] = useState("")
  const [minSalesVolume, setMinSalesVolume] = useState("")
  const [maxSalesVolume, setMaxSalesVolume] = useState("")
  const [areasOfFocus, setAreasOfFocus] = useState([])
  const [levelOfInterest, setLevelOfInterest] = useState("")
  const [levelOfInterestInputValue, setLevelOfInterestInputValue] = useState("")

  const isMobile = useIsMobile()

  const filters = {
    status: status ? status.value : "",
    min_years_in_business: minYears,
    max_years_in_business: maxYears,
    min_homes_sold_last_year: minSalesCount,
    max_homes_sold_last_year: maxSalesCount,
    min_avg_sale_price: minAvgSalePrice,
    max_avg_sale_price: maxAvgSalePrice,
    min_sales_volume_last_year: minSalesVolume,
    max_sales_volume_last_year: maxSalesVolume,
    area_of_focus: areasOfFocus,
    level_of_interest: levelOfInterest || "",
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={isMobile ? "xs" : "sm"}
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{ p: 0, "& > .MuiBox-root:nth-of-type(n + 2)": { mt: 2 } }}
      >
        <LabeledInputRow label="Status" addMarginTop={false}>
          <Autocomplete
            options={STATUS_OPTIONS}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={status}
            onChange={(_, newValue) => setStatus(newValue)}
            inputValue={statusInputValue}
            onInputChange={(e, newValue) => setStatusInputValue(newValue)}
            renderInput={(params) => <TextField {...params} />}
            size="medium"
          />
        </LabeledInputRow>
        <LabeledInputRow label="Experience">
          <TwoTextFieldsRow>
            <TextField
              label="Min"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                endAdornment: <YearsInputAdornment />,
              }}
              value={minYears}
              onChange={(e) => setMinYears(optionalParseInt(e.target.value))}
            />
            <TextField
              label="Max"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                endAdornment: <YearsInputAdornment />,
              }}
              value={maxYears}
              onChange={(e) => setMaxYears(optionalParseInt(e.target.value))}
            />
          </TwoTextFieldsRow>
        </LabeledInputRow>
        <LabeledInputRow label="Sales number last year">
          <TwoTextFieldsRow>
            <TextField
              label="Min"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                endAdornment: <UnitsInputAdornment />,
              }}
              value={minSalesCount}
              onChange={(e) =>
                setMinSalesCount(optionalParseInt(e.target.value))
              }
            />
            <TextField
              label="Max"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                endAdornment: <UnitsInputAdornment />,
              }}
              value={maxSalesCount}
              onChange={(e) =>
                setMaxSalesCount(optionalParseInt(e.target.value))
              }
            />
          </TwoTextFieldsRow>
        </LabeledInputRow>
        <LabeledInputRow label="Average sale price per home">
          <TwoTextFieldsRow>
            <TextField
              label="Min"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                startAdornment: <CurrencyInputAdornment />,
              }}
              value={minAvgSalePrice}
              onChange={(e) =>
                setMinAvgSalePrice(optionalParseInt(e.target.value))
              }
            />
            <TextField
              label="Max"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                startAdornment: <CurrencyInputAdornment />,
              }}
              value={maxAvgSalePrice}
              onChange={(e) =>
                setMaxAvgSalePrice(optionalParseInt(e.target.value))
              }
            />
          </TwoTextFieldsRow>
        </LabeledInputRow>
        <LabeledInputRow label="Total volume last year">
          <TwoTextFieldsRow>
            <TextField
              label="Min"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                startAdornment: <CurrencyInputAdornment />,
              }}
              value={minSalesVolume}
              onChange={(e) =>
                setMinSalesVolume(optionalParseInt(e.target.value))
              }
            />
            <TextField
              label="Max"
              type="number"
              size="medium"
              fullWidth
              InputProps={{
                startAdornment: <CurrencyInputAdornment />,
              }}
              value={maxSalesVolume}
              onChange={(e) =>
                setMaxSalesVolume(optionalParseInt(e.target.value))
              }
            />
          </TwoTextFieldsRow>
        </LabeledInputRow>
        <LabeledInputRow label="Areas of focus">
          <AreasOfFocusEditor
            areasOfFocus={areasOfFocus}
            setAreasOfFocus={setAreasOfFocus}
          />
        </LabeledInputRow>
        <LabeledInputRow label="Level of interest">
          <Autocomplete
            options={Object.keys(AGENT_LEVELS_OF_INTEREST_OPTIONS)}
            getOptionLabel={(label) =>
              AGENT_LEVELS_OF_INTEREST_OPTIONS[label] || ""
            }
            value={levelOfInterest}
            onChange={(_, newValue) => setLevelOfInterest(newValue)}
            inputValue={levelOfInterestInputValue}
            onInputChange={(_, newValue) =>
              setLevelOfInterestInputValue(newValue)
            }
            renderInput={(params) => <TextField {...params} />}
            size="medium"
            fullWidth
          />
        </LabeledInputRow>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onClose(filters)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function LabeledInputRow({ label, children }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="caption">{label}</Typography>
      <Box
        sx={{
          mt: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function TwoTextFieldsRow({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiTextField-root:nth-of-type(n + 2)": {
          ml: 2,
        },
      }}
    >
      {children}
    </Box>
  )
}

function YearsInputAdornment() {
  return <InputAdornment position="end">Yrs</InputAdornment>
}

function UnitsInputAdornment() {
  return <InputAdornment position="end">Units</InputAdornment>
}

function CurrencyInputAdornment() {
  return <InputAdornment position="start">$</InputAdornment>
}
