import FinishTourDialog from "../../../common/components/FinishTourDialog"
import BlueCheckIcon from "../../../common/resources/icons/blue-check.png"
import { useUpdateAgentTourStatus } from "../utils"

export default function MaximumOffersTourDialog() {
  return (
    <FinishTourDialog
      openStepIndex={7}
      Icon={() => (
        <img
          src={BlueCheckIcon}
          width={96}
          height={96}
          alt="brokerage building"
        />
      )}
      title="One more step to maximum offers!"
      body={
        <>
          The first step to getting the highest possible offers on HighRise is
          to accurately complete your personal Profile
          <br />
          <br />
          Let's take care of that now so you'll be ready to maximize your
          brokerage proposals..
        </>
      }
      letsGoURL="/agent/profile"
      useUpdateTourStatus={useUpdateAgentTourStatus}
    />
  )
}
