import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import { formatCurrency } from "../../../common/utils"
import { AGENT_FIELD_HIDDEN_MARKER } from "../utils"
import AnimatedProgressBar from "./AnimatedProgressBar"
import {
  AgentVerticalLinearProgress,
  DisabledVerticalLinearProgress,
  MarketVerticalLinearProgress,
} from "./VerticalProgressBars"

export default function AgentToMarketStatComparison({
  title,
  subtitle,
  agentValue,
  marketValue,
  tooltip,
  titleVariant = "h6",
  subtitleColor = "text.secondary2",
  tight = true,
}) {
  const { agentProgress, marketProgress } = getProgressValues(
    agentValue,
    marketValue
  )

  const MarketProgressBarComponent = getMarketProgressbarComponent(marketValue)
  const AgentProgressBarComponent = getAgentProgressbarCompoent(agentValue)

  return (
    <Row
      sx={{
        height: `48px`,
      }}
    >
      <Row className="bar-container">
        <Tooltip title={tooltip} placement="left" color="primary" arrow>
          <AnimatedProgressBar
            Component={MarketProgressBarComponent}
            variant="determinate"
            color="secondary"
            value={marketProgress}
          />
        </Tooltip>
        <AnimatedProgressBar
          Component={AgentProgressBarComponent}
          variant="determinate"
          color="secondary"
          value={agentProgress}
          sx={{
            ml: 0.5,
          }}
          title="Agent"
        />
      </Row>
      <Column
        sx={{
          justifyContent: "space-between",
          ml: tight ? 1 : 2,
        }}
      >
        <Typography variant={titleVariant} lineHeight={1}>
          {title}
        </Typography>
        <Typography variant="subtitle1" color={subtitleColor} lineHeight={1}>
          {subtitle}
        </Typography>
      </Column>
    </Row>
  )
}

const NO_DATA_MESSAGE = "No data available"

export function getProgressValues(agentValue, marketValue) {
  let marketProgress, agentProgress
  if (marketValue > agentValue) {
    marketProgress = 100
    agentProgress = (agentValue / marketValue) * 100
  } else {
    agentProgress = 100
    marketProgress = (marketValue / agentValue) * 100
  }
  return { agentProgress, marketProgress }
}

export function getMarketProgressbarComponent(marketValue) {
  return marketValue === null
    ? DisabledVerticalLinearProgress
    : MarketVerticalLinearProgress
}

export function getAgentProgressbarCompoent(agentValue) {
  return agentValue === null || agentValue === AGENT_FIELD_HIDDEN_MARKER
    ? DisabledVerticalLinearProgress
    : AgentVerticalLinearProgress
}

export function buildStatTitle(
  value,
  notMissingCallback = (value) => value,
  nullValue = "Unknown"
) {
  switch (value) {
    case null:
      return nullValue
    case undefined:
      return nullValue
    case AGENT_FIELD_HIDDEN_MARKER:
      return "Hidden"
    default:
      return notMissingCallback(value)
  }
}

export function yearsInBusinessTooltip(yearsInBusiness) {
  return yearsInBusiness
    ? `${Math.round(yearsInBusiness)} years market average`
    : NO_DATA_MESSAGE
}

export function salesVolumeTooltip(salesVolume) {
  return salesVolume
    ? `${formatCurrency(salesVolume)} market average`
    : NO_DATA_MESSAGE
}

export function homesSoldTooltip(homesSold) {
  return homesSold ? `${homesSold} units market average` : NO_DATA_MESSAGE
}

export function avgSalePriceTooltip(avgSalePrice) {
  return salesVolumeTooltip(avgSalePrice)
}
