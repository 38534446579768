import { useContext, useEffect, useState } from "react"

import { useAuthorizedAxiosClient } from "../../utils"
import InboxContext, { MESSAGE_SOURCES } from "./InboxContext"

export function getConversationBrokerageFullName(conversation) {
  if (conversation.brokerage.claimed_by) {
    return conversation.brokerage.claimed_by.get_full_name
  }
  return "Unknown Owner"
}

export function useConversationDetailsLoader(
  apiURLPrefix,
  selectedConversation
) {
  const [conversationDetails, setConversationDetails] = useState({
    brokerage: {},
    messages: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const axios = useAuthorizedAxiosClient()

  useEffect(
    () => {
      if (!selectedConversation) {
        return
      }
      setIsLoading(true)
      axios
        .get(`/${apiURLPrefix}/api/conversations/${selectedConversation.id}/`)
        .then(({ data }) => setConversationDetails(data))
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedConversation && selectedConversation.id]
  )
  return { isLoading, conversationDetails, setConversationDetails }
}

export function stringMatchesQuery(string, query) {
  return string.toLowerCase().includes(query.toLowerCase())
}

export function useInboxActionEntityNames() {
  const inboxContext = useContext(InboxContext)
  let actionPerformer =
    inboxContext.messageSource === MESSAGE_SOURCES.agent ? "Agent" : "Broker"
  let actionTarget =
    inboxContext.messageSource === MESSAGE_SOURCES.agent ? "broker" : "agent"
  return { actionPerformer, actionTarget }
}
