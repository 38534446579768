import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useContext, useState } from "react"

import { segmentAnalytics, useAuthorizedAxiosClient } from "../../utils"
import { DangerHaloCircularIcon } from "../CircularIcon"
import LoadingButton from "../LoadingButton"
import InboxContext from "./InboxContext"
import { useInboxActionEntityNames } from "./utils"

export default function BlockDeleteConversationDialog({
  open,
  onClose,
  conversation,
  name,
  onConversationDeleted,
}) {
  const [isBlockingConversation, setIsBlockingConversation] = useState(false)

  const inboxContext = useContext(InboxContext)
  const axios = useAuthorizedAxiosClient()
  const { actionPerformer: blockSource, actionTarget: blockedEntity } =
    useInboxActionEntityNames()

  function handleBlockButtonClicked() {
    setIsBlockingConversation(true)
    axios
      .put(
        `/${inboxContext.apiURLPrefix}/api/conversations/${conversation.id}/block/`
      )
      .then(({ data }) => {
        onConversationDeleted(data)
        onClose()
        segmentAnalytics.track(`${blockSource} blocked ${blockedEntity}`, {
          "Blocked name": name,
          "Conversation ID": conversation.id,
        })
      })
      .finally(() => {
        setIsBlockingConversation(false)
      })
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <DangerHaloCircularIcon Icon={DeleteForeverOutlinedIcon} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Are you sure you want to block and delete chat with {name}?
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          This will remove the chat from your inbox and block any future
          messages from them.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          isLoading={isBlockingConversation}
          color="error"
          onClick={handleBlockButtonClicked}
        >
          Block and delete chat
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
