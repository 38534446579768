import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import { formatDistanceToNowStrict } from "date-fns"
import { useSelector } from "react-redux"

import {
  selectNotifications,
  selectNotificationsCount,
} from "../../pages/notifications/slice"
import { useIsMobile } from "../utils"
import Column from "./Column"
import Row from "./Row"

export default function Notifications({
  NotificationTypeToComponentMap,
  closeMenu,
}) {
  const notifications = useSelector(selectNotifications)

  function NotificationByType({ notification }) {
    const Notification = NotificationTypeToComponentMap[notification.type]
    if (Notification) {
      return <Notification notification={notification} closeMenu={closeMenu} />
    }
    return null
  }

  return notifications.map((notification, index) => (
    <Box key={index}>
      <NotificationByType key={index} notification={notification} />
      {index < notifications.length - 1 && <Divider />}
    </Box>
  ))
}

export const NOTIFICATION_ICON_SIZE = 48

export function NotificationLayout({
  show,
  leftIcon,
  title,
  subtitle,
  actionWidget,
  date,
}) {
  return (
    <Collapse in={show}>
      <Row
        sx={{
          p: 2,
        }}
      >
        <Box sx={{ width: `${NOTIFICATION_ICON_SIZE}px` }}>{leftIcon}</Box>
        <Column
          sx={{
            ml: 2,
            flexBasis: "100%",
          }}
        >
          <Row sx={{ justifyContent: "space-between" }}>
            <Typography variant="h6" fontSize="1rem">
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize="0.7rem"
              textAlign="right"
              sx={{ ml: 2 }}
            >
              {formatDistanceToNowStrict(date)}
            </Typography>
          </Row>
          <Typography variant="subtitle1" fontSize="0.85em">
            {subtitle}
          </Typography>
          <Box sx={{ mt: 1, alignSelf: "start" }}>{actionWidget}</Box>
        </Column>
      </Row>
    </Collapse>
  )
}

export function NotificationsMenu({
  NotificationTypeToComponentMap,
  anchorEl,
  onClose,
}) {
  const notificationsCount = useSelector(selectNotificationsCount)
  const isMobile = useIsMobile()
  const positionPropValue = isMobile ? "0!important" : "initial"

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      sx={{
        "& .MuiDivider-root": {
          mx: 2,
        },
        "& .MuiPopover-paper": {
          width: { xs: "100vw", sm: "fit-content" },
          height: { xs: "100vh", sm: "fit-content" },
          top: positionPropValue,
          left: positionPropValue,
          maxWidth: { xs: "100%", sm: "initial" },
          maxHeight: { xs: "100%", sm: "400px" },
          borderRadius: (theme) => (isMobile ? 0 : theme.units.borderRadius),
        },
      }}
      anchorReference={isMobile ? "anchorPosition" : "anchorEl"}
      anchorPosition={{
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "400px" },
        }}
      >
        <Row
          sx={{
            alignItems: "center",
            p: 2,
          }}
        >
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, fontSize: "1.2rem" }}>
            Notifications
          </Typography>
        </Row>
        <Divider />
        {notificationsCount === 0 && (
          <Row sx={{ justifyContent: "center", p: 2 }}>
            <Typography
              variant="h6"
              sx={{ color: "text.secondary2", fontSize: "1.1rem" }}
            >
              No notifications to show at this time
            </Typography>
          </Row>
        )}
        {notificationsCount > 1 && <Divider />}
        <Notifications
          NotificationTypeToComponentMap={NotificationTypeToComponentMap}
          closeMenu={onClose}
        />
      </Box>
    </Popover>
  )
}
