import Collapse from "@mui/material/Collapse"
import Snackbar from "@mui/material/Snackbar"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { useAuthorizedAxiosClient } from "../utils"
import Column from "./Column"
import DigitCodeTextFields, {
  useDigitCodeTextFieldsState,
} from "./DigitCodeTextFields"
import ErrorMessage from "./ErrorMessage"
import LoadingButton from "./LoadingButton"
import PasswordTextField from "./PasswordTextField"
import Row from "./Row"

export default function ChangePasswordForm() {
  const CHANGE_PASSWORD_STAGES = {
    changePasswordButton: 1,
    inputDigitCode: 2,
    setNewPassowrd: 3,
  }
  const CHANGE_PASSWORD_DIGIT_CODE_LENGTH = 6
  const ACCOUNT_MANAGEMENT_TITLES = {
    [CHANGE_PASSWORD_STAGES.changePasswordButton]: "Change password",
    [CHANGE_PASSWORD_STAGES.inputDigitCode]:
      "Confirmation email has been sent to you!",
    [CHANGE_PASSWORD_STAGES.setNewPassowrd]: "Almost done!",
  }
  const ACCOUNT_MANAGEMENT_SUBTITLES = {
    [CHANGE_PASSWORD_STAGES.changePasswordButton]:
      "It's not bad to change your password once in a while, we'll make it easy for you.",
    [CHANGE_PASSWORD_STAGES.inputDigitCode]: `We've just sent you an email with ${CHANGE_PASSWORD_DIGIT_CODE_LENGTH}-digit code. Enter it below.`,
    [CHANGE_PASSWORD_STAGES.setNewPassowrd]:
      "Great work! Enter your new password below and you should be done.",
  }
  const [isChangePasswordFormLoading, setIsChangePasswordFormLoading] =
    useState(false)
  const [changePasswordStage, setChangePasswordStage] = useState(
    CHANGE_PASSWORD_STAGES.changePasswordButton
  )
  const {
    digitCode: sixDigitCode,
    setDigitCode: setSixDigitCode,
    error: sixDigitCodeError,
    setError: setSixDigitCodeError,
    digitString,
  } = useDigitCodeTextFieldsState()
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordError, setPasswordError] = useState(null)
  const [
    showPasswordChangedSuccessSnackbar,
    setShowPasswordChangedSuccessSnackbar,
  ] = useState(false)

  const axios = useAuthorizedAxiosClient()

  function handleChangePasswordButtonClicked() {
    setIsChangePasswordFormLoading(true)
    axios
      .post("/users/api/send-change-password-email/")
      .then(() => {
        setChangePasswordStage(CHANGE_PASSWORD_STAGES.inputDigitCode)
      })
      .finally(() => {
        setIsChangePasswordFormLoading(false)
      })
  }

  function handleConfirmDigitCodeButtonClicked() {
    setIsChangePasswordFormLoading(true)
    axios
      .post("/users/api/verify-change-password-token/", {
        token: digitString,
      })
      .then(() => {
        setChangePasswordStage(CHANGE_PASSWORD_STAGES.setNewPassowrd)
      })
      .catch(() => {
        setSixDigitCodeError("Invalid code")
      })
      .finally(() => {
        setIsChangePasswordFormLoading(false)
      })
  }

  function handleTextFieldChangedWrapper(setter) {
    return function (e) {
      setter(e.target.value)
      setPasswordError(null)
    }
  }

  function handleSaveNewPasswordButtonClicked() {
    setIsChangePasswordFormLoading(true)
    axios
      .post("/users/api/change-password/", {
        password_1: password1,
        password_2: password2,
      })
      .then(() => {
        setChangePasswordStage(CHANGE_PASSWORD_STAGES.changePasswordButton)
        setShowPasswordChangedSuccessSnackbar(true)
      })
      .catch(({ response: { data } }) => {
        let errorMessage = data.password_1
        if (errorMessage instanceof Array) {
          errorMessage = errorMessage.join("")
        }
        setPasswordError(errorMessage)
      })
      .finally(() => {
        setIsChangePasswordFormLoading(false)
      })
  }

  function handleShowPasswordChangedSuccessSnackbarClosed() {
    setShowPasswordChangedSuccessSnackbar(false)
  }

  return (
    <Column>
      <Typography variant="h6">
        {ACCOUNT_MANAGEMENT_TITLES[changePasswordStage]}
      </Typography>
      <Typography variant="body1" sx={{ color: "text.secondary2", mt: 1 }}>
        {ACCOUNT_MANAGEMENT_SUBTITLES[changePasswordStage]}
      </Typography>
      <Column sx={{ mt: 4, alignItems: "flex-start" }}>
        <Collapse
          in={
            changePasswordStage === CHANGE_PASSWORD_STAGES.changePasswordButton
          }
        >
          <LoadingButton
            variant="contained"
            size="large"
            onClick={handleChangePasswordButtonClicked}
            isLoading={isChangePasswordFormLoading}
          >
            Change password
          </LoadingButton>
        </Collapse>
        <Collapse
          in={changePasswordStage === CHANGE_PASSWORD_STAGES.inputDigitCode}
        >
          <Column>
            <DigitCodeTextFields
              digitCode={sixDigitCode}
              setDigitCode={setSixDigitCode}
              error={sixDigitCodeError}
              setError={setSixDigitCodeError}
              onFull={handleConfirmDigitCodeButtonClicked}
              errorSX={{ mt: 1, ml: 1 }}
            />
            <ErrorMessage error={sixDigitCodeError} sx={{ mt: 1, ml: 1 }} />
            <LoadingButton
              variant="contained"
              sx={{ mt: 4 }}
              isLoading={isChangePasswordFormLoading}
            >
              Confirm
            </LoadingButton>
          </Column>
        </Collapse>
        <Collapse
          in={changePasswordStage === CHANGE_PASSWORD_STAGES.setNewPassowrd}
        >
          <Column>
            <Row
              sx={{
                "& .MuiTextField-root": { width: "16em" },
              }}
            >
              <PasswordTextField
                label="New password"
                error={!!passwordError}
                helperText={passwordError}
                value={password1}
                onChange={handleTextFieldChangedWrapper(setPassword1)}
              />
              <PasswordTextField
                label="Confirm new password"
                sx={{ ml: 2 }}
                value={password2}
                onChange={handleTextFieldChangedWrapper(setPassword2)}
              />
            </Row>
            <LoadingButton
              variant="contained"
              isLoading={isChangePasswordFormLoading}
              disabled={!password1 || !password2}
              sx={{ mt: 4 }}
              onClick={handleSaveNewPasswordButtonClicked}
            >
              Save
            </LoadingButton>
          </Column>
        </Collapse>
      </Column>
      <Snackbar
        message="Password changed successfully!"
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        autoHideDuration={5000}
        open={showPasswordChangedSuccessSnackbar}
        onClose={handleShowPasswordChangedSuccessSnackbarClosed}
      />
    </Column>
  )
}
