import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

import Row from "./Row"

export default function SettingsSection({
  title,
  subtitle,
  subtitleButton,
  subtitleMaxWidth,
  children,
}) {
  return (
    <SettingsSectionContainer>
      <Typography variant="h6">{title}</Typography>
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "text.secondary2", mt: 1, maxWidth: subtitleMaxWidth }}
        >
          {subtitle}
        </Typography>
        {subtitleButton}
      </Row>
      {children}
    </SettingsSectionContainer>
  )
}

export function SettingsSectionContainer({ children }) {
  return (
    <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, mt: 4, maxWidth: "100%" }}>
      {children}
    </Paper>
  )
}
