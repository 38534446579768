import { useNavigate } from "react-router-dom"

import SetPasswordPage from "../components/SetPasswordPage"

export default function ForgotPasswordValidKey() {
  const navigate = useNavigate()

  return (
    <SetPasswordPage
      setPasswordUrl="/users/api/reset-password/"
      successCallback={() => {
        navigate("/users/login")
      }}
    />
  )
}
