import Slider from "@mui/material/Slider"

import { formatCurrency } from "../utils"

export default function AgentSigningBonusSlider({
  signingBonus,
  setSigningBonus,
  disabled,
}) {
  const signingBonusRange = {
    start: 0,
    end: 50e3,
  }
  return (
    <Slider
      value={signingBonus}
      onChange={(_, newValue) => setSigningBonus(newValue)}
      min={signingBonusRange.start}
      max={signingBonusRange.end}
      valueLabelDisplay="on"
      valueLabelFormat={formatCurrency}
      marks={[
        {
          label: formatCurrency(signingBonusRange.start),
          value: signingBonusRange.start,
        },
        {
          label: formatCurrency(signingBonusRange.end),
          value: signingBonusRange.end,
        },
      ]}
      disabled={disabled}
    />
  )
}
