import Box from "@mui/material/Box"
import { Outlet } from "react-router-dom"

export default function CommonLayout() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Outlet />
      </Box>
    </>
  )
}
