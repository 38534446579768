import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

export default styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  "& .MuiChip-root": {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))
