import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import PasswordTextField from "../../../common/components/PasswordTextField"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"
import { mixpanelTrackHandleConfirmed, useSetUserPassword } from "../utils"

export default function SetPasswordPage({
  setPasswordUrl = "/users/api/set-password/",
  successCallback,
  WrapperLayout = BuildingVerticalSplitLayout,
}) {
  const {
    password,
    setPassword,
    password2,
    setPassword2,
    passwordError,
    setPasswordError,
    setUserPassword,
    isSubmitting,
  } = useSetUserPassword(setPasswordUrl)

  const searchParams = useSearchParams()[0]
  const theme = useTheme()

  function handlePasswordChanged(e) {
    setPassword(e)
    setPasswordError(null)
  }

  function handlePassword2Changed(e) {
    setPassword2(e)
    setPasswordError(null)
  }

  function handleSavePasswordButtonClicked() {
    setUserPassword(searchParams.get("key")).then(successCallback)
  }

  useEffect(() => {
    mixpanelTrackHandleConfirmed()
  }, [])

  return (
    <WrapperLayout>
      <Column sx={{ mt: 2 }}>
        <Typography variant="h4">
          Create your password and you're in!
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          Use the form below to set your password
        </Typography>
        <PasswordTextField
          label="Password"
          fullWidth
          sx={{ mt: 4 }}
          value={password}
          onChange={handlePasswordChanged}
          error={!!passwordError}
          helperText={passwordError}
          autoFocus
        />
        <PasswordTextField
          label="Confirm password"
          fullWidth
          sx={{ mt: 2 }}
          value={password2}
          onChange={handlePassword2Changed}
        />
        <LoadingButton
          disabled={!password || !password2}
          sx={{
            mt: 4,
            alignSelf: "flex-start",
            width: "10em",
            [theme.breakpoints.down("sm")]: {
              alignSelf: "center",
              width: "100%",
            },
          }}
          size="large"
          onClick={handleSavePasswordButtonClicked}
          isLoading={isSubmitting}
        >
          Save
        </LoadingButton>
      </Column>
    </WrapperLayout>
  )
}
