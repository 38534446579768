import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"

import GlassesIcon from "../../resources/icons/glasses.svg"
import ConversationChip from "./ConversationChip"

export default function IdentityRevealedChip({
  identityRevealed,
  darkBackground,
}) {
  return (
    <ConversationChip
      icon={
        identityRevealed ? (
          <VisibilityOutlinedIcon fontSize="small" />
        ) : (
          <img src={GlassesIcon} alt="visiblity" />
        )
      }
      label={`You're ${identityRevealed ? "visible" : "Anonymous"}`}
      darkBackground={darkBackground}
    />
  )
}
