import { linearProgressClasses } from "@mui/material/LinearProgress"
import { useTheme } from "@mui/material/styles"
import { forwardRef, useEffect, useState } from "react"

function AnimatedProgressBar(
  { Component, value, horizontalOnMobile = false, ...props },
  ref
) {
  const [animatedValue, setAnimatedValue] = useState(0)

  useEffect(
    () => {
      if (value) {
        setAnimatedValue(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )
  return (
    <Component
      {...props}
      value={animatedValue}
      sx={{
        ...props.sx,
        ...useProgressVerticalTransformStyle(animatedValue, horizontalOnMobile),
      }}
      ref={ref}
    />
  )
}

// needed to show tooltips around the bar
export default forwardRef(AnimatedProgressBar)

function useProgressVerticalTransformStyle(progress, horizontalOnMobile) {
  const theme = useTheme()
  const styles = {
    [`& .${linearProgressClasses.bar}`]: {
      transform: `translateY(${-progress}%)!important`,
    },
  }
  if (horizontalOnMobile) {
    styles[`& .${linearProgressClasses.bar}`][theme.breakpoints.only("xs")] = {
      transform: `translateX(-${progress}%)!important`,
    }
  }
  return styles
}
