import Column from "../../../common/components/Column"
import PasswordTextField from "../../../common/components/PasswordTextField"

export default function PasswordFields({
  password,
  setPassword,
  password2,
  setPassword2,
  passwordError,
  handleKeyDownEvent,
}) {
  return (
    <Column>
      <PasswordTextField
        label="Password"
        value={password}
        onChange={setPassword}
        onKeyDown={handleKeyDownEvent}
        error={!!passwordError}
        helperText={passwordError}
        fullWidth
      />
      <PasswordTextField
        label="Confirm password"
        value={password2}
        onChange={setPassword2}
        onKeyDown={handleKeyDownEvent}
        fullWidth
        sx={{ mt: 3 }}
      />
    </Column>
  )
}
