import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const Column = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}))

export const ColumnButRowOnMobile = styled(Column)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    flexDirection: "row",
  },
}))

export default Column
