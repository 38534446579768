import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom"

import SmartsetterLogoTitle from "../../common/components/SmartsetterLogoTitle"
import { selectUserType } from "../../pages/users/slice"
import { useLoginUnauthenticatedUser } from "../../pages/users/utils"
import { USER_TYPES } from "../../pages/users/utils"
import { useShowBottomNav } from "../utils"
import Column from "./Column"
import Row from "./Row"
import {
  ImageSidebarLink,
  ImageSidebarLinkData,
  MuiIconSidebarLink,
} from "./SidebarLink"

export default function AuthenticatedUserLayout({
  sidebarLinksData,
  navbar,
  belowNavbarContent,
}) {
  const AGENT_BOTTOM_NAV_LINKS = {
    home: 0,
    brokerages: 1,
    conversations: 2,
    proposals: 3,
  }
  const BROKERAGE_BOTTOM_NAV_LINKS = {
    dashboard: 0,
    candidates: 1,
    conversations: 2,
    proposals: 3,
  }
  const [bottomNavLinkIndex, setBottomNavLinkIndex] = useState(
    BROKERAGE_BOTTOM_NAV_LINKS.dashboard
  )
  const location = useLocation()
  const userType = useSelector(selectUserType)

  useLoginUnauthenticatedUser()
  const showBottomNav = useShowBottomNav()

  useEffect(
    () => {
      if (!userType) {
        return
      }
      let bottomNavLinkIndex
      const secondURLPart = location.pathname.split("/")[2]
      switch (userType) {
        case USER_TYPES.agent:
          switch (secondURLPart) {
            case "home":
              bottomNavLinkIndex = AGENT_BOTTOM_NAV_LINKS.home
              break
            case "brokerages":
              bottomNavLinkIndex = AGENT_BOTTOM_NAV_LINKS.brokerages
              break
            case "conversations":
              bottomNavLinkIndex = AGENT_BOTTOM_NAV_LINKS.conversations
              break
            case "proposals":
              bottomNavLinkIndex = AGENT_BOTTOM_NAV_LINKS.proposals
              break
            default:
              bottomNavLinkIndex = null
          }
          break
        case USER_TYPES.customer:
          switch (secondURLPart) {
            case "dashboard":
              bottomNavLinkIndex = BROKERAGE_BOTTOM_NAV_LINKS.dashboard
              break
            case "candidates":
              bottomNavLinkIndex = BROKERAGE_BOTTOM_NAV_LINKS.candidates
              break
            case "conversations":
              bottomNavLinkIndex = BROKERAGE_BOTTOM_NAV_LINKS.conversations
              break
            case "proposals":
              bottomNavLinkIndex = BROKERAGE_BOTTOM_NAV_LINKS.proposals
              break
            default:
              bottomNavLinkIndex = null
          }
          break
        default:
          bottomNavLinkIndex = null
      }
      setBottomNavLinkIndex(bottomNavLinkIndex)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, userType]
  )

  return (
    <>
      <Row id="authenticated-user-root">
        {showBottomNav ? (
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
            elevation={3}
          >
            <StyledBottomNavigation
              value={bottomNavLinkIndex}
              onChange={(e, value) => setBottomNavLinkIndex(value)}
              showLabels
            >
              {sidebarLinksData.map((linkData, index) => (
                <BottomNavigationActionWrapper
                  key={linkData.to}
                  linkData={linkData}
                  index={index}
                  currentLinkIndex={bottomNavLinkIndex}
                />
              ))}
            </StyledBottomNavigation>
          </Paper>
        ) : (
          <Column
            sx={{
              p: 2,
              background: (theme) => theme.palette.otherwise.lightBackground,
              width: "280px",
              height: "calc(100vh)",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            <Row
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SmartsetterLogoTitle />
            </Row>
            <Column
              sx={{
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box sx={{ mt: 6 }}>
                {sidebarLinksData.map((linkData) =>
                  linkData instanceof ImageSidebarLinkData ? (
                    <ImageSidebarLink key={linkData.to} {...linkData} />
                  ) : (
                    <MuiIconSidebarLink key={linkData.to} {...linkData} />
                  )
                )}
              </Box>
            </Column>
          </Column>
        )}

        <Column sx={{ flexBasis: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              zIndex: 100,
              top: 0,
              left: 0,
              right: 0,
              background: "white",
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(to bottom left, rgba(118, 60, 219, 0.15) 0%, rgba(138, 63, 205, 0) 67.43%)",
              }}
            >
              <Row
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: showBottomNav ? 2 : 4,
                  py: 1,
                  borderBottom: (theme) =>
                    `1px solid ${theme.palette.otherwise.border}`,
                }}
              >
                {showBottomNav && <SmartsetterLogoTitle />}
                {navbar}
              </Row>
            </Box>
          </Box>
          {belowNavbarContent}
          <Box
            sx={{
              position: "relative",
              pb: showBottomNav ? 7 : 0,
            }}
          >
            <Outlet />
          </Box>
        </Column>
      </Row>
    </>
  )
}

function BottomNavigationActionWrapper({
  linkData,
  index,
  currentLinkIndex,
  ...props
}) {
  const isActive = index === currentLinkIndex

  function LinkIcon() {
    return linkData instanceof ImageSidebarLinkData ? (
      <img
        src={isActive ? linkData.activeIcon : linkData.normalIcon}
        alt="nav icon"
      />
    ) : (
      <linkData.Icon />
    )
  }

  const commonActionProps = {
    label: linkData.leftLabel,
    icon: <LinkIcon />,
    component: RouterLink,
    to: linkData.to,
    id: linkData.id,
    ...props,
  }

  return linkData.rightLabel ? (
    <StyledBottomNavigationActionWithSecondaryLabel
      {...commonActionProps}
      unread-message-count={linkData.rightLabel}
    />
  ) : (
    <StyledBottomNavigationAction {...commonActionProps} />
  )
}

const StyledBottomNavigation = styled(BottomNavigation)(() => ({
  height: "72px",
}))

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    "&.Mui-selected": {
      borderTop: `2px solid ${theme.palette.primary.main}`,
    },
    "& .MuiBottomNavigationAction-label": {
      marginTop: theme.spacing(1),
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      color: theme.palette.text.primary,
    },
  })
)

const StyledBottomNavigationActionWithSecondaryLabel = styled(
  StyledBottomNavigationAction
)(({ theme }) => ({
  "&::after": {
    content: `attr(unread-message-count)`,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    padding: theme.spacing(0.5),
    borderRadius: "100px",
    fontSize: "9px",
    width: "1.8em",
    height: "2em",
    textAlign: "center",
    lineHeight: 1,
    position: "absolute",
    top: 8,
    left: "60%",
  },
}))
