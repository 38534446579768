import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export const BorderedChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: "white",
  lineHeight: 1,
}))

export const GreenBorderedChip = styled(BorderedChip)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
}))

export const GrayBorderedChip = styled(BorderedChip)(({ theme }) => ({
  borderColor: theme.palette.text.secondary2,
  color: theme.palette.text.secondary2,
}))

const BLUE_CHIP_COLOR = "#377ce0"
export const BlueBorderedChip = styled(BorderedChip)(() => ({
  borderColor: BLUE_CHIP_COLOR,
  color: BLUE_CHIP_COLOR,
}))

export const WarningBorderedChip = styled(BorderedChip)(({ theme }) => ({
  borderColor: theme.palette.warning.main,
  color: theme.palette.warning.main,
}))

export const PurpleBorderedChip = styled(BorderedChip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  fontWeight: 500,
}))

export const PinkBorderedChip = styled(BorderedChip)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  color: theme.palette.secondary.main,
  fontWeight: 500,
}))

export const RedBorderedChip = styled(BorderedChip)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  fontWeight: 600,
}))

export default BorderedChip
