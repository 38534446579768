import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDebounce } from "usehooks-ts"

import Column from "../../../common/components/Column"
import { useUpdateCandidate } from "./utils"

export default function CandidateDrawer({
  children,
  highlightedCandidate,
  setHighlightedCandidate,
}) {
  const BASEBALL_CARD_MARGIN_TOP = 175

  return (
    <SwipeableDrawer
      open={!!highlightedCandidate}
      onOpen={() => {}}
      onClose={() => setHighlightedCandidate(null)}
      anchor="right"
      elevation={1}
      PaperProps={{
        sx: {
          width: { xs: "100vw", sm: "580px" },
          height: {
            xs: "100vh",
            sm: `calc(100% - ${BASEBALL_CARD_MARGIN_TOP}px)`,
          },
          top: { xs: 0, sm: `${BASEBALL_CARD_MARGIN_TOP}px` },
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          opacity: "0!important", // removes backdrop but allows clicking it to hide the drawer
        },
        zIndex: 1400, // more than default dialog of 1300 to show above dialog details on mobile
      }}
    >
      {children}
    </SwipeableDrawer>
  )
}

export function CloseIconButton({ setHighlightedCandidate }) {
  return (
    <IconButton onClick={() => setHighlightedCandidate(null)}>
      <KeyboardTabOutlinedIcon />
    </IconButton>
  )
}

export function BaseballCardSectionDivider() {
  return <Divider sx={{ my: 4 }} />
}

export function NotesEditor({ highlightedCandidate, onCandidateUpdated }) {
  const [notes, setNotes] = useState("")
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false)
  const [notesModified, setNotesModified] = useState(false)
  const debonucedNotes = useDebounce(notes, 1000)
  const updateCandidate = useUpdateCandidate()
  const notesNotificationStyles = {
    position: "absolute",
    bottom: 8,
    right: "1em",
  }

  function handleNotesChanged(e) {
    setNotes(e.target.value)
    setNotesModified(true)
  }

  useEffect(
    () => {
      if (!highlightedCandidate) {
        return
      }
      setNotes(highlightedCandidate.notes || "")
      setNotesModified(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [highlightedCandidate?.id]
  )

  useEffect(
    () => {
      if (!highlightedCandidate || !notesModified) {
        return
      }
      setIsUpdatingNotes(true)
      updateCandidate(highlightedCandidate, { notes: debonucedNotes })
        .then(({ data }) => {
          onCandidateUpdated({ ...highlightedCandidate, ...data })
        })
        .finally(() => setIsUpdatingNotes(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debonucedNotes]
  )

  return (
    <Column sx={{ position: "relative" }}>
      <Typography variant="h6">Notes</Typography>
      <TextField
        label="Notes"
        multiline
        rows={3}
        value={notes}
        onChange={handleNotesChanged}
        fullWidth
        sx={{ mt: 4 }}
      />
      {notesModified &&
        (isUpdatingNotes ? (
          <CircularProgress
            size={12}
            variant="indeterminate"
            color="primary"
            sx={notesNotificationStyles}
          />
        ) : (
          <Typography
            variant="body1"
            color="text.secondary2"
            fontSize="smaller"
            sx={notesNotificationStyles}
          >
            Saved
          </Typography>
        ))}
    </Column>
  )
}
