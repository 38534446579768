import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { useAuthorizedAxiosClient } from "../utils"
import LoadingButton from "./LoadingButton"
import Row from "./Row"
import SuccessSnackbar from "./SuccessSnackbar"

export default function TitledEditorSection({
  title,
  valueProps,
  editButtonClicked,
  onEditChanged,
  apiURL,
  successCallback,
  children,
  highlight = false,
}) {
  const [isSavingProfile, setIsSavingProfile] = useState(false)
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false)
  const axios = useAuthorizedAxiosClient()

  function handleEditButtonClicked() {
    if (editButtonClicked) {
      setIsSavingProfile(true)
      axios
        .put(apiURL, valueProps)
        .then(({ data }) => {
          successCallback(data)
          // first argument is whether edit mode should be enabled. second is whether saving was successful
          onEditChanged(false, true)
          setSuccessSnackbarOpen(true)
        })
        .catch(() => onEditChanged(false, false))
        .finally(() => {
          setIsSavingProfile(false)
        })
    } else {
      onEditChanged(true, false)
    }
  }

  return (
    <TitledEditorSectionLayout
      title={title}
      isEditingSection={editButtonClicked}
      onClick={handleEditButtonClicked}
      isLoading={isSavingProfile}
      highlight={highlight}
    >
      {children}
      <SuccessSnackbar
        open={successSnackbarOpen}
        onClose={() => setSuccessSnackbarOpen(false)}
      />
    </TitledEditorSectionLayout>
  )
}

/* Use this component when you have optional properties that can still be shown in non-editing status */
export function TitledEditorSectionLayout({
  title,
  subtitle,
  isEditingSection,
  onClick,
  isLoading,
  children,
  highlight = false,
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        p: 3,
        boxShadow: (theme) =>
          highlight ? theme.palette.otherwise.highlightShadow : undefined,
      }}
    >
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <LoadingButton
          variant="outlined"
          onClick={onClick}
          isLoading={isLoading}
        >
          {isEditingSection ? "Save" : "Edit"}
        </LoadingButton>
      </Row>
      {subtitle && (
        <Typography variant="body1" color="text.secondary2">
          {subtitle}
        </Typography>
      )}
      {children}
    </Paper>
  )
}
