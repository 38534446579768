import Typography from "@mui/material/Typography"

export default function FilterTitleDescription({ title, description }) {
  return (
    <>
      <Typography sx={{ fontSize: "1em" }} variant="h6">
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        {description}
      </Typography>
    </>
  )
}
