import { createSlice } from "@reduxjs/toolkit"

import { TOUR_STATUS } from "../../common/components/CommonTourProvider"
import { createRetrieveProfileAsyncThunk } from "../../common/utils"

const retrieveAgentProfile = createRetrieveProfileAsyncThunk(
  "agent/retrieveProfile",
  "/agents/api/profile/",
  "agent"
)

const INITIAL_STATE = {
  profile: {},
  noRequestsWarningElementSize: { width: null, height: null },
  // make phone dialog data global because otherwise I can't open it from
  // notifications list
  isPhoneDialogOpen: false,
}

const agentSlice = createSlice({
  name: "agent",
  initialState: INITIAL_STATE,
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload
    },
    updateProfileProperty: (state, action) => {
      state.profile[action.payload.prop] = action.payload.value
    },
    decrementUnreadConversationsCount: (state, action) => {
      state.profile.unread_conversations_count -= 1
    },
    resetProfile: (state, action) => {
      Object.assign(state, INITIAL_STATE)
    },
    updateNoRequestsWarningElementSize: (state, action) => {
      state.noRequestsWarningElementSize = action.payload
    },
    openPhoneDialog: (state) => {
      state.isPhoneDialogOpen = true
    },
    closePhoneDialog: (state) => {
      state.isPhoneDialogOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveAgentProfile.fulfilled, (state, action) => {
      state.profile = { ...state.profile, ...action.payload }
    })
  },
})

export const selectProfile = (state) => state.agent.profile

export const profileLoading = (state) =>
  Object.keys(state.agent.profile).length === 0

const profileFilledProperties = (profile) =>
  Object.keys(profile)
    .filter(filterIsInterestingProperty)
    .filter((key) => profile[key] != null && profile[key] !== "")

const profilePropertiesInSettings = [
  "job_title",
  "mission_statement",
  "phone",
  "address",
  "website",
  "profile_image",
  "years_in_business",
  "sales_volume_last_year",
  "homes_sold_last_year",
]

const filterIsInterestingProperty = (prop) =>
  profilePropertiesInSettings.indexOf(prop) !== -1

export const selectProfileMissingProperties = (state) => {
  const profile = selectProfile(state)
  return Object.keys(profile)
    .filter(filterIsInterestingProperty)
    .filter((key) => profile[key] === null || profile[key] === "")
}

export const selectProfileProgress = (state) =>
  (profileFilledProperties(selectProfile(state)).length /
    profilePropertiesInSettings.length) *
  100

export const selectIsProfileComplete = (state) =>
  selectProfileProgress(state) === 100

export const selectShowWelcomeTour = (state) =>
  selectProfile(state).welcome_tour_status === TOUR_STATUS.new && false

export const selectHasFullStats = (state) => {
  const profile = selectProfile(state)
  return (
    profile.years_in_business !== null &&
    profile.homes_sold_last_year !== null &&
    profile.sales_volume_last_year !== null
  )
}

export const selectShowBlueCheck = (state) => {
  const profile = selectProfile(state)
  return profile.has_blue_check
}

export const selectShowNoAgentRequestsWarning = (state) =>
  !profileLoading(state) &&
  !selectProfile(state).created_signup_agent_requests &&
  !selectShowBlueCheck(state)

export const selectNoRequestsWarningElementSize = (state) =>
  state.agent.noRequestsWarningElementSize

export const selectHasBoard = (state) => !!selectProfile(state).board

export const selectUnreadConversationsCount = (state) =>
  selectProfile(state).unread_conversations_count

export const selectIsPhoneDialogOpen = (state) => state.agent.isPhoneDialogOpen

export const {
  updateProfile,
  updateProfileProperty,
  decrementUnreadConversationsCount,
  resetProfile,
  updateNoRequestsWarningElementSize,
  openPhoneDialog,
  closePhoneDialog,
} = agentSlice.actions

export { retrieveAgentProfile }

export default agentSlice.reducer
