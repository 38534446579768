import { createContext } from "react"

export const MESSAGE_SOURCES = {
  agent: "agent",
  brokerage: "customer",
}

export default createContext({
  apiURLPrefix: null,
  messageSource: null,
  conversationMutedPropertyName: null,
  conversationReportedPropertyName: null,
})
