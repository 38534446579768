import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"
import CSSBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { RouterProvider } from "react-router-dom"

import reportWebVitals from "./reportWebVitals"
import router from "./router"
import { store } from "./store"
import "./styles.css"
import theme from "./theme"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <GTMProvider state={{ id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID }}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <CSSBaseline />
          <RouterProvider router={router} />
        </ReduxProvider>
      </ThemeProvider>
    </React.StrictMode>
  </GTMProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
