import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

import BuildingImage from "../resources/images/building.webp"

export const ImageBox = styled(Box)(({ theme }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    height: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}))

export default styled(ImageBox)(({ theme }) => ({
  backgroundImage: `url(${BuildingImage})`,
}))
