import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export const WhiteHeader = styled(Typography)(() => ({
  color: "white",
}))

export const WhiteSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.otherwise.secondaryWhite,
}))

export const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}))

export const WhiteOutlinedButton = styled(WhiteButton)(({ theme }) => ({
  backgroundColor: "transparent",
  borderColor: "white",
  color: "white",
  "&:hover": { color: theme.palette.primary.main },
}))
