import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useState } from "react"

import Column from "../../../../common/components/Column"
import VerifyOwnershipDialog from "../verify-ownership/Dialog"
import {
  ActionButtons,
  ContentSection,
  HeaderSection,
  REPORT_REASONS,
  useCommonReportReasonState,
  useReportBrokerage,
} from "./Common"

export default function ReportBrokerageDialog({
  open,
  onClose,
  onReopen,
  onPreviousButtonClicked,
  brokerage,
}) {
  const { reportReason, setReportReason, note, setNote } =
    useCommonReportReasonState()
  const [isVerifyOwnershipDialogOpen, setIsVerifyOwnershipDialogOpen] =
    useState(false)

  const { isReporting, reportBrokerage } = useReportBrokerage()

  function handleReportButtonClicked() {
    if (reportReason === REPORT_REASONS.claim) {
      setIsVerifyOwnershipDialogOpen(true)
      onClose()
    } else {
      reportBrokerage(reportReason, note, null, brokerage).then(() => onClose())
    }
  }

  function handleOnVerifyOwnershipDialogPreviousButtonClicked() {
    setIsVerifyOwnershipDialogOpen(false)
    onReopen()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      keepMounted
    >
      {brokerage && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Column
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <HeaderSection brokerage={brokerage} />
          </Column>
          <ContentSection
            brokerage={brokerage}
            reportReason={reportReason}
            setReportReason={setReportReason}
            note={note}
            setNote={setNote}
          />
        </DialogContent>
      )}

      <DialogActions>
        <ActionButtons
          reportReason={reportReason}
          isReporting={isReporting}
          onPreviousButtonClicked={onPreviousButtonClicked}
          onReportButtonClicked={handleReportButtonClicked}
        />
        <VerifyOwnershipDialog
          open={isVerifyOwnershipDialogOpen}
          onClose={() => setIsVerifyOwnershipDialogOpen(false)}
          onPreviousButtonClicked={
            handleOnVerifyOwnershipDialogPreviousButtonClicked
          }
          reason={reportReason}
          note={note}
          brokerage={brokerage}
        />
      </DialogActions>
    </Dialog>
  )
}
