import DeleteOutlined from "@mui/icons-material/DeleteOutlined"
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutline"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

export default function MessageContextMenu({
  contextMenu,
  onClose,
  onEditClicked,
  onDeleteClicked,
}) {
  return (
    <Menu
      open={contextMenu !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu === null
          ? undefined
          : {
              top: contextMenu.mouseY,
              left: contextMenu.mouseX,
            }
      }
      onClose={onClose}
    >
      <MenuItem onClick={onEditClicked}>
        <ListItemIcon>
          <ModeEditOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDeleteClicked}>
        <ListItemIcon>
          <DeleteOutlined color="primary" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  )
}
