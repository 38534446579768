import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { AnalyticsBrowser } from "@segment/analytics-next"
import axios from "axios"
import Hammer from "hammerjs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import { logoutUser, selectToken, setUserType } from "../pages/users/slice"

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

class SegmentWrapper {
  constructor() {
    this.segmentAnalytics = AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    })
    this.superProperties = {}
  }

  identify(userId, traits) {
    this.segmentAnalytics.identify(userId, traits)
  }

  register(props) {
    // see: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#super-properties
    this.superProperties = Object.assign(this.superProperties, props)
  }

  track(eventName, eventData = {}) {
    this.segmentAnalytics.track(
      eventName,
      Object.assign(eventData, this.superProperties)
    )
  }

  reset() {
    this.superProperties = {}
    this.segmentAnalytics.reset()
  }
}

export const segmentAnalytics = new SegmentWrapper()

export function createStorageKey(key) {
  return `agentPortal.${key}`
}

export function useAuthorizedAxiosClient() {
  const token = useSelector(selectToken)
  // fix sometimes anonymous request failing because of empty token
  const headers = {}
  if (token) {
    headers.Authorization = `Token ${token}`
  }
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  })
}

export function fixElasticSearchPoint(point) {
  return {
    type: "Point",
    coordinates: point.coordinates,
  }
}

export function buildAbsoluteURL(path, protocol) {
  const url = new URL(path, process.env.REACT_APP_API_URL)
  let urlString = url.toString()
  if (protocol) {
    urlString = urlString.replace(url.protocol, protocol + ":")
  }
  return urlString
}

export function getFileExtension(filename) {
  return filename.split(".").pop()
}

export function createRetrieveProfileAsyncThunk(
  actionName,
  profileAPIUrl,
  userType
) {
  return createAsyncThunk(actionName, (_, thunkAPI) => {
    const token = selectToken(thunkAPI.getState())
    return axiosClient
      .get(profileAPIUrl, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(({ data }) => {
        thunkAPI.dispatch(setUserType(userType))
        return data
      })
      .catch(({ response: { data } }) => {
        if (data.detail === "Invalid token.") {
          thunkAPI.dispatch(logoutUser())
        }
        return Promise.reject(data)
      })
  })
}

export function getFullName(profile) {
  return `${profile.first_name} ${profile.last_name}`
}

export function splitNameForAPI(name) {
  const [firstName, ...lastParts] = name.split(" ")
  return { first_name: firstName, last_name: lastParts.join(" ") }
}

export function formatCurrency(value) {
  let suffix, divisor
  if (value / 1e9 >= 1) {
    suffix = "B"
    divisor = 1e9
  } else if (value / 1e6 >= 1) {
    suffix = "M"
    divisor = 1e6
  } else {
    suffix = "K"
    divisor = 1e3
  }
  return `$${(
    Math.round((value * 10) / divisor) / 10
  ).toLocaleString()}${suffix}`
}

export function handleAxiosUploadProgress(progressEvent, stateSetter) {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  )
  stateSetter(percentCompleted)
}

export function brokerageSizeDisplay(headCount) {
  if (headCount === null) {
    return "Unknown size"
  }
  return `${headCount} agents`
}

export function yearFoundedDisplay(yearFounded) {
  if (yearFounded === null) {
    return "Unknown year founded"
  }
  return yearFounded
}

export function signingBonusDisplay(signingBonus) {
  return signingBonus ? signingBonus.toLocaleString() : "Unknown signing bonus"
}

export function brokerageDescriptionDisplay(description) {
  return description
    ? trimLongString(description)
    : "No description for brokerage yet."
}

export function trimLongString(s, maxLen = 100) {
  if (!s) {
    return s
  }
  if (s.length > maxLen) {
    return `${s.substring(0, maxLen)}...`
  }
  return s
}

export function optionalParseInt(value) {
  const parsedInt = parseInt(value)
  return isNaN(parsedInt) ? "" : parsedInt
}

export function handleChangedPositiveOnlyTextField(e, onPositive) {
  const newValue = optionalParseInt(e.target.value)
  if (newValue < 0) {
    e.preventDefault()
  } else {
    onPositive(newValue)
  }
}

export function useIsMobile(breakpoint = "xs") {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint))
}

export function useShowBottomNav() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("md"))
}

export function useHammerSwipeHandler(
  swipeContainerRef,
  changeState,
  handleSwipeLeft,
  moveLeftDisabled,
  handleSwipeRight,
  moveRightDisabled
) {
  useEffect(
    () => {
      if (!swipeContainerRef.current) {
        return
      }
      const hammerManager = new Hammer.Manager(swipeContainerRef.current)
      const swipe = new Hammer.Swipe({
        direction: Hammer.DIRECTION_HORIZONTAL,
      })
      hammerManager.add(swipe)
      hammerManager.on("swipe", (e) => {
        if (e.deltaX > 0 && !moveLeftDisabled) {
          // swipe right
          handleSwipeRight()
        } else if (e.deltaX < 0 && !moveRightDisabled) {
          handleSwipeLeft()
        }
      })
      return () => {
        hammerManager.off("swipe")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swipeContainerRef, changeState, moveLeftDisabled, moveRightDisabled]
  )
}

export const AGENT_REQUEST_STATUS_OPTIONS = {
  pending: "pending",
  accepted: "accepted",
  inContact: "in_contact",
  proposalReceived: "proposal_received",
  rejected: "rejected",
}

export function arrayContains(array, value) {
  return array.indexOf(value) !== -1
}

export function stringCapitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const ENVIRONMENT = {
  development: "development",
  production: "production",
}

export function useStateChangeTracker(defaultValue) {
  const [hasChanged, setHasChanged] = useState(false)
  const [state, _setState] = useState(defaultValue)

  function setState(newValue, countAsChange = true) {
    if (countAsChange) {
      setHasChanged(true)
    }
    _setState(newValue)
  }

  function handleChangeSaved() {
    setHasChanged(false)
  }

  return {
    state,
    setState,
    hasChanged,
    handleChangeSaved,
  }
}

export function useProfilePropertyChangeTrackerState({
  defaultValue,
  profilePropertyName,
  profileSelector,
  trackOnChange = false,
  eventName,
}) {
  const profile = useSelector(profileSelector)
  return useObjectChangeTrackerState({
    defaultValue,
    propertyName: profilePropertyName,
    object: profile,
    trackOnChange,
    eventName,
  })
}

export function useObjectChangeTrackerState({
  defaultValue,
  object,
  propertyName,
  trackOnChange = false,
  eventName,
}) {
  const [state, _setState] = useState(defaultValue)
  const [stateChanged, setStateChanged] = useState(false)

  function setState(newValue, countAsChange = true) {
    _setState(newValue)
    if (countAsChange) {
      setStateChanged(true)
    }
    if (typeof defaultValue === "boolean") {
      mixpanelTrack()
    }
  }

  function handleChangeSaved() {
    setStateChanged(false)
  }

  function mixpanelTrack() {
    if (stateChanged || trackOnChange) {
      segmentAnalytics.track(eventName, { "New value": state })
      setStateChanged(false)
    }
  }

  return {
    state,
    setState,
    stateChanged,
    handleChangeSaved,
    mixpanelTrack,
  }
}

export function useRetrieveAllBrands() {
  const [allBrands, setAllBrands] = useState([])

  useEffect(() => {
    axiosClient
      .get("/agents/api/all-brands/")
      .then(({ data }) => setAllBrands(data))
  }, [])
  return { allBrands }
}

export function isEnterKeyEvent(e) {
  return e.key === "Enter"
}

export const NAVBAR_HEIGHT = "74px"

export function useNavigateWithNext() {
  const navigate = useNavigate()
  const searchParams = useSearchParams()[0]

  return (to) => {
    return navigate(searchParams.get("next") || to)
  }
}

export function useTrackCampaignConversion() {
  const [searchParams, setSearchParams] = useSearchParams()
  return (email) => {
    const campaignId = searchParams.get("campaignId")
    if (campaignId) {
      const eventName = searchParams.get("ename")
      setSearchParams(new URLSearchParams())
      return axiosClient.post(
        "/klaviyo-integration/api/track-campaign-conversion/",
        { email, campaignId, ename: eventName }
      )
    }
  }
}

export function openLinkInNewTab(url) {
  window.open(url, "_blank").focus()
}
export function validateZipcode(zipcode) {
  const americanRegex = new RegExp(/\d{5}(-\d{4})?/)
  const canadianRegex = new RegExp(/[A-Z]\d[A-Z]\s?(\d[A-Z]\d)?/, "i")

  function matchesWholeZipcode(regex) {
    const match = zipcode.match(regex)
    return match && match[0] === zipcode
  }

  return (
    matchesWholeZipcode(americanRegex) || matchesWholeZipcode(canadianRegex)
  )
}
