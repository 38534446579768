import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import Divider from "@mui/material/Divider"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ChangePasswordForm from "../../../common/components/ChangePasswordForm"
import Column from "../../../common/components/Column"
import IconTitleValue from "../../../common/components/IconTitleValue"
import LoadingButton from "../../../common/components/LoadingButton"
import Row from "../../../common/components/Row"
import SettingsSection, {
  SettingsSectionContainer,
} from "../../../common/components/SettingsSection"
import TabContainer from "../../../common/components/TabContainer"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import {
  profileLoading,
  selectCurrentBrokerage,
  selectIsFreeRiding,
} from "../slice"
import { formatDate, useIsBrokerageSubscribed } from "../utils"
import PaymentMethodUpdatedDialog from "./PaymentMethodUpdatedDialog"

export default function Settings() {
  const TABS = {
    accountManagement: 0,
    paymentMethod: 1,
  }
  const [selectedTab, setSelectedTab] = useState(TABS.accountManagement)
  const [subscription, setSubscription] = useState(null)
  const [isLoadingCheckoutSession, setIsLoadingCheckoutSession] =
    useState(false)
  const [isLoadingStripe, setIsLoadingStripe] = useState(false)
  const [stripe, setStripe] = useState(null)

  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const isProfileLoading = useSelector(profileLoading)
  const axios = useAuthorizedAxiosClient()
  const searchParams = useSearchParams()[0]
  const theme = useTheme()
  const isMediumOrBelow = useMediaQuery(theme.breakpoints.down("md"))
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()
  const isFreeRiding = useSelector(selectIsFreeRiding)
  const showPaymentMethodTab =
    !isFreeRiding && isBrokerageSubscribed(currentBrokerage)

  function handleUpdatePaymentMethodButtonClicked() {
    setIsLoadingCheckoutSession(true)
    axios
      .get(`/billing/api/${currentBrokerage.id}/update-pm-checkout-session/`)
      .then(({ data }) =>
        stripe.redirectToCheckout({ sessionId: data.session_id })
      )
      .finally(() => {
        setIsLoadingCheckoutSession(false)
      })
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setSubscription(currentBrokerage.subscription)
      setSelectedTab(TABS.accountManagement)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useEffect(() => {
    setIsLoadingStripe(true)
    loadStripe(process.env.REACT_APP_STRIPE_KEY)
      .then((stripe) => setStripe(stripe))
      .finally(() => setIsLoadingStripe(false))
  }, [])

  useEffect(
    () => {
      if (!subscription) {
        return
      }
      const tabParam = searchParams.get("tab")
      if (tabParam === "pm") {
        setSelectedTab(TABS.paymentMethod)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscription]
  )

  return (
    <Column
      sx={{
        width: { xs: "100%", md: "800px" },
        mx: "auto",
        px: { xs: 2, md: 0 },
        py: 4,
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        centered={isMediumOrBelow}
      >
        <Tab label="Account management" />
        {showPaymentMethodTab && <Tab label="Payment method" />}
      </Tabs>
      <TabContainer index={TABS.accountManagement} value={selectedTab}>
        <SettingsSectionContainer>
          <ChangePasswordForm />
        </SettingsSectionContainer>
      </TabContainer>
      {showPaymentMethodTab && (
        <TabContainer index={TABS.paymentMethod} value={selectedTab}>
          <SettingsSection
            title="Payment method"
            subtitle={`Your next billing date is ${formatDate(
              subscription.valid_until
            )}.`}
          >
            <Divider sx={{ mt: 4 }} />
            <Row
              sx={{
                mt: 4,
              }}
            >
              <IconTitleValue
                Icon={() => <PaymentOutlinedIcon fontSize="large" />}
                title="Payment method"
                value={`**** ${subscription.card_data.last4 ?? "Unknown"}`}
              />
            </Row>
            <LoadingButton
              disabled={isLoadingStripe}
              isLoading={isLoadingCheckoutSession}
              onClick={handleUpdatePaymentMethodButtonClicked}
              sx={{ mt: 4 }}
            >
              Update payment method
            </LoadingButton>
          </SettingsSection>
        </TabContainer>
      )}
      <PaymentMethodUpdatedDialog />
    </Column>
  )
}
