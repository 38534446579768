import AgentToMarketStatComparison from "../../pages/brokerage/components/AgentToMarketStatComparison"

export default function CommissionSplitComparison({ commissionSplit, tight }) {
  return (
    <AgentToMarketStatComparison
      agentValue={commissionSplit}
      marketValue={100 - commissionSplit}
      title="Commission Split"
      subtitle={`${commissionSplit}/${100 - commissionSplit}`}
      titleVariant="subtitle1"
      subtitleColor="inherit"
      tight={tight}
    />
  )
}
