import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

export default styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}))
