import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import BrandAvatar from "../../../common/components/BrandAvatar"
import CircularProgressBackdrop from "../../../common/components/CircularProgressBackdrop"
import Column from "../../../common/components/Column"
import ErrorMessage from "../../../common/components/ErrorMessage"
import LoadingButton from "../../../common/components/LoadingButton"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"
import { getFullName, segmentAnalytics } from "../../../common/utils"
import { selectProfile } from "../slice"
import { PLAN_DATA } from "../utils"
import PromoCodeDialog from "./PromoCodeDialog"

export default function BillingInfoDialog({
  open,
  onClose,
  onBackButtonClicked,
  brokerage,
  plan,
}) {
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const [stripeCouponFromPromoCode, setStripeCouponFromCouponCode] = useState()
  const stripeScriptPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const axios = useAuthorizedAxiosClient()
  const isMobile = useIsMobile()

  function handleDialogClosed() {
    onClose()
    axios.post("/klaviyo-integration/api/customer/track-abandoned-paywall/")
  }

  useEffect(
    () => {
      setIsLoadingClientSecret(true)
      let getPaymentIntentAPIURL = `/billing/api/${brokerage.id}/payment-intent/?plan_type=${plan}`
      if (stripeCouponFromPromoCode) {
        getPaymentIntentAPIURL += `&coupon_id=${stripeCouponFromPromoCode.id}`
      }
      axios
        .get(getPaymentIntentAPIURL)
        .then(({ data }) => setStripeClientSecret(data.client_secret))
        .finally(() => setIsLoadingClientSecret(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plan, brokerage, stripeCouponFromPromoCode]
  )

  useEffect(
    () => {
      if (!open) {
        return
      }
      segmentAnalytics.track("Billing info modal opened", {
        "Brokerage ID": brokerage.id,
        "Brokerage name": brokerage.company,
        Plan: PLAN_DATA[plan].display,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  )

  return (
    <Dialog
      open={open}
      onClose={handleDialogClosed}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row
          sx={{
            alignItems: "center",
          }}
        >
          <IconButton onClick={onBackButtonClicked} sx={{ mr: 1 }}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <BrandAvatar brand={{ icon: brokerage.image }} size={48} />
          <Column sx={{ ml: 2 }}>
            <Typography variant="h6">Upgrade to Pro</Typography>
            <Typography variant="body1" color="text.secondary2">
              {brokerage.company}
            </Typography>
          </Column>
        </Row>
        <Divider sx={{ my: 4 }} />
        {open && stripeClientSecret && !isLoadingClientSecret && (
          <Elements
            stripe={stripeScriptPromise}
            options={{ clientSecret: stripeClientSecret, loader: "auto" }}
          >
            <PaymentDetailsForm
              brokerage={brokerage}
              plan={plan}
              onClose={onClose}
              stripeCoupon={stripeCouponFromPromoCode}
              setStripeCoupon={setStripeCouponFromCouponCode}
            />
          </Elements>
        )}
      </DialogContent>
      <CircularProgressBackdrop open={isLoadingClientSecret} />
    </Dialog>
  )
}

function PaymentDetailsForm({
  brokerage,
  plan,
  onClose,
  stripeCoupon,
  setStripeCoupon,
}) {
  const [agreeToTOS, setAgreeToTOS] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false)
  const [openPromoCodeDialog, setOpenPromoCodeDialog] = useState(false)

  const profile = useSelector(selectProfile)
  const stripe = useStripe()
  const stripeElements = useElements()

  const planData = PLAN_DATA[plan]
  const price = getDiscountedPrice()

  function handleHavePromoCodeLinkClicked(e) {
    e.preventDefault()
    setOpenPromoCodeDialog(true)
  }

  function handleRemoveCouponLinkClicked(e) {
    e.preventDefault()
    setStripeCoupon(null)
  }

  function handlePromoCodeDialogClosed(coupon) {
    if (coupon) {
      setStripeCoupon(coupon)
    }
    setOpenPromoCodeDialog(false)
  }

  function getDiscountedPrice() {
    if (stripeCoupon) {
      if (stripeCoupon.amount_off) {
        return planData.price - stripeCoupon.amount_off / 100
      }
      if (stripeCoupon.percent_off) {
        return (
          planData.price -
          (planData.price * stripeCoupon.percent_off) / 100
        ).toFixed(2)
      }
    }
    return planData.price
  }

  function handleUpgradeToProButtonClicked() {
    setIsConfirmingPayment(true)
    const redirectURL = new URL(
      `/brokerage/profile/?bid=${brokerage.id}&plan=${plan}`,
      window.location.href
    ).toString()
    stripe
      .confirmPayment({
        elements: stripeElements,
        confirmParams: {
          return_url: redirectURL,
        },
      })
      .then(({ error }) => {
        if (error) {
          setStripeError(error.message)
        } else {
          setStripeError(null)
          onClose()
        }
      })
      .finally(() => setIsConfirmingPayment(false))
  }

  return (
    <RowButColumnOnMobile sx={{ px: 1 }}>
      <Column
        sx={{ flexBasis: { xs: "100%", sm: "50%" }, mr: { xs: 0, sm: 2 } }}
      >
        <Column>
          <ErrorMessage error={stripeError} sx={{ mb: 2 }} />
          <Typography variant="h6" sx={{ mb: 2 }} lineHeight={1}>
            Payment Method
          </Typography>
          <PaymentElement />
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }} lineHeight={1}>
            Billing Address
          </Typography>
          <AddressElement
            options={{
              mode: "billing",
              defaultValues: {
                name: getFullName(profile),
                address: { line1: profile.address },
              },
            }}
          />
        </Column>
      </Column>
      <Column
        sx={{
          borderRadius: (theme) => theme.units.borderRadius,
          backgroundColor: "otherwise.lightBackground",
          flexBasis: "50%",
          p: 4,
          ml: { xs: 0, sm: 2 },
          mt: { xs: 4, sm: 0 },
        }}
      >
        <Column>
          <Typography variant="h6">Review and purchase</Typography>
          {stripeCoupon ? (
            <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                variant="subtitle2"
                color="success.main"
                sx={{ maxWidth: "18em", fontSize: "0.8em" }}
              >
                <strong>{stripeCoupon.name}</strong> coupon applied!
              </Typography>
              <Link
                href="#"
                color="error.main"
                variant="subtitle2"
                onClick={handleRemoveCouponLinkClicked}
                sx={{ fontSize: "0.8em" }}
              >
                Remove
              </Link>
            </Row>
          ) : (
            <Link
              href="#"
              color="primary.main"
              sx={{ fontWeight: "bold", fontSize: "0.75em" }}
              onClick={handleHavePromoCodeLinkClicked}
            >
              Have a promo code?
            </Link>
          )}
          <PromoCodeDialog
            open={openPromoCodeDialog}
            onClose={handlePromoCodeDialogClosed}
          />
        </Column>
        <Row sx={{ justifyContent: "space-between", mt: 4 }}>
          <Typography variant="body1" color="text.secondary2">
            Pro subscription for {brokerage.company} - {planData.display} Plan
          </Typography>
          <Column sx={{ alignItems: "center", pl: 2 }}>
            <Typography
              variant="body1"
              color="text.secondary2"
              fontWeight={500}
            >
              ${price}
            </Typography>
          </Column>
        </Row>
        <Divider sx={{ mt: 4 }} />
        <Row
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Typography variant="body1" color="text.secondary2">
            Today's payment
          </Typography>
          <Typography variant="h6">${price}</Typography>
        </Row>
        <Divider sx={{ mt: 4 }} />
        <FormControlLabel
          label="I agree to the terms of service"
          control={
            <Checkbox
              checked={agreeToTOS}
              onChange={(e) => setAgreeToTOS(e.target.checked)}
            />
          }
          sx={{ mt: 4 }}
        />
        <LoadingButton
          disabled={!stripe || !stripeElements || !agreeToTOS}
          isLoading={isConfirmingPayment}
          sx={{ alignSelf: "center", mt: 4 }}
          size="large"
          fullWidth
          onClick={handleUpgradeToProButtonClicked}
        >
          Upgrade to Pro
        </LoadingButton>
      </Column>
    </RowButColumnOnMobile>
  )
}
