import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AvatarEditorLayout from "../../../common/components/AvatarEditorLayout"
import LoadingButton from "../../../common/components/LoadingButton"
import {
  getFullName,
  splitNameForAPI,
  useAuthorizedAxiosClient,
} from "../../../common/utils"
import { profileLoading, selectProfile, updateProfile } from "../slice"

export default function EditProfileDialog({ open, onClose }) {
  const [isSavingProfileImage, setIsSavingProfileImage] = useState(false)
  const [name, setName] = useState("")
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)

  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const dispatch = useDispatch()
  const theme = useTheme()
  const axios = useAuthorizedAxiosClient()

  function handleProfileImageUpdated(data) {
    dispatch(updateProfile(data))
  }

  function handleSaveButtonClicked() {
    setIsUpdatingProfile(true)
    axios
      .put("/customers/api/profile/", { ...splitNameForAPI(name) })
      .then(({ data }) => {
        dispatch(updateProfile(data))
        onClose()
      })
      .finally(() => setIsUpdatingProfile(false))
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setName(getFullName(profile))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            alignSelf: "center",
          }}
        >
          <AvatarEditorLayout
            backgroundColor={theme.palette.secondary.main}
            currentAvatar={profile.profile_image}
            isLoading={isSavingProfileImage}
            setIsLoading={setIsSavingProfileImage}
            firstName={profile.first_name}
            profilePropertyName="profile_image"
            updateProfileURL="/customers/api/profile/"
            size={112}
            successCallback={handleProfileImageUpdated}
          />
        </Box>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{
            mt: 4,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isSavingProfileImage || isUpdatingProfile}
          onClick={handleSaveButtonClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
