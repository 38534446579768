import HelpOutlinedIcon from "@mui/icons-material/HelpOutline"
import SMSOutlinedIcon from "@mui/icons-material/SmsOutlined"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import CircularIcon from "../../../common/components/CircularIcon"
import InfoBubble from "../../../common/components/InfoBubble"
import SuccessSnackbar from "../../../common/components/SuccessSnackbar"
import { axiosClient } from "../../../common/utils"
import { BROKERAGE_PHONE_STORAGE_KEY } from "../utils"

export default function PhoneConfirmationSent() {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const searchParams = useSearchParams()[0]

  function getMaskedPhone() {
    const phone = localStorage.getItem(BROKERAGE_PHONE_STORAGE_KEY)
    const xsPart = phone.slice(0, phone.length - 4)
    const actualXS = new Array(xsPart.length).fill("X").join("")
    const phoneWithoutDashes = actualXS + phone.substring(phone.length - 4)
    let phoneWithDashes = ""
    for (let i = 0; i < phoneWithoutDashes.length; ++i) {
      const addDash = i > 0 && i % 3 === 0
      phoneWithDashes += `${addDash ? "-" : ""}${phoneWithoutDashes[i]}`
    }
    return phoneWithDashes
  }

  function handleResendConfirmationText(e) {
    e.preventDefault()
    axiosClient
      .post("/users/api/signup/resend-phone-verification-link/", {
        key: searchParams.get("key"),
      })
      .then(() => setSnackbarOpen(true))
  }

  return (
    <>
      <CircularIcon Icon={SMSOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 4 }}>
        Confirmation text has been sent to you!
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        We sent an SMS to <strong>{getMaskedPhone()}</strong>. Please verify
        your phone number to finalize your account creation.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <InfoBubble
          Icon={HelpOutlinedIcon}
          message={
            <>
              Didn't receive any message? You can{" "}
              <Link
                onClick={handleResendConfirmationText}
                color="primary"
                variant="body1"
                sx={{ fontSize: "inherit" }}
                href="#"
              >
                resend confirmation text
              </Link>
              .
            </>
          }
        />
      </Box>
      <SuccessSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        horizontalAnchor="left"
        message="Verification text was just sent"
      />
    </>
  )
}
