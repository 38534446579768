import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDebounce } from "usehooks-ts"

import { InputProps } from "../../../../common/components/BorderlessSearchTextField"
import BrandAvatar from "../../../../common/components/BrandAvatar"
import CenterEverythingBox from "../../../../common/components/CenterEverythingBox"
import Column from "../../../../common/components/Column"
import LoadingButton from "../../../../common/components/LoadingButton"
import Row, { RowButColumnOnMobile } from "../../../../common/components/Row"
import AddBrokerageIcon from "../../../../common/resources/icons/brokerage-add-active.png"
import {
  getFullName,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../../common/utils"
import { selectBrokerages, selectProfile } from "../../slice"

export default function SelectBrokerageCommon({
  onBrokerageSelected,
  onCreateNewBrokerage,
  onReportClaimedBrokerage,
}) {
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const [pageIndex, setPageIndex] = useState(1)
  const [totalResultsCount, setTotalResultsCount] = useState(0)
  const [isLoadingBrokerages, setIsLoadingBrokerages] = useState(false)
  const [brokerages, setBrokerages] = useState([])
  const PAGE_SIZE = 50

  const axios = useAuthorizedAxiosClient()
  const profile = useSelector(selectProfile)
  const customerBrokerages = useSelector(selectBrokerages)
  const isMobile = useIsMobile()

  function handleSearchQueryChanged(e) {
    setSearchQuery(e.target.value)
    setBrokerages([])
    setTotalResultsCount(0)
    setPageIndex(1)
  }

  function handleReportBrokerageButtonClickedWrapper(brokerage) {
    return () => {
      onReportClaimedBrokerage(brokerage)
    }
  }

  function handleConfirmBrokerageButtonClickedWrapper(brokerage) {
    return () => {
      onBrokerageSelected(brokerage)
    }
  }

  useEffect(
    () => {
      if (!debouncedSearchQuery) {
        return
      }
      setIsLoadingBrokerages(true)
      axios
        .get("/customers/api/search-brokerages/", {
          params: { q: debouncedSearchQuery, page: pageIndex },
        })
        .then(({ data }) => {
          setBrokerages([...brokerages, ...data.results])
          setTotalResultsCount(data.count)
        })
        .finally(() => setIsLoadingBrokerages(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchQuery, pageIndex]
  )

  useEffect(
    () => {
      const customerBrokerageIDS = customerBrokerages.map(
        (brokerage) => brokerage.id
      )
      setBrokerages(
        brokerages.map((brokerage) =>
          customerBrokerageIDS.find((id) => id === brokerage.id)
            ? { ...brokerage, claimed_by: { full_name: getFullName(profile) } }
            : brokerage
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerBrokerages]
  )

  return (
    <>
      <TextField
        value={searchQuery}
        onChange={handleSearchQueryChanged}
        InputProps={InputProps}
        placeholder="Search for a brokerage by name or address"
        sx={{ mt: 4 }}
      />
      <Column
        sx={{
          mt: 4,
          pb: 2,
          "& > .MuiBox-root:nth-of-type(n + 2)": {
            mt: 2,
          },
          overflowY: "auto",
          minHeight: "200px",
        }}
      >
        {isLoadingBrokerages && brokerages.length === 0 ? (
          <CenterEverythingBox>
            <CircularProgress variant="indeterminate" color="primary" />
          </CenterEverythingBox>
        ) : searchQuery && brokerages.length === 0 ? (
          <CenterEverythingBox sx={{ py: { xs: 0, sm: 4 } }}>
            <Column sx={{ textAlign: "center", width: "300px" }}>
              <Typography variant="h6">No brokerages found</Typography>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                Create your own Brokerage page to appear in the results of
                prospects.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2, alignSelf: "center" }}
                onClick={onCreateNewBrokerage}
              >
                Create a new brokerage
              </Button>
            </Column>
          </CenterEverythingBox>
        ) : (
          <>
            {brokerages.map((brokerage) => (
              <Row
                key={brokerage.id}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Row
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <BrandAvatar brand={{ icon: brokerage.image }} />
                  <Column sx={{ ml: 2, flexBasis: "100%" }}>
                    <Typography variant="caption">
                      {brokerage.company}
                    </Typography>
                    <Row sx={{ alignItems: "center" }}>
                      <LocationOnOutlinedIcon
                        sx={{ color: "text.secondary2" }}
                        fontSize="small"
                      />
                      <Typography
                        variant="body1"
                        color="text.secondary2"
                        sx={{ ml: 0.5 }}
                      >
                        {brokerage.address}
                      </Typography>
                    </Row>
                    {brokerage.claimed_by && (
                      <Row sx={{ alignItems: "center" }}>
                        <PersonOutlinedIcon
                          fontSize="small"
                          sx={{ color: "text.secondary2" }}
                        />
                        <Typography
                          variant="body1"
                          color="text.secondary2"
                          sx={{ ml: 0.5 }}
                        >
                          {brokerage.claimed_by.full_name}
                        </Typography>
                      </Row>
                    )}
                    {isMobile && (
                      <BrokerageActionsRow
                        brokerage={brokerage}
                        handleConfirmBrokerageButtonClickedWrapper={
                          handleConfirmBrokerageButtonClickedWrapper
                        }
                        handleReportBrokerageButtonClickedWrapper={
                          handleReportBrokerageButtonClickedWrapper
                        }
                      />
                    )}
                  </Column>
                </Row>
                {!isMobile && (
                  <BrokerageActionsRow
                    brokerage={brokerage}
                    handleConfirmBrokerageButtonClickedWrapper={
                      handleConfirmBrokerageButtonClickedWrapper
                    }
                    handleReportBrokerageButtonClickedWrapper={
                      handleReportBrokerageButtonClickedWrapper
                    }
                  />
                )}
              </Row>
            ))}
            {pageIndex * PAGE_SIZE < totalResultsCount && (
              <Row sx={{ justifyContent: "center" }}>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => setPageIndex(pageIndex + 1)}
                  isLoading={isLoadingBrokerages}
                >
                  Load more brokerages
                </LoadingButton>
              </Row>
            )}
          </>
        )}
      </Column>

      {brokerages.length > 0 && (
        <RowButColumnOnMobile
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: (theme) => theme.units.borderRadius,
            backgroundColor: (theme) => theme.palette.otherwise.lightBackground,
            p: 2,
            mt: 4,
          }}
        >
          <Row
            sx={{
              alignItems: "center",
            }}
          >
            <img
              src={AddBrokerageIcon}
              alt="Plus building"
              style={{ width: "20px", height: "20px" }}
            />
            <Typography variant="body1" color="text.secondary2" sx={{ ml: 2 }}>
              If none of these are your brokerage, create your own brokerage
              page.
            </Typography>
          </Row>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              minWidth: "10em",
              ml: { xs: 0, sm: 2 },
              mt: { xs: 2, sm: 0 },
            }}
            onClick={onCreateNewBrokerage}
          >
            Create brokerage
          </Button>
        </RowButColumnOnMobile>
      )}
    </>
  )
}

function BrokerageActionsRow({
  brokerage,
  handleReportBrokerageButtonClickedWrapper,
  handleConfirmBrokerageButtonClickedWrapper,
}) {
  const isMobile = useIsMobile()
  return (
    <Row
      sx={{
        alignItems: "center",
        mt: { xs: 1, sm: 0 },
        pl: { xs: 0, sm: 2 },
        pr: { xs: 0, md: 2 },
      }}
    >
      {brokerage.claimed_by ? (
        <>
          <Button
            variant="contained"
            disabled
            startIcon={<CheckOutlinedIcon />}
          >
            Claimed
          </Button>
          <IconButton
            sx={{ ml: 1 }}
            onClick={handleReportBrokerageButtonClickedWrapper(brokerage)}
          >
            <FlagOutlinedIcon sx={{ color: "text.secondary2" }} />
          </IconButton>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleConfirmBrokerageButtonClickedWrapper(brokerage)}
          fullWidth={isMobile}
        >
          Add
        </Button>
      )}
    </Row>
  )
}

export function useCommonSelectBrokerageState() {
  const [selectedBrokerage, setSelectedBrokerage] = useState(null)
  return {
    selectedBrokerage,
    setSelectedBrokerage,
  }
}
