import Avatar from "@mui/material/Avatar"
import { alpha, useTheme } from "@mui/material/styles"

export default function CircularIcon({
  Icon,
  DirectIcon,
  size = 96,
  background,
}) {
  return (
    <Avatar
      sx={{
        backgroundColor: (theme) => background || theme.palette.primary.light,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {Icon ? (
        <Icon color="primary" sx={{ fontSize: `${size / 2}px` }} />
      ) : (
        DirectIcon
      )}
    </Avatar>
  )
}

export function HaloCircularIcon({ Icon, DirectIcon, size }) {
  return (
    <HaloIconCommon
      Icon={Icon}
      DirectIcon={DirectIcon}
      background="linear-gradient(to bottom left, rgba(121, 59, 217, 0.1) 0%, rgba(93, 77, 164, 0.1) 10%, white 100%)"
      size={size}
    />
  )
}

export function DangerHaloCircularIcon({ Icon, size = 80 }) {
  const theme = useTheme()
  return (
    <HaloIconCommon
      DirectIcon={<Icon color="error" fontSize="large" />}
      background="linear-gradient(to bottom left, rgba(177, 59, 55, 0.1) 0%, white 100%)"
      innerBackground={alpha(theme.palette.error.light, 0.1)}
      size={size}
    />
  )
}

export function HaloIconCommon({
  Icon,
  DirectIcon,
  background,
  innerBackground,
  size = 80,
}) {
  return (
    <Avatar
      sx={{
        p: 1,
        width: `${size + 10}px`,
        height: `${size + 10}px`,
        background: background,
      }}
    >
      <CircularIcon
        Icon={Icon}
        DirectIcon={DirectIcon}
        size={size}
        background={innerBackground}
      />
    </Avatar>
  )
}
