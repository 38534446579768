import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  BasicAvatarEditorLayout,
  useBasicAvatarEditorLayoutState,
} from "../../../common/components/AvatarEditorLayout"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import {
  getFullName,
  segmentAnalytics,
  splitNameForAPI,
  useAuthorizedAxiosClient,
} from "../../../common/utils"
import LevelOfInterestAutocomplete, {
  useLevelOfInterestAutocompleteState,
} from "../components/LevelOfInterestAutocomplete"
import { profileLoading, selectProfile, updateProfile } from "../slice"
import { AGENT_LEVELS_OF_INTEREST } from "../utils"

export default function EditProfileHeaderDialog({ open, onClose }) {
  const { imageName, onImageNameChanged, imageData, onImageDataChanged } =
    useBasicAvatarEditorLayoutState()
  const [name, setName] = useState("")
  const {
    levelOfInterest,
    setLevelOfInterest,
    levelOfInterestInputValue,
    setLevelOfInterestInputValue,
  } = useLevelOfInterestAutocompleteState()
  const [isSavingProfile, setIsSavingProfile] = useState(false)

  const dispatch = useDispatch()
  const axios = useAuthorizedAxiosClient()
  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const theme = useTheme()

  function handleSaveButtonClicked() {
    setIsSavingProfile(true)
    const formData = new FormData()
    if (imageName) {
      formData.append("profile_image", imageData, imageName)
    }
    const apiName = splitNameForAPI(name)
    formData.append("first_name", apiName.first_name)
    formData.append("last_name", apiName.last_name)
    formData.append("level_of_interest", levelOfInterest)
    axios
      .put("/agents/api/profile/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        dispatch(updateProfile(data))
        onClose()
        if (imageName) {
          segmentAnalytics.track("Avatar updated")
          onImageNameChanged(null)
        }
      })
      .finally(() => {
        setIsSavingProfile(false)
      })
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setName(getFullName(profile))
      setLevelOfInterest(profile.level_of_interest || null)
      setLevelOfInterestInputValue(
        profile.level_of_interest
          ? AGENT_LEVELS_OF_INTEREST[profile.level_of_interest]
          : ""
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, profile]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Column>
        <Box
          sx={{
            alignSelf: "center",
            mt: 4,
          }}
        >
          <BasicAvatarEditorLayout
            imageFilename={imageName}
            onImageFilenameChanged={onImageNameChanged}
            imageData={imageData}
            onImageDataChanged={onImageDataChanged}
            currentAvatar={profile.profile_image}
            firstName={!isProfileLoading && profile.first_name}
            size={112}
            avatarSX={{
              backgroundColor: theme.palette.secondary.main,
            }}
          />
        </Box>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mt: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VisibilityOutlinedIcon sx={{ color: "text.secondary2" }} />
              </InputAdornment>
            ),
          }}
        />
        <LevelOfInterestAutocomplete
          levelOfInterest={levelOfInterest}
          setLevelOfInterest={setLevelOfInterest}
          levelOfInterestInputValue={levelOfInterestInputValue}
          setLevelOfInterestInputValue={setLevelOfInterestInputValue}
          autocompleteProps={{ sx: { mt: 4 } }}
        />
      </Column>
      <DialogActions sx={{ pt: 2 }}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isSavingProfile}
          disabled={!name}
          onClick={handleSaveButtonClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
