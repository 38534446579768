import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

export default function TitledSection({ title, addMarginTop, children }) {
  return (
    <>
      <Typography
        variant="h6"
        sx={{ mt: { xs: addMarginTop ? 2 : 0, sm: addMarginTop ? 4 : 0 } }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          mt: { xs: 2, sm: 4 },
        }}
      >
        {children}
      </Box>
    </>
  )
}

export function BorderedTitledSection({ title, addMarginTop, children }) {
  return (
    <BorderedSection>
      <TitledSection title={title} addMarginTop={addMarginTop}>
        {children}
      </TitledSection>
    </BorderedSection>
  )
}

export function BorderedSection({ children }) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        p: { xs: 2, sm: 4 },
      }}
    >
      {children}
    </Paper>
  )
}
