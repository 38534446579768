import Link from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom"

import { useUserHomeURL } from "../../pages/users/utils"
import HighriseLogo from "../resources/icons/highrise-logo-full.svg"

export default function SmartsetterLogoTitle() {
  const homeURL = useUserHomeURL()
  return (
    <Link component={RouterLink} to={homeURL} color="inherit" underline="none">
      <img src={HighriseLogo} alt="HighRise logo" />
    </Link>
  )
}
