import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import { profileLoading, selectBrokerageByID } from "../slice"

export default function PaymentMethodUpdatedDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const [updatedBrokerage, setUpdatedBrokerage] = useState(null)

  const searchParams = useSearchParams()[0]
  const getBrokerageByID = useSelector(selectBrokerageByID)
  const isProfileLoading = useSelector(profileLoading)

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      const paymentMethodUpdateStatusParam = searchParams.get("status")
      if (paymentMethodUpdateStatusParam === "success") {
        setIsOpen(true)
        setUpdatedBrokerage(getBrokerageByID(parseInt(searchParams.get("bid"))))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
    >
      {updatedBrokerage && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <HaloCircularIcon Icon={CheckOutlinedIcon} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Payment method updated successfully
            </Typography>
            <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
              Your new payment method will be used for future billing regarding
              your <strong>{updatedBrokerage.company}</strong> brokerage.
            </Typography>
          </Column>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(false)}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
