import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { grey } from "@mui/material/colors"
import { alpha } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"

export default function CenterMapContainer({
  Map,
  mapProps,
  recenterButtonBottomOffset = "24px",
  ...containerProps
}) {
  const [centerMap, setCenterMap] = useState(false)
  const mapContainer = useRef()
  return (
    <Box
      ref={mapContainer}
      {...containerProps}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        ...containerProps.sx,
      }}
    >
      <Map
        {...mapProps}
        mapContainer={mapContainer}
        parentCenterMap={centerMap}
        onMapCentered={() => setCenterMap(false)}
      />
      <IconButton
        size="small"
        color="primary"
        title="Recenter map"
        onClick={() => setCenterMap(true)}
        sx={{
          position: "absolute",
          right: "16px",
          bottom: recenterButtonBottomOffset,
          zIndex: 100,
          backgroundColor: alpha(grey[500], 0.5),
        }}
      >
        <MyLocationOutlinedIcon />
      </IconButton>
    </Box>
  )
}

export function useCenterMap(parentCenterMap, centerMap, onMapCentered) {
  useEffect(
    () => {
      if (parentCenterMap) {
        centerMap()
        onMapCentered()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentCenterMap]
  )
}
