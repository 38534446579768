import Dialog from "@mui/material/Dialog"
import { useEffect, useState } from "react"

import { useShowBottomNav } from "../utils"

export default function MobileFullScreenDialog({ onClosed, children }) {
  const showBottomNav = useShowBottomNav()
  const [showDialog, setShowDialog] = useState(false)

  function handleDialogClosed() {
    setShowDialog(false)
    onClosed()
  }

  useEffect(() => {
    setShowDialog(showBottomNav)
  }, [showBottomNav])

  return showBottomNav ? (
    <Dialog
      open={showDialog}
      onClose={handleDialogClosed}
      fullScreen
      sx={{
        "& .MuiPaper-root": {
          p: 0,
        },
      }}
    >
      {children}
    </Dialog>
  ) : (
    children
  )
}
