import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import { axiosClient, segmentAnalytics } from "../../../common/utils"
import { selectUserType } from "../slice"
import { getUserHomeURL, useHandleLoginSuccess } from "../utils"

export default function ImpersonateLogin() {
  const searchParams = useSearchParams()[0]
  const navigate = useNavigate()
  const handleLoginSuccess = useHandleLoginSuccess()
  const userType = useSelector(selectUserType)

  useEffect(
    () => {
      const userKey = searchParams.get("key")
      axiosClient
        .post(`/users/api/impersonate-login/${userKey}/`)
        .then(({ data }) => {
          segmentAnalytics.register({ impersonator: true })
          handleLoginSuccess(data)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (!userType) {
        return
      }
      navigate(getUserHomeURL(userType))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userType]
  )
}
