import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import Fade from "@mui/material/Fade"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { useState } from "react"

/* Default material show/hide password icon disappears after first use */
export default function PasswordTextField({ ...textFieldProps }) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      end
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Fade in={textFieldProps.value.length > 0}>
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Fade>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  )
}
