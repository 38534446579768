import { useEffect, useState } from "react"

import { useAuthorizedAxiosClient } from "../utils"
import CheckableChip from "./CheckableChip"
import ChipContainer from "./ChipContainer"

export default function AreasOfFocusEditor({ areasOfFocus, setAreasOfFocus }) {
  const [allAreasOfFocus, setAllAreasOfFocus] = useState([])
  const axios = useAuthorizedAxiosClient()

  function handleChipCheckedWrapper(areaOfFocus) {
    return () => {
      setAreasOfFocus([...areasOfFocus, areaOfFocus])
    }
  }

  function handleChipUncheckedWrapper(areaOfFocusID) {
    return () => {
      setAreasOfFocus(areasOfFocus.filter(({ id }) => areaOfFocusID !== id))
    }
  }

  useEffect(
    () => {
      axios
        .get("/agents/api/all-areas-of-focus/")
        .then(({ data }) => setAllAreasOfFocus(data))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <ChipContainer>
      {allAreasOfFocus.map((areaOfFocus) => (
        <CheckableChip
          key={areaOfFocus.id}
          label={areaOfFocus.name}
          checked={
            areasOfFocus.findIndex((aof) => aof.id === areaOfFocus.id) !== -1
          }
          onChecked={handleChipCheckedWrapper(areaOfFocus)}
          onUnchecked={handleChipUncheckedWrapper(areaOfFocus.id)}
        />
      ))}
    </ChipContainer>
  )
}
