import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import { useState } from "react"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import DialogLayout from "./DialogLayout"

export default function SendAnonymousRequestDialog({
  brokerage,
  open,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const axios = useAuthorizedAxiosClient()

  function handleConfirmButtonClicked() {
    setIsLoading(true)
    axios
      .post(`/agents/api/send-request/${brokerage.id}/`)
      .then(({ data }) => {
        onClose(data)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogLayout
        Icon={<HaloCircularIcon Icon={PersonAddOutlinedIcon} />}
        title={`You're sending an anonymous request to ${brokerage.company}.`}
        description={`This brokerage will have access to view your statistics and anonymous profile.`}
      />
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          onClick={handleConfirmButtonClicked}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
