import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { axiosClient, createStorageKey } from "../../common/utils"
import { USER_TYPES } from "./utils"

export const PROFILE_LOADING_STATUS = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  failed: "failed",
}

const STORAGE_TOKEN_KEY = createStorageKey("userToken")

const logoutUser = createAsyncThunk("user/logout", () => {
  localStorage.removeItem(STORAGE_TOKEN_KEY)
})

const retrieveUserProfile = createAsyncThunk(
  "user/retrieveProfile",
  (_, thunkAPI) => {
    const token = selectToken(thunkAPI.getState())
    return axiosClient
      .get("/users/api/profile/", {
        headers: { Authorization: `Token ${token}` },
      })
      .then(({ data }) => data)
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem(STORAGE_TOKEN_KEY),
    type: null,
    profileLoadingStatus: PROFILE_LOADING_STATUS.idle,
    // used by log out redirect effect to not append next parameter
    loggedOut: false,
  },
  reducers: {
    storeLoginData: (state, action) => {
      localStorage.setItem(STORAGE_TOKEN_KEY, action.payload.token)
      state.token = action.payload.token
      state.type = action.payload.type
    },
    setUserType: (state, action) => {
      state.type = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveUserProfile.pending, (state) => {
      state.profileLoadingStatus = PROFILE_LOADING_STATUS.loading
    })
    builder.addCase(retrieveUserProfile.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      state.profileLoadingStatus = PROFILE_LOADING_STATUS.loaded
      state.loggedOut = false
    })
    builder.addCase(retrieveUserProfile.rejected, (state) => {
      state.profileLoadingStatus = PROFILE_LOADING_STATUS.failed
    })
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.token = null
      state.type = null
      state.profileLoadingStatus = PROFILE_LOADING_STATUS.idle
      state.loggedOut = true
    })
  },
})

const { reducer } = userSlice

export { retrieveUserProfile, logoutUser }

export const selectUser = (state) => state.user

export const selectToken = (state) => selectUser(state).token

export const selectIsAuthenticated = (state) => !!selectToken(state)

export const selectIsAnonymous = (state) => !selectIsAuthenticated(state)

export const storageToken = localStorage.getItem(STORAGE_TOKEN_KEY)

export const selectUserType = (state) => selectUser(state).type

export const selectIsLoggedOut = (state) => selectUser(state).loggedOut

export const selectIsAgent = (state) =>
  selectUserType(state) === USER_TYPES.agent

export const selectIsCustomer = (state) =>
  selectUserType(state) === USER_TYPES.customer

export const { storeLoginData, setUserType } = userSlice.actions

export default reducer
