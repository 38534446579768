import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import { useContext, useState } from "react"

import { segmentAnalytics, useAuthorizedAxiosClient } from "../../utils"
import { HaloCircularIcon } from "../CircularIcon"
import LoadingButton from "../LoadingButton"
import InboxContext from "./InboxContext"
import { useInboxActionEntityNames } from "./utils"

const REPORT_REASONS = {
  hate: "hate",
  falseInfo: "fakenews",
  bullying: "bully",
  scam: "scam",
}

const REPORT_REASON_TO_TITLE_MAP = {
  [REPORT_REASONS.hate]: "Hate speech or symbols",
  [REPORT_REASONS.falseInfo]: "False information",
  [REPORT_REASONS.bullying]: "Bullying or harassment",
  [REPORT_REASONS.scam]: "Scam or fraud",
}

export default function ReportConversationDialog({
  open,
  conversation,
  onConversationChanged,
  onClose,
}) {
  const [isSendingReport, setIsSendingReport] = useState(false)
  const [reportReason, setReportReason] = useState(REPORT_REASONS.hate)

  const inboxContext = useContext(InboxContext)
  const axios = useAuthorizedAxiosClient()
  const { actionPerformer, actionTarget } = useInboxActionEntityNames()

  function handleSendReport() {
    setIsSendingReport(true)
    axios
      .put(
        `/${inboxContext.apiURLPrefix}/api/conversations/${conversation.id}/report/`,
        {
          reason: reportReason,
        }
      )
      .then(({ data }) => {
        onConversationChanged(data)
        segmentAnalytics.track(`${actionPerformer} reported ${actionTarget}`, {
          "Conversation ID": conversation.id,
          "Report reason": REPORT_REASON_TO_TITLE_MAP[reportReason],
        })
      })
      .finally(() => {
        setIsSendingReport(false)
        onClose()
      })
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <HaloCircularIcon Icon={FlagOutlinedIcon} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Select a problem to report
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            You can report this chat to SmartSetter. We won't notify the account
            that you submitted this report.
          </Typography>
        </Box>
        <Divider sx={{ my: 4 }} />
        <RadioGroup
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
        >
          <FormControlLabel
            value={REPORT_REASONS.hate}
            label={REPORT_REASON_TO_TITLE_MAP[REPORT_REASONS.hate]}
            control={<Radio />}
          />
          <FormControlLabel
            value={REPORT_REASONS.falseInfo}
            label={REPORT_REASON_TO_TITLE_MAP[REPORT_REASONS.falseInfo]}
            control={<Radio />}
          />
          <FormControlLabel
            value={REPORT_REASONS.bullying}
            label={REPORT_REASON_TO_TITLE_MAP[REPORT_REASONS.bullying]}
            control={<Radio />}
          />
          <FormControlLabel
            value={REPORT_REASONS.scam}
            label={REPORT_REASON_TO_TITLE_MAP[REPORT_REASONS.scam]}
            control={<Radio />}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton isLoading={isSendingReport} onClick={handleSendReport}>
          Send report
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
