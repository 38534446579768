import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"

export const VerticalLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "4px",
  height: "100%",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.otherwise.lightBackground,
  },
}))

export const DisabledVerticalLinearProgress = styled(VerticalLinearProgress)(
  ({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.otherwise.darkBackground,
    },
  })
)

export const MARKET_PROGRESSBAR_COLOR = "#eb82bf"

export const MarketVerticalLinearProgress = styled(VerticalLinearProgress)(
  ({ theme }) => ({
    [`&.${linearProgressClasses.colorSecondary}`]: {
      backgroundColor: MARKET_PROGRESSBAR_COLOR,
    },
  })
)

export const AgentVerticalLinearProgress = styled(VerticalLinearProgress)(
  ({ theme }) => ({
    [`&.${linearProgressClasses.colorSecondary}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
)
