import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"

import { HaloCircularIcon } from "../../../../common/components/CircularIcon"
import Column from "../../../../common/components/Column"
import LoadingButton from "../../../../common/components/LoadingButton"
import Row from "../../../../common/components/Row"
import { getFileExtension, trimLongString } from "../../../../common/utils"

export function HeaderSection() {
  return (
    <>
      <HaloCircularIcon Icon={VerifiedOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Verify your ownership
      </Typography>
      <Row sx={{ mt: 2, "& .MuiChip-root:nth-of-type(n + 2)": { ml: 1 } }}>
        {[
          "Have more visiblity",
          "Improve recognition",
          "Gain agents trust",
        ].map((title, index) => (
          <Chip
            key={title}
            label={title}
            avatar={<CheckOutlinedIcon fontSize="small" />}
            disabled
          />
        ))}
      </Row>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        Before you can claim this brokerage, we need to confirm you're the
        owner. Please upload a photo or scan of an invoice or bill (such as from
        a recognized utility, bank, or government office) clearly bearing your
        company name and address.
      </Typography>
    </>
  )
}

const FIFTY_MB = 50 * 1024 * 1024

export function ContentSection({
  verificationDocument,
  setVerificationDocument,
}) {
  const handleFileDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        if (file.size > FIFTY_MB) {
          alert("File is too large to upload (> 50 mb)")
        } else {
          setVerificationDocument(file)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
  })
  return (
    <Column>
      {verificationDocument ? (
        <FileUploadContainer
          elevation={0}
          sx={{
            backgroundColor: (theme) => theme.palette.otherwise.lightBackground,
            border: "none",
          }}
        >
          <Row
            sx={{
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: "48px",
                height: "48px",
                backgroundColor: (theme) =>
                  theme.palette.otherwise.darkBackground,
              }}
            >
              <AttachFileOutlinedIcon
                sx={{
                  transform: "rotate(45deg)",
                  color: "text.secondary2",
                }}
              />
            </Avatar>
            <Column sx={{ ml: 2 }}>
              <Typography variant="body1" fontWeight={500}>
                {trimLongString(verificationDocument.name.split(".")[0], 40)}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                {getFileExtension(verificationDocument.name).toUpperCase()}
              </Typography>
            </Column>
          </Row>
          <IconButton
            size="small"
            onClick={() => setVerificationDocument(null)}
            sx={{ ml: 2 }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </FileUploadContainer>
      ) : (
        <FileUploadContainer
          elevation={0}
          sx={{
            borderWidth: "1px",
            borderColor: (theme) =>
              isDragActive
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            borderStyle: "dashed",
          }}
          {...getRootProps()}
        >
          <Row sx={{ alignItems: "center" }}>
            <AttachFileOutlinedIcon
              color="primary"
              sx={{ transform: "rotate(45deg)" }}
            />
            <Column sx={{ ml: 2 }}>
              <Typography variant="body1" fontWeight={500}>
                Drag and drop a file, or browse
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                Max. 50 MB
              </Typography>
            </Column>
          </Row>
          <Button variant="outlined" color="primary">
            Upload a file
          </Button>
          <input {...getInputProps()} />
        </FileUploadContainer>
      )}
    </Column>
  )
}

export function ActionButtons({
  onPreviousButtonClicked,
  onNextButtonClicked,
  isReporting,
  verificationDocument,
}) {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={onPreviousButtonClicked}
        fullWidth
      >
        Previous
      </Button>
      <LoadingButton
        isLoading={isReporting}
        onClick={onNextButtonClicked}
        fullWidth
      >
        {verificationDocument ? "Verify my brokerage" : "Skip verification"}
      </LoadingButton>
    </>
  )
}

const FileUploadContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(4),
}))

export function useCommonVerifyOwnershipState() {
  const [verificationDocument, setVerificationDocument] = useState(null)
  return {
    verificationDocument,
    setVerificationDocument,
  }
}
