import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GridViewIcon from "@mui/icons-material/GridView"
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined"
import SortOutlinedIcon from "@mui/icons-material/SortOutlined"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useEffect, useRef, useState } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Link as RouterLink } from "react-router-dom"

import BorderlessSearchTextField, {
  InputProps,
} from "../../../common/components/BorderlessSearchTextField"
import BrandAvatar from "../../../common/components/BrandAvatar"
import CenterEverythingBox from "../../../common/components/CenterEverythingBox"
import CheckMenu from "../../../common/components/CheckMenu"
import Column, { ColumnButRowOnMobile } from "../../../common/components/Column"
import CommissionSplitComparison from "../../../common/components/CommissionSplitComparison"
import IconTitleValue from "../../../common/components/IconTitleValue"
import LabeledArrowToggleButton from "../../../common/components/LabeledArrowToggleButton"
import PerksSummary from "../../../common/components/PerksSummary"
import Row from "../../../common/components/Row"
import MoneyBagIcon from "../../../common/resources/icons/money-bag.png"
import ProposalsActiveIcon from "../../../common/resources/icons/proposals-active.svg"
import {
  segmentAnalytics,
  trimLongString,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import ProposalStatusChip from "../components/ProposalStatusChip"

const VIEW_TYPES = {
  list: 0,
  grid: 1,
}

export default function Proposals() {
  const [searchQuery, setSearchQuery] = useState("")
  const SORT_BY_CHOICES = {
    mostRecent: "-created",
    signingBonus: "-signing_bonus",
    manual: "order",
  }
  const SORT_BY_LABELS = {
    [SORT_BY_CHOICES.mostRecent]: "Most recent",
    [SORT_BY_CHOICES.signingBonus]: "Signing bonus",
    [SORT_BY_CHOICES.manual]: "Manual",
  }
  const [sortBy, setSortBy] = useState(SORT_BY_CHOICES.mostRecent)
  const [sortMenuAnchorElem, setSortMenuAnchorElem] = useState(null)
  const [viewType, setViewType] = useState(VIEW_TYPES.list)
  const [proposals, setProposals] = useState([])
  const [isLoadingProposals, setIsLoadingProposals] = useState(true)
  const [isUpdatingProposals, setIsUpdatingProposals] = useState(false)
  const [hasAnyProposals, setHasAnyProposals] = useState(false)
  const EMPTY_HEIGHT = "calc(100vh - 75px)"

  const axios = useAuthorizedAxiosClient()
  const isMobile = useIsMobile()

  function handleSearchFieldKeyDownEvent(e) {
    if (e.key === "Enter") {
      loadProposals(setIsUpdatingProposals)
    }
  }

  function handleSortMenuButtonClicked(e) {
    setSortMenuAnchorElem(e.currentTarget)
  }

  function handleSortByChanged(value) {
    setSortBy(value)
    setSortMenuAnchorElem(null)
    segmentAnalytics.track("Agent ordered proposals", { "New ordering": value })
  }

  function handleSortMenuClosed() {
    setSortMenuAnchorElem(null)
  }

  function handleViewTypeChanged(_, newValue) {
    setViewType(newValue)
  }

  function handleProposalMoved(fromIndex, toIndex) {
    const newProposals = proposals.slice()
    const oldFrom = newProposals[fromIndex]
    const oldTo = newProposals[toIndex]
    setIsUpdatingProposals(true)
    axios
      .put("/agents/api/proposals/order/", {
        [oldFrom.id]: toIndex,
        [oldTo.id]: fromIndex,
      })
      .then(() => {
        newProposals[fromIndex] = newProposals[toIndex]
        newProposals[toIndex] = oldFrom
        setProposals(newProposals)
        segmentAnalytics.track("Agent reordered proposals", {
          "First proposal": getProposalName(oldFrom),
          "Second proposal": getProposalName(oldTo),
        })
      })
      .finally(() => setIsUpdatingProposals(false))
  }

  function loadProposals(setIsLoadingFlag) {
    setIsLoadingFlag(true)
    return axios
      .get("/agents/api/proposals/", {
        params: { ordering: sortBy, q: searchQuery },
      })
      .then(({ data }) => {
        setProposals(data)
        return data
      })
      .finally(() => setIsLoadingFlag(false))
  }

  useEffect(
    () => {
      loadProposals(setIsLoadingProposals).then((data) =>
        setHasAnyProposals(data.length > 0)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (isLoadingProposals) {
        return
      }
      loadProposals(setIsUpdatingProposals)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortBy]
  )

  return (
    <Column
      sx={{
        p: { xs: 2, sm: 4 },
        pt: 0,
      }}
    >
      {isLoadingProposals ? (
        <CenterEverythingBox sx={{ height: EMPTY_HEIGHT }}>
          <CircularProgress variant="indeterminate" color="primary" size={96} />
        </CenterEverythingBox>
      ) : !hasAnyProposals ? (
        <CenterEverythingBox sx={{ height: EMPTY_HEIGHT }}>
          <Column
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              src={ProposalsActiveIcon}
              style={{ width: "128px", height: "128px" }}
              alt="briefcase"
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              You don't have any proposals currently
            </Typography>
            <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
              You can look up your next brokerage on your{" "}
              <Link
                component={RouterLink}
                to="/agent/brokerages"
                color="primary"
                underline="always"
              >
                brokerages
              </Link>{" "}
              page.
            </Typography>
          </Column>
        </CenterEverythingBox>
      ) : (
        <>
          <Row
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              py: { xs: 0, sm: 2 },
              borderBottom: (theme) =>
                `1px solid ${theme.palette.otherwise.border}`,
              position: "sticky",
              top: "75px",
              backgroundColor: "white",
              zIndex: 5,
            }}
          >
            <BorderlessSearchTextField
              placeholder="Search for brokerage"
              InputProps={InputProps}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearchFieldKeyDownEvent}
              sx={{
                width: "20em",
              }}
              autoFocus
            />
            <Row
              sx={{
                alignItems: "center",
              }}
            >
              {isUpdatingProposals && (
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  size={32}
                  sx={{ mr: 2 }}
                />
              )}
              {isMobile ? (
                <IconButton onClick={handleSortMenuButtonClicked}>
                  <SortOutlinedIcon />
                </IconButton>
              ) : (
                <LabeledArrowToggleButton
                  label="Sort by: "
                  emLabel={SORT_BY_LABELS[sortBy]}
                  toggled={!!sortMenuAnchorElem}
                  onClick={handleSortMenuButtonClicked}
                />
              )}
              <CheckMenu
                anchorEl={sortMenuAnchorElem}
                onClose={handleSortMenuClosed}
                value={sortBy}
                onChange={handleSortByChanged}
                choices={[
                  {
                    label: SORT_BY_LABELS[SORT_BY_CHOICES.mostRecent],
                    value: SORT_BY_CHOICES.mostRecent,
                  },
                  {
                    label: SORT_BY_LABELS[SORT_BY_CHOICES.signingBonus],
                    value: SORT_BY_CHOICES.signingBonus,
                  },
                  {
                    label: SORT_BY_LABELS[SORT_BY_CHOICES.manual],
                    value: SORT_BY_CHOICES.manual,
                  },
                ]}
              />
              {!isMobile && (
                <ToggleButtonGroup
                  exclusive
                  value={viewType}
                  onChange={handleViewTypeChanged}
                  color="primary"
                  size="small"
                  sx={{
                    ml: 2,
                  }}
                >
                  <ToggleButton value={VIEW_TYPES.list}>
                    <FormatListBulletedIcon />
                  </ToggleButton>
                  <ToggleButton value={VIEW_TYPES.grid}>
                    <GridViewIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Row>
          </Row>
          {proposals.length === 0 ? (
            <CenterEverythingBox sx={{ height: "calc(100vh - 220px)" }}>
              <Typography variant="h6">
                No proposals match your search.
              </Typography>
            </CenterEverythingBox>
          ) : (
            <Column
              sx={{
                pt: 4,
                pb: 2,
                width:
                  viewType === VIEW_TYPES.grid
                    ? "100%"
                    : isMobile
                    ? "100%"
                    : "75%",
                mx: { xs: 0, sm: "auto" },
              }}
            >
              <ProposalLayout
                viewType={viewType}
                proposals={proposals}
                draggable={sortBy === SORT_BY_CHOICES.manual}
                onProposalMoved={handleProposalMoved}
              />
            </Column>
          )}
        </>
      )}
    </Column>
  )
}

function ProposalLayout({ proposals, viewType, draggable, onProposalMoved }) {
  if (viewType === VIEW_TYPES.grid) {
    return (
      <DndProvider backend={HTML5Backend}>
        <Grid
          container
          sx={{
            "--Grid-borderWidth": "1px",
            borderTop: "var(--Grid-borderWidth) solid",
            borderLeft: "var(--Grid-borderWidth) solid",
            borderColor: (theme) => theme.palette.otherwise.border,
            "& > div": {
              borderRight: "var(--Grid-borderWidth) solid",
              borderBottom: "var(--Grid-borderWidth) solid",
              borderColor: (theme) => theme.palette.otherwise.border,
            },
          }}
        >
          {proposals.map((proposal, index) => (
            <Grid key={index} xs={6} sx={{ display: "flex" }}>
              <GridViewProposal
                proposal={proposal}
                index={index}
                draggable={draggable}
                onMoved={onProposalMoved}
              />
            </Grid>
          ))}
        </Grid>
      </DndProvider>
    )
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        {proposals.map((proposal, index) => (
          <React.Fragment key={proposal.id}>
            <ListViewProposal
              proposal={proposal}
              index={index}
              draggable={draggable}
              onMoved={onProposalMoved}
            />
            {index < proposals.length - 1 && (
              <Divider sx={{ my: 4 }} key={index + 100} />
            )}
          </React.Fragment>
        ))}
      </DndProvider>
    )
  }
}

const ITEM_TYPE_PROPOSAL = "PROPOSAL"

function ListViewProposal({ proposal, index, draggable, onMoved }) {
  const { ref, isDragging, handleID } = useDragDrop(index, draggable, onMoved)
  const isMobile = useIsMobile()

  return (
    <Row
      sx={{
        justifyContent: "space-between",
        opacity: isDragging ? 0.5 : 1,
      }}
      ref={ref}
      data-handler-id={handleID}
    >
      <Row>
        <DragIndicatorBrandAvatar
          icon={getProposalBrokerageImage(proposal)}
          draggable={draggable}
        />
        <Column
          sx={{
            ml: 2,
          }}
        >
          <ProposalTitle proposal={proposal} />
          <AddressRow address={proposal.agent_request.brokerage.address} />
          <Typography variant="body1" sx={{ mt: 1 }}>
            {getProposalIntroduction(proposal)}
          </Typography>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <PerksSummary perks={proposal.proposal.perks} />
            {isMobile && (
              <Column sx={{ mt: 2 }}>
                <Divider sx={{ mb: 2 }} />
                <ProposalCommissionSigningBars proposal={proposal} />
              </Column>
            )}
          </Box>
          <PersonalNote proposal={proposal} mt={2} />
        </Column>
      </Row>
      {!isMobile && <ProposalCommissionSigningBars proposal={proposal} />}
    </Row>
  )
}

function ProposalCommissionSigningBars({ proposal }) {
  return (
    <ColumnButRowOnMobile
      sx={{
        alignItems: { xs: "center", sm: "flex-start" },
        "& .bar-container": {
          justifyContent: "center",
          width: "24px",
        },
      }}
    >
      <CommissionSplitComparison
        commissionSplit={proposal.proposal.brokerage_commission_ratio}
        tight={false}
      />
      <Box
        sx={{
          mt: { xs: 0, sm: 2 },
          ml: { xs: 2, sm: 0 },
        }}
      >
        <SigningBonusIconTitleValue proposal={proposal} />
      </Box>
    </ColumnButRowOnMobile>
  )
}

function GridViewProposal({ proposal, index, draggable, onMoved }) {
  const { ref, isDragging, handleID } = useDragDrop(index, draggable, onMoved)
  return (
    <Column
      sx={{
        p: 4,
        pl: draggable ? 6 : 4,
        opacity: isDragging ? 0.5 : 1,
        width: "100%",
      }}
      ref={ref}
      data-handle-id={handleID}
    >
      <Row
        sx={{
          alignItems: "center",
        }}
      >
        <DragIndicatorBrandAvatar
          icon={getProposalBrokerageImage(proposal)}
          draggable={draggable}
        />
        <Column
          sx={{
            ml: 2,
          }}
        >
          <ProposalTitle proposal={proposal} />
          <AddressRow address={proposal.agent_request.brokerage.address} />
        </Column>
      </Row>
      <Typography variant="body1" sx={{ mt: 1, maxWidth: "30em" }}>
        {getProposalIntroduction(proposal)}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <PerksSummary perks={proposal.proposal.perks} />
      </Box>
      <Divider sx={{ mt: 4 }} />
      <Grid container sx={{ mt: 4 }}>
        <Grid xs={5}>
          <SigningBonusIconTitleValue proposal={proposal} />
        </Grid>
        <Grid xs={5}>
          <CommissionSplitComparison
            commissionSplit={proposal.proposal.brokerage_commission_ratio}
            tight={false}
          />
        </Grid>
      </Grid>
      <PersonalNote proposal={proposal} mt={4} />
    </Column>
  )
}

function AddressRow({ address }) {
  return (
    <Row
      sx={{
        alignItems: "center",
        mt: 1,
      }}
    >
      <PlaceOutlinedIcon fontSize="small" sx={{ color: "text.secondary2" }} />
      <Typography variant="body2" color="text.secondary2" sx={{ ml: 1 }}>
        {address}
      </Typography>
    </Row>
  )
}

function SigningBonusIconTitleValue({ proposal }) {
  return (
    <IconTitleValue
      Icon={() => (
        <img
          src={MoneyBagIcon}
          alt="money bag"
          style={{ width: "24px", height: "24px" }}
        />
      )}
      title="Signing Bonus"
      value={`$${proposal.proposal.signing_bonus}`}
    />
  )
}

function DragIndicatorBrandAvatar({ icon, draggable }) {
  return (
    <Row
      sx={{
        display: "flex",
        position: "relative",
      }}
    >
      <DragIndicatorIcon
        sx={{
          display: draggable ? "initial" : "none",
          position: "absolute",
          top: "12px",
          left: "-28px",
          color: "text.secondary2",
          "&:hover": {
            cursor: "grab",
          },
        }}
      />
      <BrandAvatar brand={{ icon }} size={48} brokerageIconPadding={1.2} />
    </Row>
  )
}

function ProposalTitle({ proposal }) {
  return (
    <Row sx={{ alignItems: "center" }}>
      <Link
        variant="h6"
        component={RouterLink}
        to={`${proposal.id}/`}
        color="inherit"
      >
        {getProposalName(proposal)}
      </Link>
      <Box component="span" sx={{ ml: 2 }}>
        <ProposalStatusChip proposal={proposal} />
      </Box>
    </Row>
  )
}

function PersonalNote({ proposal, mt }) {
  const note = proposal.agent_note
  if (!note) {
    return null
  }
  return (
    <Paper
      elevation={0}
      sx={{
        border: "none",
        backgroundColor: (theme) => theme.palette.otherwise.lightBackground,
        width: "100%",
        mt: mt,
      }}
    >
      <Column>
        <Typography variant="subtitle1">Personal Note</Typography>
        <Typography variant="body1">{trimLongString(note, 100)}</Typography>
      </Column>
    </Paper>
  )
}

function useDragDrop(index, draggable, onMoved) {
  const ref = useRef(null)
  const [{ handleID }, drop] = useDrop({
    accept: ITEM_TYPE_PROPOSAL,
    collect(monitor) {
      return {
        handleID: monitor.getHandlerId(),
      }
    },
    drop(item, monitor) {
      if (!draggable || !ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      onMoved(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE_PROPOSAL,
    item: () => ({ index }),
    canDrag: draggable,
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })
  drag(drop(ref))
  return { ref, handleID, isDragging }
}

function getProposalName(proposal) {
  const agentRequest = proposal.agent_request
  return proposal.proposal.name || agentRequest.brokerage.company
}

function getProposalIntroduction(proposal) {
  const introdction = proposal.proposal.introduction
  return introdction
    ? trimLongString(introdction, 100)
    : "No introudction provided."
}

function getProposalBrokerageImage(proposal) {
  return proposal.agent_request.brokerage.image
}
