import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import PlaceIcon from "@mui/icons-material/PlaceOutlined"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import BlurredBackgroundRow from "../../../common/components/BlurredBackgroundRow"
import BorderlessSearchTextField, {
  InputProps as SearchInputProps,
} from "../../../common/components/BorderlessSearchTextField"
import BrandAvatar from "../../../common/components/BrandAvatar"
import BrokeragesAroundZipcodeMap from "../../../common/components/BrokeragesAroundZipcodeMap"
import Column from "../../../common/components/Column"
import LabeledArrowToggleButton from "../../../common/components/LabeledArrowToggleButton"
import LoadingWrapper, {
  CircularSkeleton,
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import {
  brokerageDescriptionDisplay,
  brokerageSizeDisplay,
  useAuthorizedAxiosClient,
  useIsMobile,
  yearFoundedDisplay,
} from "../../../common/utils"
import AgentRequestStatusChip from "../components/AgentRequestStatusChip"
import {
  selectNoRequestsWarningElementSize,
  selectProfile,
  selectShowNoAgentRequestsWarning,
} from "../slice"
import FiltersMobileDialog from "./FiltersMobileDialog"
import DistanceFilterDialog from "./distance-filter-dialog"
import ExcludeBrokeragesDialog from "./exclude-brokerages-dialog"
import { VERBOSE_DISTANCE_UNITS } from "./utils"
import ZipcodeFilterDialog from "./zipcode-filter-dialog"

export default function Brokerages() {
  const [brokerageData, setBrokerageData] = useState({
    brokerages: [],
    other_brokerages: [],
  })
  const [isLoadingBrokerages, setIsLoadingBrokerages] = useState(false)
  const [brokeragePageIndex, setBrokeragePageIndex] = useState(1)
  const BROKERAGE_PAGE_SIZE = 25
  const mapContainer = useRef(null)
  const profile = useSelector(selectProfile)
  const [textFilter, setTextFilter] = useState("")
  const [zipcodeFilter, setZipcodeFilter] = useState(profile.zip_code)
  const [distanceFilter, setDistanceFilter] = useState({
    distance: profile.distance_from_work,
    units: profile.distance_units,
  })
  const [locationFilter, setLocationFilter] = useState(null)
  const [excludedBrandsFilter, setExcludedBrandsFilter] = useState([])
  const hasExcludedBrandsFilter =
    excludedBrandsFilter && excludedBrandsFilter.length > 0
  const [ignoreMovementFinishedEvent, setIgnoreMovementFinishedEvent] =
    useState(true)
  const [zipcodeFilterDialogOpen, setZipcodeFilterDialogOpen] = useState(false)
  const [distanceFilterDialogOpen, setDistanceFilterDialogOpen] =
    useState(false)
  const [excludedBrokeragesDialogOpen, setExcludedBrokeragesDialogOpen] =
    useState(false)
  const [filterMobileDialogOpen, setFiltersMobileDialogOpen] = useState(false)
  const showNoAgentRequestsWarning = useSelector(
    selectShowNoAgentRequestsWarning
  )
  const noRequestsWarningElementSize = useSelector(
    selectNoRequestsWarningElementSize
  )
  const brandFilteredBrokerageData = {
    brokerages: filterBrokeragesByBrand(
      brokerageData.brokerages,
      excludedBrandsFilter
    ),
    other_brokerages: filterBrokeragesByBrand(
      brokerageData.other_brokerages,
      excludedBrandsFilter
    ),
  }

  const pagedBrokerages = brandFilteredBrokerageData.brokerages.slice(
    0,
    brokeragePageIndex * BROKERAGE_PAGE_SIZE
  )
  const showMoreResultsButton =
    pagedBrokerages.length < brandFilteredBrokerageData.brokerages.length

  const axios = useAuthorizedAxiosClient()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const topStickyHeight = showNoAgentRequestsWarning
    ? `${noRequestsWarningElementSize.height + 64}px`
    : "64px"

  function handleTextFilterChanged(e) {
    setTextFilter(e.target.value)
  }

  function handleTextFilterFieldKeyUpEvent(e) {
    if (e.code === "Enter") {
      queryBrokerages()
    }
  }

  function mapMovementFinishedEventHandler(center) {
    if (ignoreMovementFinishedEvent) return
    if (center) {
      if (
        locationFilter &&
        almostEqual(center.lat, locationFilter.coordinates[1]) &&
        almostEqual(center.lng, locationFilter.coordinates[0])
      ) {
        return
      }
      setLocationFilter({
        type: "Point",
        coordinates: [center.lng, center.lat],
      })
    }
  }

  function handleZipcodeFilterButtonClicked() {
    setZipcodeFilterDialogOpen(true)
  }

  function handleZipcodeFilterDialogClosed(result) {
    setZipcodeFilterDialogOpen(false)
    updateZipcodeFilter(result)
  }

  function handleDistanceFilterButtonClicked() {
    setDistanceFilterDialogOpen(true)
  }

  function handleDitanceFilterDialogClosed(result) {
    setDistanceFilterDialogOpen(false)
    updateDistanceFilter(result)
  }

  function handleExcludedBrokeragesFilterButtonClicked() {
    setExcludedBrokeragesDialogOpen(true)
  }

  function handleExcludedBrokeragesDialogClosed(result) {
    setExcludedBrokeragesDialogOpen(false)
    updateExcludedBrandsFilter(result)
  }

  function handleMobileFiltersDialogClosed(
    zipcodeFilter,
    distanceFilter,
    excludeBrandsFilter
  ) {
    setFiltersMobileDialogOpen(false)
    updateZipcodeFilter(zipcodeFilter)
    updateDistanceFilter(distanceFilter)
    updateExcludedBrandsFilter(excludeBrandsFilter)
  }

  function updateZipcodeFilter(data) {
    if (data) {
      setZipcodeFilter(data.zipCode)
      setLocationFilter(data.location)
    }
  }

  function updateDistanceFilter(data) {
    if (data) {
      setDistanceFilter(data)
    }
  }

  function updateExcludedBrandsFilter(data) {
    if (data) {
      setExcludedBrandsFilter(data)
    }
  }

  function filterBrokeragesByBrand(brokerages, excludedBrands) {
    if (!excludedBrands || excludedBrands.length === 0) {
      return brokerages
    }
    const excludedBrandIDS = excludedBrands.map(({ id }) => id)
    return brokerages.filter(
      (brokerage) =>
        !brokerage.brand || excludedBrandIDS.indexOf(brokerage.brand.id) === -1
    )
  }

  function queryBrokerages() {
    let params = {}
    if (locationFilter) {
      params = {
        lat: locationFilter.coordinates[1],
        lng: locationFilter.coordinates[0],
      }
    }
    if (textFilter) {
      params.q = textFilter
    }
    if (distanceFilter) {
      params.distance = distanceFilter.distance
      params.units = distanceFilter.units
    }
    setIsLoadingBrokerages(true)
    axios
      .get("/agents/api/brokerages/", { params })
      .then(({ data }) => {
        setBrokerageData(data)
      })
      .finally(() => setIsLoadingBrokerages(false))
  }

  useEffect(
    () => {
      setZipcodeFilter(profile.zip_code)
      setDistanceFilter({
        distance: profile.distance_from_work,
        units: profile.distance_units,
      })
      setLocationFilter(profile.location)
      setExcludedBrandsFilter(profile.excluded_brands)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile]
  )

  useEffect(
    () => {
      queryBrokerages()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationFilter, distanceFilter]
  )

  useEffect(() => {
    // only handle movement finished events after 10 seconds of page load start
    // to avoid initial spurt of events
    const timeout = setTimeout(
      () => setIgnoreMovementFinishedEvent(false),
      10000
    )
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Column>
      <Row
        sx={{
          justifyContent: { xs: "space-between", sm: "flex-start" },
          alignItems: "center",
          position: "sticky",
          top: topStickyHeight,
          background: "white",
          px: { xs: 2, sm: 4 },
          py: 1,
          borderBottom: (theme) =>
            `1px solid ${theme.palette.otherwise.border}`,
          zIndex: 100,
        }}
      >
        <BorderlessSearchTextField
          variant="standard"
          size="small"
          placeholder="Search for brokerage"
          value={textFilter}
          onChange={handleTextFilterChanged}
          onKeyUp={handleTextFilterFieldKeyUpEvent}
          InputProps={SearchInputProps}
          autoFocus
        />
        {isMobile ? (
          <>
            <IconButton onClick={() => setFiltersMobileDialogOpen(true)}>
              <Badge
                badgeContent={
                  hasExcludedBrandsFilter
                    ? excludedBrandsFilter.length
                    : undefined
                }
                color="secondary"
                sx={{
                  "& .MuiBadge-badge": {
                    border: "2px solid white",
                  },
                }}
              >
                <FilterAltOutlinedIcon />
              </Badge>
            </IconButton>
            <FiltersMobileDialog
              open={filterMobileDialogOpen}
              filteredBrokerages={brokerageData.brokerages}
              onClose={handleMobileFiltersDialogClosed}
            />
          </>
        ) : (
          <>
            <FilterDivider />
            <LabeledArrowToggleButton
              label="Zip Code: "
              emLabel={zipcodeFilter}
              toggled={zipcodeFilterDialogOpen}
              onClick={handleZipcodeFilterButtonClicked}
            />
            <ZipcodeFilterDialog
              open={zipcodeFilterDialogOpen}
              onClose={handleZipcodeFilterDialogClosed}
              initialZipcode={zipcodeFilter}
            />
            <FilterDivider />
            <LabeledArrowToggleButton
              label="Within "
              emLabel={`${distanceFilter.distance} ${
                VERBOSE_DISTANCE_UNITS[distanceFilter.units]
              }`}
              toggled={distanceFilterDialogOpen}
              onClick={handleDistanceFilterButtonClicked}
            />
            <DistanceFilterDialog
              open={distanceFilterDialogOpen}
              onClose={handleDitanceFilterDialogClosed}
              distance={distanceFilter}
            />
            <FilterDivider />
            <LabeledArrowToggleButton
              label={hasExcludedBrandsFilter ? "Excluded: " : "All brokerages"}
              emLabel={
                hasExcludedBrandsFilter
                  ? `${excludedBrandsFilter.length} brokerages`
                  : undefined
              }
              toggled={excludedBrokeragesDialogOpen}
              onClick={handleExcludedBrokeragesFilterButtonClicked}
            />
            <ExcludeBrokeragesDialog
              open={excludedBrokeragesDialogOpen}
              onClose={handleExcludedBrokeragesDialogClosed}
              filteredBrokerages={brokerageData.brokerages}
            />
          </>
        )}
      </Row>
      <RowButColumnOnMobile sx={{ position: "relative" }}>
        <Column
          sx={{
            overflowY: "auto",
            py: 4,
            minWidth: { xs: "100%", sm: "500px" },
            order: { xs: 1, sm: 0 },
          }}
        >
          <BrokerageSection
            title="Recommended for you"
            brokerages={pagedBrokerages}
            isLoading={isLoadingBrokerages}
            chipLabel={
              showMoreResultsButton
                ? `${pagedBrokerages.length}/${brandFilteredBrokerageData.brokerages.length}`
                : brandFilteredBrokerageData.brokerages.length
            }
          />
          {showMoreResultsButton && (
            <Column sx={{ mx: 4, "& .MuiDivider-root": { mt: 2 } }}>
              <Divider />
              <Button
                variant="text"
                color="primary"
                startIcon={<ExpandMoreIcon />}
                sx={{ alignSelf: "center", mt: 2 }}
                onClick={() => setBrokeragePageIndex(brokeragePageIndex + 1)}
              >
                View more
              </Button>
              <Divider />
            </Column>
          )}
          <Box sx={{ mt: 4 }}>
            <BrokerageSection
              title="Other brokerages you might like"
              brokerages={brandFilteredBrokerageData.other_brokerages}
              isLoading={isLoadingBrokerages}
              chipLabel={brandFilteredBrokerageData.other_brokerages.length}
            />
          </Box>
        </Column>
        <Box
          sx={{
            position: "relative",
            order: { xs: 0, sm: 1 },
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: `calc(${topStickyHeight} + 50px)`,
              height: { xs: "500px", sm: "calc(100vh - 135px)" },
              width: {
                xs: "100vw",
                sm: "calc(100vw - 500px)",
                md: "calc(100vw - 800px)",
              },
            }}
            ref={mapContainer}
          >
            <BlurredBackgroundRow
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                borderBottomRightRadius: (theme) => theme.units.borderRadius,
                height: "40px",
                p: { xs: 1, sm: 2 },
                boxSizing: "content-box",
                zIndex: 100,
              }}
            >
              <Column>
                <Typography variant="subtitle2" fontSize="1em">
                  Move the map to update your results
                </Typography>
                <Typography variant="h6" sx={{ mt: 0, fontSize: "1.2em" }}>
                  Location: {zipcodeFilter} ・ Within {distanceFilter.distance}{" "}
                  {VERBOSE_DISTANCE_UNITS[distanceFilter.units]}
                </Typography>
              </Column>
              {isLoadingBrokerages && (
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  sx={{ ml: 2 }}
                />
              )}
            </BlurredBackgroundRow>
          </Box>
        </Box>
        <BrokeragesAroundZipcodeMap
          mapContainer={mapContainer}
          brokerages={brandFilteredBrokerageData.brokerages.concat(
            brandFilteredBrokerageData.other_brokerages.map((brokerage) => ({
              ...brokerage,
              outlineColor: theme.palette.secondary.main,
            }))
          )}
          distanceFromHome={distanceFilter.distance}
          distanceUnits={distanceFilter.units}
          isBrokeragesLoading={isLoadingBrokerages}
          mapCenter={locationFilter}
          onMapMovementFinished={mapMovementFinishedEventHandler}
          usePinIconForAgentHome
          zoomOut
          showRecenterButton={false}
        />
      </RowButColumnOnMobile>
    </Column>
  )
}

function FilterDivider() {
  return <Divider orientation="vertical" sx={{ mx: 2, height: "32px" }} />
}

function BrokerageSection({ title, brokerages, isLoading, chipLabel }) {
  if (isLoading) {
    brokerages = [{ id: 1 }, { id: 2 }]
  }
  return (
    <Column sx={{ px: 2 }}>
      <Typography
        variant="h5"
        sx={{ display: "flex", alignItems: "center", pl: 2 }}
      >
        {title}{" "}
        <Chip color="secondary" label={chipLabel} size="small" sx={{ ml: 2 }} />
      </Typography>
      <Box sx={{ mt: 2 }}>
        {brokerages.map((brokerage) => (
          <Brokerage key={brokerage.id} {...brokerage} />
        ))}
      </Box>
    </Column>
  )
}

function Brokerage({
  id,
  image,
  company,
  address,
  description,
  head_count,
  year_founded,
  agent_request_status,
  isLoading,
}) {
  const navigate = useNavigate()

  function handleClick() {
    navigate(`/agent/brokerages/${id}/`)
  }

  return (
    <Row
      sx={{
        px: { sm: 2 },
        py: { xs: 2 },
        borderRadius: (theme) => theme.units.borderRadius,
        cursor: "pointer",
        "&:hover": {
          background: (theme) => theme.palette.otherwise.lightBackground,
        },
      }}
      onClick={handleClick}
    >
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={<CircularSkeleton size={48} />}
      >
        <BrandAvatar
          brand={image && { icon: image }}
          size={48}
          brokerageIconPadding={1.5}
        />
      </LoadingWrapper>
      <Column sx={{ ml: 2 }}>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={<TextSkeleton width={12} height={2} />}
        >
          <Box
            sx={{
              display: "inline",
            }}
          >
            <Typography variant="h6">{company}</Typography>
            {agent_request_status && (
              <Box sx={{ ml: 1 }}>
                <AgentRequestStatusChip
                  agentRequest={{ status: agent_request_status }}
                />
              </Box>
            )}
          </Box>
        </LoadingWrapper>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={<TextSkeleton width={10} />}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            fontSize="0.85em"
            sx={{ display: "flex" }}
          >
            <PlaceIcon
              sx={{ height: "16px", width: "16px", mr: 0.5, mt: 0.5 }}
            />{" "}
            {address}
          </Typography>
        </LoadingWrapper>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={<TextSkeleton width={20} height={3} />}
        >
          <Typography variant="body2" sx={{ mt: 1 }}>
            {brokerageDescriptionDisplay(description)}
          </Typography>
        </LoadingWrapper>
        <RowButColumnOnMobile
          sx={{
            mt: 2,
            "& .MuiChip-root:nth-of-type(n + 2)": {
              ml: { sm: 1 },
              mt: { xs: 1, sm: 0 },
            },
          }}
        >
          <LoadingWrapper isLoading={isLoading}>
            <BrokerageChip
              icon={<PersonOutlineIcon />}
              label={brokerageSizeDisplay(head_count)}
            />
          </LoadingWrapper>
          <LoadingWrapper isLoading={isLoading}>
            <BrokerageChip
              icon={
                <CalendarTodayOutlinedIcon
                  sx={{ width: "14px", height: "14px" }}
                />
              }
              label={yearFoundedDisplay(year_founded)}
            />
          </LoadingWrapper>
        </RowButColumnOnMobile>
      </Column>
    </Row>
  )
}

function BrokerageChip({ icon, label }) {
  return <Chip icon={icon} label={label} size="small" />
}

function almostEqual(v1, v2, epsilon) {
  if (!epsilon) {
    epsilon = 0.01
  }
  return Math.abs(v1 - v2) < epsilon
}
