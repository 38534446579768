import { useState } from "react"

import CreateBrokerageWizard from "../create-office/Wizard"
import ReportBrokerageWizard from "./report-brokerage/Wizard"
import { useCommonSelectBrokerageState } from "./select-brokerage/Common"
import SelectBrokerageWizard from "./select-brokerage/Wizard"

export default function ClaimOfficeWizard({
  onAnonymousPreviousButtonClicked,
  onBrokerageSelected,
}) {
  const WIZARDS = {
    selectBrokerage: 0,
    reportBrokerage: 1,
    createBrokerage: 2,
  }

  const [activeWizard, setActiveWizard] = useState(WIZARDS.selectBrokerage)
  const { selectedBrokerage, setSelectedBrokerage } =
    useCommonSelectBrokerageState()

  function handleReportClaimedBrokerage(brokerage) {
    setSelectedBrokerage(brokerage)
    setActiveWizard(WIZARDS.reportBrokerage)
  }

  function restartWizard() {
    setActiveWizard(WIZARDS.selectBrokerage)
  }

  return activeWizard === WIZARDS.selectBrokerage ? (
    <SelectBrokerageWizard
      selectedBrokerage={selectedBrokerage}
      setSelectedBrokerage={setSelectedBrokerage}
      onReportClaimedBrokerage={handleReportClaimedBrokerage}
      onCreateNewBrokerage={() => setActiveWizard(WIZARDS.createBrokerage)}
      onAnonymousPreviousButtonClicked={onAnonymousPreviousButtonClicked}
      onBrokerageSelected={onBrokerageSelected}
    />
  ) : activeWizard === WIZARDS.reportBrokerage ? (
    <ReportBrokerageWizard
      brokerage={selectedBrokerage}
      onReturn={restartWizard}
      onRestartWizard={restartWizard}
    />
  ) : (
    <CreateBrokerageWizard
      onReturn={restartWizard}
      onBrokerageCreated={onBrokerageSelected}
    />
  )
}
