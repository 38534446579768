import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { axiosClient } from "../../common/utils"
import { selectToken } from "../users/slice"

export const retrieveNotifications = createAsyncThunk(
  "notifications/retrieveNotifications",
  (_, thunkAPI) => {
    const token = selectToken(thunkAPI.getState())
    return axiosClient
      .get("/notifications/api/notifications/", {
        headers: { Authorization: `Token ${token}` },
      })
      .then(({ data }) => data)
  }
)

export const hideNotification = createAsyncThunk(
  "notifications/hideNotification",
  (data, thunkAPI) => {
    const token = selectToken(thunkAPI.getState())
    return axiosClient
      .post(`/notifications/api/notifications/${data.id}/hide/`, data.data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(({ data }) => data)
  }
)

const INITIAL_STATE = {
  notifications: [],
  showNewNotificationDot: false,
  notificationsLoaded: false,
  isHidingNotification: false,
}

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: INITIAL_STATE,
  reducers: {
    addNotification: (state, action) => {
      const existingNotificationIDS = state.notifications.map(({ id }) => id)
      if (existingNotificationIDS.find((id) => id === action.payload.id)) {
        return
      }
      state.notifications.push(action.payload)
      updateNotificationsDotStatus(state)
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      )
      updateNotificationsDotStatus(state)
    },
    hideNotificationDot: (state) => {
      state.showNewNotificationDot = false
    },
    clearNotifications: (state) => {
      Object.assign(state, INITIAL_STATE)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveNotifications.fulfilled, (state, action) => {
      if (state.notificationsLoaded) {
        return
      }
      state.notifications = state.notifications.concat(action.payload)
      state.notificationsLoaded = true
      updateNotificationsDotStatus(state)
    })
    builder.addCase(hideNotification.pending, (state) => {
      state.isHidingNotification = true
    })
    builder.addCase(hideNotification.fulfilled, (state, action) => {
      state.isHidingNotification = false
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload.id
      )
      updateNotificationsDotStatus(state)
    })
  },
})

function updateNotificationsDotStatus(state) {
  state.showNewNotificationDot = state.notifications.length > 0
}

export const selectNotifications = (state) => state.notifications.notifications

export const selectNotificationsCount = (state) =>
  selectNotifications(state).length

export const selectNotificationByType = (type, _default) => (state) =>
  selectNotifications(state).find(
    (notification) => notification.type === type
  ) || _default

export const selectIsHidingNotification = (state) =>
  state.notifications.isHidingNotification

export const selectShowNotificationsDot = (state) =>
  state.notifications.showNewNotificationDot

export const {
  addNotification,
  removeNotification,
  hideNotificationDot,
  clearNotifications,
} = notificationsSlice.actions

export default notificationsSlice.reducer
