import Divider from "@mui/material/Divider"

export default function NavbarDivider() {
  return (
    <Divider
      orientation="vertical"
      sx={{
        ml: { xs: 0, sm: 2 },
        height: "48px",
      }}
    />
  )
}
