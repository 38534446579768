import { Navigate, createBrowserRouter } from "react-router-dom"

import CommonLayout from "./common-layout"
import AgentLayout from "./pages/agent"
import BrokerageDetail from "./pages/agent/brokerage-detail"
import AgentBrokerages from "./pages/agent/brokerages"
import AgentCalendar from "./pages/agent/calendar"
import AgentHelpCenter from "./pages/agent/help-center"
import AgentHome from "./pages/agent/home"
import AgentInbox from "./pages/agent/inbox"
import Profile from "./pages/agent/profile"
import Proposal from "./pages/agent/proposal"
import AgentProposals from "./pages/agent/proposals"
import Settings from "./pages/agent/settings"
import BrokerageLayout from "./pages/brokerage"
import BrokerageCandidates from "./pages/brokerage/candidates"
import ClaimOfficeWizard from "./pages/brokerage/claim-office"
import BrokerageDashboard from "./pages/brokerage/dashboard"
import EditBrokerage from "./pages/brokerage/edit-brokerage"
import BrokerageInbox from "./pages/brokerage/inbox"
import Invoices from "./pages/brokerage/invoices"
import BrokerageProfile from "./pages/brokerage/profile"
import ProposalDetails from "./pages/brokerage/proposal-details"
import BrokerageProposals from "./pages/brokerage/proposals"
import BrokerageSettings from "./pages/brokerage/settings"
import NotFound from "./pages/common/404"
import UsersLayout from "./pages/users"
import AgentSignup from "./pages/users/agent-signup"
import BrokerageSignup from "./pages/users/brokerage-signup"
import AdminBrokerageSignup from "./pages/users/brokerage-signup/admin-variant"
import EmailConfirmationInvalid from "./pages/users/email-confirmation-invalid"
import ForgotPassword from "./pages/users/forgot-password"
import ForgotPasswordEmailSent from "./pages/users/forgot-password-email-sent"
import ForgotPasswordValidKey from "./pages/users/forgot-password-valid-key"
import ImpersonateLogin from "./pages/users/impersonate-login"
import Login from "./pages/users/login"
import PhoneConfirmationInvalid from "./pages/users/phone-confirmation-invalid"
import AgentPhoneConfirmationSent from "./pages/users/phone-confirmation-sent/agent"
import BrokeragePhoneConfirmationSent from "./pages/users/phone-confirmation-sent/brokerage"
import ResendEmailConfirmation from "./pages/users/resend-email-confirmation"
import ResendPhoneConfirmation from "./pages/users/resend-phone-confirmation"
import AgentSetSignupPassword from "./pages/users/set-signup-password/agent"
import BrokerageSetSignupPassword from "./pages/users/set-signup-password/brokerage"
import SignupSuccess from "./pages/users/signup-success"
import SignupUserTypeChoice from "./pages/users/signup-user-type-choice"

export default createBrowserRouter([
  {
    path: "/users",
    element: <UsersLayout />,
    children: [
      {
        path: "login/",
        element: <Login />,
      },
      {
        path: "_impersonate/",
        element: <ImpersonateLogin />,
      },
      {
        path: "signup/select-type/",
        element: <SignupUserTypeChoice />,
      },
      {
        path: "signup/agent/",
        element: <AgentSignup />,
      },
      {
        path: "signup/brokerage/",
        element: <BrokerageSignup />,
      },
      {
        path: "signup/success/",
        element: <SignupSuccess />,
      },
      {
        path: "signup/agent/phone-confirmation-sent/",
        element: <AgentPhoneConfirmationSent />,
      },
      {
        path: "signup/brokerage/phone-confirmation-sent/",
        element: <BrokeragePhoneConfirmationSent />,
      },
      {
        path: "signup/agent/phone-confirmed/",
        element: <AgentSetSignupPassword />,
      },
      {
        path: "signup/brokerage/phone-confirmed/",
        element: <BrokerageSetSignupPassword />,
      },
      {
        path: "signup/agent/email-confirmed/",
        element: <AgentSetSignupPassword />,
      },
      {
        path: "signup/brokerage/email-confirmed/",
        element: <BrokerageSetSignupPassword />,
      },
      {
        path: "signup/brokerage/set-password/",
        element: <BrokerageSetSignupPassword />,
      },
      {
        path: "signup/agent/set-password/",
        element: <AgentSetSignupPassword />,
      },
      {
        path: "signup/phone-confirmation-invalid/",
        element: <PhoneConfirmationInvalid />,
      },
      {
        path: "email-confirmation-invalid/",
        element: <EmailConfirmationInvalid />,
      },
      {
        path: "signup/resend-phone-confirmation/",
        element: <ResendPhoneConfirmation />,
      },
      {
        path: "resend-confirmation-email/",
        element: <ResendEmailConfirmation />,
      },
      {
        path: "forgot-password/",
        element: <ForgotPassword />,
      },
      {
        path: "forgot-password-email-sent/",
        element: <ForgotPasswordEmailSent />,
      },
      {
        path: "forgot-password/valid/",
        element: <ForgotPasswordValidKey />,
      },
    ],
  },
  {
    path: "/complete-signup",
    element: <CommonLayout />,
    children: [
      {
        path: "claim-office/",
        element: <ClaimOfficeWizard />,
      },
    ],
  },
  {
    path: "/admin",
    element: <UsersLayout />,
    children: [
      {
        path: "signup/agent/",
        element: <AgentSignup />,
      },
      {
        path: "signup/brokerage/",
        element: <AdminBrokerageSignup />,
      },
    ],
  },
  {
    path: "/agent",
    element: <AgentLayout />,
    children: [
      {
        path: "home/",
        element: <AgentHome />,
      },
      {
        path: "brokerages/",
        element: <AgentBrokerages />,
      },
      {
        path: "brokerages/:id/",
        element: <BrokerageDetail />,
      },
      {
        path: "conversations/",
        element: <AgentInbox />,
      },
      {
        path: "proposals/",
        children: [
          {
            index: true,
            element: <AgentProposals />,
          },
          {
            path: ":id/",
            element: <Proposal />,
          },
        ],
      },
      {
        path: "calendar/",
        element: <AgentCalendar />,
      },
      {
        path: "profile/",
        element: <Profile />,
      },
      {
        path: "settings/",
        element: <Settings />,
      },
    ],
  },
  {
    path: "brokerage/",
    element: <BrokerageLayout />,
    children: [
      {
        path: "dashboard/",
        element: <BrokerageDashboard />,
      },
      {
        path: "candidates/",
        element: <BrokerageCandidates />,
      },
      {
        path: "conversations/",
        element: <BrokerageInbox />,
      },
      {
        path: "proposals/",
        element: <BrokerageProposals />,
      },
      {
        path: "proposals/:id/",
        element: <ProposalDetails />,
      },
      {
        path: ":id/edit/",
        element: <EditBrokerage />,
      },
      {
        path: "profile/",
        element: <BrokerageProfile />,
      },
      {
        path: "settings/",
        element: <BrokerageSettings />,
      },
      {
        path: "invoices/",
        element: <Invoices />,
      },
    ],
  },
  {
    path: "help-center/",
    element: <AgentHelpCenter />,
  },
  {
    path: "/",
    element: <Navigate to="/users/login" />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
])
