import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AddIcCallIcon from "@mui/icons-material/AddIcCallOutlined"
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import LogoutIcon from "@mui/icons-material/Logout"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import SettingsIcon from "@mui/icons-material/Settings"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { subMinutes } from "date-fns"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useElementSize } from "usehooks-ts"

import AuthenticatedUserLayout from "../../common/components/AuthenticatedUserLayout"
import BrandAvatar from "../../common/components/BrandAvatar"
import {
  DangerHaloCircularIcon,
  HaloCircularIcon,
} from "../../common/components/CircularIcon"
import Column from "../../common/components/Column"
import CommonTourProvider, {
  TOUR_STATUS,
  useNavLinkTourPopoverStyles,
  usePopoverBelowStyles,
} from "../../common/components/CommonTourProvider"
import { finishTourDialogStep } from "../../common/components/FinishTourDialog"
import LoadingWrapper from "../../common/components/LoadingSkeleton"
import NavbarAvatar from "../../common/components/NavbarAvatar"
import NavbarDivider from "../../common/components/NavbarDivider"
import {
  NOTIFICATION_ICON_SIZE,
  NotificationLayout,
  NotificationsMenu,
} from "../../common/components/Notifications"
import NotificationsBellButton, {
  useNotificationsBellButtonState,
} from "../../common/components/NotificationsBellButton"
import Row, { RowButColumnOnMobile } from "../../common/components/Row"
import {
  ImageSidebarLinkData,
  MuiIconSidebarLinkData,
} from "../../common/components/SidebarLink"
import { StampedAvatarLayout } from "../../common/components/StampedAvatar"
import BlueCheckIcon from "../../common/resources/icons/blue-check.png"
import GoldDiamondIcon from "../../common/resources/icons/gold-diamond.png"
import InboxActiveIcon from "../../common/resources/icons/inbox-active.svg"
import InboxIcon from "../../common/resources/icons/inbox.svg"
import { getFullName, useShowBottomNav } from "../../common/utils"
import {
  addNotification,
  hideNotification,
  removeNotification,
  retrieveNotifications,
} from "../notifications/slice"
import { createNotification } from "../notifications/utils"
import { useLogoutUser } from "../users/utils"
import EnterPhoneDialog from "./components/EnterPhoneDialog"
import ExperienceRatingStars from "./home/experience-rating-stars"
import ProfileCircularProgress from "./home/profile-circular-progress"
import ExperienceCheckIcon from "./resources/icons/experience-check.svg"
import {
  closePhoneDialog,
  openPhoneDialog,
  profileLoading,
  retrieveAgentProfile,
  selectIsPhoneDialogOpen,
  selectIsProfileComplete,
  selectProfile,
  selectShowBlueCheck,
  selectShowNoAgentRequestsWarning,
  selectUnreadConversationsCount,
  updateNoRequestsWarningElementSize,
  updateProfile,
} from "./slice"
import {
  COMPLETE_PROFILE_NOTIFICATION,
  NOTIFICATION_TYPES,
  useNavigateToConversation,
  useProfileNotificationRequirements,
  useRateExperienceNotificationRequirements,
  useUpdateAgentTourStatus,
} from "./utils"

export default function AgentLayout() {
  const [profileMenuAnchorElem, setProfileMenuIconElem] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const isProfileComplete = useSelector(selectIsProfileComplete)
  const showBlueCheck = useSelector(selectShowBlueCheck)
  const {
    notificationsMenuAnchorElem,
    handleBellButtonClicked,
    handleNotificationsMenuClosed,
  } = useNotificationsBellButtonState()
  const logoutUser = useLogoutUser("/agents/api/logout/", () => {
    setProfileMenuIconElem(null)
  })
  const showBottomNav = useShowBottomNav()
  const { updateStatus: updateTourStatus } = useUpdateAgentTourStatus()
  const navLinkTourPopoverStyles = useNavLinkTourPopoverStyles()
  const popoverBelowStyles = usePopoverBelowStyles()
  const showNoAgentRequestsWarning = useSelector(
    selectShowNoAgentRequestsWarning
  )
  const [
    noRequestsWarningRef,
    {
      width: noRequestsWarningElemWidth,
      height: noRequestsWarningElementHeight,
    },
  ] = useElementSize()
  const secondURLPart = useSecondURLPart()
  const isPhoneDialogOpen = useSelector(selectIsPhoneDialogOpen)
  const unreadConversationsCount = useSelector(selectUnreadConversationsCount)

  const NotificationTypeToComponentMap = {
    [NOTIFICATION_TYPES.completeProfile]: CompleteProfileNotification,
    [NOTIFICATION_TYPES.experienceRating]: ExperienceRatingNotification,
    [NOTIFICATION_TYPES.conversationNewMessages]:
      ConversationNewMessagesNotification,
    [NOTIFICATION_TYPES.proposalReceived]: ProposalReceivedNotification,
    [NOTIFICATION_TYPES.verifyPhone]: VerifyPhoneNotification,
  }

  function handlePhoneVerified(data) {
    dispatch(updateProfile(data))
    dispatch(removeNotification(NOTIFICATION_TYPES.verifyPhone))
  }

  function handleAvatarButtonClicked(event) {
    setProfileMenuIconElem(event.currentTarget)
  }

  function handleProfileMenuClosed() {
    setProfileMenuIconElem(null)
  }

  function handleProfileMenuItemClicked() {
    navigate("/agent/profile")
    setProfileMenuIconElem(null)
  }

  function handleSettingsMenuItemClicked() {
    navigate("/agent/settings")
    setProfileMenuIconElem(null)
  }

  function handleLogoutMenuItemClicked() {
    logoutUser()
  }

  useEffect(
    () => {
      dispatch(retrieveAgentProfile())
      dispatch(retrieveNotifications())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      if (!isProfileComplete) {
        dispatch(addNotification(COMPLETE_PROFILE_NOTIFICATION))
      }
      if (!profileData.phone_verified) {
        dispatch(
          addNotification(
            createNotification(
              NOTIFICATION_TYPES.verifyPhone,
              NOTIFICATION_TYPES.verifyPhone,
              true
            )
          )
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  useEffect(
    () => {
      dispatch(
        updateNoRequestsWarningElementSize({
          width: noRequestsWarningElemWidth,
          height: noRequestsWarningElementHeight,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noRequestsWarningElemWidth, noRequestsWarningElementHeight]
  )

  return (
    <CommonTourProvider
      steps={[
        {
          selector: "#home",
          content:
            "This area is your dashboard.  It's your home base when working in HighRise.",
          position: [showBottomNav ? 0 : 240, 74],
        },
        {
          selector: "#home-map",
          content: `
            This map is where you'll find your candidate brokerages.
            Brokerages who are interested in showing you hiring proposals will appear here.
            We will notify you as brokerages start engaging with you.
          `,
        },
        {
          selector: "#brokerages-nav-link",
          content:
            "Once you've started to hear from brokerages, they can be found listed here under your Brokerages tab.",
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#conversations-nav-link",
          content: (
            <>
              This is your inbox, where you'll manage any conversations with
              brokerages.
              <br />
              <br />
              You can chat, negotiate, set meetings, exchange files and more in
              here.
            </>
          ),
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#proposals-nav-link",
          content: (
            <>
              Here is where you'll receive offers and hiring proposals from
              brokerages for you to consider.
              <br />
              <br />
              Using HighRise proposals helps you gain valuable insights and
              analytics for comparing brokerages with each other. Be sure to try
              it out.
            </>
          ),
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#profile-button",
          content: `
            This is where your personal settings can be found.
            Use your Settings to manage important security features, privacy settings, contact information, and more.
          `,
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        {
          selector: "#profile-button",
          content: (
            <>
              This is also where you can build and manage your personal Agent
              Profile.
              <br />
              <br />
              Your Agent Profile represents you, and is what brokerages will see
              when considering how much to offer you.
              <br />
              <br />
              Building a complete Profile is the first step in preparing your
              HighRise account.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        finishTourDialogStep,
        {
          selector: "#profile-button",
          content: (
            <>
              Setting up your Profile is extremely important to your success in
              HighRise.
              <br />
              <br />
              Remember to return to the dropdown here to set up your Profile as
              soon as you're ready.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
          actionAfter: () => {
            updateTourStatus(TOUR_STATUS.finished)
          },
        },
      ]}
    >
      <AuthenticatedUserLayout
        sidebarLinksData={[
          new MuiIconSidebarLinkData(
            "Home",
            undefined,
            "/agent/home",
            GridViewOutlinedIcon
          ),
          new MuiIconSidebarLinkData(
            "Brokerages",
            undefined,
            "/agent/brokerages",
            MapOutlinedIcon,
            "brokerages"
          ),
          new ImageSidebarLinkData(
            "Conversations",
            unreadConversationsCount > 0 ? unreadConversationsCount : undefined,
            "/agent/conversations",
            InboxIcon,
            InboxActiveIcon,
            "conversations"
          ),
          new MuiIconSidebarLinkData(
            "Proposals",
            undefined,
            "/agent/proposals",
            BusinessCenterOutlinedIcon,
            "proposals"
          ),
        ]}
        navbar={
          <>
            {!showBottomNav && (
              <Typography variant="h5">
                <PageTitle />
              </Typography>
            )}
            <Row sx={{ alignItems: "center" }}>
              <NotificationsBellButton onClick={handleBellButtonClicked} />
              <NotificationsMenu
                anchorEl={notificationsMenuAnchorElem}
                onClose={handleNotificationsMenuClosed}
                NotificationTypeToComponentMap={NotificationTypeToComponentMap}
              />
              <NavbarDivider />
              <Box sx={{ ml: 2, display: { xs: "none", sm: "initial" } }}>
                <LoadingWrapper isLoading={isProfileLoading}>
                  <Typography
                    variant="caption"
                    fontSize="0.78em"
                    fontWeight={400}
                  >
                    {getFullName(profileData)}
                  </Typography>
                </LoadingWrapper>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Box sx={{ position: "relative" }}>
                  <NavbarAvatar
                    profile={profileData}
                    isProfileLoading={isProfileLoading}
                    onClick={handleAvatarButtonClicked}
                  />
                  {showBlueCheck && (
                    <img
                      src={BlueCheckIcon}
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                      alt="blue check"
                    />
                  )}
                </Box>
                <Menu
                  anchorEl={profileMenuAnchorElem}
                  open={Boolean(profileMenuAnchorElem)}
                  onClose={handleProfileMenuClosed}
                  anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                  }}
                  transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                  }}
                >
                  <MenuItem onClick={handleProfileMenuItemClicked}>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleSettingsMenuItemClicked}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogoutMenuItemClicked}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Log out</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
              <EnterPhoneDialog
                open={isPhoneDialogOpen}
                onClose={() => dispatch(closePhoneDialog())}
                onPhoneVerified={handlePhoneVerified}
              />
            </Row>
          </>
        }
        belowNavbarContent={
          showNoAgentRequestsWarning &&
          secondURLPart !== "profile" && (
            <RowButColumnOnMobile
              sx={{
                justifyContent: "center",
                alignItems: "center",
                px: 4,
                py: 2,
                position: "sticky",
                top: "64px",
                zIndex: 100,
                bgcolor: "white",
              }}
              ref={noRequestsWarningRef}
            >
              <DangerHaloCircularIcon
                Icon={() => <ErrorOutlinedIcon color="error" />}
                size={48}
              />
              <Column
                sx={{
                  ml: { xs: 0, sm: 2 },
                  mt: { xs: 1, sm: 0 },
                  textAlign: { xs: "center", sm: "inherit" },
                }}
              >
                <Typography variant="h6">
                  Your agent profile is not visible to any brokerages!
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary2"
                  sx={{ maxWidth: "30em" }}
                >
                  Complete your profile to earn your Blue Check and receive
                  better offers
                </Typography>
              </Column>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: { xs: 0, sm: 4 }, mt: { xs: 2, sm: 0 } }}
                onClick={() => navigate("/agent/profile/")}
                startIcon={
                  <img
                    src={BlueCheckIcon}
                    alt="Blue check"
                    style={{ width: "16px", height: "16px" }}
                  />
                }
              >
                Earn blue check
              </Button>
            </RowButColumnOnMobile>
          )
        }
      />
    </CommonTourProvider>
  )
}

function PageTitle() {
  const profileData = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const secondURLPart = useSecondURLPart()

  switch (secondURLPart) {
    case "home":
      return (
        <>
          Welcome,{" "}
          <LoadingWrapper isLoading={isProfileLoading}>
            <Typography
              variant="h5"
              component="span"
              fontWeight={200}
              color="text.secondary"
              display="inline"
            >
              {profileData.first_name}!
            </Typography>
          </LoadingWrapper>
        </>
      )
    case "brokerages":
      return "My brokerages"
    case "conversations":
      return "Conversations"
    case "proposals":
      return "Proposals"
    case "profile":
      return "Profile"
    case "settings":
      return "Settings"
    default:
      return ""
  }
}

function useSecondURLPart() {
  const currentURL = useLocation().pathname
  return currentURL.split("/")[2]
}

function CompleteProfileNotification({ notification, closeMenu }) {
  const {
    title: completeProfileTitle,
    subtitle: completeProfileSubtitle,
    buttonHandler,
  } = useProfileNotificationRequirements()

  function handleProfileNotificationClicked() {
    closeMenu()
    buttonHandler()
  }

  return (
    <NotificationLayout
      show={!!notification}
      leftIcon={
        <ProfileCircularProgress
          fontSize="0.9rem"
          size={NOTIFICATION_ICON_SIZE}
        />
      }
      title={completeProfileTitle}
      subtitle={completeProfileSubtitle}
      actionWidget={
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleProfileNotificationClicked}
        >
          View profile
        </Button>
      }
      date={subMinutes(new Date(), 5)}
    />
  )
}

function ExperienceRatingNotification({ notification }) {
  const { title: rateExperienceTitle, subtitle: rateExperienceSubtitle } =
    useRateExperienceNotificationRequirements()

  return (
    <NotificationLayout
      show={!!notification}
      leftIcon={
        <img
          src={ExperienceCheckIcon}
          style={{ width: "100%" }}
          alt="rate your experience"
        />
      }
      title={rateExperienceTitle}
      subtitle={rateExperienceSubtitle}
      actionWidget={<ExperienceRatingStars onIsLoadingChanged={() => {}} />}
      date={new Date(notification.modified)}
    />
  )
}

function ConversationNewMessagesNotification({ notification, closeMenu }) {
  const navigateToConversation = useNavigateToConversation()
  const dispatch = useDispatch()

  function handleButtonClicked() {
    navigateToConversation(notification.conversation_id)
    dispatch(removeNotification(notification.id))
    closeMenu()
  }

  return (
    <NotificationLayout
      show={!!notification}
      leftIcon={
        <BrandAvatar
          brand={{ icon: notification.agent_request.brokerage.image }}
        />
      }
      title={`You have ${notification.unread_messages_count} new messages from ${notification.agent_request.brokerage.company}`}
      actionWidget={
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleButtonClicked}
        >
          View messages
        </Button>
      }
      date={new Date(notification.last_message_date)}
    />
  )
}

function ProposalReceivedNotification({ notification, closeMenu }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleButtonClicked() {
    closeMenu()
    navigate(`/agent/proposals/${notification.proposal.id}/`)
    dispatch(hideNotification({ id: notification.id }))
  }

  const brokerage = notification.proposal.agent_request.brokerage

  return (
    <NotificationLayout
      show={!!notification}
      leftIcon={
        <StampedAvatarLayout
          MainAvatar={<BrandAvatar brand={{ icon: brokerage.image }} />}
          stampAvatar={GoldDiamondIcon}
          borderWidth="0"
          stampSize="24px"
          stampOffset="-8px"
        />
      }
      title={`Congrats! You received a proposal from ${brokerage.company}`}
      actionWidget={
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleButtonClicked}
        >
          View proposal
        </Button>
      }
      date={new Date(notification.created)}
    />
  )
}

function VerifyPhoneNotification({ notification, closeMenu }) {
  const dispatch = useDispatch()

  function handleVerifyButtonClicked() {
    dispatch(openPhoneDialog())
    closeMenu()
  }

  return (
    <NotificationLayout
      show={!!notification}
      title="Gain access to Conversations"
      subtitle="Verify your phone now"
      leftIcon={<HaloCircularIcon Icon={AddIcCallIcon} size={44} />}
      actionWidget={
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleVerifyButtonClicked}
        >
          Verify
        </Button>
      }
      date={new Date()}
    />
  )
}
