import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useEffect } from "react"

import CircularProgressBackdrop from "../../../../common/components/CircularProgressBackdrop"
import Column from "../../../../common/components/Column"
import LoadingButton from "../../../../common/components/LoadingButton"
import { segmentAnalytics, useIsMobile } from "../../../../common/utils"
import {
  ContentSection,
  HeaderSection,
  useConfirmClaimBrokerage,
  useGetConfirmBrokerageDetails,
} from "./Common"

export default function ConfirmBrokerageDialog({
  open,
  onClose,
  onSelectAnotherBrokerage,
  brokerage,
}) {
  const { brokerageDetails, isLoadingBrokerageDetails, loadBrokerageDetails } =
    useGetConfirmBrokerageDetails()
  const { isClaimingBrokerage, confirmClaimBrokerage } =
    useConfirmClaimBrokerage()

  const isMobile = useIsMobile()

  function handleConfirmOwnershipButtonClicked() {
    confirmClaimBrokerage(brokerageDetails).then(() => {
      segmentAnalytics.track("Broker claimed brokerage", {
        "Brokerage ID": brokerageDetails.id,
        "Brokerage name": brokerageDetails.company,
      })
      onClose()
    })
  }

  useEffect(
    () => {
      if (!open || !brokerage) {
        return
      }
      loadBrokerageDetails(brokerage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, brokerage]
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          pb: 0.5,
        }}
      >
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <HeaderSection brokerage={brokerageDetails} />
        </Column>
        <ContentSection brokerageDetails={brokerageDetails} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onSelectAnotherBrokerage()}
        >
          Connect to another brokerage
        </Button>
        <LoadingButton
          isLoading={isClaimingBrokerage}
          onClick={handleConfirmOwnershipButtonClicked}
        >
          Confirm ownership
        </LoadingButton>
      </DialogActions>
      <CircularProgressBackdrop open={isLoadingBrokerageDetails} />
    </Dialog>
  )
}
