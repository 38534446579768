import ArticleOutlined from "@mui/icons-material/ArticleOutlined"
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import axiosClient from "axios"
import fileDownload from "js-file-download"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import {
  GreenBorderedChip,
  PinkBorderedChip,
} from "../../../common/components/BorderedChips"
import BorderlessSearchTextField, {
  InputProps,
} from "../../../common/components/BorderlessSearchTextField"
import CenterEverythingBox from "../../../common/components/CenterEverythingBox"
import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import {
  arrayContains,
  openLinkInNewTab,
  stringCapitalize,
  useAuthorizedAxiosClient,
} from "../../../common/utils"
import {
  BoldTableCell,
  HeaderTableCell,
  NoResultsTableRow,
  SortableHeaderTableCell,
  getOrderingParam,
  useTableSort,
} from "../components/Tables"
import { profileLoading, selectCurrentBrokerage } from "../slice"
import { PRODUCT_TYPES, formatDate } from "../utils"

const INVOICE_TYPES = {
  invoice: "invoice",
  billingLog: "billing_log",
}

export default function Invoices() {
  const [searchQuery, setSearchQuery] = useState("")
  const [invoices, setInvoices] = useState([])
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true)
  const [isUpdatingInvoices, setIsUpdatingInvoices] = useState(false)
  const [hasAnyInvoices, setHasAnyInvoices] = useState(false)

  const { sortTableBy, sortDirection, sortByColumnWrapper } =
    useTableSort("created_at")
  const axios = useAuthorizedAxiosClient()
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)

  const emptyHeight = "calc(100vh - 75px)"

  function queryInvoices(setIsLoadingFlag) {
    setIsLoadingFlag(true)
    return axios
      .get(`/billing/api/${currentBrokerage.id}/invoices/`, {
        params: {
          q: searchQuery,
          ordering: getOrderingParam(sortDirection, sortTableBy),
        },
      })
      .then(({ data }) => {
        setInvoices(data)
        return data
      })
      .finally(() => setIsLoadingFlag(false))
  }

  function handleSearchKeyDownEvent(e) {
    if (e.key === "Enter") {
      queryInvoices(setIsUpdatingInvoices)
    }
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      queryInvoices(setIsLoadingInvoices).then((invoices) =>
        setHasAnyInvoices(invoices.length > 0)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      queryInvoices(setIsUpdatingInvoices)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, sortTableBy, sortDirection, currentBrokerage]
  )

  return (
    <Column
      sx={{
        px: { xs: 2, sm: 8 },
        pb: { xs: 2, sm: 4 },
      }}
    >
      {isLoadingInvoices ? (
        <CenterEverythingBox sx={{ height: emptyHeight }}>
          <CircularProgress size={96} />
        </CenterEverythingBox>
      ) : hasAnyInvoices ? (
        <>
          <Row sx={{ pt: 2 }}>
            <BorderlessSearchTextField
              placeholder="Search for an invoice"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearchKeyDownEvent}
              InputProps={InputProps}
            />
          </Row>
          <TableContainer sx={{ mt: 2, maxWidth: "calc(100vw - 32px)" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableHeaderTableCell
                    columnName="created_at"
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                  >
                    Date
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    columnName="description"
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                  >
                    Item
                  </SortableHeaderTableCell>
                  <HeaderTableCell>Product</HeaderTableCell>
                  <SortableHeaderTableCell
                    columnName="amount"
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                  >
                    Amount
                  </SortableHeaderTableCell>
                  <SortableHeaderTableCell
                    columnName="status"
                    sortTableBy={sortTableBy}
                    sortDirection={sortDirection}
                    sortByColumnWrapper={sortByColumnWrapper}
                  >
                    Status
                  </SortableHeaderTableCell>
                  <HeaderTableCell>
                    {/* download button */}
                    {isUpdatingInvoices && (
                      <CircularProgress color="primary" size={24} />
                    )}
                  </HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.length === 0 ? (
                  <NoResultsTableRow columnCount={6}>
                    No invoices found for the applied filters.
                  </NoResultsTableRow>
                ) : (
                  invoices.map((invoice) => (
                    <TableRow key={invoice.id} hover>
                      <BoldTableCell>
                        {formatDate(invoice.created_at)}
                      </BoldTableCell>
                      <BoldTableCell>{invoice.description}</BoldTableCell>
                      <BoldTableCell>
                        {new InvoiceWrapper(invoice).product}
                      </BoldTableCell>
                      <BoldTableCell>
                        ${Math.abs(invoice.amount).toLocaleString()}
                      </BoldTableCell>
                      <TableCell>
                        <InvoiceStatusChip invoice={invoice} />
                      </TableCell>
                      <TableCell>
                        {new InvoiceWrapper(invoice).downloadURL ? (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              new InvoiceWrapper(invoice).download()
                            }
                          >
                            <GetAppOutlinedIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <CenterEverythingBox sx={{ height: emptyHeight }}>
          <Column
            sx={{
              alignItems: "center",
              textAlign: "center",
              maxWidth: "35em",
            }}
          >
            <ArticleOutlined color="primary" sx={{ fontSize: "120px" }} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              You don't have any invoices for {currentBrokerage.company} yet
            </Typography>
          </Column>
        </CenterEverythingBox>
      )}
    </Column>
  )
}

const INVOICE_STATUS = {
  draft: "draft",
  open: "open",
  paid: "paid",
  uncollectible: "uncollectible",
  void: "void",
}

function InvoiceStatusChip({ invoice }) {
  const status = invoice.status
  if (arrayContains([INVOICE_STATUS.paid, "succeeded"], status)) {
    return <GreenBorderedChip label="Paid" size="small" />
  }
  return <PinkBorderedChip label={stringCapitalize(status)} size="small" />
}

class InvoiceWrapper {
  constructor(invoice) {
    this.invoice = invoice
  }
  get isStripeInvoice() {
    return this.invoice.type === INVOICE_TYPES.invoice
  }
  get downloadURL() {
    if (this.isStripeInvoice) {
      return this.invoice.pdf_file
    }
    return this.invoice.pdf_url
  }
  get product() {
    if (this.isStripeInvoice) {
      return PRODUCT_TYPES.highrise
    }
    return PRODUCT_TYPES.campaigns
  }
  download() {
    if (this.isStripeInvoice) {
      axiosClient
        .get(this.downloadURL, { responseType: "arraybuffer" })
        .then(({ data }) =>
          fileDownload(data, `${this.invoice.description}.pdf`)
        )
    } else {
      openLinkInNewTab(this.downloadURL)
    }
  }
}
