import IconButton from "@mui/material/IconButton"

import { useDefaultAvatar } from "../../pages/users/utils"
import { useIsMobile } from "../utils"
import LoadingWrapper, { CircularSkeleton } from "./LoadingSkeleton"
import UserAvatar from "./UserAvatar"

const HEADER_HEIGHT = 48

export default function NavbarAvatar({ profile, isProfileLoading, onClick }) {
  const defaultAvatar = useDefaultAvatar()
  const isMobile = useIsMobile()
  const avatarSize = isMobile ? 36 : HEADER_HEIGHT

  return (
    <LoadingWrapper
      isLoading={isProfileLoading}
      skeleton={<CircularSkeleton size={avatarSize} />}
    >
      <IconButton
        color="secondary"
        size="small"
        onClick={onClick}
        id="profile-button"
      >
        <UserAvatar
          profileData={profile}
          isProfileLoading={isProfileLoading}
          size={avatarSize}
          defaultAvatar={defaultAvatar}
        />
      </IconButton>
    </LoadingWrapper>
  )
}
