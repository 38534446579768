import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"

import Column from "../../../common/components/Column"
import FilterTitleDescription from "./FilterTitleDescription"
import DistanceUnitsSelector, {
  useDistanceUnitsSelectorState,
} from "./distance-units-selector"
import { ANCHOR_TOP } from "./zipcode-filter-dialog"

export default function DistanceFilterDialog({
  open,
  onClose,
  distance: distanceParam,
}) {
  const {
    distance,
    handleDistanceChanged,
    units,
    handleUnitsToggleChanged,
    distanceError,
    setDistanceError,
    distanceFilter,
  } = useDistanceUnitsSelectorState(distanceParam.distance, distanceParam.units)

  function handleApplyButtonClicked() {
    onClose(distanceFilter)
  }

  return (
    <Popover
      open={open}
      onClose={() => onClose()}
      anchorReference="anchorPosition"
      anchorPosition={{
        left: 700,
        top: ANCHOR_TOP,
      }}
    >
      <Column
        sx={{
          p: 2,
          maxWidth: "280px",
        }}
      >
        <DistanceFilterTitleDescription />
        <Box
          sx={{
            mt: 1,
          }}
        >
          <DistanceUnitsSelector
            distance={distance}
            onDistanceChanged={handleDistanceChanged}
            units={units}
            onUnitsChanged={handleUnitsToggleChanged}
            onError={setDistanceError}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          disabled={!!distanceError}
          sx={{ mt: 2 }}
          onClick={handleApplyButtonClicked}
        >
          Apply
        </Button>
      </Column>
    </Popover>
  )
}

export function DistanceFilterTitleDescription() {
  return (
    <FilterTitleDescription
      title="Distance from me"
      description="See brokerages within this range"
    />
  )
}
