import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Avatar from "@mui/material/Avatar"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import Column from "./Column"
import Row from "./Row"

export default function BorderedIconTitleSubtitle({
  title,
  subtitle,
  isActive,
  onClick,
  Icon,
  showActiveCheckmark = true,
}) {
  return (
    <BorderedTitleSubtitleLayout
      title={title}
      subtitle={subtitle}
      isActive={isActive}
      onClick={onClick}
      Icon={Icon}
      showActiveCheckmark={showActiveCheckmark}
    />
  )
}

export function BorderedIconTitleSubtitleButton({
  title,
  subtitle,
  onClick,
  Icon,
  showActiveCheckmark = true,
}) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <BorderedTitleSubtitleLayout
      title={title}
      subtitle={subtitle}
      isActive={isHovered}
      showActiveCheckmark={isHovered}
      Icon={Icon}
      onClick={onClick}
      onHover={(hovered) => setIsHovered(hovered)}
    />
  )
}

function BorderedTitleSubtitleLayout({
  title,
  subtitle,
  isActive,
  onHover = () => {},
  onClick,
  Icon,
  showActiveCheckmark,
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderColor: (theme) =>
          isActive
            ? theme.palette.primary.main
            : theme.palette.otherwise.border,
        p: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <Row
        sx={{
          alignItems: "center",
          p: 1,
        }}
      >
        <Avatar
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <Icon fontSize="medium" color="primary" />
        </Avatar>
        <Column
          sx={{
            ml: 2,
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {subtitle}
          </Typography>
        </Column>
      </Row>
      {isActive && showActiveCheckmark && <CheckCircleIcon color="primary" />}
    </Paper>
  )
}
