import { useDispatch } from "react-redux"

import { useAuthorizedAxiosClient } from "../../../common/utils"
import { updateProfileProperty } from "../slice"

export function useHandleToggleChanged() {
  const dispatch = useDispatch()
  const axios = useAuthorizedAxiosClient()

  return (propName, setter, newValue) => {
    setter(newValue)
    return axios
      .put("/agents/api/profile/", { [propName]: newValue })
      .then(() =>
        dispatch(updateProfileProperty({ prop: propName, value: newValue }))
      )
  }
}
