import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

import Row from "../../../common/components/Row"
import Perk from "./Perk"

export default function OnePerkPlus({ perks }) {
  if (perks.length === 0) {
    return null
  }
  return (
    <Row>
      <Perk perk={perks[0]} />
      {perks.length > 1 && (
        <Row
          sx={{
            alignItems: "center",
            ml: 2,
          }}
        >
          <Divider orientation="vertical" sx={{ height: "100%" }} />
          <Typography variant="body1" sx={{ ml: 1 }}>
            +{perks.length - 1}
          </Typography>
        </Row>
      )}
    </Row>
  )
}
