import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Column from "../../../../common/components/Column"
import LengthLimitedTextField from "../../../../common/components/LengthLimitedTextField"
import { optionalParseInt } from "../../../../common/utils"

export function HeaderSection() {
  return (
    <>
      <Typography variant="h4">Describe your brokerage</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Tell agents a little about the past and present of your brokerage
      </Typography>
    </>
  )
}

export function ContentSection({
  yearFounded,
  setYearFounded,
  description,
  setDescription,
}) {
  return (
    <>
      <TextField
        label="Year founded"
        placeholder="1976"
        value={yearFounded}
        onChange={(e) => setYearFounded(optionalParseInt(e.target.value))}
        type="number"
        required
        sx={{
          mt: 4,
          width: "10em",
        }}
      />
      <Column sx={{ mt: 4 }}>
        <LengthLimitedTextField
          label="Description"
          placeholder="We pride ourselves on running an agent-focused business, with an emphasis on support, training and innovation..."
          maxLength={300}
          value={description}
          setValue={setDescription}
          multiline
          required
        />
      </Column>
    </>
  )
}

export function ActionButtons({
  yearFounded,
  description,
  onPreviousButtonClicked,
  onNextButtonClicked,
}) {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={onPreviousButtonClicked}
        fullWidth
      >
        Previous
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onNextButtonClicked}
        fullWidth
      >
        {yearFounded || description ? "Next" : "Skip"}
      </Button>
    </>
  )
}
