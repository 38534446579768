import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useContext, useState } from "react"

import Column from "../../../../common/components/Column"
import { useIsMobile } from "../../../../common/utils"
import PerksDialog from "../perks/Dialog"
import { NewBrokerageContext } from "../utils"
import { ActionButtons, ContentSection, HeaderSection } from "./Common"

export default function MediaDialog({
  open,
  onClose,
  onPreviousButtonClicked,
  onReopen,
}) {
  const { images, setImages } = useContext(NewBrokerageContext)
  const [isPerksDialogOpen, setIsPerksDialogOpen] = useState(false)

  const isMobile = useIsMobile()

  function handleNextButtonClicked() {
    setIsPerksDialogOpen(true)
    onClose()
  }

  function handlePerksDialogPreviousButtonClicked() {
    setIsPerksDialogOpen(false)
    onReopen()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          pb: 1,
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <HeaderSection />
        </Column>
        <ContentSection images={images} setImages={setImages} />
      </DialogContent>
      <DialogActions>
        <ActionButtons
          images={images}
          onPreviousButtonClicked={onPreviousButtonClicked}
          onNextButtonClicked={handleNextButtonClicked}
        />
        <PerksDialog
          open={isPerksDialogOpen}
          onClose={() => setIsPerksDialogOpen(false)}
          onPreviousButtonClicked={handlePerksDialogPreviousButtonClicked}
          onReopen={() => setIsPerksDialogOpen(true)}
        />
      </DialogActions>
    </Dialog>
  )
}
