import MUIAvatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import { formatDistance } from "date-fns"
import { useEffect, useState } from "react"
import { FileIcon } from "react-file-icon"

import EditOutlinedRawIcon from "../../resources/icons/edit-outlined.svg"
import { getFileExtension } from "../../utils"
import BrandAvatar from "../BrandAvatar"
import Row from "../Row"
import { AgentAvatar } from "../UserAvatar"
import { MESSAGE_SOURCES } from "./InboxContext"

export default function ChatMessage({
  message,
  conversationDetails,
  index,
  onContextMenu,
}) {
  const theme = useTheme()
  const identityRevealedMessageIndex = conversationDetails.messages.findIndex(
    (message) => message.type === MESSAGE_TYPES.identityRevealed
  )
  let backgroundColor
  let textColor = "white"
  if (message.source === MESSAGE_SOURCES.agent) {
    if (
      !conversationDetails.is_concierge_conversation &&
      (index < identityRevealedMessageIndex ||
        identityRevealedMessageIndex === -1)
    ) {
      backgroundColor = "#383443"
    } else {
      backgroundColor = theme.palette.otherwise.gradientBackground
    }
  } else {
    backgroundColor = theme.palette.otherwise.lightBackground
    textColor = "inherit"
  }

  function getTimeString() {
    return formatDistance(new Date(message.created), new Date(), {
      addSuffix: true,
    })
  }

  const [messageTime, setMessageTime] = useState(getTimeString())

  useEffect(
    () => {
      const intervalID = setInterval(() => {
        setMessageTime(getTimeString())
      }, 60 * 1000)
      return () => clearInterval(intervalID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <MessageContainer
      sx={{
        background: backgroundColor,
      }}
    >
      <div
        onContextMenu={(e) => onContextMenu(e, message)}
        style={{
          cursor: "context-menu",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        {message.type === MESSAGE_TYPES.text ? (
          <Typography
            variant="body1"
            color={textColor}
            component="pre"
            whiteSpace="break-spaces"
            title={message.edited ? "Edited" : undefined}
            sx={{
              display: "inline-block",
              overflowWrap: "break-word",
              maxWidth: "100%",
              "&:after": message.edited
                ? {
                    content: '" "',
                    backgroundImage: `url(${EditOutlinedRawIcon})`,
                    width: "16px",
                    height: "16px",
                    backgroundSize: "16px 16px",
                    display: "inline-block",
                    ml: 1,
                  }
                : undefined,
            }}
            dangerouslySetInnerHTML={{ __html: message.text }}
          />
        ) : (
          <Row
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ minWidth: "48px", maxWidth: "48px", height: "48px" }}>
              <FileIcon
                extension={getFileExtension(message.file_url)}
                color={theme.palette.secondary.light}
              />
            </Box>
            <Link
              href={message.file_url}
              target="_blank"
              color="inherit"
              sx={{ ml: 2, lineBreak: "anywhere" }}
            >
              Download {message.file_url.split("/").pop()}
            </Link>
          </Row>
        )}
        <Typography variant="subtitle1" textAlign={"right"} sx={{ mt: 1 }}>
          {messageTime}
        </Typography>
      </div>
    </MessageContainer>
  )
}

export function LeftChatMessage({ message, conversationDetails, index }) {
  let Avatar
  if (message.source === MESSAGE_SOURCES.agent) {
    const profileData = {
      profile_image: conversationDetails.agent_request.profile_image,
      first_name: conversationDetails.agent_request.final_name,
    }
    Avatar = <AgentAvatar agent={profileData} size={32} />
  } else {
    const customerProfileImage =
      conversationDetails.brokerage.claimed_by.profile_image
    if (customerProfileImage) {
      Avatar = (
        <MUIAvatar
          src={customerProfileImage}
          sx={{ width: "32px", height: "32px" }}
        />
      )
    } else {
      const brandData = {
        icon: conversationDetails.brokerage.image,
      }
      Avatar = <BrandAvatar brand={brandData} size={32} />
    }
  }
  return (
    <LeftChatMessageLayout Avatar={Avatar}>
      <ChatMessage
        message={message}
        conversationDetails={conversationDetails}
        index={index}
      />
    </LeftChatMessageLayout>
  )
}

export const MessageContainer = styled(Box)(({ theme }) => ({
  flexBasis: 0,
  alignSelf: "flex-end",
  width: "320px",
  [theme.breakpoints.only("xs")]: {
    width: "max(100%, 240px)",
  },
  borderRadius: theme.units.borderRadius,
}))

export function LeftChatMessageLayout({ Avatar, children }) {
  return (
    <Row>
      <Box
        sx={{
          alignSelf: "flex-end",
        }}
      >
        {Avatar}
      </Box>
      <Box
        sx={{
          ml: 1,
        }}
      >
        {children}
      </Box>
    </Row>
  )
}

export const MESSAGE_TYPES = {
  text: "text",
  identityRevealed: "identity_revealed",
  attachment: "attachment",
  proposal: "proposal",
  proposalRequest: "proposal_request",
}
