import {
  GrayBorderedChip,
  GreenBorderedChip,
  PinkBorderedChip,
  WarningBorderedChip,
} from "../../../common/components/BorderedChips"
import { AGENT_REQUEST_STATUS_OPTIONS } from "../../../common/utils"

export default function AgentRequestStatusChip({ agentRequest }) {
  switch (agentRequest.status) {
    case AGENT_REQUEST_STATUS_OPTIONS.accepted:
      return <GreenBorderedChip label="Connected" size="small" />
    case AGENT_REQUEST_STATUS_OPTIONS.pending:
      return <GrayBorderedChip label="Request pending" size="small" />
    case AGENT_REQUEST_STATUS_OPTIONS.inContact:
      return <GreenBorderedChip label="In contact" size="small" />
    case AGENT_REQUEST_STATUS_OPTIONS.proposalReceived:
      return <PinkBorderedChip label="Proposal received" size="small" />
    default:
      return <WarningBorderedChip label="Request rejected" size="small" />
  }
}
