import { useSelector } from "react-redux"

import PercentageCircularProgress from "../../../common/components/PercentageCircularProgress"
import { selectProfileProgress } from "../slice"

export default function ProfileCircularProgress({
  fontSize = "1.25rem",
  size,
}) {
  const profileProgress = useSelector(selectProfileProgress)
  return (
    <PercentageCircularProgress
      progress={profileProgress}
      size={size}
      fontSize={fontSize}
    />
  )
}
