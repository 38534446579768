import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"

import BorderedChip from "../../../common/components/BorderedChips"
import BrandAvatar from "../../../common/components/BrandAvatar"
import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import { trimLongString, useIsMobile } from "../../../common/utils"
import { WhiteHeader, WhiteSubtitle } from "../components/White"
import { LIGHT_WHITE } from "../utils"

export default function BrokeragePaper({ brokerage }) {
  const isMobile = useIsMobile()

  return (
    <Paper
      elevation={1}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        p: (theme) => `${theme.spacing(2)}!important`,
        mt: 4,
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        width: { xs: "320px", sm: "400px" },
      }}
    >
      <BrandAvatar brand={{ icon: brokerage.image }} />
      <Column
        sx={{
          ml: 2,
          alignItems: "flex-start",
        }}
      >
        <WhiteHeader variant="caption">
          {trimLongString(brokerage.company, isMobile ? 20 : 30)}{" "}
          <WhiteBorderedChip label="Pro" sx={{ ml: 1 }} size="small" />
        </WhiteHeader>
        <Row sx={{ alignItems: "center", mt: 1 }}>
          <LocationOnOutlinedIcon
            sx={{ color: "otherwise.secondaryWhite" }}
            fontSize="small"
          />
          <WhiteSubtitle variant="body2" sx={{ ml: 0.5 }}>
            {trimLongString(brokerage.address, 25)}
          </WhiteSubtitle>
        </Row>
      </Column>
    </Paper>
  )
}

const WhiteBorderedChip = styled(BorderedChip)(() => ({
  borderColor: "white",
  color: "white",
  backgroundColor: LIGHT_WHITE,
}))
