import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

export default function IconValue({ Icon, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon />
      <Typography variant="body1" sx={{ ml: 1 }}>
        {value}
      </Typography>
    </Box>
  )
}
