import Box from "@mui/material/Box"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import CandidateCard, {
  CANDIDATE_CARD_HEIGHT,
  CANDIDATE_CARD_WIDTH,
} from "../../../common/components/CandidateCard"
import CircularProgressBackdrop from "../../../common/components/CircularProgressBackdrop"
import Column, { ColumnButRowOnMobile } from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import StampedAvatar from "../../../common/components/StampedAvatar"
import {
  ContentSection,
  SmartsetterLogoTitleLayout,
  VerticalSplitLayoutBox,
} from "../../../common/components/VerticalSplitLayout"
import TopProducerStarIcon from "../../../common/resources/icons/top-producer-star.png"
import {
  AGENT_REQUEST_STATUS_OPTIONS,
  useIsMobile,
} from "../../../common/utils"
import { WhiteHeader, WhiteSubtitle } from "../../brokerage/components/White"
import { ImageBox } from "../components/BuildingImageBox"
import BrokerageSignupBackgroundImage from "../resources/images/brokerage-signup-bg.jpg"
import RyanBrownAvatar from "../resources/images/ryan-brown.png"
import { useGetAgentRequestData } from "../utils"

export function BrokerageAgentPreview() {
  const { agentRequestData, isLoadingAgentRequestData } =
    useGetAgentRequestData()
  const isMobile = useIsMobile()

  const CARD_WIDTH_EM = `${CANDIDATE_CARD_WIDTH}em`
  const CARD_HEIGHT = CANDIDATE_CARD_HEIGHT - CANDIDATE_CARD_HEIGHT / 6
  const CARD_HEIGHT_EM = `${CARD_HEIGHT}em`

  function getStylesForCardIndex(index) {
    if (index === 1) {
      return { zIndex: 2, width: CARD_WIDTH_EM }
    }
    const scaleFactor = 0.7
    const styles = {
      transform: `scale(${scaleFactor})`,
      opacity: 0.5,
      zIndex: 1,
      width: CARD_WIDTH_EM,
    }
    const negativeMargin = `-${CANDIDATE_CARD_WIDTH * 0.6}em`
    if (index === 0) {
      styles.mr = negativeMargin
    } else {
      styles.ml = negativeMargin
    }
    return styles
  }

  return (
    <BrokerageImageBox>
      <ColumnButRowOnMobile
        sx={{
          justifyContent: { xs: "flex-start", sm: "center" },
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {isMobile ? (
          <StampedAvatar
            mainAvatar={RyanBrownAvatar}
            mainSize="56px"
            stampAvatar={TopProducerStarIcon}
            stampSize="24px"
            borderWidth="2px"
            stampOffset="-2px"
          />
        ) : (
          <Row
            sx={{
              justifyContent: "center",
              width: "100%",
              height: CARD_HEIGHT_EM,
            }}
          >
            {[0, 1, 2].map((index) => (
              <Box key={index} sx={{ ...getStylesForCardIndex(index) }}>
                <CandidateCard
                  agentName={agentRequestData.final_name}
                  levelOfInterest={agentRequestData.agent.level_of_interest}
                  requestStatus={AGENT_REQUEST_STATUS_OPTIONS.pending}
                  salesVolumeLastYear={agentRequestData.sales_volume_last_year}
                  yearInBusiness={agentRequestData.years_in_business}
                  signingBonus={5.5e3}
                  stats={{
                    yearsInBusiness: agentRequestData.avg_years_in_business,
                    salesVolume: agentRequestData.avg_sales_volume_last_year,
                  }}
                  Avatar={
                    <StampedAvatar
                      mainAvatar={RyanBrownAvatar}
                      stampAvatar={TopProducerStarIcon}
                    />
                  }
                />
              </Box>
            ))}
          </Row>
        )}
        <Column
          sx={{
            alignItems: { xs: "flex-start", sm: "center" },
            mt: { xs: 0, sm: 4 },
            ml: { xs: 2, sm: 0 },
          }}
        >
          <WhiteHeader variant={isMobile ? "h6" : "h5"}>
            Your Next Hire Is Waiting
          </WhiteHeader>
          <WhiteSubtitle
            sx={{
              mt: { xs: 0, sm: 2 },
              maxWidth: "20em",
              textAlign: { xs: "left", sm: "center" },
            }}
          >
            Create your account and access the world's largest real estate
            hiring site
          </WhiteSubtitle>
        </Column>
        <CircularProgressBackdrop open={isLoadingAgentRequestData} />
      </ColumnButRowOnMobile>
    </BrokerageImageBox>
  )
}

export default function BrokerageVerticalSplitLayout({
  children,
  verticalCenter = true,
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <VerticalSplitLayoutBox>
      {isMobile ? (
        <>
          <Box sx={{ p: 2 }}>
            <SmartsetterLogoTitleLayout />
          </Box>
          <BrokerageAgentPreview />
          <ContentSection>{children}</ContentSection>
        </>
      ) : (
        <>
          <ContentSection verticalCenter={verticalCenter}>
            {children}
          </ContentSection>
          <BrokerageAgentPreview />
        </>
      )}
    </VerticalSplitLayoutBox>
  )
}

const BrokerageImageBox = styled(ImageBox)(({ theme }) => ({
  backgroundImage: `url(${BrokerageSignupBackgroundImage})`,
  flexBasis: "50%",
  position: "sticky",
  top: 0,
  right: 0,
  [theme.breakpoints.down("md")]: {
    height: "500px",
    flexBasis: "100%",
    position: "relative",
  },
  [theme.breakpoints.only("xs")]: {
    padding: theme.spacing(2),
  },
}))
