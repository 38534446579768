import Tooltip from "@mui/material/Tooltip"

import Row from "../../../common/components/Row"
import BlueCheckIcon from "../../../common/resources/icons/blue-check.png"
import { getAgentName } from "../utils"

export default function AgentNameBlueCheck({ agentRequest }) {
  return (
    <Row component="span" sx={{ alignItems: "center" }}>
      {getAgentName(agentRequest)}
      {agentRequest.has_blue_check ? (
        <Tooltip title="Agent is HighRise Certified">
          <img
            src={BlueCheckIcon}
            style={{ marginLeft: "8px", width: "24px", height: "24px" }}
            alt="Blue check"
          />
        </Tooltip>
      ) : null}
    </Row>
  )
}
