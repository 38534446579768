import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import MailOutlinedIcon from "@mui/icons-material/MailOutline"
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import PhoneOutlined from "@mui/icons-material/PhoneOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import LinearProgress from "@mui/material/LinearProgress"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useTheme } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import AvatarEditorLayout from "../../../common/components/AvatarEditorLayout"
import BrokerageLocationUnknownWarning from "../../../common/components/BrokerageLocationUnknownWarning"
import BrokerageMediaGallery, {
  StyledVideo,
  VideoPlayIcon,
} from "../../../common/components/BrokerageMediaGallery"
import CenterMapContainer, {
  useCenterMap,
} from "../../../common/components/CenterMapContainer"
import Column from "../../../common/components/Column"
import FlexBackgroundCloseButton, {
  CloseIcon,
} from "../../../common/components/FlexBackgroundCloseButton"
import IconTitleValue from "../../../common/components/IconTitleValue"
import JumpLink from "../../../common/components/JumpLink"
import LoadingButton from "../../../common/components/LoadingButton"
import LoadingWrapper, {
  CircularSkeleton,
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import PerksColumns from "../../../common/components/PerksColumns"
import { RowButColumnOnMobile } from "../../../common/components/Row"
import Row from "../../../common/components/Row"
import SuccessSnackbar from "../../../common/components/SuccessSnackbar"
import TitledEditorSection, {
  TitledEditorSectionLayout,
} from "../../../common/components/TitledEditorSection"
import TwoColumnGrid from "../../../common/components/TwoColumnGrid"
import UnsavedChangesPreventLeaveDialog from "../../../common/components/UnsavedChangesPreventLeaveDialog"
import {
  addBrokerageMarkerToMap,
  fitMapToCircle,
  useMapLoader,
} from "../../../common/map-utils"
import BrokerageImage from "../../../common/resources/icons/brokerage.png"
import {
  brokerageDescriptionDisplay,
  handleAxiosUploadProgress,
  optionalParseInt,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
  useStateChangeTracker,
} from "../../../common/utils"
import PerksEditor from "../components/PerksEditor"
import SubscriptionStatusChip from "../components/SubscriptionStatusChip"
import ZipcodeTextField, {
  useZipcodeFieldState,
} from "../components/ZipcodeTextField"
import { updateBrokerage } from "../slice"
import { getEditBrokerageURL, useBrokerageProfileCompleteness } from "../utils"
import PreviewBrokerageAsAgentDialog from "./PreviewBrokerageAsAgentDailog"

const AVATAR_SIZE = 160

const BROKERAGE_FIELD_NAME_MAP = {
  phone: "phone",
  company: "company",
  description: "description",
  perks: "perks",
}

export default function EditBrokerage() {
  const [isLoadingBrokerage, setIsLoadingBrokerage] = useState(false)
  const [brokerage, setBrokerage] = useState({
    images: [],
    videos: [],
    perks: [],
  })
  const [isSavingBrandImage, setIsSavingBrandImage] = useState(false)
  const {
    property: company,
    setProperty: setCompany,
    propertyChanged: companyChanged,
    handleChangeSaved: handleCompanySaved,
    mixpanelTrack: mixpanelTrackCompanyChanged,
  } = useTrackBrokeragePropertyChangedEvent({
    defaultValue: "",
    propertyName: BROKERAGE_FIELD_NAME_MAP.company,
    eventName: "Brokerage name updated",
  })
  const [isEditingCompany, setIsEditingCompany] = useState(false)
  const [isSavingCompany, setIsSavingCompany] = useState(false)
  const [isEditingMediaSection, setIsEditingMediaSection] = useState(false)
  const [isEditingPerksSection, setIsEditingPerksSection] = useState(false)
  const [isSavingPerksSection, setIsSavingPerksSection] = useState(false)
  const {
    property: selectedPerks,
    setProperty: setSelectedPerks,
    propertyChanged: perksChanged,
    handleChangeSaved: handlePerksSaved,
    mixpanelTrack: mixpanelTrackPerksChanged,
  } = useTrackBrokeragePropertyChangedEvent({
    defaultValue: [],
    propertyName: BROKERAGE_FIELD_NAME_MAP.perks,
    eventName: "Brokerage perks updated",
  })
  const [isEditingAboutSection, setIsEditingAboutSection] = useState(false)
  const {
    state: companySize,
    setState: setCompanySize,
    hasChanged: companySizeChanged,
    handleChangeSaved: handleCompanySizeSaved,
  } = useStateChangeTracker(null)
  const {
    state: yearFounded,
    setState: setYearFounded,
    hasChanged: yearFoundedChanged,
    handleChangeSaved: handleYearFoundedSaved,
  } = useStateChangeTracker("")
  const { zipcode, setZipcode, zipcodeError, setZipcodeError } =
    useZipcodeFieldState()
  const {
    property: description,
    setProperty: setDescription,
    propertyChanged: descriptionChanged,
    handleChangeSaved: handleDescriptionSaved,
    mixpanelTrack: mixpanelTrackDescriptionChanged,
  } = useTrackBrokeragePropertyChangedEvent({
    defaultValue: "",
    propertyName: BROKERAGE_FIELD_NAME_MAP.description,
    eventName: "Brokerage description updated",
  })
  const [isEditingContactsSection, setIsEditingContactsSection] =
    useState(false)
  const {
    state: website,
    setState: setWebsite,
    hasChanged: websiteChanged,
    handleChangeSaved: handleWebsiteSaved,
  } = useStateChangeTracker("")
  const {
    state: address,
    setState: setAddress,
    hasChanged: addressChanged,
    handleChangeSaved: handleAddressSaved,
  } = useStateChangeTracker("")
  const {
    state: email,
    setState: setEmail,
    hasChanged: emailChanged,
    handleChangeSaved: handleEmailSaved,
  } = useStateChangeTracker("")
  const {
    property: phone,
    setProperty: setPhone,
    propertyChanged: phoneChanged,
    handleChangeSaved: handlePhoneSaved,
    mixpanelTrack: mixpanelTrackPhoneChanged,
  } = useTrackBrokeragePropertyChangedEvent({
    defaultValue: "",
    propertyName: BROKERAGE_FIELD_NAME_MAP.phone,
    eventName: "Brokerage phone number updated",
  })
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false)
  const [
    isPreviewBrokerageAsAgentDailogOpen,
    setIsPreviewBrokerageAsAgentDailogOpen,
  ] = useState(false)
  const { profileCompleteness, calculateProfileCompleteness } =
    useBrokerageProfileCompleteness()

  const searchParams = useSearchParams()[0]
  const highlightIncompleteSections =
    searchParams.get("source") === "complete-callout"

  const axios = useAuthorizedAxiosClient()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const UPDATE_BROKERAGE_URL = useUpdateBrokerageURL()
  const brokerageHasLocation = !!brokerage.location

  function handleSaveCompanyButtonClicked() {
    setIsSavingCompany(true)
    axios
      .put(UPDATE_BROKERAGE_URL, { company })
      .then(({ data }) => {
        dispatch(updateBrokerage(data))
        setBrokerage(data)
        setIsEditingCompany(false)
        setShowSuccessSnackbar(true)
        mixpanelTrackCompanyChanged(data)
        handleCompanySaved()
      })
      .finally(() => {
        setIsSavingCompany(false)
      })
  }

  function handleEditPerksSectionButtonClicked() {
    if (isEditingPerksSection) {
      setIsSavingPerksSection(true)
      axios
        .put(UPDATE_BROKERAGE_URL, {
          perks_update: selectedPerks.map(({ id }) => id),
        })
        .then(({ data }) => {
          setBrokerage(data)
          dispatch(updateBrokerage(data))
          setShowSuccessSnackbar(true)
          mixpanelTrackPerksChanged(data)
          handlePerksSaved()
        })
        .finally(() => {
          setIsEditingPerksSection(false)
          setIsSavingPerksSection(false)
        })
    } else {
      setIsEditingPerksSection(true)
    }
  }

  function handleBrokerageImageUpdated(data) {
    trackBrokerageEvent(data, "Brokerage icon updated", data.custom_brand_image)
    handleSectionDataUpdated(data)
  }

  function handleMediaVideoDeleted(brokerage) {
    setBrokerage(brokerage)
    trackBrokerageEvent(brokerage, "Brokerage video deleted", brokerage.videos)
  }

  function handleMediaImageDeleted(brokerage) {
    setBrokerage(brokerage)
    trackBrokerageEvent(brokerage, "Brokerage image deleted", brokerage.images)
  }

  function handleMediaUploaded(brokerage) {
    setBrokerage(brokerage)
    trackBrokerageEvent(brokerage, "Brokerage media uploaded", {
      images: brokerage.images,
      videos: brokerage.videos,
    })
  }

  function handleSectionDataUpdated(data) {
    setBrokerage(data)
    dispatch(updateBrokerage(data))
  }

  function handleAboutSectionEditingChanged(
    isEditingSection,
    editingSucceeded
  ) {
    setIsEditingAboutSection(isEditingSection || !editingSucceeded)
    if (editingSucceeded) {
      mixpanelTrackDescriptionChanged(brokerage)
      handleCompanySizeSaved()
      handleYearFoundedSaved()
      handleDescriptionSaved()
    }
  }

  function handleContactsSectionButtonClicked(editingFinished, changesSaved) {
    setIsEditingContactsSection(editingFinished)
    mixpanelTrackPhoneChanged(brokerage)
    if (changesSaved) {
      handleWebsiteSaved()
      handleEmailSaved()
      handleAddressSaved()
      handlePhoneSaved()
    }
  }

  useEffect(
    () => {
      setIsLoadingBrokerage(true)
      axios
        .get(UPDATE_BROKERAGE_URL)
        .then(({ data }) => {
          setBrokerage(data)
          setCompany(data[BROKERAGE_FIELD_NAME_MAP.company], false)
          setSelectedPerks(data.perks, false)
          setCompanySize(data.head_count || "", false)
          setYearFounded(data.year_founded || "", false)
          setZipcode(data.zip_code || "")
          setDescription(data.description || "", false)
          setWebsite(data.website || "", false)
          setAddress(data.address || "", false)
          setEmail(data.email || "", false)
          setPhone(data[BROKERAGE_FIELD_NAME_MAP.phone] || "", false)
        })
        .finally(() => setIsLoadingBrokerage(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UPDATE_BROKERAGE_URL]
  )

  useEffect(
    () => {
      calculateProfileCompleteness(brokerage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brokerage]
  )

  useEffect(
    () => {
      if (isLoadingBrokerage) {
        return
      }
      segmentAnalytics.track("Broker visited brokerage page", {
        "Brokerage ID": brokerage.id,
        "Brokerage name": brokerage.company,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingBrokerage]
  )

  return (
    <Column>
      <CenterMapContainer
        sx={{ height: "320px" }}
        Map={
          brokerageHasLocation
            ? BrokerageLocationMap
            : () => (
                <BrokerageLocationUnknownWarning subtitle="Try updating the location field below with your brokerage address" />
              )
        }
        mapProps={{ brokerage, isLoadingBrokerage }}
      ></CenterMapContainer>
      <Box
        sx={{
          px: { xs: 2, sm: 8 },
          pb: 4,
        }}
      >
        <RowButColumnOnMobile
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: { xs: "center", sm: "left" },
            position: "relative",
          }}
        >
          <RowButColumnOnMobile sx={{ alignItems: "center" }}>
            <Box
              sx={{
                position: "absolute",
                top: "-32px",
              }}
            >
              <LoadingWrapper
                isLoading={isLoadingBrokerage}
                skeleton={<CircularSkeleton size={AVATAR_SIZE} />}
              >
                <AvatarEditorLayout
                  isLoading={isSavingBrandImage}
                  setIsLoading={setIsSavingBrandImage}
                  defaultAvatar={BrokerageImage}
                  currentAvatar={brokerage.image}
                  updateProfileURL={UPDATE_BROKERAGE_URL}
                  profilePropertyName="custom_brand_image"
                  successCallback={handleBrokerageImageUpdated}
                  size={AVATAR_SIZE}
                  defaultAvatarPadding="0.9em"
                  avatarSX={{
                    backgroundColor: theme.palette.otherwise.darkBackground,
                    border: "2px solid white",
                    boxShadow: (theme) =>
                      highlightIncompleteSections &&
                      !profileCompleteness.logoComplete
                        ? theme.palette.otherwise.highlightShadow
                        : undefined,
                  }}
                />
              </LoadingWrapper>
            </Box>
            <Column
              sx={{
                ml: (theme) =>
                  isMobile ? 0 : `calc(${AVATAR_SIZE}px + ${theme.spacing(4)})`,
                mt: (theme) => (isMobile ? `calc(${AVATAR_SIZE}px - 24px)` : 0),
                pt: 2,
                alignItems: { xs: "center", sm: "flex-start" },
                flexGrow: 2,
              }}
            >
              <LoadingWrapper
                isLoading={isLoadingBrokerage}
                skeleton={<TextSkeleton width={12} height={3} />}
              >
                <SubscriptionStatusChip
                  subscription={brokerage.subscription}
                  addIcon
                />
                <Box sx={{ mt: 2, width: "100%" }}>
                  {isEditingCompany ? (
                    <Row sx={{ alignItems: "center" }}>
                      <TextField
                        label="Brokerage name"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        variant="standard"
                        autoFocus
                        sx={{ flexBasis: "90%" }}
                      />
                      <LoadingButton
                        variant="text"
                        isLoading={isSavingCompany}
                        disabled={!company}
                        onClick={handleSaveCompanyButtonClicked}
                        sx={{ ml: 2 }}
                      >
                        Save
                      </LoadingButton>
                    </Row>
                  ) : (
                    <Typography variant="h4" component="div">
                      {brokerage.company}
                      <IconButton
                        size="small"
                        onClick={() => setIsEditingCompany(true)}
                        sx={{ ml: 2 }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Typography>
                  )}
                </Box>
              </LoadingWrapper>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Brokerage
              </Typography>
            </Column>
          </RowButColumnOnMobile>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonOutlinedIcon />}
            onClick={() => setIsPreviewBrokerageAsAgentDailogOpen(true)}
            sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
          >
            Preview as Agent
          </Button>
          <PreviewBrokerageAsAgentDialog
            brokerageID={brokerage.id}
            open={isPreviewBrokerageAsAgentDailogOpen}
            onClose={() => setIsPreviewBrokerageAsAgentDailogOpen(false)}
          />
        </RowButColumnOnMobile>
        <Box
          sx={{
            mt: 8,
          }}
        >
          <TitledEditorSectionLayout
            title="Media"
            isEditingSection={isEditingMediaSection}
            isLoading={false}
            onClick={() => setIsEditingMediaSection(!isEditingMediaSection)}
            highlight={
              highlightIncompleteSections && !profileCompleteness.mediaComplete
            }
          >
            <Box sx={{ mt: 4 }}>
              {isEditingMediaSection ? (
                <Grid container spacing={2}>
                  {brokerage.videos.map((video, index) => (
                    <MediaVideo
                      key={index}
                      {...video}
                      brokerage={brokerage}
                      onVideoDeleted={handleMediaVideoDeleted}
                    />
                  ))}
                  {brokerage.images.map((image) => (
                    <MediaImage
                      key={image.id}
                      {...image}
                      brokerage={brokerage}
                      onImageDeleted={handleMediaImageDeleted}
                    />
                  ))}
                  <UploadMediaBox onUploadFinished={handleMediaUploaded} />
                </Grid>
              ) : (
                <BrokerageMediaGallery
                  videos={brokerage.videos}
                  images={brokerage.images}
                />
              )}
            </Box>
          </TitledEditorSectionLayout>
        </Box>
        <TitledEditorSectionLayout
          title={"Perks"}
          isEditingSection={isEditingPerksSection}
          isLoading={isSavingPerksSection}
          onClick={handleEditPerksSectionButtonClicked}
        >
          <Box sx={{ mt: 4 }}>
            {isEditingPerksSection ? (
              <PerksEditor
                selectedPerks={selectedPerks}
                setSelectedPerks={setSelectedPerks}
              />
            ) : (
              <PerksColumns perks={brokerage.perks} />
            )}
          </Box>
        </TitledEditorSectionLayout>
        <TitledEditorSection
          title="About"
          editButtonClicked={isEditingAboutSection}
          onEditChanged={handleAboutSectionEditingChanged}
          valueProps={{
            head_count: companySize,
            year_founded: yearFounded,
            zip_code: zipcode,
            description,
          }}
          apiURL={UPDATE_BROKERAGE_URL}
          successCallback={handleSectionDataUpdated}
          highlight={
            highlightIncompleteSections &&
            !profileCompleteness.descriptionComplete
          }
        >
          <Column
            sx={{
              mt: 4,
            }}
          >
            {isEditingAboutSection ? (
              <>
                <RowButColumnOnMobile
                  sx={{
                    "& .MuiTextField-root": {
                      width: { sm: "12em" },
                      "&:nth-of-type(n + 2)": {
                        ml: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 0 },
                      },
                    },
                  }}
                >
                  <TextField
                    label="Size"
                    value={companySize}
                    onChange={(e) =>
                      setCompanySize(optionalParseInt(e.target.value))
                    }
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={isMobile}
                  />
                  <TextField
                    label="Year founded"
                    value={yearFounded}
                    onChange={(e) =>
                      setYearFounded(optionalParseInt(e.target.value))
                    }
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={isMobile}
                  />
                  <ZipcodeTextField
                    zipcode={zipcode}
                    setZipcode={setZipcode}
                    zipcodeError={zipcodeError}
                    setZipcodeError={setZipcodeError}
                  />
                </RowButColumnOnMobile>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={3}
                  fullWidth
                  sx={{ mt: 4 }}
                />
              </>
            ) : (
              <>
                <RowButColumnOnMobile
                  sx={{
                    alignItems: { xs: "flex-start", sm: "center" },
                    "& .MuiBox-root:nth-of-type(n + 2)": {
                      ml: { xs: 0, sm: 4 },
                      mt: { xs: 4, sm: 0 },
                    },
                  }}
                >
                  <IconTitleValue
                    Icon={PersonOutlinedIcon}
                    title="Size"
                    value={
                      brokerage.head_count && `${brokerage.head_count} agents`
                    }
                    defaultValue="Head count here."
                  />
                  <IconTitleValue
                    Icon={CalendarTodayOutlinedIcon}
                    title="Year founded"
                    value={brokerage.year_founded}
                    defaultValue="Year founded here."
                  />
                  <IconTitleValue
                    Icon={MarkunreadMailboxOutlinedIcon}
                    title="Zipcode"
                    value={zipcode}
                    defaultValue="Enter zipcode here."
                  />
                </RowButColumnOnMobile>
                <Typography
                  variant="body1"
                  sx={{ mt: 4, whiteSpace: "break-spaces" }}
                >
                  {brokerageDescriptionDisplay(brokerage.description)}
                </Typography>
              </>
            )}
          </Column>
        </TitledEditorSection>
        <TitledEditorSection
          title="Contacts"
          editButtonClicked={isEditingContactsSection}
          onEditChanged={handleContactsSectionButtonClicked}
          valueProps={{ website, address, email, phone }}
          apiURL={UPDATE_BROKERAGE_URL}
          successCallback={handleSectionDataUpdated}
        >
          <Box
            sx={{
              mt: 4,
            }}
          >
            <TwoColumnGrid>
              {isEditingContactsSection ? (
                <>
                  <Grid xs={12} sm={6}>
                    <TextField
                      label="Website"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      type="url"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <TextField
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <TextField
                      label="Location"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <TextField
                      label="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="tel"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid xs={12} sm={6}>
                    <IconTitleValue
                      Icon={LanguageOutlinedIcon}
                      title="Website"
                      value={
                        website && <JumpLink title={website} url={website} />
                      }
                      defaultValue="Brokerage website here."
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <IconTitleValue
                      Icon={MailOutlinedIcon}
                      title="Email"
                      value={email}
                      defaultValue="Brokerage email here."
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <IconTitleValue
                      Icon={LocationOnOutlinedIcon}
                      title="Location"
                      value={address}
                      defaultValue="Brokerage address here."
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <IconTitleValue
                      Icon={PhoneOutlined}
                      title="Phone"
                      value={phone}
                      defaultValue="Brokerage phone here."
                    />
                  </Grid>
                </>
              )}
            </TwoColumnGrid>
          </Box>
        </TitledEditorSection>
      </Box>
      <SuccessSnackbar
        open={showSuccessSnackbar}
        onClose={() => setShowSuccessSnackbar(false)}
      />
      <UnsavedChangesPreventLeaveDialog
        dataHasChanged={
          companyChanged ||
          perksChanged ||
          companySizeChanged ||
          yearFoundedChanged ||
          descriptionChanged ||
          websiteChanged ||
          emailChanged ||
          addressChanged ||
          phoneChanged
        }
      />
    </Column>
  )
}

function BrokerageLocationMap({
  mapContainer,
  parentCenterMap,
  onMapCentered,
  brokerage,
  isLoadingBrokerage,
}) {
  const { map, mapLoaded } = useMapLoader(mapContainer)
  const brokerageHasLocation = !!brokerage.location

  function centerMap() {
    if (brokerageHasLocation) {
      fitMapToCircle(map, brokerage.location, 5, "mi", false)
    }
  }

  useEffect(
    () => {
      if (isLoadingBrokerage || !mapLoaded) {
        return
      }

      let brokerageMarker
      if (brokerageHasLocation) {
        brokerageMarker = addBrokerageMarkerToMap(map, brokerage)
        centerMap()
      }

      return () => {
        if (brokerageMarker) {
          brokerageMarker.remove()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingBrokerage, mapLoaded, brokerage]
  )

  useCenterMap(parentCenterMap, centerMap, onMapCentered)
}

function MediaImage({ id, image, brokerage, onImageDeleted }) {
  const deleteBrokerageMedia = useDeleteBrokerageMedia(
    id,
    brokerage,
    "images",
    "images_update",
    onImageDeleted
  )

  return (
    <RemoveableMedia
      Container={({ children }) => (
        <MediaBox
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          {children}
        </MediaBox>
      )}
      onDeleteButtonClicked={deleteBrokerageMedia}
    />
  )
}

function MediaVideo({ id, file, brokerage, onVideoDeleted }) {
  const deleteBrokerageMedia = useDeleteBrokerageMedia(
    id,
    brokerage,
    "videos",
    "videos_update",
    onVideoDeleted
  )
  return (
    <RemoveableMedia
      Container={({ children }) => (
        <MediaBox>
          <StyledVideo src={file} />
          <VideoPlayIcon />
          {children}
        </MediaBox>
      )}
      onDeleteButtonClicked={deleteBrokerageMedia}
    />
  )
}

function RemoveableMedia({ Container, onDeleteButtonClicked }) {
  return (
    <MediaColumn>
      <Container>
        <FlexBackgroundCloseButton
          sx={{
            top: 4,
            right: 4,
          }}
          onClick={onDeleteButtonClicked}
        >
          <CloseIcon />
        </FlexBackgroundCloseButton>
      </Container>
    </MediaColumn>
  )
}

function UploadMediaBox({ onUploadFinished }) {
  const fileInputRef = useRef()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const axios = useAuthorizedAxiosClient()
  const updateProfileURL = useUpdateBrokerageURL()
  const dispatch = useDispatch()

  function handleUploadButtonClicked() {
    fileInputRef.current.click()
  }

  function handleSelectedFileChanged(e) {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData()
      let uploadFieldName = "media_image"
      const uploadedFile = e.target.files[0]
      if (uploadedFile.type.match(/video/)) {
        uploadFieldName = "media_video"
      }
      formData.append(uploadFieldName, uploadedFile)
      setIsUploading(true)
      axios
        .put(updateProfileURL, formData, {
          onUploadProgress: (event) => {
            handleAxiosUploadProgress(event, setUploadProgress)
          },
        })
        .then(({ data }) => {
          dispatch(updateBrokerage(data))
          onUploadFinished(data)
        })
        .finally(() => {
          setIsUploading(false)
          setUploadProgress(0)
        })
    }
  }

  return (
    <MediaColumn>
      <MediaBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: (theme) => `1px dashed ${theme.palette.primary.main}`,
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
            }}
            onClick={handleUploadButtonClicked}
            disabled={isUploading}
          >
            <AddOutlinedIcon color="primary" />
          </IconButton>
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.primary.main, mt: 1 }}
          >
            Upload media
          </Typography>
          {isUploading && (
            <LinearProgress
              color="primary"
              variant="determinate"
              value={uploadProgress}
              sx={{ width: "calc(100% + 50px)", mt: 1, height: "2px" }}
            />
          )}
        </Column>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*,video/mp4,video/x-m4v,video/*"
          onChange={handleSelectedFileChanged}
          style={{ display: "none" }}
        />
      </MediaBox>
    </MediaColumn>
  )
}

function MediaColumn({ children }) {
  return (
    <Grid xs={4} md={3}>
      {children}
    </Grid>
  )
}

const MediaBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.units.borderRadius,
  width: "100%",
  aspectRatio: "1 / 1",
  position: "relative",
  cursor: "pointer",
}))

function useUpdateBrokerageURL() {
  const { id } = useParams()
  return getEditBrokerageURL(id)
}

function useDeleteBrokerageMedia(
  mediaID,
  brokerage,
  mediaFieldName,
  targetFieldName,
  onMediaDeleted
) {
  const axios = useAuthorizedAxiosClient()
  const updateProfileURL = useUpdateBrokerageURL()

  return (e) => {
    e.stopPropagation()
    const remainingImageIDS = brokerage[mediaFieldName]
      .map(({ id }) => id)
      .filter((id) => id !== mediaID)
    axios
      .put(updateProfileURL, { [targetFieldName]: remainingImageIDS })
      .then(({ data }) => onMediaDeleted(data))
  }
}

function useTrackBrokeragePropertyChangedEvent({
  defaultValue,
  propertyName,
  eventName,
}) {
  const [property, _setProperty] = useState(defaultValue)
  const [propertyChanged, setPropertyChanged] = useState(false)

  function setProperty(newValue, countAsChange = true) {
    _setProperty(newValue)
    if (countAsChange) {
      setPropertyChanged(true)
    }
  }

  function handleChangeSaved() {
    setPropertyChanged(false)
  }

  function mixpanelTrack(brokerage) {
    if (!propertyChanged || property === defaultValue) {
      return
    }
    trackBrokerageEvent(brokerage, eventName, property)
  }
  return {
    property,
    setProperty,
    mixpanelTrack,
    propertyChanged,
    handleChangeSaved,
  }
}

function trackBrokerageEvent(brokerage, eventName, newValue) {
  segmentAnalytics.track(eventName, {
    "Brokerage ID": brokerage.id,
    "Brokerage name": brokerage.company,
    "New value": newValue,
  })
}
