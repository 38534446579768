import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useTheme } from "@mui/material/styles"
import { useDropzone } from "react-dropzone"

import Column from "../../../../common/components/Column"

export function HeaderSection() {
  return (
    <>
      <Typography variant="h4">Add media to your brokerage</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        Show potential agents what your brokerage looks like on the inside
        and/or outside.
      </Typography>
    </>
  )
}

export function ContentSection({ images, setImages }) {
  const theme = useTheme()

  const handleFileDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file.size > 5 * 1024 * 1024) {
      alert("File is too large > 5MB")
    } else {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        setImages([...images, { file, data: fileReader.result }])
      }
      fileReader.readAsDataURL(file)
    }
  }
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    accept: {
      "image/*": [],
    },
  })

  function handleImageDeleteButtonClickedWrapper(index) {
    return () => {
      setImages(images.filter((_, idx) => index !== idx))
    }
  }

  return (
    <Column sx={{ mt: 4 }}>
      <Grid container xs={12} spacing={2}>
        {images.map(({ data }, index) => (
          <Grid xs={4} key={index}>
            <Box
              sx={{
                position: "relative",
                borderRadius: theme.units.borderRadius,
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(1),
                }}
                onClick={handleImageDeleteButtonClickedWrapper(index)}
              >
                <CloseOutlinedIcon />
              </IconButton>
              <img
                src={data}
                style={{
                  width: "100%",
                  aspectRatio: "1/1",
                  borderRadius: theme.units.borderRadius,
                }}
                alt="brokerage"
              />
            </Box>
          </Grid>
        ))}
        <Grid xs={4}>
          <Column
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              borderWidth: "1px",
              borderStyle: "dashed",
              borderColor: (theme) =>
                isDragActive
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
              borderRadius: (theme) => theme.units.borderRadius,
              width: "100%",
              aspectRatio: "1/1",
              p: 2,
            }}
            {...getRootProps()}
          >
            <IconButton
              color="primary"
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
            >
              <AddOutlinedIcon />
            </IconButton>
            <Typography variant="caption" color="primary" sx={{ mt: 1 }}>
              Upload a photo
            </Typography>
            <input {...getInputProps()} accept="image/*" />
          </Column>
        </Grid>
      </Grid>
    </Column>
  )
}

export function ActionButtons({
  images,
  onPreviousButtonClicked,
  onNextButtonClicked,
}) {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onPreviousButtonClicked()}
        fullWidth
      >
        Previous
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onNextButtonClicked}
        fullWidth
      >
        {images.length === 0 ? "Skip" : "Next"}
      </Button>
    </>
  )
}
