import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import HelpOutlinedIcon from "@mui/icons-material/HelpOutline"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router-dom"

import CircularIcon from "../../../common/components/CircularIcon"
import InfoBubble from "../../../common/components/InfoBubble"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"

export default function SignupSuccess() {
  return (
    <BuildingVerticalSplitLayout>
      <CircularIcon Icon={EmailOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Confirmation email has been sent to you!
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Please verify your email before continuing to your account.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <InfoBubble
          Icon={HelpOutlinedIcon}
          message={
            <>
              Didn't receive any email? Please check your spam folder or{" "}
              <Link
                component={RouterLink}
                to="/users/resend-confirmation-email/"
                color="primary"
                variant="body1"
                sx={{ fontSize: "inherit" }}
              >
                resend confirmation email
              </Link>
              .
            </>
          }
        />
      </Box>
    </BuildingVerticalSplitLayout>
  )
}
