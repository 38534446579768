import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useState } from "react"

import Column from "../../../../common/components/Column"
import { useReportBrokerage } from "../report-brokerage/Common"
import VerificationRequestSentDialog from "../verification-request-sent/Dialog"
import {
  ActionButtons,
  ContentSection,
  HeaderSection,
  useCommonVerifyOwnershipState,
} from "./Common"

export default function VerifyOwnershipDialog({
  open,
  onClose,
  onPreviousButtonClicked,
  reason,
  note,
  brokerage,
}) {
  const { verificationDocument, setVerificationDocument } =
    useCommonVerifyOwnershipState()
  const { isReporting, reportBrokerage } = useReportBrokerage()
  const [isVerificationSentDialogOpen, setIsVerificationSentDialogOpen] =
    useState(false)

  function handleNextButtonClicked() {
    reportBrokerage(reason, note, verificationDocument, brokerage).then(() => {
      onClose()
      setIsVerificationSentDialogOpen(true)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <HeaderSection />
        </Column>
        <ContentSection
          verificationDocument={verificationDocument}
          setVerificationDocument={setVerificationDocument}
        />
      </DialogContent>
      <DialogActions>
        <ActionButtons
          onNextButtonClicked={handleNextButtonClicked}
          onPreviousButtonClicked={onPreviousButtonClicked}
          isReporting={isReporting}
          verificationDocument={verificationDocument}
        />
        <VerificationRequestSentDialog
          open={isVerificationSentDialogOpen}
          onClose={() => setIsVerificationSentDialogOpen(false)}
        />
      </DialogActions>
    </Dialog>
  )
}
