import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useSelector } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"
import {
  getFullName,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import { selectProfile } from "../slice"

export default function ConfirmRequestProposalDialog({
  open,
  onClose,
  conversationDetails,
}) {
  const [isSendingProposalRequest, setIsSendingProposalRequest] =
    useState(false)
  const isMobile = useIsMobile()
  const axios = useAuthorizedAxiosClient()
  const profile = useSelector(selectProfile)

  function handleSendProposalRequestButtonClicked() {
    setIsSendingProposalRequest(true)
    axios
      .post(
        `/agents/api/conversations/${conversationDetails.id}/send-proposal-request/`
      )
      .then(() => {
        segmentAnalytics.track("Agent requested proposal", {
          agentName: getFullName(profile),
          broker: conversationDetails.brokerage.claimed_by.get_full_name,
          brokerage: conversationDetails.brokerage.company,
        })
        onClose()
      })
      .finally(() => setIsSendingProposalRequest(false))
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <HaloCircularIcon Icon={BusinessCenterOutlinedIcon} size={80} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Are you sure you want to send a proposal request to{" "}
          {conversationDetails.brokerage.company}?
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          The broker will be notified of your request and should respond soon.
          Please keep in mind that proposals are non-binding.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          isLoading={isSendingProposalRequest}
          onClick={handleSendProposalRequestButtonClicked}
        >
          Send proposal request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
