import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import { useIsMobile } from "../../../common/utils"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import AddressAutocomplete, {
  useAddressAutocompleteState,
} from "../components/AddressAutocomplete"
import {
  profileLoading,
  selectCurrentBrokerage,
  selectShowDashboardTour,
  updateBrokerage,
} from "../slice"
import { getEditBrokerageURL } from "../utils"

export default function UpdateAddressDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const {
    address,
    setAddress,
    addressInput,
    setAddressInput,
    addressOptions,
    setAddressOptions,
    debouncedSearchQuery,
  } = useAddressAutocompleteState()
  const [isSaving, setIsSaving] = useState(false)
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const showDashboardTour = useSelector(selectShowDashboardTour)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const axios = useAuthorizedAxiosClient()

  function handleSaveButtonClicked() {
    setIsSaving(true)
    axios
      .put(getEditBrokerageURL(currentBrokerage.id), { address: addressInput })
      .then(({ data }) => {
        dispatch(updateBrokerage(data))
        setIsOpen(false)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsOpen(!showDashboardTour && !currentBrokerage.address)
      setAddressInput(currentBrokerage.address || "")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column
          sx={{
            width: "100%",
            alignItems: "center",
          }}
        >
          <HaloCircularIcon Icon={NotListedLocationOutlinedIcon} />
          <Column sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="h4">Address is required</Typography>
            <Typography variant="body" color="text.secondary2" sx={{ mt: 1 }}>
              Enter your brokerage address so local agents can find you.
              <br /> You will not receive new candidates until updated.
            </Typography>
          </Column>
        </Column>
        <AddressAutocomplete
          address={address}
          setAddress={setAddress}
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          addressOptions={addressOptions}
          setAddressOptions={setAddressOptions}
          debouncedSearchQuery={debouncedSearchQuery}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isSaving}
          disabled={!address}
          onClick={handleSaveButtonClicked}
        >
          Save address
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
