import CheckIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDebounce } from "usehooks-ts"

import Column from "../../../common/components/Column"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"

export default function PromoCodeDialog({ open, onClose }) {
  const [promoCode, setPromoCode] = useState("")
  const [promoCodeValid, setPromoCodeValid] = useState(null)
  const [isValidting, setIsValidating] = useState(false)
  const [stripeCoupon, setStripeCoupon] = useState()
  const debouncedPromoCode = useDebounce(promoCode)

  const isMobile = useIsMobile()
  const axios = useAuthorizedAxiosClient()

  function handleApplyButtonClicked() {
    onClose(stripeCoupon)
  }

  useEffect(
    () => {
      if (!promoCode) {
        return
      }
      setStripeCoupon(null)
      setPromoCodeValid(null)
      setIsValidating(true)
      axios
        .get(`/billing/api/validate-promo-code/?code=${promoCode}`)
        .then(({ data }) => {
          setPromoCodeValid(true)
          setStripeCoupon(data.coupon)
        })
        .catch(() => {
          setPromoCodeValid(false)
        })
        .finally(() => {
          setIsValidating(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPromoCode]
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <Typography variant="h6">Enter your Promotional Code here</Typography>
          <TextField
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            label="Promotional Code"
            focused
            fullWidth
            sx={{ mt: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {promoCode ? (
                    isValidting ? (
                      <CircularProgress variant="indeterminate" size={16} />
                    ) : promoCodeValid ? (
                      <CheckIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!promoCodeValid || isValidting}
          onClick={handleApplyButtonClicked}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}
