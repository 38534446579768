import { styled } from "@mui/material/styles"

import Column from "../Column"

export default styled(Column)(({ theme }) => ({
  flexBasis: "40%",
  [theme.breakpoints.down("md")]: {
    flexBasis: "100%",
  },
  padding: theme.spacing(2),
  overflowY: "auto",
  borderRight: `1px solid ${theme.palette.otherwise.border}`,
}))
