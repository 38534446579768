import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import EventOutlinedIcon from "@mui/icons-material/EventOutlined"
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined"
import Typography from "@mui/material/Typography"

import Column from "../../../common/components/Column"
import IconTitleValue from "../../../common/components/IconTitleValue"
import Row from "../../../common/components/Row"
import { AgentAvatar } from "../../../common/components/UserAvatar"
import BrokerageGrayIcon from "../../../common/resources/icons/brokerage-gray.png"
import { formatDate } from "../utils"
import CandidateDrawer, {
  BaseballCardSectionDivider,
  CloseIconButton,
  NotesEditor,
} from "./CandidateDrawerCommon"

export default function AirtableCandidateDrawer({
  highlightedCandidate,
  setHighlightedCandidate,
  onCandidateUpdated,
}) {
  return (
    <CandidateDrawer
      highlightedCandidate={highlightedCandidate}
      setHighlightedCandidate={setHighlightedCandidate}
    >
      {highlightedCandidate && (
        <Column sx={{ p: 4 }}>
          <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Row>
              <AgentAvatar
                agent={{ first_name: highlightedCandidate.candidate_name }}
              />
              <Column sx={{ ml: 2 }}>
                <Typography variant="h6">
                  {highlightedCandidate.candidate_name}
                </Typography>
                <Row sx={{ alignItems: "center", color: "text.secondary2" }}>
                  <EventOutlinedIcon fontSize="small" />
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    Connected on {formatDate(highlightedCandidate.stamp)}
                  </Typography>
                </Row>
              </Column>
            </Row>
            <CloseIconButton
              setHighlightedCandidate={setHighlightedCandidate}
            />
          </Row>
          <BaseballCardSectionDivider />
          <Typography variant="h6">About me</Typography>
          <Row sx={{ mt: 4 }}>
            <IconTitleValue
              Icon={() => (
                <img
                  src={BrokerageGrayIcon}
                  style={{ width: "16px", height: "16px" }}
                  alt="brokerage"
                />
              )}
              title="Current brokerage"
              value={highlightedCandidate.company_name}
            />
          </Row>
          <BaseballCardSectionDivider />
          <Typography variant="h6">Contact info</Typography>
          <Column
            sx={{ mt: 4, "& > .MuiBox-root:nth-of-type(n + 2)": { mt: 4 } }}
          >
            <IconTitleValue
              Icon={EmailOutlinedIcon}
              title="Email"
              value={highlightedCandidate.email}
            />
            <IconTitleValue
              Icon={PhoneOutlinedIcon}
              title="Phone"
              value={highlightedCandidate.phone}
            />
          </Column>
          <BaseballCardSectionDivider />
          <NotesEditor
            highlightedCandidate={highlightedCandidate}
            onCandidateUpdated={onCandidateUpdated}
          />
        </Column>
      )}
    </CandidateDrawer>
  )
}
