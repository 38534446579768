import Link from "@mui/material/Link"
import Snackbar from "@mui/material/Snackbar"
import { useState } from "react"

import { useSendPhoneVerificationSMS } from "../utils"

export default function ResendActivationCodeLink({ phone }) {
  const [
    showVerificationCodeResentSnackbar,
    setShowVerificationCodeResentSnackbar,
  ] = useState(false)
  const { sendVerification } = useSendPhoneVerificationSMS()

  function handleResendActivationCode() {
    sendVerification(phone).then(() =>
      setShowVerificationCodeResentSnackbar(true)
    )
  }

  return (
    <Link
      href="#"
      onClick={handleResendActivationCode}
      underline="always"
      sx={{ mt: 4, color: "primary.main" }}
    >
      I didn't receive an activation code
      <Snackbar
        open={showVerificationCodeResentSnackbar}
        onClose={() => setShowVerificationCodeResentSnackbar(false)}
        message="Activation code has been sent"
        autoHideDuration={5000}
      />
    </Link>
  )
}
