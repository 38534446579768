import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

import { trimLongString, useIsMobile } from "../utils"
import { getIconForPerkCategory } from "./PerksColumns"
import Row from "./Row"

export default function PerksSummary({ perks, perkLengthCap }) {
  const isMobile = useIsMobile()

  if (perks.length === 0) {
    return null
  }

  return (
    <Row
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PerkIconTitle
        perk={perks[0]}
        perkLengthCap={isMobile ? 50 : perkLengthCap}
      />
      {!isMobile && perks.length > 1 && (
        <Box sx={{ ml: 1 }}>
          <PerkIconTitle perk={perks[1]} perkLengthCap={perkLengthCap} />
        </Box>
      )}
      {perks.length > 2 && (
        <>
          <Row sx={{ alignItems: "center" }}>
            <Divider orientation="vertical" sx={{ ml: 2, height: "32px" }} />
            <Typography variant="body" sx={{ ml: 1 }}>
              +{perks.length - 2}
            </Typography>
          </Row>
        </>
      )}
    </Row>
  )
}

function PerkIconTitle({ perk, perkLengthCap = 20 }) {
  const Icon = getIconForPerkCategory(perk.category)
  return (
    <Row sx={{ alignItems: "center" }}>
      <Icon />
      <Typography variant="body1" sx={{ ml: 1 }}>
        {trimLongString(perk.title, perkLengthCap)}
      </Typography>
    </Row>
  )
}
