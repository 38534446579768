import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useContext } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"

import Column from "../../../../common/components/Column"
import { useIsMobile } from "../../../../common/utils"
import { addBrokerage, showProDialogForLatestBrokerage } from "../../slice"
import { NewBrokerageContext } from "../utils"
import { ActionButtons, ContentSection, HeaderSection } from "./Common"

export default function ContactInfoDialog({
  open,
  onClose,
  onPreviousButtonClicked,
}) {
  const {
    website,
    setWebsite,
    email,
    setEmail,
    phone,
    setPhone,
    company,
    addressInput,
    geoLocation,
    image,
    imageName,
    images,
    selectedPerks,
    yearFounded,
    description,
  } = useContext(NewBrokerageContext)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  function handleCreatingBrokerageFinished(data) {
    onClose()
    dispatch(addBrokerage(data))
    dispatch(showProDialogForLatestBrokerage())
    navigate(`/brokerage/${data.id}/edit/`)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <HeaderSection />
        </Column>
        <ContentSection
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          website={website}
          setWebsite={setWebsite}
        />
      </DialogContent>
      <DialogActions>
        <ActionButtons
          email={email}
          phone={phone}
          website={website}
          addressInput={addressInput}
          company={company}
          description={description}
          geoLocation={geoLocation}
          image={image}
          imageName={imageName}
          images={images}
          selectedPerks={selectedPerks}
          yearFounded={yearFounded}
          onPreviousButtonClicked={onPreviousButtonClicked}
          onCreatingBrokerageFinished={handleCreatingBrokerageFinished}
        />
      </DialogActions>
    </Dialog>
  )
}
