import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import IconButton from "@mui/material/IconButton"
import { alpha, styled } from "@mui/material/styles"

export default styled(IconButton)(() => ({
  position: "absolute",
  background: alpha("#000000", 0.5),
}))

export function CloseIcon() {
  return (
    <CloseOutlinedIcon
      sx={{
        color: "white",
        "&:hover": { color: "black" },
      }}
    />
  )
}
