import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import Typography from "@mui/material/Typography"

import Row from "./Row"

export default function ErrorMessage({ error, sx }) {
  return (
    error && (
      <Row sx={{ alignItems: "center", mt: 1, ...sx }}>
        <ErrorOutlineIcon
          color="error"
          sx={{ width: "16px", height: "16px" }}
        />
        <Typography
          variant="body1"
          fontSize="smaller"
          sx={{ ml: 1 }}
          color="error"
        >
          {error}
        </Typography>
      </Row>
    )
  )
}
