import Badge from "@mui/material/Badge"
import IconButton from "@mui/material/IconButton"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  hideNotificationDot,
  selectShowNotificationsDot,
} from "../../pages/notifications/slice"
import BellIcon from "../resources/icons/bell.svg"
import NotificationsDotIcon from "../resources/icons/notifications-dot.png"

export default function NotificationsBellButton({ onClick }) {
  const showNotificationsDot = useSelector(selectShowNotificationsDot)
  const dispatch = useDispatch()

  function handleClicked(e) {
    dispatch(hideNotificationDot())
    onClick(e)
  }

  return (
    <IconButton onClick={handleClicked}>
      <Badge
        badgeContent={
          showNotificationsDot ? (
            <img
              src={NotificationsDotIcon}
              alt="purple dot"
              style={{
                border: "1px solid white",
                borderRadius: "10px",
                width: "12px",
                height: "12px",
              }}
            />
          ) : undefined
        }
        sx={{
          "& .MuiBadge-badge": {
            minWidth: 0,
            height: "min-content",
            right: "2px",
          },
        }}
      >
        <img src={BellIcon} alt="notifications icon" />
      </Badge>
    </IconButton>
  )
}

export function useNotificationsBellButtonState() {
  const [notificationsMenuAnchorElem, setNotificationsMenuAnchorElem] =
    useState(null)

  function handleBellButtonClicked(e) {
    setNotificationsMenuAnchorElem(e.currentTarget)
  }

  function handleNotificationsMenuClosed() {
    setNotificationsMenuAnchorElem(null)
  }

  return {
    notificationsMenuAnchorElem,
    handleBellButtonClicked,
    handleNotificationsMenuClosed,
  }
}
