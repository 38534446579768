import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined"
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useContext, useState } from "react"

import { CreateProposalMenu } from "../../../pages/brokerage/inbox"
import ProposalDetailsBanner from "../../resources/images/proposal-request-card-head.png"
import { HaloCircularIcon } from "../CircularIcon"
import Column from "../Column"
import InboxContext, { MESSAGE_SOURCES } from "./InboxContext"
import RoundedCardContents from "./RoundedCardContents"
import RoundedImageCardHeader from "./RoundedImageCardHeader"

export default function ProposalRequestMessage({
  conversationDetails,
  message,
}) {
  const [buttonMenuRef, setButtonMenuRef] = useState(null)
  const inboxContext = useContext(InboxContext)
  const userIsAgent = inboxContext.messageSource === MESSAGE_SOURCES.agent

  return (
    <Column
      sx={{
        alignSelf: userIsAgent ? "flex-end" : "flex-start",
        maxWidth: { xs: "100%", sm: "320px" },
      }}
    >
      <RoundedImageCardHeader
        sx={{ backgroundImage: `url(${ProposalDetailsBanner})` }}
      />
      <RoundedCardContents sx={{ alignItems: "center", textAlign: "center" }}>
        <HaloCircularIcon
          size={48}
          Icon={() => (
            <BusinessCenterOutlinedIcon
              color="primary"
              sx={{ fontSize: "32px" }}
            />
          )}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          {userIsAgent
            ? `You sent a proposal request to ${conversationDetails.brokerage.company}`
            : "Agent has requested a proposal"}
          {!userIsAgent && (
            <>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                Click the button below to get started.
              </Typography>
              <Button
                color="primary"
                variant="contained"
                endIcon={<KeyboardArrowDownOutlined />}
                size="large"
                sx={{ mt: 2 }}
                onClick={(e) => setButtonMenuRef(e.currentTarget)}
              >
                Send proposal
              </Button>
              <CreateProposalMenu
                anchorElem={buttonMenuRef}
                onClose={() => setButtonMenuRef(null)}
                conversationDetails={conversationDetails}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                transformOrigin={{ horizontal: "center", vertical: "bottom" }}
              />
            </>
          )}
        </Typography>
      </RoundedCardContents>
    </Column>
  )
}
