import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"

import LoadingButton from "../../../common/components/LoadingButton"
import PasswordTextField from "../../../common/components/PasswordTextField"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"
import { axiosClient, isEnterKeyEvent } from "../../../common/utils"
import { retrieveUserProfile, selectToken } from "../slice"
import { useHandleLoginSuccess } from "../utils"

export default function Login() {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const loginToken = useSelector(selectToken)
  const theme = useTheme()
  const handleLoginSuccess = useHandleLoginSuccess()

  function handleEmailChanged(e) {
    setEmail(e.target.value)
    setEmailError(null)
  }

  function handlePasswordChanged(e) {
    setPassword(e.target.value)
    setEmailError(null)
  }

  function handleLogInButtonClicked() {
    setIsLoading(true)
    axiosClient
      .post("/users/api/login/", {
        email,
        password,
      })
      .then(({ data }) => {
        setEmailError(null)
        handleLoginSuccess(data)
      })
      .catch(({ response: { data } }) => {
        if (data.email) {
          setEmailError(data.email)
        } else if (data.phone) {
          setEmailError("Your phone is not yet verified")
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(
    () => {
      if (loginToken) {
        dispatch(retrieveUserProfile())
      }
    },
    // eslint-disable-next-line
    [loginToken]
  )

  return (
    <BuildingVerticalSplitLayout verticalCenterContent>
      <Typography variant="h4">Welcome to HighRise</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Join thousands of agents and brokerages, and find your perfect match.
      </Typography>
      <TextField
        label="Email"
        type="email"
        fullWidth
        required
        error={!!emailError}
        helperText={emailError}
        sx={{ mt: 4 }}
        onChange={handleEmailChanged}
        autoFocus
      />
      <PasswordTextField
        label="Password"
        value={password}
        onChange={handlePasswordChanged}
        onKeyDown={(e) =>
          isEnterKeyEvent(e) && email && handleLogInButtonClicked()
        }
        fullWidth
        required
        sx={{ mt: 2 }}
      />
      <Link
        component={RouterLink}
        to="/users/forgot-password"
        variant="body1"
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontSize: "0.8rem",
          alignSelf: "flex-end",
          mt: 1,
        }}
      >
        Forgot my password
      </Link>
      <LoadingButton
        variant="contained"
        disabled={!email || !password}
        isLoading={isLoading}
        onClick={handleLogInButtonClicked}
        sx={{
          mt: 2,
          alignSelf: "flex-start",
          minWidth: "10em",
          [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
            width: "100%",
          },
        }}
        size="large"
      >
        Log in
      </LoadingButton>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 4 }}>
        Don't have an account yet?{" "}
        <Link
          component={RouterLink}
          to="/users/signup/select-type/"
          variant="body1"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Sign up
        </Link>
      </Typography>
    </BuildingVerticalSplitLayout>
  )
}
