import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"

import Column from "../../common/components/Column"
import { RowButColumnOnMobile } from "../../common/components/Row"
import { useIsMobile } from "../../common/utils"

export default function NotFound() {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return (
    <Column
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background:
          "repeating-linear-gradient(-45deg,#fff 0 20px,#f4f4f4 0 40px)",
      }}
    >
      <RowButColumnOnMobile sx={{ alignItems: "center" }}>
        <Typography
          variant="h1"
          color="text.secondary"
          fontWeight={1000}
          sx={{
            fontSize: { xs: "8em", sm: "12em" },
            textShadow: "0 0 8px darkgray",
          }}
        >
          404
        </Typography>
        <Divider
          orientation={isMobile ? "horizontal" : "vertical"}
          sx={{
            borderWidth: "1px",
            borderColor: "lightgray",
            ml: { xs: 0, sm: 2 },
            mb: { xs: 2, sm: 0 },
            boxShadow: "1px 0 1px darkgray",
            width: { xs: "200px", sm: "initial" },
            height: { xs: "initial", sm: "164px" },
          }}
        />
        <Column sx={{ alignItems: "center", ml: { xs: 0, sm: 2 } }}>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            fontWeight={700}
            sx={{ color: "text.secondary2" }}
          >
            Page Not Found
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => navigate("/")}
            sx={{ mt: 2 }}
          >
            Go to Homepage
          </Button>
        </Column>
      </RowButColumnOnMobile>
    </Column>
  )
}
