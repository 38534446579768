import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"

import CircularIcon from "../../../common/components/CircularIcon"
import { BuildingVerticalSplitLayout } from "../../../common/components/VerticalSplitLayout"

export default function ForgotPasswordEmailSent() {
  const theme = useTheme()

  return (
    <BuildingVerticalSplitLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ [theme.breakpoints.down("sm")]: { alignSelf: "center" } }}>
          <CircularIcon Icon={MarkEmailReadIcon} />
        </Box>
        <Typography variant="h4" sx={{ mt: 2 }}>
          Check your email!
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Confirm your email before continuing to set up a new password
        </Typography>
      </Box>
    </BuildingVerticalSplitLayout>
  )
}
