import { useTheme } from "@mui/material/styles"
import { TourProvider } from "@reactour/tour"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { useAuthorizedAxiosClient } from "../utils"

export default function CommonTourProvider({ steps, children }) {
  const theme = useTheme()

  return (
    <TourProvider
      steps={steps}
      showBadge={false}
      showCloseButton
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: theme.units.borderRadius,
        }),
        dot: (base) => ({
          ...base,
          background: theme.palette.primary.main,
        }),
        maskArea: (base) => ({
          ...base,
          rx: 12,
        }),
      }}
      disableInteraction
      onClickHighlighted={(e) => e.stopPropagation()}
    >
      {children}
    </TourProvider>
  )
}

export function useNavLinkTourPopoverStyles() {
  const theme = useTheme()
  return (base) => ({
    ...base,
    borderRadius: theme.units.borderRadius,
    marginLeft: theme.spacing(1),
  })
}

export function usePopoverBelowStyles() {
  const theme = useTheme()
  return (base) => ({
    ...base,
    borderRadius: theme.units.borderRadius,
    marginTop: theme.spacing(1),
  })
}

export function useUpdateTourStatus(
  updateAPIURL,
  profilePropertyName,
  updateProfileAction
) {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()

  function updateStatus(newStatus) {
    setIsUpdatingStatus(true)
    return axios
      .put(updateAPIURL, { [profilePropertyName]: newStatus })
      .then(({ data }) => dispatch(updateProfileAction(data)))
      .finally(() => setIsUpdatingStatus(false))
  }

  return {
    isUpdatingStatus,
    updateStatus,
  }
}

export const TOUR_STATUS = {
  new: "new",
  skipped: "skipped",
  finished: "finished",
}
