import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import { useState } from "react"

import CircularProgressBackdrop from "../../../common/components/CircularProgressBackdrop"
import ProposalsCommon from "../proposals/ProposalsCommon"
import { useSendProposalToConversation } from "../utils"

export default function SelectExistingProposalDialog({
  open,
  onClose,
  conversation,
}) {
  const [isSendingProposal, setIsSendingProposal] = useState(false)
  const sendProposalToConversation = useSendProposalToConversation()

  function handleProposalClicked(proposal, isDraft) {
    let proposalID, message
    if (isDraft) {
      proposalID = proposal.id
      message = ""
    } else {
      proposalID = proposal.proposal.id
      message = proposal.message
    }
    setIsSendingProposal(true)
    sendProposalToConversation(proposalID, conversation.id, message)
      .then(() => onClose())
      .finally(() => setIsSendingProposal(false))
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Divider sx={{ mb: 2 }} />
        <ProposalsCommon dialogMode onProposalClicked={handleProposalClicked} />
      </DialogContent>
      <CircularProgressBackdrop open={isSendingProposal} />
    </Dialog>
  )
}
