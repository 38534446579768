import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined"
import SMSOutlinedIcon from "@mui/icons-material/SmsOutlined"
import Button from "@mui/material/Button"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Wizard, useWizard } from "react-use-wizard"

import ActionButtonsContainer from "../../../common/components/ActionButtonsContainer"
import Column from "../../../common/components/Column"
import DigitCodeTextFields, {
  useDigitCodeTextFieldsState,
} from "../../../common/components/DigitCodeTextFields"
import LoadingButton from "../../../common/components/LoadingButton"
import { axiosClient, useIsMobile } from "../../../common/utils"
import ClaimOfficeWizard from "../../brokerage/claim-office"
import ConfirmLeaveSignupWizardDialog from "../components/ConfirmLeaveSignupWizardDialog"
import PasswordFields from "../components/PasswordFields"
import ResendActivationCodeLink from "../components/ResendActivationCodeLink"
import { useHandleStepInputKeyDownEventWrapper } from "../components/SignupWizardSteps"
import { storeLoginData } from "../slice"
import {
  getBrokerageHomeURL,
  mixpanelTrackSignupWizardFinished,
  useSendPhoneVerificationSMS,
  useSetUserPassword,
  useSignupCampaign,
  useValidatePassowrds,
  useVerifyPhoneCode,
} from "../utils"
import {
  PersonalInfoStep,
  StepLayout,
  useCommonBrokerageSignupState,
} from "./common"

export default function BrokerageSignup() {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    emailError,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
    setBrokerageDetails,
    isValidatingData,
    validateSignupData,
    isSubmitting,
    setIsSubmitting,
    getSignupData,
  } = useCommonBrokerageSignupState()
  const {
    password,
    setPassword,
    password2,
    setPassword2,
    passwordError,
    setPasswordErrorFromData,
  } = useSetUserPassword()

  const {
    digitCode,
    setDigitCode,
    digitString,
    error: digitCodeError,
    setError: setDigitCodeError,
  } = useDigitCodeTextFieldsState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { clearSignupCampaign } = useSignupCampaign()

  function handleBrokerageSelected(brokerageDetails) {
    setBrokerageDetails(brokerageDetails)
  }

  function handlePasswordValidated() {
    setIsSubmitting(true)
    axiosClient
      .post("/customers/api/signup/", getSignupData(password))
      .then(({ data }) => {
        dispatch(storeLoginData(data))
        mixpanelTrackSignupWizardFinished("brokerage")
        navigate(getBrokerageHomeURL(1))
        clearSignupCampaign()
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Wizard>
      <PersonalInfoStep
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        handlePhoneChanged={setPhone}
        phoneError={phoneError}
        email={email}
        handleEmailChanged={setEmail}
        emailError={emailError}
        validateSignupData={validateSignupData}
        isValidatingData={isValidatingData}
      />
      <ClaimOfficeWizardWrapper
        phone={phone}
        setPhoneError={setPhoneError}
        onBrokerageSelected={handleBrokerageSelected}
      />
      <PhoneConfirmationSentStep
        phone={phone}
        digitCode={digitCode}
        setDigitCode={setDigitCode}
        digitString={digitString}
        error={digitCodeError}
        setError={setDigitCodeError}
      />
      <SetUpPasswordStep
        password={password}
        setPassword={setPassword}
        password2={password2}
        setPassword2={setPassword2}
        passwordError={passwordError}
        setPasswordErrorFromData={setPasswordErrorFromData}
        isSigningUp={isSubmitting}
        onPasswordValidated={handlePasswordValidated}
      />
    </Wizard>
  )
}

function ClaimOfficeWizardWrapper({
  phone,
  setPhoneError,
  onBrokerageSelected,
}) {
  const { previousStep, nextStep, goToStep } = useWizard()
  const { sendVerification } = useSendPhoneVerificationSMS()

  function handleBrokerageSelected(brokerageDetails) {
    sendVerification(phone, setPhoneError)
      .then(() => {
        onBrokerageSelected(brokerageDetails)
        nextStep()
      })
      .catch(() => {
        goToStep(0)
      })
  }

  return (
    <>
      <ClaimOfficeWizard
        onAnonymousPreviousButtonClicked={() => previousStep()}
        onBrokerageSelected={handleBrokerageSelected}
      />
      <ConfirmLeaveSignupWizardDialog />
    </>
  )
}

function PhoneConfirmationSentStep({
  phone,
  digitCode,
  setDigitCode,
  digitString,
  error,
  setError,
}) {
  const { isVerifying, verifyPhoneCode } = useVerifyPhoneCode()
  const isMobile = useIsMobile()
  const { previousStep, nextStep } = useWizard()

  function handleVerifyPhoneButtonClicked() {
    verifyPhoneCode(phone, digitString, setError).then(() => {
      nextStep()
    })
  }

  return (
    <StepLayout
      Icon={SMSOutlinedIcon}
      title="We've sent you an activation code via SMS"
      subtitle={`
        You should be receiving an activation code on your phone SMS messages
        within 2 minutes. Please type it in the field below:
      `}
    >
      <Column sx={{ mt: 4 }}>
        <DigitCodeTextFields
          digitCode={digitCode}
          setDigitCode={setDigitCode}
          error={error}
          setError={setError}
          large={!isMobile}
          onFull={handleVerifyPhoneButtonClicked}
        />
      </Column>
      <ActionButtonsContainer>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => previousStep()}
        >
          Select a different brokerage
        </Button>
        <LoadingButton isLoading={isVerifying} sx={{ ml: 2 }} fullWidth>
          Confirm
        </LoadingButton>
      </ActionButtonsContainer>
      <ResendActivationCodeLink phone={phone} />
    </StepLayout>
  )
}

function SetUpPasswordStep({
  password,
  setPassword,
  password2,
  setPassword2,
  passwordError,
  setPasswordErrorFromData,
  isSigningUp,
  onPasswordValidated,
}) {
  const isNextEnabled = !!password && !!password2 && !passwordError
  const handleKeyDownEvent = useHandleStepInputKeyDownEventWrapper(
    isNextEnabled,
    handleNextButtonClicked
  )
  const { isValidating: isValidatingPassword, validatePasswords } =
    useValidatePassowrds()

  function handleNextButtonClicked() {
    validatePasswords(password, password2, setPasswordErrorFromData).then(
      () => {
        onPasswordValidated()
      }
    )
  }

  return (
    <StepLayout
      Icon={PasswordOutlinedIcon}
      title="Set up your password"
      subtitle="This will be the password you use to access your account."
    >
      <Column
        sx={{
          mt: 4,
        }}
      >
        <PasswordFields
          password={password}
          setPassword={setPassword}
          password2={password2}
          setPassword2={setPassword2}
          passwordError={passwordError}
          handleKeyDownEvent={handleKeyDownEvent}
        />
      </Column>
      <LoadingButton
        isLoading={isValidatingPassword || isSigningUp}
        disabled={!isNextEnabled}
        onClick={handleNextButtonClicked}
        fullWidth
        sx={{ mt: 4 }}
      >
        Access your dashboard
      </LoadingButton>
    </StepLayout>
  )
}
