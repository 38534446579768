import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { HaloCircularIcon } from "../../../../common/components/CircularIcon"
import Column from "../../../../common/components/Column"
import AddBrokerageIcon from "../../../../common/resources/icons/brokerage-add-active.png"
import { useIsMobile } from "../../../../common/utils"
import CreateOfficeBasicInfoDialog from "../../create-office/basic-info/Dialog"
import ConfirmBrokerageDialog from "../confirm-brokerage/Dialog"
import ReportBrokerageDialog from "../report-brokerage/Dialog"
import SelectBrokerageCommon, { useCommonSelectBrokerageState } from "./Common"

export default function SelectBrokerageDialog({
  open,
  onClose,
  onSelectAnotherBrokerage,
}) {
  const { selectedBrokerage, setSelectedBrokerage } =
    useCommonSelectBrokerageState()
  const [isCreateNewBrokerageDialogOpen, setIsCreateNewBrokerageDialogOpen] =
    useState(false)
  const [
    isConfirmClaimBrokerageDialogOpen,
    setIsConfirmClaimBrokerageDialogOpen,
  ] = useState(false)
  const [isReportBrokerageDialogOpen, setIsReportBrokerageDialogOpen] =
    useState(false)

  const isMobile = useIsMobile()

  function handleCreateBrokerageButtonClicked() {
    setIsCreateNewBrokerageDialogOpen(true)
    onClose()
  }

  function handleBasicInfoDialogPreviousButtonClicked() {
    setIsCreateNewBrokerageDialogOpen(false)
    onSelectAnotherBrokerage()
  }

  function handleBrokerageSelected(brokerage) {
    setSelectedBrokerage(brokerage)
    setIsConfirmClaimBrokerageDialogOpen(true)
    onClose()
  }

  function handleConfirmBrokerageDialogClosed() {
    setSelectedBrokerage(null)
    setIsConfirmClaimBrokerageDialogOpen(false)
  }

  function handleReportBrokerageButtonClicked(brokerage) {
    setSelectedBrokerage(brokerage)
    setIsReportBrokerageDialogOpen(true)
    onClose()
  }

  function handleReportBrokerageDialogClosed() {
    setIsReportBrokerageDialogOpen(false)
  }

  function handleReportBrokerageDialogPreviousButtonClicked() {
    setIsReportBrokerageDialogOpen(false)
    onSelectAnotherBrokerage()
  }

  function handleSelectAnotherBrokerageButtonClicked() {
    handleConfirmBrokerageDialogClosed()
    onSelectAnotherBrokerage()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <HaloCircularIcon
            Icon={() => (
              <img
                src={AddBrokerageIcon}
                alt="Brokerage plus"
                style={{ width: "48px", height: "48px" }}
              />
            )}
          />
          <Typography variant="h4" sx={{ mt: 2 }}>
            Add Brokerage Page
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            Search for the brokerage you'd like to add.
            <br />
            You may be asked to validate your ownership in the next step.
          </Typography>
        </Column>
        <SelectBrokerageCommon
          onBrokerageSelected={handleBrokerageSelected}
          onCreateNewBrokerage={handleCreateBrokerageButtonClicked}
          onReportClaimedBrokerage={handleReportBrokerageButtonClicked}
        />
        <CreateOfficeBasicInfoDialog
          open={isCreateNewBrokerageDialogOpen}
          onClose={() => setIsCreateNewBrokerageDialogOpen(false)}
          onPreviousButtonClicked={handleBasicInfoDialogPreviousButtonClicked}
          onReopen={handleCreateBrokerageButtonClicked}
        />
        <ConfirmBrokerageDialog
          open={isConfirmClaimBrokerageDialogOpen}
          onClose={handleConfirmBrokerageDialogClosed}
          brokerage={selectedBrokerage}
          onSelectAnotherBrokerage={handleSelectAnotherBrokerageButtonClicked}
        />
        <ReportBrokerageDialog
          open={isReportBrokerageDialogOpen}
          onClose={handleReportBrokerageDialogClosed}
          onReopen={() => setIsReportBrokerageDialogOpen(true)}
          onPreviousButtonClicked={
            handleReportBrokerageDialogPreviousButtonClicked
          }
          brokerage={selectedBrokerage}
        />
      </DialogContent>
    </Dialog>
  )
}

export function useSelectBrokerageDialogState() {
  const [isOpen, setIsOpen] = useState(false)

  function onClosed() {
    setIsOpen(false)
  }

  function open() {
    setIsOpen(true)
  }

  function onSelectAnotherBrokerage() {
    setIsOpen(true)
  }

  return { isOpen, onClosed, open, onSelectAnotherBrokerage }
}
