import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useTour } from "@reactour/tour"
import { useNavigate } from "react-router-dom"

import { useIsMobile } from "../utils"
import Column from "./Column"
import { TOUR_STATUS } from "./CommonTourProvider"
import LoadingButton from "./LoadingButton"

export default function FinishTourDialog({
  openStepIndex,
  Icon,
  title,
  body,
  letsGoURL,
  useUpdateTourStatus,
}) {
  const { currentStep, setCurrentStep, setIsOpen } = useTour()
  const { isUpdatingStatus, updateStatus } = useUpdateTourStatus()

  const isMobile = useIsMobile()
  const navigate = useNavigate()

  function handleNoThanksButtonClicked() {
    updateStatus(TOUR_STATUS.finished).then(() =>
      setCurrentStep(currentStep + 1)
    )
  }

  function handleLetsGoButtonClicked() {
    updateStatus(TOUR_STATUS.finished).then(() => {
      setIsOpen(false)
      setCurrentStep(currentStep + 1) // workaround for modal still showing even after closing the tour
      navigate(letsGoURL)
    })
  }

  return (
    <Dialog
      open={currentStep === openStepIndex}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column
          sx={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Icon />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            {body}
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          isLoading={isUpdatingStatus}
          onClick={handleNoThanksButtonClicked}
        >
          No, thanks.
        </LoadingButton>
        <LoadingButton
          isLoading={isUpdatingStatus}
          onClick={handleLetsGoButtonClicked}
        >
          Let's go!
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const finishTourDialogStep = {
  selector: "#authenticated-user-root",
  content: "",
  // show outside of page
  position: (positionProps) => [5000, -500],
  stepInteraction: true,
}
