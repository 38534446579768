import { createContext } from "react"

export const NewBrokerageContext = createContext({
  company: null,
  addressInput: null,
  geoLocation: null,
  image: null,
  imageName: null,
  images: null,
  setImages: null,
  selectedPerks: null,
  setSelectedPerks: null,
  yearFounded: null,
  setYearFounded: null,
  description: null,
  setDescription: null,
  website: null,
  setWebsite: null,
  email: null,
  setEmail: null,
  phone: null,
  setPhone: null,
  zipcode: null,
  setZipcode: null,
})
