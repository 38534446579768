import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"

import { HeaderSection } from "./Common"

export default function VerificationRequestSentDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderSection />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button variant="contained" onClick={() => onClose()}>
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  )
}
