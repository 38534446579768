import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

import MoneyBagIcon from "../resources/icons/money-bag.png"
import Column from "./Column"
import CommissionSplitComparison from "./CommissionSplitComparison"
import PerksSummary from "./PerksSummary"
import Row, { RowButColumnOnMobile } from "./Row"

export default function SendProposalStatsPerks({
  commissionSplit,
  signingBonus,
  perks,
  perkLengthCap,
}) {
  return (
    <>
      <RowButColumnOnMobile
        sx={{
          alignItems: { xs: "flex-start", sm: "center" },
          mt: 2,
        }}
      >
        <CommissionSplitComparison
          commissionSplit={commissionSplit}
          tight={false}
        />
        <Row
          sx={{
            alignItems: "center",
            ml: { xs: 0, sm: 4 },
            mt: { xs: 4, sm: 0 },
          }}
        >
          <img
            src={MoneyBagIcon}
            style={{ width: "24px", height: "24px" }}
            alt="Signing bonus"
          />
          <Column
            sx={{
              ml: 2,
            }}
          >
            <Typography variant="subtitle1" lineHeight={1}>
              Signing Bonus
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              ${signingBonus}
            </Typography>
          </Column>
        </Row>
      </RowButColumnOnMobile>
      {perks.length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />
          <PerksSummary perks={perks} perkLengthCap={perkLengthCap} />
        </>
      )}
    </>
  )
}
