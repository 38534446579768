import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useTour } from "@reactour/tour"
import { useState } from "react"
import { useSelector } from "react-redux"

import { useIsMobile } from "../utils"
import { HaloCircularIcon } from "./CircularIcon"
import Column from "./Column"
import { TOUR_STATUS } from "./CommonTourProvider"
import LoadingButton from "./LoadingButton"

export default function StartTourDialog({
  showTourSelector,
  useUpdateTourStatus,
}) {
  const showDashboardTour = useSelector(showTourSelector)
  const [isDialogClosed, setIsDialogClosed] = useState(false)
  const { setIsOpen: openBrokerageTour } = useTour()

  const isMobile = useIsMobile()
  const { isUpdatingStatus, updateStatus } = useUpdateTourStatus()

  function handleLetsGoButtonClicked() {
    setIsDialogClosed(true)
    openBrokerageTour(true)
  }

  return (
    <Dialog
      open={!isMobile && showDashboardTour && !isDialogClosed}
      onClose={() => setIsDialogClosed(true)}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column
          sx={{ width: "100%", alignItems: "center", textAlign: "center" }}
        >
          <HaloCircularIcon Icon={ChecklistOutlinedIcon} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Welcome to HighRise
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            Please take a moment to view the setup tour, and to familiarize
            yourself with the tools.
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          isLoading={isUpdatingStatus}
          onClick={() => updateStatus(TOUR_STATUS.skipped)}
        >
          No thanks
        </LoadingButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLetsGoButtonClicked}
        >
          Let's go!
        </Button>
      </DialogActions>
    </Dialog>
  )
}
