import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import Badge from "@mui/material/Badge"
import Button from "@mui/material/Button"
import Fab from "@mui/material/Fab"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Zoom from "@mui/material/Zoom"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"

import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import { AgentAvatar } from "../../../common/components/UserAvatar"
import CenteredLoadingAndNoConversationsIndicator, {
  shouldShowIndicator,
} from "../../../common/components/inbox/CenteredLoadingAndNoConversationsIndicator"
import { MESSAGE_TYPES } from "../../../common/components/inbox/ChatMessage"
import {
  CustomerConversation,
  useCommonConversationHandlers,
  useConversationsLoader,
} from "../../../common/components/inbox/Conversation"
import ConversationDetailsWrapper from "../../../common/components/inbox/ConversationDetails"
import ConversationFilterRow, {
  useConversationFiltersState,
} from "../../../common/components/inbox/ConversationFilterRow"
import ConversationListPagination from "../../../common/components/inbox/ConversationListPagination"
import ConversationsContainer from "../../../common/components/inbox/ConversationsContainer"
import InboxContext, {
  MESSAGE_SOURCES,
} from "../../../common/components/inbox/InboxContext"
import MainContainer from "../../../common/components/inbox/MainContainer"
import { useConversationDetailsLoader } from "../../../common/components/inbox/utils"
import ProposalActiveIcon from "../../../common/resources/icons/proposals-active.svg"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import { AGENT_LEVELS_OF_INTEREST } from "../../agent/utils"
import BaseballCardDrawer, {
  useBaseballCardState,
} from "../candidates/BaseballCardDrawer"
import CreateProposalDialog from "../proposals/CreateProposalDialog"
import {
  decrementUnreadConversationsCount,
  profileLoading,
  selectCurrentBrokerage,
  selectProfile,
} from "../slice"
import { getAgentName, useStartAgentConversation } from "../utils"
import SelectExistingProposalDialog from "./SelectExistingProposalDialog"

export default function Inbox() {
  const {
    searchQuery,
    setSearchQuery,
    selectedConversationType,
    conversationTypeSelectorMenuAnchorElem,
    handleConversationTypeChanged,
    handleConversationTypesSelectorButtonClicked,
    handleConversationTypesSelectorMenuClosed,
  } = useConversationFiltersState()
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const {
    conversations,
    setConversations,
    isLoadingConversations,
    loadConversations,
    pageIndex,
    setPageIndex,
    totalConversationCount,
    hasAnyConversations,
    setHasAnyConversations,
  } = useConversationsLoader(
    currentBrokerage
      ? `/customers/api/conversations/brokerage/${currentBrokerage?.id}/`
      : null,
    searchQuery,
    selectedConversationType
  )
  const [createProposalMenuAnchorElem, setCreateProposalMenuAnchorElem] =
    useState(null)
  const { requestStartConversation } = useStartAgentConversation()
  const { highlightedCandidate, setHighlightedCandidate } =
    useBaseballCardState()

  const inboxContextData = {
    messageSource: MESSAGE_SOURCES.brokerage,
    apiURLPrefix: "customers",
    conversationMutedPropertyName: "brokerage_muted",
    conversationReportedPropertyName: "brokerage_report_reason",
  }
  const inboxContext = useState(inboxContextData)[0]

  const {
    selectedConversationIndex,
    handleConversationSelected,
    handleConversationClosed,
    handleConversationSelectedWrapper,
    handleConversationUpdated,
    handleConversationDeletedWrapper,
  } = useCommonConversationHandlers({
    conversations,
    setConversations,
    filteredConversations: conversations,
    decrementUnreadConversationsCount,
  })
  const selectedConversation = conversations[selectedConversationIndex]
  const {
    conversationDetails,
    setConversationDetails,
    isLoading: isLoadingConversationDetails,
  } = useConversationDetailsLoader(
    inboxContextData.apiURLPrefix,
    selectedConversation
  )
  const [chattableAgents, setChattableAgents] = useState([])
  const [fabButtonRef, setFabButtonRef] = useState(null)

  const axios = useAuthorizedAxiosClient()
  const selectedConversationIdentityRevealed =
    conversationDetails.identity_revealed ||
    conversationDetails.messages.findIndex(
      (message) => message.type === MESSAGE_TYPES.identityRevealed
    ) !== -1
  const isProfileLoading = useSelector(profileLoading)

  function handleAddConversationMenuItemClickedWrapper(candidate) {
    return () => {
      requestStartConversation(candidate).then((data) => {
        setConversations([...conversations, data])
        handleConversationSelected(conversations.length)
        setChattableAgents(
          chattableAgents.filter((agent) => agent.id !== candidate.id)
        )
      })
      setFabButtonRef(null)
    }
  }

  function handleIdentityRevealed(agentRequestData) {
    setConversations(
      conversations.map((conversation) =>
        conversation.id === selectedConversation.id
          ? { ...selectedConversation, agent_request: agentRequestData }
          : conversation
      )
    )
    setConversationDetails({
      ...conversationDetails,
      agent_request: agentRequestData,
    })
  }

  function handleProposalReceived(message) {
    setConversations(
      conversations.map((conversation) =>
        conversation.id === selectedConversation.id
          ? { ...selectedConversation, has_proposal: true }
          : conversation
      )
    )
  }

  function handleCandidateUpdated(candidate) {
    setConversationDetails({ ...conversationDetails, agent_request: candidate })
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      loadConversations(handleConversationSelected).then((data) =>
        setHasAnyConversations(data.results.length > 0)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBrokerage, isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      axios
        .get(`/customers/api/${currentBrokerage.id}/conversations/agents/`)
        .then(({ data }) => setChattableAgents(data))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBrokerage, isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      // reset selected conversation when brokerage changes
      handleConversationClosed()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  return (
    <InboxContext.Provider value={inboxContext}>
      <MainContainer>
        {shouldShowIndicator(conversations.length, hasAnyConversations) ? (
          <Column sx={{ justifyContent: "center", width: "100%" }}>
            <CenteredLoadingAndNoConversationsIndicator
              isLoadingConversations={isLoadingConversations}
              noConversationsTitle="Start chatting and find your next amazing agent"
              noConversationsSubtitle={
                <>
                  Go to your{" "}
                  <Link
                    component={RouterLink}
                    to="/brokerage/candidates/"
                    underline="always"
                    color="primary"
                  >
                    candidates
                  </Link>{" "}
                  page to find your next agent or click the{" "}
                  <AddOutlinedIcon color="primary" fontSize="small" /> button
                  below.
                </>
              }
            />
            <AddConversationFAB
              selectedConversation={selectedConversation}
              chattableAgents={chattableAgents}
              setFabButtonRef={setFabButtonRef}
            />
          </Column>
        ) : (
          <>
            <ConversationsContainer>
              <ConversationFilterRow
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                conversationTypeSelectorMenuAnchorElem={
                  conversationTypeSelectorMenuAnchorElem
                }
                selectedConversationType={selectedConversationType}
                handleConversationTypeChanged={handleConversationTypeChanged}
                handleConversationTypesSelectorButtonClicked={
                  handleConversationTypesSelectorButtonClicked
                }
                handleConversationTypesSelectorMenuClosed={
                  handleConversationTypesSelectorMenuClosed
                }
                searchPlaceholder="Search candidates"
              />
              {conversations.map((conversation, index) => (
                <CustomerConversation
                  key={conversation.id}
                  conversation={conversation}
                  onConversationChanged={handleConversationUpdated}
                  onConversationDeleted={handleConversationDeletedWrapper(
                    index
                  )}
                  selectedConversation={selectedConversation}
                  onClick={handleConversationSelectedWrapper(index)}
                />
              ))}
              <ConversationListPagination
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                totalConversationsCount={totalConversationCount}
              />
              <AddConversationFAB
                selectedConversation={selectedConversation}
                chattableAgents={chattableAgents}
                setFabButtonRef={setFabButtonRef}
              />
            </ConversationsContainer>

            {selectedConversation && (
              <ConversationDetailsWrapper
                selectedConversation={selectedConversation}
                conversationDetails={conversationDetails}
                name={selectedConversation.agent_request.final_name}
                isLoading={isLoadingConversationDetails}
                onConversationClosed={handleConversationClosed}
                onConversationChanged={handleConversationUpdated}
                onConversationDeleted={handleConversationDeletedWrapper(
                  selectedConversationIndex
                )}
                onMessagesUpdated={(messages) =>
                  setConversationDetails({ ...conversationDetails, messages })
                }
                onIdentityRevealed={handleIdentityRevealed}
                onProposalReceived={handleProposalReceived}
                profileDataSelector={selectProfile}
                HeaderContents={
                  <Row
                    sx={{
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setHighlightedCandidate(
                          conversationDetails.agent_request
                        )
                      }
                    >
                      <AgentAvatar
                        agent={{
                          ...selectedConversation.agent_request,
                          first_name:
                            selectedConversation.agent_request.final_name,
                        }}
                        size={48}
                      />
                    </IconButton>
                    <RowButColumnOnMobile
                      sx={{
                        justifyContent: "space-between",
                        alignItems: { xs: "flex-start", sm: "center" },
                        width: "100%",
                        ml: 2,
                      }}
                    >
                      <Link
                        href="#"
                        onClick={() =>
                          setHighlightedCandidate(
                            conversationDetails.agent_request
                          )
                        }
                        variant="h6"
                        color="text.primary"
                        title="See candidate profile card"
                      >
                        {selectedConversation.agent_request.final_name}
                      </Link>
                      <BaseballCardDrawer
                        highlightedCandidate={highlightedCandidate}
                        setHighlightedCandidate={setHighlightedCandidate}
                        onCandidateUpdated={handleCandidateUpdated}
                      />
                      {selectedConversationIdentityRevealed &&
                        !selectedConversation.is_concierge_conversation && (
                          <Button
                            color="primary"
                            variant="contained"
                            endIcon={<KeyboardArrowDownIcon />}
                            size="large"
                            onClick={(e) =>
                              setCreateProposalMenuAnchorElem(e.currentTarget)
                            }
                            sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
                          >
                            Send proposal
                          </Button>
                        )}
                    </RowButColumnOnMobile>
                  </Row>
                }
              />
            )}
            <CreateProposalMenu
              anchorElem={createProposalMenuAnchorElem}
              onClose={() => setCreateProposalMenuAnchorElem(null)}
              conversationDetails={conversationDetails}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            />
          </>
        )}
        <Menu
          anchorEl={fabButtonRef}
          open={!!fabButtonRef}
          onClose={() => setFabButtonRef(null)}
          PaperProps={{
            sx: {
              maxHeight: "400px",
            },
          }}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {chattableAgents.map((candidate) => (
            <MenuItem
              key={candidate.id}
              onClick={handleAddConversationMenuItemClickedWrapper(candidate)}
            >
              <Row sx={{ alignItems: "center" }}>
                <AgentAvatar agent={candidate} size={32} />
                <Column sx={{ ml: 2 }}>
                  <Typography variant="caption">
                    {getAgentName(candidate)}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary2"
                    fontSize="0.9em"
                  >
                    {
                      AGENT_LEVELS_OF_INTEREST[
                        candidate.agent.level_of_interest
                      ]
                    }
                  </Typography>
                </Column>
              </Row>
            </MenuItem>
          ))}
        </Menu>
      </MainContainer>
    </InboxContext.Provider>
  )
}

export function CreateProposalMenu({
  anchorElem,
  onClose,
  conversationDetails,
  anchorOrigin,
  transformOrigin,
}) {
  const [isCreateProposalDialogOpen, setIsCreateProposalDialogOpen] =
    useState(false)
  const [
    isSelectExistingProposalDialogOpen,
    setIsSelectExistingProposalDialogOpen,
  ] = useState(false)

  function handleCreateProposalMenuItemClicked() {
    setIsCreateProposalDialogOpen(true)
    onClose()
  }

  function handleCreateProposalDialogClosed(newProposal, isDraft) {
    setIsCreateProposalDialogOpen(false)
  }

  function handleSelectExistingProposalMenuItemClicked() {
    setIsSelectExistingProposalDialogOpen(true)
    onClose()
  }

  function handleSelectExistingProposalDialogClosed() {
    setIsSelectExistingProposalDialogOpen(false)
  }

  return (
    <>
      <Menu
        anchorEl={anchorElem}
        open={!!anchorElem}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={handleCreateProposalMenuItemClicked}>
          <ListItemIcon>
            <AddOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Create a new proposal</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSelectExistingProposalMenuItemClicked}>
          <ListItemIcon sx={{ pl: 0.5 }}>
            <img
              src={ProposalActiveIcon}
              alt="use existing proposal"
              style={{ width: "16px", height: "16px" }}
            />
          </ListItemIcon>
          <ListItemText>Use existing proposal</ListItemText>
        </MenuItem>
      </Menu>
      <CreateProposalDialog
        open={isCreateProposalDialogOpen}
        onClose={handleCreateProposalDialogClosed}
        conversation={conversationDetails}
      />
      <SelectExistingProposalDialog
        open={isSelectExistingProposalDialogOpen}
        onClose={handleSelectExistingProposalDialogClosed}
        conversation={conversationDetails}
      />
    </>
  )
}

function AddConversationFAB({
  selectedConversation,
  setFabButtonRef,
  chattableAgents,
}) {
  return (
    <Zoom in={!selectedConversation}>
      <Fab
        color="primary"
        variant="circular"
        sx={{ position: "absolute", bottom: { xs: 56, sm: 16 }, right: "16px" }}
        onClick={(e) => setFabButtonRef(e.currentTarget)}
        disabled={chattableAgents.length === 0}
      >
        <AddOutlinedIcon />
        <Badge
          color="secondary"
          badgeContent={chattableAgents.length}
          sx={{
            position: "absolute",
            right: 8,
            bottom: 8,
            "& .MuiBadge-badge": {
              border: "1px solid white",
              padding: "0 4px",
            },
          }}
        />
      </Fab>
    </Zoom>
  )
}
