import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import ShowMoreText from "react-show-more-text"

export default function TextExpandCollapse({ text, defaultText }) {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Typography
      variant="body1"
      component="div"
      sx={{ "& span": { overflowWrap: "break-word" } }}
    >
      <ShowMoreText
        more={<ShowSomethingLink>Read more</ShowSomethingLink>}
        less={<ShowSomethingLink>View less</ShowSomethingLink>}
        lines={1}
        expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        anchorClass=""
      >
        {text || defaultText}
      </ShowMoreText>
    </Typography>
  )
}

function ShowSomethingLink({ children }) {
  return (
    <Link underline="always" sx={{ fontSize: "inherit", cursor: "pointer" }}>
      {children}
    </Link>
  )
}
