import { useNavigate } from "react-router"

import ProposalsCommon from "./ProposalsCommon"

export default function Proposals() {
  const navigate = useNavigate()

  function handleProposalClicked(proposal, isDraft) {
    if (!isDraft) {
      navigate(`${proposal.id}/`)
    }
  }

  return <ProposalsCommon onProposalClicked={handleProposalClicked} />
}
