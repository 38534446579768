import { useState } from "react"

import { isEnterKeyEvent } from "../../../common/utils"
import { useStepNavigator } from "../utils"

export function useNameStepState({
  defaultFirstName = "",
  defaultLastName = "",
}) {
  const [firstName, setFirstName] = useState(defaultFirstName)
  const [lastName, setLastName] = useState(defaultLastName)
  return { firstName, setFirstName, lastName, setLastName }
}

export function useHandleStepInputKeyDownEventWrapper(
  isNextEnabled,
  nextStepHandler
) {
  const { nextStep } = useStepNavigator()
  return (e) => {
    if (isNextEnabled && isEnterKeyEvent(e)) {
      if (nextStepHandler) {
        nextStepHandler()
      } else {
        nextStep()
      }
    }
  }
}
