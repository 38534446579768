import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import DeleteOutlined from "@mui/icons-material/DeleteOutlined"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined"
import NotificationOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import Divider from "@mui/material/Divider"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useContext, useState } from "react"

import {
  segmentAnalytics,
  stringCapitalize,
  useAuthorizedAxiosClient,
} from "../../utils"
import BlockDeleteConversationDialog from "./BlockDeleteConversationDialog"
import InboxContext, { MESSAGE_SOURCES } from "./InboxContext"
import ReportConversationDialog from "./ReportConversationDialog"
import { useInboxActionEntityNames } from "./utils"

export default function ConversationMenuNoItems({
  anchorEl,
  onClose,
  children,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      keepMounted
    >
      {children}
    </Menu>
  )
}

export function CommonConversationMenuItems({
  conversation,
  name,
  onMuted,
  onReported,
  onDeleted,
  onClose,
}) {
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { actionPerformer, actionTarget } = useInboxActionEntityNames()

  const axios = useAuthorizedAxiosClient()
  const inboxContext = useContext(InboxContext)

  function handleToggleMuteMenuItemClicked() {
    axios
      .put(
        `/${inboxContext.apiURLPrefix}/api/conversations/${conversation.id}/toggle-mute/`
      )
      .then(({ data }) => {
        onMuted(data)
        const updatedPropertyName =
          inboxContext.messageSource === MESSAGE_SOURCES.agent
            ? "agent_muted"
            : "brokerage_muted"
        const actionName = data[updatedPropertyName] ? "muted" : "unmuted"
        segmentAnalytics.track(
          `${actionPerformer} ${actionName} ${actionTarget}`,
          {
            "Conversation ID": conversation.id,
            [`${stringCapitalize(actionName)} name`]: name,
          }
        )
      })
      .finally(() => onClose())
  }

  function handleReportMenuItemClicked() {
    setIsReportDialogOpen(true)
    onClose()
  }

  function handleReportDialogClosed() {
    setIsReportDialogOpen(false)
  }

  function handleDeleteMenuItemClicked() {
    setIsDeleteDialogOpen(true)
    onClose()
  }

  function handleDeleteDialogClosed() {
    setIsDeleteDialogOpen(false)
  }

  const conversationMuted =
    conversation[inboxContext.conversationMutedPropertyName]
  const MuteIcon = conversationMuted
    ? NotificationOutlinedIcon
    : NotificationsOffOutlinedIcon
  return [
    <MenuItem onClick={handleToggleMuteMenuItemClicked} key={1}>
      <ListItemIcon>
        <MuteIcon color="primary" />
      </ListItemIcon>
      <ListItemText>{conversationMuted ? "Unmute" : "Mute"}</ListItemText>
    </MenuItem>,
    <Divider key={2} />,
    <MenuItem
      onClick={handleReportMenuItemClicked}
      disabled={!!conversation[inboxContext.conversationReportedPropertyName]}
      key={3}
    >
      <ListItemIcon>
        <FlagOutlinedIcon color="primary" />
      </ListItemIcon>
      <ListItemText>Report</ListItemText>
    </MenuItem>,
    <Divider key={4} />,
    <MenuItem onClick={handleDeleteMenuItemClicked} key={5}>
      <ListItemIcon>
        <DeleteOutlined color="primary" />
      </ListItemIcon>
      <ListItemText>Block/Delete</ListItemText>
    </MenuItem>,
    <ReportConversationDialog
      open={isReportDialogOpen}
      onClose={handleReportDialogClosed}
      conversation={conversation}
      onConversationChanged={onReported}
      key={6}
    />,
    <BlockDeleteConversationDialog
      open={isDeleteDialogOpen}
      onClose={handleDeleteDialogClosed}
      conversation={conversation}
      name={name}
      onConversationDeleted={onDeleted}
      key={7}
    />,
  ]
}

export function CloseConversationMenuItem({ onClick, onCloseMenu }) {
  return [
    <Divider key={8} />,
    <MenuItem onClick={onClick} key={9}>
      <ListItemIcon>
        <CancelOutlinedIcon />
      </ListItemIcon>
      <ListItemText>Close</ListItemText>
    </MenuItem>,
  ]
}

export function handleConversationChangedWrapper(
  conversation,
  onConversationChanged
) {
  return (data) => {
    onConversationChanged({ ...conversation, ...data })
  }
}
