import Box from "@mui/material/Box"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import BuildingImageBox from "../../pages/users/components/BuildingImageBox"
import Column from "./Column"
import Row from "./Row"
import SmartsetterLogoTitle from "./SmartsetterLogoTitle"

export default function VerticalSplitLayout({
  children,
  verticalCenterContent = false,
  rightSection,
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <VerticalSplitLayoutBox>
      {isMobile ? (
        <>
          <Box sx={{ p: 2 }}>
            <SmartsetterLogoTitleLayout />
          </Box>
          <ImageSectionBox>{rightSection}</ImageSectionBox>
          <ContentSection>{children}</ContentSection>
        </>
      ) : (
        <>
          <ContentSection verticalCenter={verticalCenterContent}>
            {children}
          </ContentSection>
          <ImageSectionBox>{rightSection}</ImageSectionBox>
        </>
      )}
    </VerticalSplitLayoutBox>
  )
}

export function BuildingVerticalSplitLayout({
  children,
  verticalCenterContent = false,
}) {
  return (
    <VerticalSplitLayout
      verticalCenterContent={verticalCenterContent}
      rightSection={<BuildingImageBox />}
    >
      {children}
    </VerticalSplitLayout>
  )
}

export function ContentSection({ children, verticalCenter = false }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Column
      sx={{
        flexBasis: "50%",
        p: { xs: 2, md: 4 },
      }}
    >
      {!isMobile && <SmartsetterLogoTitleLayout />}
      <Column
        sx={{
          width: "75%",
          height: verticalCenter ? "calc(100vh - 200px)" : "max-content",
          mx: "auto",
          mt: 4,
          justifyContent: verticalCenter ? "center" : "flex-start",
          [theme.breakpoints.down("md")]: {
            mt: 2,
            width: "100%",
          },
        }}
      >
        {children}
      </Column>
      <Box sx={{ p: 2 }} />
    </Column>
  )
}

export function SmartsetterLogoTitleLayout() {
  return (
    <Row
      sx={{
        alignItems: "center",
      }}
    >
      <SmartsetterLogoTitle />
    </Row>
  )
}

export const VerticalSplitLayoutBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100vh",
  position: "relative",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

export const ImageSectionBox = styled(Box)(({ theme }) => ({
  flexBasis: "50%",
  position: "sticky",
  top: "0px",
  height: "min(100vh, 100%)",
  [theme.breakpoints.down("md")]: {
    position: "relative",
    flexBasis: 1,
  },
}))
