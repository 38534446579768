import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import Typography from "@mui/material/Typography"

import { HaloCircularIcon } from "../../../../common/components/CircularIcon"

export function HeaderSection() {
  return (
    <>
      <HaloCircularIcon Icon={CheckOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Verification request sent
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Your ownership claim has been submitted for review. We aim to provide
        your approval within 2 business days, and will send you an SMS once your
        claim has been processed.
      </Typography>
    </>
  )
}
