import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { CheckedChip } from "../../../../common/components/CheckableChip"
import ChipContainer from "../../../../common/components/ChipContainer"
import Column from "../../../../common/components/Column"
import Row from "../../../../common/components/Row"
import PerksEditor from "../../components/PerksEditor"

export function HeaderSection() {
  return (
    <>
      <Typography variant="h4">Add perks to your brokerage</Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Add perks that your brokerage offers to agents
      </Typography>
    </>
  )
}

export function ContentSection({ selectedPerks, setSelectedPerks }) {
  return (
    <>
      <Typography variant="h5" sx={{ mt: 4 }}>
        Added perks{" "}
        <Typography component="span" variant="h5" color="text.secondary2">
          {selectedPerks.length}/8
        </Typography>
      </Typography>
      <Row sx={{ mt: 2 }}>
        <ChipContainer>
          {selectedPerks.map((perk) => (
            <CheckedChip key={perk.id} label={perk.title} />
          ))}
        </ChipContainer>
      </Row>
      <Column sx={{ mt: 4 }}>
        <PerksEditor
          selectedPerks={selectedPerks}
          setSelectedPerks={setSelectedPerks}
        />
      </Column>
    </>
  )
}

export function ActionButtons({
  perks,
  onPreviousButtonClicked,
  onNextButtonClicked,
}) {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={onPreviousButtonClicked}
        fullWidth
      >
        Previous
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onNextButtonClicked}
        fullWidth
      >
        {perks.length === 0 ? "Skip" : "Next"}
      </Button>
    </>
  )
}
