import Chip from "@mui/material/Chip"

export default function ConversationChip({ darkBackground, label, icon }) {
  return (
    <Chip
      sx={{
        borderRadius: "8px",
        backgroundColor: (theme) =>
          darkBackground
            ? theme.palette.otherwise.border
            : theme.palette.otherwise.lightBackground,
      }}
      icon={icon}
      label={label}
    />
  )
}
