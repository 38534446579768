import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"

import Column from "../../../../common/components/Column"
import { useIsMobile } from "../../../../common/utils"
import MediaDialog from "../media/Dialog"
import { NewBrokerageContext } from "../utils"
import {
  ActionButtons,
  ContentSection,
  HeaderSection,
  useCommonBasicInfoState,
} from "./Common"

export default function BasicInfoDialog({
  open,
  onClose,
  onPreviousButtonClicked,
  onReopen,
}) {
  const {
    company,
    setCompany,
    address,
    setAddress,
    addressInput,
    setAddressInput,
    addressOptions,
    setAddressOptions,
    geoLocation,
    setGeoLocation,
    isQueryingPlaceDetails,
    setIsQueryingPlaceDetails,
    images,
    setImages,
    isMediaDialogOpen,
    setIsMediaDialogOpen,
    selectedPerks,
    setSelectedPerks,
    yearFounded,
    setYearFounded,
    description,
    setDescription,
    website,
    setWebsite,
    email,
    setEmail,
    phone,
    setPhone,
    zipcode,
    setZipcode,
    zipcodeError,
    setZipcodeError,
    imageName,
    onImageNameChanged,
    imageData,
    onImageDataChanged,
    debouncedSearchQuery,
  } = useCommonBasicInfoState()

  const isMobile = useIsMobile()

  function handleGoogleQueryFinished() {
    setIsMediaDialogOpen(true)
    onClose()
  }

  function handleMediaDialogPreviousButtonClicked() {
    setIsMediaDialogOpen(false)
    onReopen()
  }

  return (
    <NewBrokerageContext.Provider
      value={{
        company,
        addressInput,
        geoLocation,
        imageName: imageName,
        image: imageData,
        images,
        setImages,
        selectedPerks,
        setSelectedPerks,
        yearFounded,
        setYearFounded,
        description,
        setDescription,
        website,
        setWebsite,
        email,
        setEmail,
        phone,
        setPhone,
        zipcode,
        setZipcode,
      }}
    >
      <Dialog
        open={open}
        onClose={() => onClose()}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        keepMounted
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Column
            sx={{
              alignItems: "center",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <HeaderSection
              imageName={imageName}
              onImageNameChanged={onImageNameChanged}
              imageData={imageData}
              onImageDataChanged={onImageDataChanged}
            />
          </Column>
          <ContentSection
            address={address}
            setAddress={setAddress}
            addressInput={addressInput}
            setAddressInput={setAddressInput}
            addressOptions={addressOptions}
            setAddressOptions={setAddressOptions}
            company={company}
            setCompany={setCompany}
            zipcode={zipcode}
            setZipcode={setZipcode}
            zipcodeError={zipcodeError}
            setZipcodeError={setZipcodeError}
            debouncedSearchQuery={debouncedSearchQuery}
          />
        </DialogContent>
        <DialogActions>
          <ActionButtons
            address={address}
            company={company}
            zipcodeError={zipcodeError}
            imageData={imageData}
            isQueryingPlaceDetails={isQueryingPlaceDetails}
            setIsQueryingPlaceDetails={setIsQueryingPlaceDetails}
            setGeoLocation={setGeoLocation}
            onGoogleQueryFinished={handleGoogleQueryFinished}
            onPreviousButtonClicked={onPreviousButtonClicked}
          />
          <MediaDialog
            open={isMediaDialogOpen}
            onClose={() => setIsMediaDialogOpen(false)}
            onPreviousButtonClicked={handleMediaDialogPreviousButtonClicked}
            onReopen={() => setIsMediaDialogOpen(true)}
          />
        </DialogActions>
      </Dialog>
    </NewBrokerageContext.Provider>
  )
}
