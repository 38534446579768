import Box from "@mui/material/Box"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"

export default function DialogLayout({ Icon, title, description, footer }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {Icon}
      <Typography variant="button" color="primary.main" sx={{ mt: 1 }}>
        You're Anonymous
      </Typography>
      <DialogTitle variant="h6" sx={{ p: 0 }}>
        {title}
      </DialogTitle>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        {description}
      </Typography>
      {footer}
    </Box>
  )
}
