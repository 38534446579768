import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"

import Column from "./Column"
import ErrorMessage from "./ErrorMessage"
import Row from "./Row"

export default function DigitCodeTextFields({
  digitCode,
  setDigitCode,
  error,
  setError,
  onFull,
  errorSX,
  large,
}) {
  function handleDigitCodeChangedWrapper(index) {
    return function (e) {
      const newValue = e.target.value
      if (newValue !== "" && isNaN(parseInt(newValue))) return
      if (index === 0 && newValue.length > 1) {
        setDigitCode(newValue.split(""))
      } else {
        if (newValue.length > 1) return
        const codeCopy = digitCode.slice()
        codeCopy.splice(index, 1, newValue)
        setDigitCode(codeCopy)
      }
      setError(null)
    }
  }

  useEffect(
    () => {
      if (digitCode.every((digit) => digit !== "")) {
        onFull()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [digitCode]
  )

  return (
    <Column>
      <Row
        sx={{
          "& .MuiTextField-root:nth-of-type(n + 2)": { ml: 1 },
        }}
      >
        {digitCode.map((_, index) => (
          <TextField
            key={index}
            size={large ? "medium" : "small"}
            autoFocus={index === 0}
            sx={{
              width: large ? "4rem" : "3rem",
              "& .MuiInputBase-input": { textAlign: "center" },
            }}
            value={digitCode[index]}
            onChange={handleDigitCodeChangedWrapper(index)}
            inputRef={(input) =>
              input &&
              digitCode.findIndex((v) => v === "") === index &&
              input.focus()
            }
          />
        ))}
      </Row>
      <ErrorMessage error={error} sx={errorSX} />
    </Column>
  )
}

export function useDigitCodeTextFieldsState(length = 6) {
  const [digitCode, setDigitCode] = useState(new Array(length).fill(""))
  const [error, setError] = useState(null)
  const digitString = digitCode.join("")

  return { digitCode, setDigitCode, error, setError, digitString }
}
