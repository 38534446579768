import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import Link from "@mui/material/Link"

import { trimLongString } from "../utils"
import { useIsMobile } from "../utils"

const linkRegex = new RegExp("^(http|https)://")

export default function JumpLink({ title, url }) {
  let protocolURL = url
  if (!linkRegex.test(url)) {
    protocolURL = `http://${protocolURL}`
  }
  const isMobile = useIsMobile()
  return (
    <Link
      rel="noreferrer"
      href={protocolURL}
      target="_blank"
      sx={{
        display: "flex",
        alignItems: "center",
        color: "inherit",
        fontSize: "inherit",
      }}
    >
      {trimLongString(title, isMobile ? 22 : 50)}
      <OpenInNewIcon sx={{ fontSize: "1.2rem", color: "#383443", ml: 1 }} />
    </Link>
  )
}
