import SouthOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import NorthOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import SwapVerticalOutlinedIcon from "@mui/icons-material/SwapVertOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tab from "@mui/material/Tab"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { formatDistance } from "date-fns"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDebounce } from "usehooks-ts"

import BorderlessSearchTextField, {
  InputProps,
} from "../../../common/components/BorderlessSearchTextField"
import CenterEverythingBox from "../../../common/components/CenterEverythingBox"
import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TabContainer from "../../../common/components/TabContainer"
import { AgentAvatar } from "../../../common/components/UserAvatar"
import {
  formatCurrency,
  trimLongString,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import OnePerkPlus from "../components/OnePerkPlus"
import ProposalStatusChip from "../components/ProposalStatusChip"
import {
  BoldTableCell,
  HeaderTableCell,
  NoResultsTableRow,
  SortableHeaderTableCell,
  getOrderingParam,
  useTableSort,
} from "../components/Tables"
import ProposalDiamondImage from "../resources/images/proposal-gold-diamond.png"
import { profileLoading, selectCurrentBrokerage } from "../slice"
import CreateProposalDialog from "./CreateProposalDialog"

export default function ProposalsCommon({ dialogMode, onProposalClicked }) {
  const [sentProposals, setSentProposals] = useState([])
  const [draftProposals, setDraftProposals] = useState([])
  const [isLoadingSentProposals, setIsLoadingSentProposals] = useState(true)
  const [isLoadingDraftProposals, setIsLoadingDraftProposals] = useState(true)
  const [isUpdatingSentProposals, setIsUpdatingSentProposals] = useState(false)
  const [isUpdatingDraftProposals, setIsUpdatingDraftProposals] =
    useState(false)
  const [hasAnyProposals, setHasAnyProposals] = useState(false)
  const [isCreateProposalDialogOpen, setIsCreateProposalDialogOpen] =
    useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const {
    sortTableBy: sortSentTableBy,
    setSortTableBy: setSortSentTableBy,
    setSortDirection: setSortSentTableDirection,
    sortDirection: sentSortDirection,
    sortByColumnWrapper: sentSortByColumnWrapper,
  } = useTableSort()
  const {
    sortTableBy: sortDraftTableBy,
    sortDirection: draftSortDirection,
    sortByColumnWrapper: draftSortByColumnWrapper,
  } = useTableSort()
  const PROPOSAL_TABS = {
    sent: 0,
    draft: 1,
  }
  const [activeTabIndex, setActiveTabIndex] = useState(PROPOSAL_TABS.sent)
  const [proposalToDuplicate, setProposalToDuplicate] = useState(null)
  const [sortMenuAnchorElem, setSortMenuAnchorElem] = useState(null)

  const axios = useAuthorizedAxiosClient()
  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const isMobile = useIsMobile()
  const AVATAR_SIZE = 32

  function handleCreateProposalButtonClicked() {
    setIsCreateProposalDialogOpen(true)
    setProposalToDuplicate(null)
  }

  function handleCreateProposalDialogClosed(newProposal, isDraft) {
    setIsCreateProposalDialogOpen(false)
    if (newProposal) {
      if (isDraft) {
        setDraftProposals([newProposal, ...draftProposals])
        setActiveTabIndex(PROPOSAL_TABS.draft)
      } else {
        setSentProposals([newProposal, ...sentProposals])
        setActiveTabIndex(PROPOSAL_TABS.sent)
      }
    }
  }

  function handleSearchKeyDownEvent(e) {
    if (e.key === "Enter") {
      queryProposals(setIsUpdatingSentProposals, setIsUpdatingDraftProposals)
    }
  }

  function handleSortMenuItemClickedWrapper(fieldName, sortDirection) {
    return () => {
      setSortSentTableBy(fieldName)
      setSortSentTableDirection(sortDirection)
      setSortMenuAnchorElem(null)
    }
  }

  function handleDuplicateProposalButtonWrapper(proposal) {
    return (e) => {
      e.stopPropagation()
      setProposalToDuplicate(proposal)
      setIsCreateProposalDialogOpen(true)
    }
  }

  function handleProposalClickedWrapper(proposal, isDraft) {
    return () => {
      onProposalClicked(proposal, isDraft)
    }
  }

  function queryProposals(setSentLoadingFlag, setDraftLoadingFlag) {
    return Promise.all([
      querySentProposals(setSentLoadingFlag),
      queryDraftProposals(setDraftLoadingFlag),
    ])
  }

  function querySentProposals(setSentLoadingFlag) {
    const urlQuery = getURLQuery(sentSortDirection, sortSentTableBy)
    setSentLoadingFlag(true)
    return axios
      .get(`/customers/api/${currentBrokerage.id}/proposals/sent/`, urlQuery)
      .then(({ data }) => {
        setSentProposals(data)
        return data
      })
      .finally(() => setSentLoadingFlag(false))
  }

  function queryDraftProposals(setDraftLoadingFlag) {
    const urlQuery = getURLQuery(draftSortDirection, sortDraftTableBy)
    setDraftLoadingFlag(true)
    return axios
      .get(`/customers/api/${currentBrokerage.id}/proposals/draft/`, urlQuery)
      .then(({ data }) => {
        setDraftProposals(data)
        return data
      })
      .finally(() => setDraftLoadingFlag(false))
  }

  function getURLQuery(sortDirection, sortTableBy) {
    return {
      params: {
        q: searchQuery,
        ordering: getOrderingParam(sortDirection, sortTableBy),
      },
    }
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      queryProposals(
        setIsLoadingSentProposals,
        setIsLoadingDraftProposals
      ).then(([sentProposals, draftProposals]) =>
        setHasAnyProposals(
          sentProposals.length > 0 || draftProposals.length > 0
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBrokerage, isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      querySentProposals(setIsUpdatingSentProposals)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchQuery, sortSentTableBy, sentSortDirection, isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      queryDraftProposals(setIsUpdatingDraftProposals)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      debouncedSearchQuery,
      sortDraftTableBy,
      draftSortDirection,
      isProfileLoading,
    ]
  )

  return (
    <Box
      sx={{
        px: dialogMode ? 0 : { xs: 2, sm: 8 },
        pb: dialogMode ? 0 : { xs: 2, sm: 4 },
      }}
    >
      {isLoadingSentProposals || isLoadingDraftProposals ? (
        <InboxCenterEverythingBox>
          <CircularProgress color="primary" variant="indeterminate" size={96} />
        </InboxCenterEverythingBox>
      ) : sentProposals.length === 0 &&
        draftProposals.length === 0 &&
        !hasAnyProposals ? (
        <CenteredCreateProposalButton
          onCreateProposal={handleCreateProposalButtonClicked}
        />
      ) : (
        <Column>
          <Row
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              py: { xs: 1, sm: 2 },
              borderBottom: (theme) =>
                `1px solid ${theme.palette.otherwise.border}`,
            }}
          >
            <BorderlessSearchTextField
              placeholder="Search for a proposal"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearchKeyDownEvent}
              InputProps={InputProps}
              sx={{ minWidth: { xs: "min-content", sm: "15rem" } }}
            />
            <Row sx={{ alignItems: "center" }}>
              {isMobile && (
                <>
                  <IconButton
                    onClick={(e) => setSortMenuAnchorElem(e.currentTarget)}
                    disabled={activeTabIndex === PROPOSAL_TABS.draft}
                  >
                    <SwapVerticalOutlinedIcon />
                  </IconButton>
                  <Menu
                    open={!!sortMenuAnchorElem}
                    anchorEl={sortMenuAnchorElem}
                    onClose={() => setSortMenuAnchorElem(null)}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                  >
                    <MenuItem
                      onClick={handleSortMenuItemClickedWrapper(
                        "created",
                        "asc"
                      )}
                    >
                      <ListItemIcon>
                        <NorthOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Sent</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleSortMenuItemClickedWrapper(
                        "created",
                        "desc"
                      )}
                    >
                      <ListItemIcon>
                        <SouthOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Sent</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleSortMenuItemClickedWrapper("name", "asc")}
                    >
                      <ListItemIcon>
                        <NorthOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Agent name</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={handleSortMenuItemClickedWrapper("name", "desc")}
                    >
                      <ListItemIcon>
                        <SouthOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Agent name</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
              {!dialogMode && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateProposalButtonClicked}
                  size="large"
                  sx={{ ml: 1 }}
                >
                  Create proposal
                </Button>
              )}
            </Row>
          </Row>
          <Tabs
            value={activeTabIndex}
            onChange={(e, newValue) => setActiveTabIndex(newValue)}
            sx={{ mt: { xs: 2, sm: 4 } }}
          >
            <Tab label="Sent" />
            <Tab label="Drafts" />
          </Tabs>
          <Box sx={{ mt: { xs: 2, sm: 4 } }}>
            <TabContainer index={activeTabIndex} value={PROPOSAL_TABS.sent}>
              {isMobile ? (
                <Column>
                  {sentProposals.length === 0 ? (
                    <Row sx={{ justifyContent: "center" }}>
                      <Typography variant="body1" color="text.secondary2">
                        No sent proposals found
                      </Typography>
                    </Row>
                  ) : (
                    sentProposals.map(
                      (
                        {
                          id,
                          status,
                          created,
                          agent_request: { agent },
                          proposal,
                          message,
                        },
                        index
                      ) => (
                        <>
                          <RowButColumnOnMobile
                            key={id}
                            sx={{ py: 4 }}
                            onClick={handleProposalClickedWrapper(
                              {
                                id,
                                proposal,
                                message,
                              },
                              false
                            )}
                          >
                            <AgentAvatar
                              agent={{
                                profile_image: agent.profile_image,
                                first_name: agent.full_name,
                              }}
                            />
                            <Column
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  "& .MuiChip-root": { ml: 1 },
                                }}
                              >
                                {agent.full_name}
                                <ProposalStatusChip proposal={{ status }} />
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.secondary2"
                              >
                                {formatDistance(new Date(created), new Date(), {
                                  addSuffix: true,
                                })}
                              </Typography>
                              <Typography variant="body1" sx={{ mt: 2 }}>
                                {trimLongString(message, 80)}
                              </Typography>
                              <Box sx={{ mt: 4 }}>
                                <OnePerkPlus perks={proposal.perks} />
                              </Box>
                              <Divider sx={{ mt: 4 }} />
                              <Row
                                sx={{
                                  mt: 4,
                                  "& .MuiBox-root:last-child": { ml: 4 },
                                }}
                              >
                                <MobileStat
                                  title="Production"
                                  value={
                                    agent.sales_volume_last_year
                                      ? formatCurrency(
                                          agent.sales_volume_last_year
                                        )
                                      : ""
                                  }
                                />
                                <MobileStat
                                  title="Years in business"
                                  value={agent.years_in_business}
                                />
                              </Row>
                            </Column>
                          </RowButColumnOnMobile>
                          {index < sentProposals.length - 1 && <Divider />}
                        </>
                      )
                    )
                  )}
                </Column>
              ) : (
                <TableContainer sx={{ maxWidth: "calc(100vw - 128px)" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <HeaderTableCell
                          sx={{
                            width: `${AVATAR_SIZE}px`,
                            textAlign: "center",
                          }}
                        >
                          {isUpdatingSentProposals && (
                            <CircularProgress
                              variant="indeterminate"
                              color="primary"
                              size={AVATAR_SIZE}
                            />
                          )}
                        </HeaderTableCell>
                        <SortableHeaderTableCell
                          columnName="name"
                          sortTableBy={sortSentTableBy}
                          sortDirection={sentSortDirection}
                          sortByColumnWrapper={sentSortByColumnWrapper}
                        >
                          Name
                        </SortableHeaderTableCell>
                        <SortableHeaderTableCell
                          columnName="status"
                          sortTableBy={sortSentTableBy}
                          sortDirection={sentSortDirection}
                          sortByColumnWrapper={sentSortByColumnWrapper}
                        >
                          Status
                        </SortableHeaderTableCell>
                        <SortableHeaderTableCell
                          columnName="created"
                          sortTableBy={sortSentTableBy}
                          sortDirection={sentSortDirection}
                          sortByColumnWrapper={sentSortByColumnWrapper}
                        >
                          Sent
                        </SortableHeaderTableCell>
                        <SortableHeaderTableCell
                          columnName="sales_volume_last_year"
                          sortTableBy={sortSentTableBy}
                          sortDirection={sentSortDirection}
                          sortByColumnWrapper={sentSortByColumnWrapper}
                        >
                          Production
                        </SortableHeaderTableCell>
                        <SortableHeaderTableCell
                          columnName="years_in_business"
                          sortTableBy={sortSentTableBy}
                          sortDirection={sentSortDirection}
                          sortByColumnWrapper={sentSortByColumnWrapper}
                        >
                          Years in business
                        </SortableHeaderTableCell>
                        <HeaderTableCell>Looking for</HeaderTableCell>
                        {!dialogMode && (
                          <HeaderTableCell>
                            {/* duplicate action */}
                          </HeaderTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sentProposals.length === 0 ? (
                        <NoResultsTableRow columnCount={dialogMode ? 6 : 7}>
                          No sent proposals found
                        </NoResultsTableRow>
                      ) : (
                        sentProposals.map(
                          ({
                            id,
                            status,
                            created,
                            agent_request: { agent },
                            proposal,
                            message,
                          }) => (
                            <TableRow
                              key={id}
                              hover
                              sx={{ cursor: "pointer" }}
                              onClick={handleProposalClickedWrapper({
                                id,
                                proposal,
                                message,
                              })}
                            >
                              <TableCell>
                                <AgentAvatar
                                  agent={{
                                    profile_image: agent.profile_image,
                                    first_name: agent.full_name,
                                  }}
                                />
                              </TableCell>
                              <BoldTableCell>{agent.full_name}</BoldTableCell>
                              <TableCell>
                                <ProposalStatusChip proposal={{ status }} />
                              </TableCell>
                              <BoldTableCell>
                                {formatDistance(new Date(created), new Date(), {
                                  addSuffix: true,
                                })}
                              </BoldTableCell>
                              <BoldTableCell>
                                {agent.sales_volume_last_year
                                  ? formatCurrency(agent.sales_volume_last_year)
                                  : ""}
                              </BoldTableCell>
                              <BoldTableCell>
                                {agent.years_in_business}
                              </BoldTableCell>
                              <BoldTableCell>
                                <OnePerkPlus perks={agent.perks} />
                              </BoldTableCell>
                              {!dialogMode && (
                                <TableCell>
                                  <IconButton
                                    onClick={handleDuplicateProposalButtonWrapper(
                                      proposal
                                    )}
                                  >
                                    <ContentCopyOutlinedIcon htmlColor="text.secondary2" />
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </TabContainer>
            <TabContainer index={activeTabIndex} value={PROPOSAL_TABS.draft}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <SortableHeaderTableCell
                        columnName="name"
                        sortTableBy={sortDraftTableBy}
                        sortDirection={draftSortDirection}
                        sortByColumnWrapper={draftSortByColumnWrapper}
                      >
                        Name
                      </SortableHeaderTableCell>
                      <SortableHeaderTableCell
                        columnName="created"
                        sortTableBy={sortDraftTableBy}
                        sortDirection={draftSortDirection}
                        sortByColumnWrapper={draftSortByColumnWrapper}
                      >
                        Created
                      </SortableHeaderTableCell>
                      <SortableHeaderTableCell
                        columnName="signing_bonus"
                        sortTableBy={sortDraftTableBy}
                        sortDirection={draftSortDirection}
                        sortByColumnWrapper={draftSortByColumnWrapper}
                      >
                        Signing bonus
                      </SortableHeaderTableCell>
                      <HeaderTableCell>
                        {/* duplicate action */}
                      </HeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {draftProposals.length === 0 ? (
                      <NoResultsTableRow columnCount={5}>
                        No draft proposals found
                      </NoResultsTableRow>
                    ) : (
                      draftProposals.map((proposal) => (
                        <TableRow
                          hover
                          key={proposal.id}
                          sx={{ cursor: dialogMode ? "pointer" : "default" }}
                          onClick={handleProposalClickedWrapper(proposal, true)}
                        >
                          <BoldTableCell title={proposal.name}>
                            {trimLongString(proposal.name, 50)}
                          </BoldTableCell>
                          <BoldTableCell>
                            {formatDistance(
                              new Date(proposal.created),
                              new Date(),
                              { addSuffix: true }
                            )}
                          </BoldTableCell>
                          <BoldTableCell>
                            {formatCurrency(proposal.signing_bonus)}
                          </BoldTableCell>
                          <TableCell>
                            <IconButton
                              onClick={handleDuplicateProposalButtonWrapper(
                                proposal
                              )}
                            >
                              <ContentCopyOutlinedIcon htmlColor="text.secondary2" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabContainer>
          </Box>
        </Column>
      )}
      <CreateProposalDialog
        open={isCreateProposalDialogOpen}
        onClose={handleCreateProposalDialogClosed}
        proposalToDuplicate={proposalToDuplicate}
      />
    </Box>
  )
}

function CenteredCreateProposalButton({ onCreateProposal }) {
  return (
    <InboxCenterEverythingBox>
      <Column
        sx={{
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={ProposalDiamondImage}
          alt="Diamond add"
          style={{ width: "96px", height: "96px" }}
        />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Create proposal from template
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          Create and send a new proposal or save as template and send later on
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 2 }}
          onClick={onCreateProposal}
        >
          Create proposal
        </Button>
      </Column>
    </InboxCenterEverythingBox>
  )
}

function MobileStat({ title, value }) {
  return (
    <Column>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="caption">{value || "Unknown"}</Typography>
    </Column>
  )
}

const InboxCenterEverythingBox = styled(CenterEverythingBox)(() => ({
  height: "calc(100vh - 75px)",
}))
