import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { useWizard } from "react-use-wizard"

import Column from "./Column"
import Row from "./Row"

export const SignupWizardProgress = (params) => {
  const { activeStep, stepCount } = useWizard()
  const wizardProgress = activeStep / (stepCount - 1)

  const progressLabels = [
    ["Personal info", -1, 0],
    ["Location", 0.01, 0.5],
    ["Activation", 0.51, 0.99],
  ]

  return (
    <Column sx={{ mt: { xs: 2, sm: 0 } }}>
      <ThickLinearProgress
        value={wizardProgress * 100}
        color="primary"
        variant="determinate"
      />
      <Row
        sx={{
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        {progressLabels.map(
          ([label, percentageActive, percentageDone], index) => {
            const stepActive = percentageActive < wizardProgress
            const stepComplete = percentageDone < wizardProgress
            return (
              <Typography
                variant="h6"
                sx={{
                  color: (theme) =>
                    stepActive || stepComplete
                      ? theme.palette.primary.main
                      : theme.palette.text.disabled,
                  fontSize: { xs: "0.85em", sm: "1em" },
                  fontWeight: stepComplete ? "bold" : "normal",
                  display: "flex",
                  alignItems: "center",
                }}
                key={index}
              >
                <Column
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    width: "2.2em",
                    height: "2.25em",
                  }}
                >
                  {stepComplete ? (
                    <CheckCircleIcon fontSize="large" />
                  ) : (
                    <>
                      <CircleOutlinedIcon
                        fontSize="large"
                        sx={{
                          position: "absolute",
                          top: { xs: -2, sm: 0 },
                          left: { xs: -3, sm: 0 },
                        }}
                      />
                      <Typography variant="h6">{index + 1}</Typography>
                    </>
                  )}
                </Column>
                &nbsp;
                {label}
              </Typography>
            )
          }
        )}
      </Row>
    </Column>
  )
}

const ThickLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "8px",
  },
}))
