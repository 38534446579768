import Box from "@mui/material/Box"
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

export default function PercentageCircularProgress({
  progress,
  fontSize = "1.25rem",
  size,
}) {
  const roundedProfileProgress = Math.round(progress)
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size={size}
        value={100}
        sx={{ color: (theme) => theme.palette.otherwise.border }}
      />
      <CircularProgress
        variant="determinate"
        color="primary"
        size={size}
        value={roundedProfileProgress}
        sx={{
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontSize={fontSize}>
          {roundedProfileProgress}%
        </Typography>
      </Box>
    </Box>
  )
}
