import TuneIcon from "@mui/icons-material/TuneOutlined"
import IconButton from "@mui/material/IconButton"
import { useState } from "react"

import BorderlessSearchTextField, {
  InputProps,
} from "../BorderlessSearchTextField"
import CheckMenu from "../CheckMenu"
import Row from "../Row"

export const CONVERSATION_TYPES = {
  all: "",
  unread: "unread",
  proposals: "proposal",
}

export default function ConversationFilterRow({
  searchQuery,
  setSearchQuery,
  conversationTypeSelectorMenuAnchorElem,
  selectedConversationType,
  handleConversationTypesSelectorButtonClicked,
  handleConversationTypesSelectorMenuClosed,
  handleConversationTypeChanged,
  searchPlaceholder,
}) {
  return (
    <Row
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <BorderlessSearchTextField
        placeholder={searchPlaceholder}
        InputProps={InputProps}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <IconButton onClick={handleConversationTypesSelectorButtonClicked}>
        <TuneIcon />
      </IconButton>
      <CheckMenu
        anchorEl={conversationTypeSelectorMenuAnchorElem}
        onClose={handleConversationTypesSelectorMenuClosed}
        value={selectedConversationType}
        onChange={handleConversationTypeChanged}
        choices={[
          { label: "All messages", value: CONVERSATION_TYPES.all },
          { label: "Unread", value: CONVERSATION_TYPES.unread },
          { label: "Proposals", value: CONVERSATION_TYPES.proposals },
        ]}
      />
    </Row>
  )
}

export function useConversationFiltersState() {
  const [
    conversationTypeSelectorMenuAnchorElem,
    setConversationTypeSelectorMenuAnchorElem,
  ] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedConversationType, setSelectedConversationType] = useState(
    CONVERSATION_TYPES.all
  )

  function handleConversationTypesSelectorButtonClicked(e) {
    setConversationTypeSelectorMenuAnchorElem(e.currentTarget)
  }

  function handleConversationTypesSelectorMenuClosed() {
    setConversationTypeSelectorMenuAnchorElem(null)
  }

  function handleConversationTypeChanged(conversationType) {
    setSelectedConversationType(conversationType)
    handleConversationTypesSelectorMenuClosed()
  }

  return {
    searchQuery,
    setSearchQuery,
    conversationTypeSelectorMenuAnchorElem,
    selectedConversationType,
    handleConversationTypesSelectorButtonClicked,
    handleConversationTypesSelectorMenuClosed,
    handleConversationTypeChanged,
  }
}
