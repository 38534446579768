import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { HaloCircularIcon } from "../../../../common/components/CircularIcon"
import Column from "../../../../common/components/Column"
import LoadingButton from "../../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../../common/utils"

export function HeaderSection({ brokerage }) {
  return (
    <>
      <HaloCircularIcon Icon={FlagOutlinedIcon} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Report an issue
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Please let us know what is wrong with {brokerage.company} listing and we
        will review the issue.
      </Typography>
    </>
  )
}

export function ContentSection({
  brokerage,
  reportReason,
  setReportReason,
  note,
  setNote,
}) {
  return (
    <>
      <Divider sx={{ mt: 4 }} />
      <Column sx={{ mt: 4, pl: 2 }}>
        <RadioGroup
          value={reportReason}
          onChange={(e, newValue) => setReportReason(newValue)}
        >
          <FormControlLabel
            label="I want to claim ownership"
            value={REPORT_REASONS.claim}
            control={<Radio size="small" />}
          />
          <Typography variant="body1" color="text.secondary2" sx={{ ml: 4 }}>
            To claim ownership, you'll go through a verification process and
            upload supporting documents.
          </Typography>
          <Divider sx={{ my: 4 }} />
          <FormControlLabel
            label="False or misleading information"
            value={REPORT_REASONS.falseInfo}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Not the right owner"
            value={REPORT_REASONS.falseOwner}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Brokerage closed or does not exist"
            value={REPORT_REASONS.notExist}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            label="Other"
            value={REPORT_REASONS.other}
            control={<Radio size="small" />}
          />
        </RadioGroup>
        <TextField
          value={note}
          onChange={(e) => setNote(e.target.value)}
          label="Notes"
          placeholder="Explain why you want to report an issue (optional)"
          multiline
          rows={3}
          sx={{ mt: 4 }}
          fullWidth
        />
      </Column>
    </>
  )
}

export function ActionButtons({
  reportReason,
  isReporting,
  onPreviousButtonClicked,
  onReportButtonClicked,
}) {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={onPreviousButtonClicked}
        fullWidth
      >
        Previous
      </Button>
      <LoadingButton
        isLoading={isReporting}
        onClick={onReportButtonClicked}
        fullWidth
      >
        {reportReason === REPORT_REASONS.claim ? "Next" : "Report"}
      </LoadingButton>
    </>
  )
}

export const REPORT_REASONS = {
  claim: "dispute_ownership",
  falseInfo: "fakenews",
  falseOwner: "fakeowner",
  notExist: "not_exist",
  other: "other",
}

export function useCommonReportReasonState() {
  const [reportReason, setReportReason] = useState(REPORT_REASONS.claim)
  const [note, setNote] = useState("")
  return {
    reportReason,
    setReportReason,
    note,
    setNote,
  }
}

export function useReportBrokerage() {
  const [isReporting, setIsReporting] = useState(false)
  const axios = useAuthorizedAxiosClient()

  function reportBrokerage(reason, note, verificationDocument, brokerage) {
    const formData = new FormData()
    formData.append("reason", reason)
    formData.append("note", note)
    if (verificationDocument) {
      formData.append("verification_document", verificationDocument)
    }
    setIsReporting(true)
    return axios
      .post(`/customers/api/report/${brokerage.id}/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => data)
      .finally(() => setIsReporting(false))
  }

  return { isReporting, reportBrokerage }
}
