import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"

export default styled(TextField)(() => ({
  minWidth: "15rem",
  "& fieldset": {
    border: "none!important",
  },
  "& .MuiInput-root:before": { borderBottom: "none" },
  "& .MuiInput-root:after": {
    borderBottom: "none",
    transition: "none",
  },
  "& .MuiInput-root:hover:before": { borderBottom: "0!important" },
  "& .MuiInputBase-root": {
    border: 0,
  },
}))

export const InputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <SearchIcon fontSize="medium" />
    </InputAdornment>
  ),
}
