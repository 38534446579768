import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Collapse from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import FormLabel from "@mui/material/FormLabel"
import InputAdornment from "@mui/material/InputAdornment"
import Snackbar from "@mui/material/Snackbar"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useTheme } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AgentSigningBonusSlider from "../../../common/components/AgentSigningBonusSlider"
import AreasOfFocusEditor from "../../../common/components/AreasOfFocusEditor"
import ChipContainer from "../../../common/components/ChipContainer"
import Column from "../../../common/components/Column"
import DetailedHeader from "../../../common/components/DetailedHeader"
import EditableTextDisplay from "../../../common/components/EditableTextDisplay"
import IconTitleValue from "../../../common/components/IconTitleValue"
import JumpLink from "../../../common/components/JumpLink"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import PerksColumns from "../../../common/components/PerksColumns"
import { handleTextValueChanged } from "../../../common/components/RoundedTextField"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TextExpandCollapse from "../../../common/components/TextExpandCollapse"
import TitledEditorSection, {
  TitledEditorSectionLayout,
} from "../../../common/components/TitledEditorSection"
import UnsavedChangesPreventLeaveDialog from "../../../common/components/UnsavedChangesPreventLeaveDialog"
import UserAvatar from "../../../common/components/UserAvatar"
import DefaultAgentAvatar from "../../../common/resources/icons/agent-default.jpg"
import BlueCheckIcon from "../../../common/resources/icons/blue-check.png"
import TopProducerIcon from "../../../common/resources/icons/top-producer-star.png"
import {
  formatCurrency,
  getFullName,
  useAuthorizedAxiosClient,
  useIsMobile,
  useProfilePropertyChangeTrackerState,
  useStateChangeTracker,
} from "../../../common/utils"
import PerksEditor from "../../brokerage/components/PerksEditor"
import { agentCanEnterSigningBonus } from "../../users/utils"
import {
  profileLoading,
  selectHasFullStats,
  selectProfile,
  selectShowBlueCheck,
  updateProfile,
} from "../slice"
import { AGENT_LEVELS_OF_INTEREST } from "../utils"
import EditProfileHeaderDialog from "./edit-profile-header.dialog"

const profileFieldNameMap = {
  currentPosition: "job_title",
  missionStatement: "mission_statement",
  yearsInBusiness: "years_in_business",
  salesVolumeLastYear: "sales_volume_last_year",
  homesSoldLastYear: "homes_sold_last_year",
  signingBonus: "signing_bonus",
  phone: "phone",
  perks: "perks",
}

export default function Profile() {
  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false)
  const [isEditingAboutMeSection, setIsEditingAboutMeSection] = useState(false)
  const {
    state: currentPosition,
    setState: setCurrentPosition,
    stateChanged: currentPositionChanged,
    handleChangeSaved: handleCurrentPositionSaved,
    mixpanelTrack: mixpanelTrackCurrentPosition,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.currentPosition,
    profileSelector: selectProfile,
    eventName: "Brokerage info updated",
  })
  const {
    state: missionStatement,
    setState: setMissionStatement,
    stateChanged: missionStatementChanged,
    handleChangeSaved: handleMissionStatementSaved,
    mixpanelTrack: mixpanelTrackMissionStatement,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.missionStatement,
    profileSelector: selectProfile,
    eventName: "Bio updated",
  })
  const {
    state: areasOfFocus,
    setState: setAreasOfFocus,
    hasChanged: areasOfFocusChanged,
    handleChangeSaved: handleAreasOfFocusSaved,
  } = useStateChangeTracker([])
  const [isEditingStatsSection, setIsEditingStatsSection] = useState(false)
  const [isSavingStatsSection, setIsSavingStatsSection] = useState(false)
  const {
    state: yearsInBusiness,
    setState: setYearsInBusiness,
    stateChanged: yearsInBusinessChanged,
    handleChangeSaved: handleYearsInBusinessSaved,
    mixpanelTrack: mixpanelTrackYearsInBusiness,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.yearsInBusiness,
    profileSelector: selectProfile,
    eventName: "Years in business updated",
  })
  const {
    state: salesVolumeLastYear,
    setState: setSalesVolumeLastYear,
    stateChanged: salesVolumeLastYearChanged,
    handleChangeSaved: handleSalesVolumeLastYearSaved,
    mixpanelTrack: mixpanelTrackSalesVolumeLastYear,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.salesVolumeLastYear,
    profileSelector: selectProfile,
    eventName: "Production amount updated",
  })
  const {
    state: homesSoldLastYear,
    setState: setHomesSoldLastYear,
    stateChanged: homesSoldLastYearChanged,
    handleChangeSaved: handleHomesSoldLastYearSaved,
    mixpanelTrack: mixpanelTrackHomesSoldLastYear,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.homesSoldLastYear,
    profileSelector: selectProfile,
    eventName: "Sales amount updated",
  })
  const [isEditingSigningBonus, setIsEditingSigningBonus] = useState(false)
  const [isSavingSigningBonusSection, setIsSavingSigningBonusSection] =
    useState(false)
  const {
    state: signingBonus,
    setState: setSigningBonus,
    stateChanged: signingBonusChanged,
    handleChangeSaved: handleSigningBonusSaved,
    mixpanelTrack: mixpanelTrackSigningBonus,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.signingBonus,
    profileSelector: selectProfile,
    eventName: "Signing bonus updated",
  })
  const {
    state: selectedPerks,
    setState: setSelectedPerks,
    stateChanged: selectedPerksChanged,
    handleChangeSaved: handleSelectedPerksSaved,
    mixpanelTrack: mixpanelTrackSelectedPerks,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: [],
    profilePropertyName: profileFieldNameMap.perks,
    profileSelector: selectProfile,
    eventName: "Perks updated",
  })
  const [isEditingPerksSection, setIsEditingPerksSection] = useState(false)
  const [isSavingPerksSection, setIsSavingPerksSection] = useState(false)
  const [isEditingContactInfoSection, setIsEditingContactInfoSection] =
    useState(false)
  const {
    state: phone,
    setState: setPhone,
    stateChanged: phoneChanged,
    handleChangeSaved: handlePhoneSaved,
    mixpanelTrack: mixpanelTrackPhone,
  } = useProfilePropertyChangeTrackerState({
    defaultValue: "",
    profilePropertyName: profileFieldNameMap.phone,
    profileSelector: selectProfile,
    eventName: "Phone number updated",
  })
  const {
    state: email,
    setState: setEmail,
    hasChanged: emailChanged,
    handleChangeSaved: handleEmailSaved,
  } = useStateChangeTracker("")
  const {
    state: location,
    setState: setLocation,
    hasChanged: locationChanged,
    handleChangeSaved: handleLocationSaved,
  } = useStateChangeTracker("")
  const {
    state: website,
    setState: setWebsite,
    hasChanged: websiteChanged,
    handleChangeSaved: handleWebsiteSaved,
  } = useStateChangeTracker("")
  const [isTopProducer, setIsTopProducer] = useState(false)
  const BLUE_CHECK_SNACKBAR_STATUS = {
    earned: 1,
    lost: 2,
  }
  const profileBeforeEditing = useRef(null)
  const [blueCheckSnacbarStatus, setBlueCheckSnackbarStatus] = useState(null)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const showBlueCheck = useSelector(selectShowBlueCheck)
  const hasFullStats = useSelector(selectHasFullStats)

  const STATS_TOOLTIPS = {
    yearsInBusiness: "Your total years in the real estate business",
    salesVolume:
      "The total sum of all real estate transactions you sold in the last 12 months or calendar year. For example, if you sold one $400,000 house and one $300,000 condos last year, you would enter $700,000",
    homesSold:
      "The total number of all real estate transactions you sold in the last 12 months or calendar year. For example, if you sold one condos last year, you would enter 2",
    avgSalePrice:
      "The average value of all real estate transactions you sold in the last 12 months or calendar year. This field is not editable and is completing the other fields.",
  }

  const StatsComponents = [
    <Stat
      title="Total years in business"
      value={profile.years_in_business}
      tooltip={STATS_TOOLTIPS.yearsInBusiness}
    />,
    <Stat
      title="Total sales volume last year"
      value={
        profile.sales_volume_last_year === null
          ? null
          : formatCurrency(profile.sales_volume_last_year)
      }
      tooltip={STATS_TOOLTIPS.salesVolume}
    />,
    <Stat
      title="Number of homes sold last year"
      value={profile.homes_sold_last_year}
      tooltip={STATS_TOOLTIPS.homesSold}
    />,
    <Stat
      title="Average sale price per home"
      value={
        profile.sales_volume_last_year && profile.homes_sold_last_year
          ? formatCurrency(
              profile.sales_volume_last_year / profile.homes_sold_last_year
            )
          : null
      }
      tooltip={STATS_TOOLTIPS.avgSalePrice}
    />,
  ]

  function handleEditProfileButtonClicked() {
    setEditProfileDialogOpen(true)
  }

  function handleEditProfileDialogClosed() {
    setEditProfileDialogOpen(false)
  }

  function handleEditAboutMeSectionButtonClicked(
    editingFinished,
    changesSaved
  ) {
    setIsEditingAboutMeSection(editingFinished)
    if (changesSaved) {
      mixpanelTrackCurrentPosition()
      mixpanelTrackMissionStatement()
      handleCurrentPositionSaved()
      handleMissionStatementSaved()
      handleAreasOfFocusSaved()
    }
  }

  function handleEditStatsSectionButtonClicked() {
    const profileUpdatedPromise = handleEditLayoutSectionButtonClicked(
      isEditingStatsSection,
      setIsEditingStatsSection,
      setIsSavingStatsSection,
      {
        years_in_business: yearsInBusiness,
        sales_volume_last_year: salesVolumeLastYear,
        homes_sold_last_year: homesSoldLastYear,
      }
    )
    if (profileUpdatedPromise) {
      profileUpdatedPromise.then(() => {
        mixpanelTrackYearsInBusiness()
        mixpanelTrackSalesVolumeLastYear()
        mixpanelTrackHomesSoldLastYear()
        handleYearsInBusinessSaved()
        handleSalesVolumeLastYearSaved()
        handleHomesSoldLastYearSaved()
        const oldProfileHasBlueCheck = selectShowBlueCheck({
          agent: { profile: profileBeforeEditing.current },
        })
        const newProfileHasBlueCheck = showBlueCheck
        if (oldProfileHasBlueCheck === newProfileHasBlueCheck) {
          return
        }
        if (oldProfileHasBlueCheck && !newProfileHasBlueCheck) {
          // inverted for some reason
          setBlueCheckSnackbarStatus(BLUE_CHECK_SNACKBAR_STATUS.earned)
        } else {
          setBlueCheckSnackbarStatus(BLUE_CHECK_SNACKBAR_STATUS.lost)
        }
      })
    }
  }

  function handleEditSigningBonusSectionButtonClicked() {
    const profileUpdatedPromise = handleEditLayoutSectionButtonClicked(
      isEditingSigningBonus,
      setIsEditingSigningBonus,
      setIsSavingSigningBonusSection,
      {
        signing_bonus: signingBonus,
      }
    )
    if (profileUpdatedPromise) {
      profileUpdatedPromise.then(() => {
        handleSigningBonusSaved()
        mixpanelTrackSigningBonus()
      })
    }
  }

  function handleEditPerksSectionButtonClicked() {
    const profileUpdatedPromise = handleEditLayoutSectionButtonClicked(
      isEditingPerksSection,
      setIsEditingPerksSection,
      setIsSavingPerksSection,
      {
        perks_update: selectedPerks.map(({ id }) => id),
      }
    )
    if (profileUpdatedPromise) {
      profileUpdatedPromise.then(() => {
        handleSelectedPerksSaved()
        mixpanelTrackSelectedPerks()
      })
    }
  }

  function handleEditLayoutSectionButtonClicked(
    isEditingSection,
    setIsEditingSection,
    setIsSavingSection,
    data
  ) {
    if (isEditingSection) {
      setIsSavingSection(true)
      profileBeforeEditing.current = Object.assign({}, profile)
      return axios
        .put("/agents/api/profile/", data)
        .then(({ data }) => {
          dispatch(updateProfile(data))
          setIsEditingSection(false)
        })
        .finally(() => setIsSavingSection(false))
    } else {
      setIsEditingSection(true)
    }
  }

  function handleEditContactInfoButtonClicked(editingFinished, changesSaved) {
    setIsEditingContactInfoSection(editingFinished)
    mixpanelTrackPhone()
    if (changesSaved) {
      handlePhoneSaved()
      handleEmailSaved()
      handleLocationSaved()
      handleWebsiteSaved()
    }
  }

  function handleBlueCheckSnackbarClosed() {
    setBlueCheckSnackbarStatus(null)
  }

  useEffect(
    () => {
      if (!isProfileLoading) {
        function profileStatValue(fieldName) {
          const value = profile[fieldName]
          return value === null ? "" : value
        }
        setCurrentPosition(
          profile[profileFieldNameMap.currentPosition] || "",
          false
        )
        setMissionStatement(
          profile[profileFieldNameMap.missionStatement] || "",
          false
        )
        setAreasOfFocus(profile.areas_of_focus, false)
        setSelectedPerks(profile.perks, false)
        setYearsInBusiness(
          profileStatValue(profileFieldNameMap.yearsInBusiness),
          false
        )
        setSalesVolumeLastYear(
          profileStatValue(profileFieldNameMap.salesVolumeLastYear),
          false
        )
        setHomesSoldLastYear(
          profileStatValue(profileFieldNameMap.homesSoldLastYear),
          false
        )
        setSigningBonus(
          profileStatValue(profileFieldNameMap.signingBonus),
          false
        )
        setPhone(profile[profileFieldNameMap.phone] || "", false)
        setEmail(profile.email || "", false)
        setLocation(profile.address || "", false)
        setWebsite(profile.website || "", false)
        profileBeforeEditing.current = Object.assign({}, profile)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, isProfileLoading]
  )

  useEffect(
    () => {
      if (!profile[profileFieldNameMap.salesVolumeLastYear]) {
        return
      }
      axios
        .get("/agents/api/top-producer/")
        .then(({ data }) => setIsTopProducer(data.is_top_producer))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile[profileFieldNameMap.salesVolumeLastYear]]
  )

  return (
    <Column
      sx={{
        py: 4,
        px: { xs: 2, sm: 8 },
      }}
    >
      <RowButColumnOnMobile
        sx={{
          justifyContent: "space-between",
        }}
      >
        <RowButColumnOnMobile
          sx={{
            alignItems: "center",
          }}
        >
          <UserAvatar
            isProfileLoading={isProfileLoading}
            profileData={profile}
            size={112}
            avatarSX={{
              boxShadow: profile.profile_image
                ? undefined
                : theme.palette.otherwise.highlightShadow,
            }}
            defaultAvatar={DefaultAgentAvatar}
          />
          <Column
            sx={{
              ml: { xs: 0, sm: 2 },
              mt: { xs: 2, sm: 0 },
            }}
          >
            <Row sx={{ alignItems: "center" }}>
              <Typography variant="h5" fontSize="2rem" fontWeight={500}>
                {getFullName(profile)}
              </Typography>
              {showBlueCheck && (
                <img
                  src={BlueCheckIcon}
                  style={{ width: "32px", height: "32px", marginLeft: "16px" }}
                  alt="blue check"
                />
              )}
            </Row>
            <RowButColumnOnMobile
              sx={{
                alignItems: "center",
                mt: 1,
                "& .MuiBox-root:nth-of-type(n + 2)": {
                  ml: { xs: 0, sm: 2 },
                  mt: { xs: 1, sm: 0 },
                },
              }}
            >
              <IconValueHeaderRow
                icon={
                  <TravelExploreOutlinedIcon
                    sx={{ width: "16px", color: "text.secondary2" }}
                  />
                }
                value={AGENT_LEVELS_OF_INTEREST[profile.level_of_interest]}
                missingValue="What's your level of interest?"
              />
            </RowButColumnOnMobile>
          </Column>
        </RowButColumnOnMobile>
        <Row
          sx={{
            alignItems: "center",
            mt: { xs: 4, sm: 0 },
          }}
        >
          <Button
            variant="outlined"
            startIcon={<EditOutlinedIcon />}
            onClick={handleEditProfileButtonClicked}
            fullWidth={isMobile}
          >
            Edit
          </Button>
        </Row>
      </RowButColumnOnMobile>
      <TitledEditorSection
        title="About me"
        editButtonClicked={isEditingAboutMeSection}
        onEditChanged={handleEditAboutMeSectionButtonClicked}
        valueProps={{
          job_title: currentPosition,
          mission_statement: missionStatement,
          areas_of_focus_update: areasOfFocus.map(({ id }) => id),
        }}
        apiURL="/agents/api/profile/"
        successCallback={(data) => dispatch(updateProfile(data))}
      >
        <Box
          sx={{
            mt: 2,
          }}
        >
          <EditableTextDisplay
            editMode={isEditingAboutMeSection}
            Icon={PersonOutlineIcon}
            textFieldProps={{
              label: "Current brokerage",
              value: currentPosition,
              onChange: (e) => handleTextValueChanged(e, setCurrentPosition),
            }}
            defaultValue="The brokerage you're currently with"
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          {isEditingAboutMeSection ? (
            <Column>
              <LengthLimitedTextField
                multiline
                value={missionStatement}
                setValue={setMissionStatement}
                label="Biography"
                maxLength={300}
              />
            </Column>
          ) : (
            <TextExpandCollapse
              text={profile.mission_statement}
              defaultText="Share some words about your experience."
            />
          )}
        </Box>
        <Column
          sx={{
            mt: 4,
          }}
        >
          <Typography
            variant={isEditingAboutMeSection ? "caption" : "body1"}
            color={isEditingAboutMeSection ? "inherit" : "text.secondary2"}
          >
            {isEditingAboutMeSection
              ? "Add your areas of focus"
              : "Areas of focus"}
          </Typography>
          <Column sx={{ mt: 1 }}>
            {isEditingAboutMeSection ? (
              <AreasOfFocusEditor
                areasOfFocus={areasOfFocus}
                setAreasOfFocus={setAreasOfFocus}
              />
            ) : areasOfFocus.length === 0 ? (
              <Typography variant="body1">
                No areas of focus added yet
              </Typography>
            ) : (
              <ChipContainer>
                {areasOfFocus.map((areaOfFocus) => (
                  <Chip key={areaOfFocus.id} label={areaOfFocus.name} />
                ))}
              </ChipContainer>
            )}
          </Column>
        </Column>
      </TitledEditorSection>
      <TitledEditorSectionLayout
        title="My statistics"
        subtitle="Complete this data to access powerful proposal insights and receive better offers"
        isEditingSection={isEditingStatsSection}
        onClick={handleEditStatsSectionButtonClicked}
        isLoading={isSavingStatsSection}
        highlight={!hasFullStats}
      >
        <Box sx={{ mt: 4 }}>
          {isEditingStatsSection ? (
            <Grid container spacing={2}>
              <Grid xs={6}>
                <TextField
                  label={
                    <TextFieldInfoLabel
                      label="Total years in business"
                      tooltip={STATS_TOOLTIPS.yearsInBusiness}
                    />
                  }
                  fullWidth
                  type="number"
                  value={yearsInBusiness}
                  onChange={(e) => setYearsInBusiness(e.target.value)}
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  label={
                    <TextFieldInfoLabel
                      label="Total sales volume last year"
                      tooltip={STATS_TOOLTIPS.salesVolume}
                    />
                  }
                  fullWidth
                  type="number"
                  value={salesVolumeLastYear}
                  onChange={(e) => setSalesVolumeLastYear(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  label={
                    <TextFieldInfoLabel
                      label="Number of homes sold last year"
                      tooltip={STATS_TOOLTIPS.homesSold}
                    />
                  }
                  fullWidth
                  type="number"
                  value={homesSoldLastYear}
                  onChange={(e) => setHomesSoldLastYear(e.target.value)}
                />
              </Grid>
            </Grid>
          ) : (
            <Column>
              {isMobile ? (
                <Grid container spacing={2}>
                  {StatsComponents.map((StatsComponent, index) => (
                    <Grid key={index} xs={6}>
                      {StatsComponent}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  {StatsComponents.map((StatsComponent, index) => (
                    <Grid md={3} key={index}>
                      <Row
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        {index > 0 && <StatDivider />}
                        {StatsComponent}
                      </Row>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Collapse in={isTopProducer}>
                <Column>
                  <Divider sx={{ my: 4 }} />
                  <IconTitleValue
                    Icon={() => (
                      <img
                        src={TopProducerIcon}
                        style={{ width: "24px", height: "24px" }}
                        alt="yellow star"
                      />
                    )}
                    title="Top producer"
                    value="Your production is higher than the market average"
                  />
                </Column>
              </Collapse>
            </Column>
          )}
        </Box>
      </TitledEditorSectionLayout>
      <Collapse
        in={agentCanEnterSigningBonus(
          profile[profileFieldNameMap.yearsInBusiness],
          profile[profileFieldNameMap.salesVolumeLastYear]
        )}
      >
        <TitledEditorSectionLayout
          title="Signing Bonus"
          subtitle="Brokerages get this information while browsing through available agents"
          isEditingSection={isEditingSigningBonus}
          onClick={handleEditSigningBonusSectionButtonClicked}
          isLoading={isSavingSigningBonusSection}
        >
          <Row sx={{ justifyContent: "center", mt: 6 }}>
            <Column sx={{ width: { xs: "280px", sm: "500px" } }}>
              <AgentSigningBonusSlider
                signingBonus={signingBonus}
                setSigningBonus={setSigningBonus}
                disabled={!isEditingSigningBonus}
              />
            </Column>
          </Row>
        </TitledEditorSectionLayout>
      </Collapse>
      <TitledEditorSectionLayout
        title={
          isEditingPerksSection ? (
            <DetailedHeader
              title="Looking for"
              subtitle={`${selectedPerks.length}/8`}
            />
          ) : (
            "Looking for"
          )
        }
        isEditingSection={isEditingPerksSection}
        isLoading={isSavingPerksSection}
        onClick={handleEditPerksSectionButtonClicked}
      >
        <Column sx={{ mt: 4 }}>
          {isEditingPerksSection ? (
            <PerksEditor
              selectedPerks={selectedPerks}
              setSelectedPerks={setSelectedPerks}
            />
          ) : (
            <PerksColumns perks={selectedPerks} />
          )}
        </Column>
      </TitledEditorSectionLayout>
      <TitledEditorSection
        title="Contact info"
        valueProps={{ phone, email, address: location, website }}
        editButtonClicked={isEditingContactInfoSection}
        onEditChanged={handleEditContactInfoButtonClicked}
        apiURL="/agents/api/profile/"
        successCallback={(data) => dispatch(updateProfile(data))}
      >
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Grid container spacing={4}>
            <Grid xs={12} sm={6}>
              <PhoneNumberEditor
                editMode={isEditingContactInfoSection}
                phone={phone}
                setPhone={setPhone}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EmailEditor
                editMode={isEditingContactInfoSection}
                email={email}
                setEmail={setEmail}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <LocationEditor
                editMode={isEditingContactInfoSection}
                location={location}
                setLocation={setLocation}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <WebsiteEditor
                editMode={isEditingContactInfoSection}
                website={website}
                setWebsite={setWebsite}
              />
            </Grid>
          </Grid>
        </Box>
      </TitledEditorSection>

      <EditProfileHeaderDialog
        open={editProfileDialogOpen}
        onClose={handleEditProfileDialogClosed}
      />
      <Snackbar
        open={blueCheckSnacbarStatus !== null}
        autoHideDuration={5000}
        onClose={handleBlueCheckSnackbarClosed}
      >
        <Alert
          onClose={handleBlueCheckSnackbarClosed}
          severity={
            blueCheckSnacbarStatus === BLUE_CHECK_SNACKBAR_STATUS.earned
              ? "success"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          {blueCheckSnacbarStatus === BLUE_CHECK_SNACKBAR_STATUS.earned
            ? "You earned Blue Check!"
            : "You lost your Blue Check!"}
        </Alert>
      </Snackbar>
      <UnsavedChangesPreventLeaveDialog
        dataHasChanged={
          currentPositionChanged ||
          missionStatementChanged ||
          areasOfFocusChanged ||
          yearsInBusinessChanged ||
          salesVolumeLastYearChanged ||
          homesSoldLastYearChanged ||
          signingBonusChanged ||
          selectedPerksChanged ||
          phoneChanged ||
          emailChanged ||
          locationChanged ||
          websiteChanged
        }
      />
    </Column>
  )
}

function Stat({ title, value, tooltip }) {
  return (
    <Column>
      <Typography variant="h6">{value === null ? "-" : value}</Typography>
      <Tooltip placement="bottom-start" title={tooltip}>
        <Typography variant="subtitle1" sx={{ maxWidth: "10em" }}>
          {title}
        </Typography>
      </Tooltip>
    </Column>
  )
}

function StatDivider() {
  return <Divider orientation="vertical" sx={{ height: "48px", mr: 2 }} />
}

function IconValueHeaderRow({ icon, value, missingValue }) {
  return (
    <Row
      sx={{
        alignItems: "center",
      }}
    >
      {icon}
      <Typography variant="subtitle1" sx={{ ml: 1 }}>
        {value || missingValue}
      </Typography>
    </Row>
  )
}

function PhoneNumberEditor({ editMode, phone, setPhone }) {
  return (
    <EditableTextDisplay
      editMode={editMode}
      Icon={PhoneIcon}
      textFieldProps={{
        label: "Phone Number",
        value: phone,
        type: "tel",
        onChange: (e) => handleTextValueChanged(e, setPhone),
      }}
      defaultValue="Phone number here."
    />
  )
}

function EmailEditor({ editMode, email, setEmail }) {
  return (
    <EditableTextDisplay
      editMode={editMode}
      Icon={EmailIcon}
      textFieldProps={{
        label: "Email",
        value: email,
        type: "email",
        onChange: (e) => handleTextValueChanged(e, setEmail),
      }}
      defaultValue="Email here."
    />
  )
}

function LocationEditor({ editMode, location, setLocation }) {
  return (
    <EditableTextDisplay
      editMode={editMode}
      Icon={LocationOnOutlinedIcon}
      textFieldProps={{
        label: "Location",
        value: location,
        onChange: (e) => handleTextValueChanged(e, setLocation),
      }}
      typographyProps={{}}
      defaultValue="Address here."
    />
  )
}

function WebsiteEditor({ editMode, website, setWebsite }) {
  return (
    <EditableTextDisplay
      editMode={editMode}
      Icon={LanguageOutlinedIcon}
      textFieldProps={{
        label: "Website",
        value: website,
        type: "url",
        onChange: (e) => handleTextValueChanged(e, setWebsite),
      }}
      typographyProps={
        website && {
          text: <JumpLink title={website} url={website} />,
        }
      }
      defaultValue="Website here."
    />
  )
}

function TextFieldInfoLabel({ label, tooltip }) {
  return (
    <Row sx={{ alignItems: "center" }}>
      <FormLabel>{label}</FormLabel>
      <Tooltip title={tooltip}>
        <InfoOutlinedIcon fontSize="small" />
      </Tooltip>
    </Row>
  )
}
