import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useContext, useState } from "react"

import Column from "../../../../common/components/Column"
import { useIsMobile } from "../../../../common/utils"
import DescribeDialog from "../describe/Dialog"
import { NewBrokerageContext } from "../utils"
import { ActionButtons, ContentSection, HeaderSection } from "./Common"

export default function PerksDialog({
  open,
  onClose,
  onPreviousButtonClicked,
  onReopen,
}) {
  const { selectedPerks, setSelectedPerks } = useContext(NewBrokerageContext)
  const [isDescribeDialogOpen, setIsDescribeDialogOpen] = useState(false)
  const isMobile = useIsMobile()

  function handleNextButtonClicked() {
    setIsDescribeDialogOpen(true)
    onClose()
  }

  function handleDescribeDialogPreviousButtonClicked() {
    setIsDescribeDialogOpen(false)
    onReopen()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      keepMounted
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <HeaderSection />
        </Column>
        <ContentSection
          selectedPerks={selectedPerks}
          setSelectedPerks={setSelectedPerks}
        />
      </DialogContent>
      <DialogActions>
        <ActionButtons
          perks={selectedPerks}
          onPreviousButtonClicked={onPreviousButtonClicked}
          onNextButtonClicked={handleNextButtonClicked}
        />
        <DescribeDialog
          open={isDescribeDialogOpen}
          onClose={() => setIsDescribeDialogOpen(false)}
          onPreviousButtonClicked={handleDescribeDialogPreviousButtonClicked}
          onReopen={() => setIsDescribeDialogOpen(true)}
        />
      </DialogActions>
    </Dialog>
  )
}
