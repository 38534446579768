import { alpha } from "@mui/material/styles"
import { format as dateFormat } from "date-fns"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useUpdateTourStatus } from "../../common/components/CommonTourProvider"
import { useAuthorizedAxiosClient } from "../../common/utils"
import { arrayContains } from "../../common/utils"
import {
  getAnonymousName,
  trackSignupStep,
  useIsSigningUp,
} from "../users/utils"
import {
  selectIsFreeRiding,
  showProDialogForCurrentBrokerage,
  updateProfile,
} from "./slice"

export const AGENT_FIELD_HIDDEN_MARKER = "__hidden_value__"

export function getAgentName(agent) {
  return agent.first_name === AGENT_FIELD_HIDDEN_MARKER
    ? getAnonymousName(agent)
    : agent.first_name
}

export function useCandidateStatsLoader() {
  const [stats, setStats] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const axios = useAuthorizedAxiosClient()

  useEffect(
    () => {
      setIsLoading(true)
      axios
        .get("/customers/api/candidates/stats/")
        .then(({ data }) => setStats(data))
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return { stats, isLoading }
}

export function useStartAgentConversation() {
  const [isCreatingConversation, setIsCreatingConversation] = useState(false)

  const navigate = useNavigate()
  const axios = useAuthorizedAxiosClient()

  function requestStartConversation(agentRequest) {
    setIsCreatingConversation(true)
    return axios
      .get(`/customers/api/conversations/${agentRequest.id}/start/`)
      .then(({ data }) => {
        navigate(`/brokerage/conversations?cid=${data.id}`)
        return data
      })
      .finally(() => setIsCreatingConversation(false))
  }
  return { isCreatingConversation, requestStartConversation }
}

export function useSendProposalToConversation() {
  const axios = useAuthorizedAxiosClient()
  return (proposalID, conversationID, message) => {
    return axios.post(
      `/customers/api/conversations/${conversationID}/send-proposal/${proposalID}/`,
      { message }
    )
  }
}

export const PLAN_TYPES = {
  monthly: "monthly",
  annual: "annual",
}

export const PLAN_DATA = {
  [PLAN_TYPES.monthly]: {
    code: PLAN_TYPES.monthly,
    price: 399,
    discountedPrice: 299,
    period: "mo",
    display: "Monthly",
  },
  [PLAN_TYPES.annual]: {
    code: PLAN_TYPES.annual,
    price: 3999,
    discountedPrice: 2999,
    period: "yr",
    display: "Annual",
  },
}

export const PAYMENT_INTENT_STATUSES = {
  requiresPaymentMethod: "requires_payment_method",
  requiresConfirmation: "requires_confirmation",
  requiresAction: "requires_action",
  processing: "processing",
  requiresCapture: "requires_capture",
  cancelled: "canceled",
  succeeded: "succeeded",
}

export const SUBSCRIPTION_STATUSES = {
  incomplete: "incomplete",
  incompleteExpired: "incomplete_expired",
  trial: "trialing",
  active: "active",
  pastDue: "past_due",
  cancelled: "canceled",
  unpaid: "unpaid",
}

export function formatDate(dateStr) {
  return dateFormat(new Date(dateStr), "LLLL d, yyyy")
}

export function getTogglePlanData(subscription) {
  const otherPlan =
    subscription.plan === PLAN_TYPES.monthly
      ? PLAN_TYPES.annual
      : PLAN_TYPES.monthly
  return PLAN_DATA[otherPlan]
}

export const LIGHT_WHITE = alpha("#ffffff", 0.2)

export function useIsBrokerageSubscribed() {
  const isFreeRiding = useSelector(selectIsFreeRiding)

  function isBrokerageSubscribed(brokerage) {
    const subscription = brokerage?.subscription

    return (
      isFreeRiding ||
      (subscription &&
        subscription.status &&
        !arrayContains(
          [
            SUBSCRIPTION_STATUSES.incomplete,
            SUBSCRIPTION_STATUSES.incompleteExpired,
          ],
          subscription.status
        ))
    )
  }

  return {
    isBrokerageSubscribed,
  }
}

export function useGoSubscribe(/* which button */ source) {
  const dispatch = useDispatch()

  return () => {
    dispatch(showProDialogForCurrentBrokerage(source))
  }
}

export const NOTIFICATION_TYPES = {
  proposalAccepted: "customer.proposal_accepted",
}

export function getEditBrokerageURL(brokerageID) {
  return `/customers/api/${brokerageID}/edit/`
}

export const TOUR_STEPS = {
  dashboard: 0,
  candidateCards1: 1,
  candidateCards2: 2,
  buildBrokeragePage: 8,
}

export function useUpdateBrokerageTourStatus() {
  return useUpdateTourStatus(
    "/customers/api/profile/",
    "brokerage_tour_status",
    updateProfile
  )
}

export function useTrackSignupWizards(stepIndex, stepTitle) {
  const isSigningUp = useIsSigningUp()
  useEffect(
    () => {
      if (isSigningUp) {
        trackSignupStep("broker", stepIndex, stepTitle)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSigningUp]
  )
}

export function useBrokerageProfileCompleteness() {
  const [profileCompleteness, setProfileCompleteness] = useState({
    nameComplete: true,
    logoComplete: true,
    descriptionComplete: true,
    mediaComplete: true,
  })

  function calculateProfileCompleteness(brokerage) {
    const nameComplete = !!brokerage.company
    const logoComplete = !!brokerage.image
    const descriptionComplete = !!(
      brokerage.head_count &&
      brokerage.year_founded &&
      brokerage.description
    )
    const mediaComplete =
      brokerage.images?.length > 0 || brokerage.videos.length > 0
    setProfileCompleteness({
      nameComplete,
      logoComplete,
      descriptionComplete,
      mediaComplete,
    })
  }
  return {
    profileCompleteness,
    calculateProfileCompleteness,
  }
}

export const PRODUCT_TYPES = {
  highrise: "HighRise",
  campaigns: "Campaigns",
}

export const FREE_PROSPECTS = 20

export function getCandidateProfileURL(candidate) {
  return `/brokerage/candidates?arid=${candidate.id}`
}
