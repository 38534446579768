import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit"

import agentReducer, {
  selectIsProfileComplete,
  updateProfile,
} from "./pages/agent/slice"
import { resetProfile as resetAgentProfile } from "./pages/agent/slice"
import {
  NOTIFICATION_TYPES as AGENT_NOTIFICATION_TYPES,
  COMPLETE_PROFILE_NOTIFICATION,
} from "./pages/agent/utils"
import brokerageReducer from "./pages/brokerage/slice"
import notificationsReducer, {
  addNotification,
  clearNotifications,
  removeNotification,
} from "./pages/notifications/slice"
import userReducer, { logoutUser } from "./pages/users/slice"

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: updateProfile,
  effect: (action, listenerApi) => {
    if (selectIsProfileComplete(listenerApi.getState())) {
      listenerApi.dispatch(
        removeNotification(AGENT_NOTIFICATION_TYPES.completeProfile)
      )
    } else {
      listenerApi.dispatch(addNotification(COMPLETE_PROFILE_NOTIFICATION))
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: logoutUser.fulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(resetAgentProfile())
    listenerApi.dispatch(clearNotifications())
  },
})

export const store = configureStore({
  reducer: {
    user: userReducer,
    agent: agentReducer,
    brokerage: brokerageReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})
