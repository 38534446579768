import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

export default function CircularProgressBackdrop({ open, backdropSX }) {
  return (
    <Backdrop open={open} sx={backdropSX}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
