import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Column from "./Column"
import Row from "./Row"

export default function EditableTextDisplay({
  editMode,
  Icon,
  textFieldProps,
  typographyProps,
  defaultValue,
}) {
  const label = textFieldProps.label
  const value = textFieldProps.value
  const displayValue = (typographyProps && typographyProps.text) || value
  return (
    <Column>
      {editMode ? (
        <TextField fullWidth size="medium" {...textFieldProps} />
      ) : (
        <Row
          sx={{
            alignItems: "center",
          }}
        >
          <Icon htmlColor="inherit" />
          <Column
            sx={{
              ml: 2,
            }}
          >
            <Typography variant="subtitle1">{label}</Typography>
            <Typography
              variant="body1"
              color={displayValue ? "inherit" : "text.secondary2"}
              fontStyle={displayValue ? "normal" : "italic"}
            >
              {displayValue || defaultValue}
            </Typography>
          </Column>
        </Row>
      )}
    </Column>
  )
}
