import SMSOutlinedIcon from "@mui/icons-material/SmsOutlined"
import Button from "@mui/material/Button"
import { Wizard, useWizard } from "react-use-wizard"

import { axiosClient } from "../../../common/utils"
import ClaimOfficeWizard from "../../brokerage/claim-office"
import {
  PersonalInfoStep,
  StepLayout,
  useCommonBrokerageSignupState,
} from "./common"

export default function AdminBrokerageSignup() {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    emailError,
    phone,
    setPhone,
    phoneError,
    validateSignupData,
    isValidatingData,
    setBrokerageDetails,
    getSignupData,
  } = useCommonBrokerageSignupState()

  function handleWizardRestarted() {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setBrokerageDetails(null)
  }

  return (
    <Wizard>
      <PersonalInfoStep
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        handlePhoneChanged={setPhone}
        phoneError={phoneError}
        email={email}
        handleEmailChanged={setEmail}
        emailError={emailError}
        validateSignupData={validateSignupData}
        isValidatingData={isValidatingData}
      />
      <ClaimOfficeWizardWrapper
        firstName={firstName}
        lastName={lastName}
        phone={phone}
        email={email}
        getSignupData={getSignupData}
      />
      <SetPasswordSMSLinkSentStep onWizardRestarted={handleWizardRestarted} />
    </Wizard>
  )
}

function ClaimOfficeWizardWrapper({
  firstName,
  lastName,
  phone,
  email,
  setIsSubmitting,
  getSignupData,
}) {
  const { previousStep, nextStep } = useWizard()

  function handleBrokerageSelected(brokerageDetails) {
    setIsSubmitting(true)
    axiosClient
      .post("/customers/api/admin-signup/", getSignupData())
      .then(() => {
        nextStep()
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <ClaimOfficeWizard
      onAnonymousPreviousButtonClicked={() => previousStep()}
      onBrokerageSelected={handleBrokerageSelected}
    />
  )
}

function SetPasswordSMSLinkSentStep({ onWizardRestarted }) {
  const { goToStep } = useWizard()

  function handleRestartButtonClicked() {
    goToStep(0)
    onWizardRestarted()
  }

  return (
    <StepLayout
      Icon={SMSOutlinedIcon}
      title={`We've sent them an activation link via SMS`}
      subtitle="They should click the link to continue signing up and setting up a password."
    >
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleRestartButtonClicked}
        sx={{ mt: { xs: 4, sm: 8 } }}
      >
        Restart wizard
      </Button>
    </StepLayout>
  )
}
