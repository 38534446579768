import Typography from "@mui/material/Typography"

import { IconTitleValueLayout } from "../../../common/components/IconTitleValue"
import { AGENT_FIELD_HIDDEN_MARKER } from "../utils"

export default function BlurMissingIconTitleValue({
  Icon,
  title,
  value,
  missingPlaceholder,
}) {
  return (
    <IconTitleValueLayout Icon={Icon} title={title}>
      <Typography
        variant="body1"
        maxWidth={"20em"}
        sx={{
          filter: (theme) =>
            value === AGENT_FIELD_HIDDEN_MARKER && theme.units.textBlur,
        }}
      >
        {value === AGENT_FIELD_HIDDEN_MARKER ? missingPlaceholder : value}
      </Typography>
    </IconTitleValueLayout>
  )
}
