import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"

import {
  BasicAvatarEditorLayout,
  useBasicAvatarEditorLayoutState,
} from "../../../../common/components/AvatarEditorLayout"
import LoadingButton from "../../../../common/components/LoadingButton"
import Row from "../../../../common/components/Row"
import BrokerageIcon from "../../../../common/resources/icons/brokerage-gray.png"
import AddressAutocomplete, {
  googleServices,
  useAddressAutocompleteState,
} from "../../components/AddressAutocomplete"
import ZipcodeTextField, {
  useZipcodeFieldState,
} from "../../components/ZipcodeTextField"

export function HeaderSection({
  imageName,
  onImageNameChanged,
  imageData,
  onImageDataChanged,
}) {
  const theme = useTheme()

  return (
    <>
      <Typography variant="h4">
        What is your brokerage name and address?
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        Provide the name, logo and address that you would like agents to see
        when searching your public profile.
      </Typography>
      <Row sx={{ mt: 4 }}>
        <BasicAvatarEditorLayout
          defaultAvatar={BrokerageIcon}
          defaultAvatarPadding="20px"
          size={96}
          imageFilename={imageName}
          onImageFilenameChanged={onImageNameChanged}
          imageData={imageData}
          onImageDataChanged={onImageDataChanged}
          avatarSX={{
            backgroundColor: theme.palette.otherwise.lightBackground,
          }}
        />
      </Row>
    </>
  )
}

export function ContentSection({
  company,
  setCompany,
  zipcode,
  setZipcode,
  zipcodeError,
  setZipcodeError,
  debouncedSearchQuery,
  address,
  setAddress,
  addressInput,
  setAddressInput,
  addressOptions,
  setAddressOptions,
}) {
  return (
    <>
      <TextField
        label="Name"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        fullWidth
        sx={{ mt: 4 }}
      />
      <ZipcodeTextField
        zipcode={zipcode}
        setZipcode={setZipcode}
        zipcodeError={zipcodeError}
        setZipcodeError={setZipcodeError}
        sx={{
          mt: 4,
        }}
      />
      <AddressAutocomplete
        address={address}
        setAddress={setAddress}
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        addressOptions={addressOptions}
        setAddressOptions={setAddressOptions}
        debouncedSearchQuery={debouncedSearchQuery}
      />
    </>
  )
}

export function ActionButtons({
  imageData,
  company,
  address,
  zipcodeError,
  isQueryingPlaceDetails,
  setIsQueryingPlaceDetails,
  setGeoLocation,
  onPreviousButtonClicked,
  onGoogleQueryFinished,
}) {
  function handleNextButtonClicked() {
    setIsQueryingPlaceDetails(true)
    googleServices.placeDetails.getDetails(
      { placeId: address.place_id, fields: ["geometry"] },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setGeoLocation(place.geometry.location.toJSON())
          onGoogleQueryFinished()
        }
        setIsQueryingPlaceDetails(false)
      }
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onPreviousButtonClicked()}
        fullWidth
      >
        Back
      </Button>
      <LoadingButton
        disabled={
          !company || !address || !googleServices.placeDetails || zipcodeError
        }
        isLoading={isQueryingPlaceDetails}
        onClick={handleNextButtonClicked}
        fullWidth
      >
        Next
      </LoadingButton>
    </>
  )
}

export function useCommonBasicInfoState() {
  const { imageName, onImageNameChanged, imageData, onImageDataChanged } =
    useBasicAvatarEditorLayoutState()
  const [company, setCompany] = useState("")
  const {
    address,
    setAddress,
    addressInput,
    setAddressInput,
    addressOptions,
    setAddressOptions,
    debouncedSearchQuery,
  } = useAddressAutocompleteState()
  const [geoLocation, setGeoLocation] = useState(null)
  const [isQueryingPlaceDetails, setIsQueryingPlaceDetails] = useState(false)
  const [images, setImages] = useState([])
  const [isMediaDialogOpen, setIsMediaDialogOpen] = useState(false)
  const [selectedPerks, setSelectedPerks] = useState([])
  const [yearFounded, setYearFounded] = useState("")
  const [description, setDescription] = useState("")
  const [website, setWebsite] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const { zipcode, setZipcode, zipcodeError, setZipcodeError } =
    useZipcodeFieldState()

  return {
    company,
    setCompany,
    address,
    setAddress,
    addressInput,
    setAddressInput,
    debouncedSearchQuery,
    addressOptions,
    setAddressOptions,
    geoLocation,
    setGeoLocation,
    isQueryingPlaceDetails,
    setIsQueryingPlaceDetails,
    images,
    setImages,
    isMediaDialogOpen,
    setIsMediaDialogOpen,
    selectedPerks,
    setSelectedPerks,
    yearFounded,
    setYearFounded,
    description,
    setDescription,
    website,
    setWebsite,
    email,
    setEmail,
    phone,
    setPhone,
    zipcode,
    setZipcode,
    zipcodeError,
    setZipcodeError,
    imageName,
    onImageNameChanged,
    imageData,
    onImageDataChanged,
  }
}
