import { useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"

import {
  addCircleLayerToMap,
  addImageLayerToMap,
  addPinIconToMap,
  addZipcodeCircleOutlineToMap,
  addZipcodeCircleToMap,
  createAgentHomeLocationSource,
  fitMapToCircle,
  removeImage,
  removeLayer,
  removeSource,
  useAddBrokerageMarkersToMap,
  useMapLoader,
} from "../map-utils"
import HomeIcon from "../resources/icons/home.png"
import { useCenterMap } from "./CenterMapContainer"
import "./map-styles.css"

export default function Map({
  mapContainer,
  brokerages,
  isBrokeragesLoading,
  mapCenter,
  distanceFromHome,
  distanceUnits = "mi",
  usePinIconForAgentHome,
  zoomOut,
  onMapMovementFinished,
  parentCenterMap,
  onMapCentered,
}) {
  const { map, mapLoaded } = useMapLoader(mapContainer)
  const [internalMapCenter, setInternalMapCenter] = useState(null)
  const theme = useTheme()

  function centerMap() {
    fitMapToCircle(map, mapCenter, distanceFromHome, distanceUnits, zoomOut)
  }

  useEffect(() => {
    if (!mapLoaded) {
      return
    }
    map.current.on("moveend", (event) => {
      if (typeof onMapMovementFinished === "function") {
        setInternalMapCenter(map.current.getCenter())
      }
    })
  })

  useEffect(
    () => {
      if (typeof onMapMovementFinished === "function") {
        onMapMovementFinished(internalMapCenter)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalMapCenter]
  )

  useAddBrokerageMarkersToMap(map, mapLoaded, brokerages, isBrokeragesLoading)

  useEffect(() => {
    if (!mapLoaded || isBrokeragesLoading) return
    const LAYERS = {
      brokerageLocations: "brokerage-locations",
      pinIcon: "pin-icon",
      homeIcon: "home-icon",
      agentLocationPoint: "agent-location-point",
      agentZipcodeAreaCircle: "agent-zipcode-area-circle",
      agentZipcodeAreaCircleOutline: "travel-distance-circle-outline",
    }

    const IMAGES = {
      homeIcon: "home-icon",
      pinIcon: "pin-icon",
    }

    if (mapCenter) {
      const agentZipcodeLocationSource = createAgentHomeLocationSource(
        mapCenter.coordinates,
        theme
      )
      if (usePinIconForAgentHome) {
        addPinIconToMap(
          map,
          mapCenter.coordinates,
          theme,
          LAYERS.agentLocationPoint,
          IMAGES.pinIcon,
          LAYERS.pinIcon
        )
      } else {
        addCircleLayerToMap(
          map,
          LAYERS.agentLocationPoint,
          agentZipcodeLocationSource
        )
      }

      addImageLayerToMap(
        map,
        HomeIcon,
        IMAGES.homeIcon,
        LAYERS.agentLocationPoint,
        LAYERS.homeIcon,
        1.1
      )

      addZipcodeCircleToMap(
        map,
        mapCenter,
        distanceFromHome,
        distanceUnits,
        LAYERS.agentZipcodeAreaCircle,
        theme
      )

      addZipcodeCircleOutlineToMap(
        map,
        LAYERS.agentZipcodeAreaCircleOutline,
        LAYERS.agentZipcodeAreaCircle,
        theme
      )

      centerMap()
    }

    const mapRef = map.current

    return () => {
      for (const layerName of Object.values(LAYERS)) {
        removeLayer(mapRef, layerName)
      }
      // sometimes a source is used in multiple layers so can't do this in the loop above
      for (const layerName of Object.values(LAYERS)) {
        removeSource(mapRef, layerName)
      }
      for (const imageName of Object.values(IMAGES)) {
        removeImage(mapRef, imageName)
      }
    }
    // eslint-disable-next-line
  }, [mapLoaded, isBrokeragesLoading, brokerages])

  useCenterMap(parentCenterMap, centerMap, onMapCentered)

  return <></>
}
