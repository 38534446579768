import { useSelector } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import FinishTourDialog from "../../../common/components/FinishTourDialog"
import BrokeragePurpleIcon from "../../../common/resources/icons/brokerage-purple.png"
import { selectCurrentBrokerage } from "../slice"
import { TOUR_STEPS, useUpdateBrokerageTourStatus } from "../utils"

export default function BuildPageDialog() {
  const currentBrokerage = useSelector(selectCurrentBrokerage)

  return (
    <FinishTourDialog
      openStepIndex={TOUR_STEPS.buildBrokeragePage}
      Icon={() => (
        <HaloCircularIcon
          Icon={() => (
            <img src={BrokeragePurpleIcon} alt="brokerage building" />
          )}
        />
      )}
      title="Let's build your Page!"
      body={
        <>
          The first step to building a winning recruiting presence on HighRise
          is to accurately complete your Brokerage Page(s).
          <br />
          <br />
          Let's take care of that now so you'll be ready to welcome your first
          agent candidates.
        </>
      }
      letsGoURL={`/brokerage/${currentBrokerage?.id}/edit/`}
      useUpdateTourStatus={useUpdateBrokerageTourStatus}
    />
  )
}
