import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

export default function InfoBubble({ Icon, message }) {
  return (
    <AlertLayout
      Icon={() => <Icon sx={{ color: "text.secondary" }} />}
      message={message}
      wrapperSX={{
        background: (theme) => theme.palette.otherwise.lightBackground,
      }}
      typographySX={{
        color: "text.secondary2",
      }}
    />
  )
}

export function AlertLayout({ Icon, message, wrapperSX, typographySX }) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        alignItems: "center",
        border: "none",
        ...wrapperSX,
      }}
    >
      <Icon sx={{ color: "text.secondary" }} />
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary2",
          ml: 1,
          fontWeight: 300,
        }}
      >
        {message}
      </Typography>
    </Paper>
  )
}
