import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useState } from "react"
import { useDispatch } from "react-redux"

import BrandAvatar from "../../../../common/components/BrandAvatar"
import { HaloCircularIcon } from "../../../../common/components/CircularIcon"
import Column from "../../../../common/components/Column"
import IconTitleValue from "../../../../common/components/IconTitleValue"
import Row from "../../../../common/components/Row"
import BrokerageCheckIcon from "../../../../common/resources/icons/brokerage-check.png"
import {
  brokerageSizeDisplay,
  useAuthorizedAxiosClient,
  yearFoundedDisplay,
} from "../../../../common/utils"
import { addBrokerage, showProDialogForLatestBrokerage } from "../../slice"

export function HeaderSection({ brokerage }) {
  return (
    <>
      <HaloCircularIcon
        Icon={() => (
          <img
            src={BrokerageCheckIcon}
            alt="Brokerage with checkmark"
            style={{ width: "48px", height: "48px" }}
          />
        )}
      />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Confirm your brokerage
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
        We think your brokerage is {brokerage.company}. Is this correct?
      </Typography>
    </>
  )
}

export function ContentSection({
  brokerageDetails,
  showBrokerageStats = true,
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        p: { xs: 2, sm: 4 },
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        <BrandAvatar
          brand={{ icon: brokerageDetails.image }}
          size={72}
          brokerageIconPadding="16px"
        />
        <Column
          sx={{
            ml: 2,
          }}
        >
          <Typography variant="h6" lineHeight={1.2}>
            {brokerageDetails.company}
          </Typography>
          <Row sx={{ alignItems: "center", mt: 1 }}>
            <LocationOnOutlinedIcon
              sx={{ color: "text.secondary2" }}
              fontSize="small"
            />
            <Typography
              variant="body1"
              color="text.secondary2"
              sx={{ ml: 0.5 }}
            >
              {brokerageDetails.address}
            </Typography>
          </Row>
        </Column>
      </Row>
      {showBrokerageStats && (
        <>
          <Divider sx={{ mt: 4 }} />
          <Row sx={{ mt: 4 }}>
            <IconTitleValue
              Icon={PersonOutlinedIcon}
              title="Size"
              value={brokerageSizeDisplay(brokerageDetails.head_count)}
            />
            <Row sx={{ ml: 8 }}>
              <IconTitleValue
                Icon={CalendarTodayOutlinedIcon}
                title="Year founded"
                value={yearFoundedDisplay(brokerageDetails.year_founded)}
              />
            </Row>
          </Row>
          <Typography variant="body1" sx={{ mt: 4 }}>
            {brokerageDetails.description || "No description provided."}
          </Typography>
          {brokerageDetails.images.length > 0 && (
            <Row sx={{ mt: 4 }}>
              <Grid container xs={12} rowSpacing={2} columnSpacing={2}>
                {brokerageDetails.images.map((image) => (
                  <Grid xs={3} key={image.id}>
                    <Avatar
                      src={image.image}
                      variant="rounded"
                      sx={{
                        width: "100%",
                        height: "initial",
                        aspectRatio: "1/1",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Row>
          )}
        </>
      )}
    </Paper>
  )
}

export function useGetConfirmBrokerageDetails() {
  const [brokerageDetails, setBrokerageDetails] = useState({ images: [] })
  const [isLoadingBrokerageDetails, setIsLoadingBrokerageDetails] =
    useState(false)

  const axios = useAuthorizedAxiosClient()

  function loadBrokerageDetails(brokerage) {
    setIsLoadingBrokerageDetails(true)
    axios
      .get(`/customers/api/confirm-claim/${brokerage.id}/`)
      .then(({ data }) => setBrokerageDetails(data))
      .finally(() => setIsLoadingBrokerageDetails(false))
  }

  return { brokerageDetails, isLoadingBrokerageDetails, loadBrokerageDetails }
}

export function useConfirmClaimBrokerage() {
  const [isClaimingBrokerage, setIsClaimingBrokerage] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()

  function confirmClaimBrokerage(brokerageDetails) {
    setIsClaimingBrokerage(true)
    return axios
      .put(`/customers/api/claim/${brokerageDetails.id}/`)
      .then(({ data }) => {
        dispatch(addBrokerage(data))
        dispatch(showProDialogForLatestBrokerage())
      })
      .finally(() => setIsClaimingBrokerage(false))
  }

  return { isClaimingBrokerage, confirmClaimBrokerage }
}
