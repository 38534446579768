import {
  BlueBorderedChip,
  GreenBorderedChip,
  PinkBorderedChip,
  WarningBorderedChip,
} from "../../../common/components/BorderedChips"

export default function ProposalStatusChip({ proposal }) {
  switch (proposal.status) {
    case "sent":
      return <BlueBorderedChip label="Sent" size="small" />
    case "accepted":
      return <GreenBorderedChip label="Accepted" size="small" />
    case "hired":
      return <PinkBorderedChip label="Hired" size="small" />
    default:
      return <WarningBorderedChip label="Rejected" size="small" />
  }
}
