import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useState } from "react"

import { AGENT_LEVELS_OF_INTEREST } from "../utils"

export default function LevelOfInterestAutocomplete({
  levelOfInterest,
  setLevelOfInterest,
  levelOfInterestInputValue,
  setLevelOfInterestInputValue,
  autocompleteProps,
}) {
  return (
    <Autocomplete
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label="Level of Interest" />
      )}
      options={Object.keys(AGENT_LEVELS_OF_INTEREST)}
      getOptionLabel={(option) => AGENT_LEVELS_OF_INTEREST[option] || ""}
      value={levelOfInterest}
      onChange={(_, newValue) => setLevelOfInterest(newValue)}
      inputValue={levelOfInterestInputValue}
      onInputChange={(e, newValue) => setLevelOfInterestInputValue(newValue)}
      disableClearable
      {...autocompleteProps}
    />
  )
}

export function useLevelOfInterestAutocompleteState() {
  const [levelOfInterest, setLevelOfInterest] = useState("")
  const [levelOfInterestInputValue, setLevelOfInterestInputValue] = useState("")
  return {
    levelOfInterest,
    setLevelOfInterest,
    levelOfInterestInputValue,
    setLevelOfInterestInputValue,
  }
}
