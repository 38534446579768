import { differenceInDays } from "date-fns"

import {
  BlueBorderedChip,
  GrayBorderedChip,
  GreenBorderedChip,
  PinkBorderedChip,
  WarningBorderedChip,
} from "../../../common/components/BorderedChips"
import { AGENT_REQUEST_STATUS_OPTIONS } from "../../../common/utils"

export const AGENT_STATUS_DISPLAY_MAP = {
  [AGENT_REQUEST_STATUS_OPTIONS.pending]: "Pending",
  [AGENT_REQUEST_STATUS_OPTIONS.accepted]: "Accepted",
  [AGENT_REQUEST_STATUS_OPTIONS.inContact]: "In contact",
  [AGENT_REQUEST_STATUS_OPTIONS.proposalReceived]: "Proposal sent",
  [AGENT_REQUEST_STATUS_OPTIONS.rejected]: "Rejected",
}

export default function AgentStatusChip({ agent }) {
  switch (agent.status) {
    case AGENT_REQUEST_STATUS_OPTIONS.accepted:
      return (
        <BlueBorderedChip
          label={
            AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.accepted]
          }
          size="small"
        />
      )
    case AGENT_REQUEST_STATUS_OPTIONS.inContact:
      return (
        <GreenBorderedChip
          label={
            AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.inContact]
          }
          size="small"
        />
      )
    case AGENT_REQUEST_STATUS_OPTIONS.pending:
      if (differenceInDays(new Date(), new Date(agent.created)) < 15) {
        return <BlueBorderedChip label="New" size="small" />
      } else {
        return <WarningBorderedChip label="Unconnected" size="small" />
      }
    case AGENT_REQUEST_STATUS_OPTIONS.proposalReceived:
      return (
        <GrayBorderedChip
          label={
            AGENT_STATUS_DISPLAY_MAP[
              AGENT_REQUEST_STATUS_OPTIONS.proposalReceived
            ]
          }
          size="small"
        />
      )
    default:
      return (
        <PinkBorderedChip
          label={
            AGENT_STATUS_DISPLAY_MAP[AGENT_REQUEST_STATUS_OPTIONS.rejected]
          }
          size="small"
        />
      )
  }
}
