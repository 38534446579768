import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useContext } from "react"
import { useNavigate } from "react-router"

import ProposalCardBackgroundImage from "../../resources/images/proposal-card-bg.png"
import Column from "../Column"
import SendProposalStatsPerks from "../SendProposalStatsPerks"
import InboxContext, { MESSAGE_SOURCES } from "./InboxContext"
import RoundedCardContents from "./RoundedCardContents"
import RoundedImageCardHeader from "./RoundedImageCardHeader"

export default function ProposalMessage({ message }) {
  const inboxContext = useContext(InboxContext)
  const proposal = message.proposal.proposal
  const navigate = useNavigate()

  function handleReviewProposalButtonClicked() {
    if (inboxContext.messageSource === MESSAGE_SOURCES.agent) {
      navigate(`/agent/proposals/${message.proposal.id}/`)
    } else {
      navigate(`/brokerage/proposals/${message.proposal.id}/`)
    }
  }

  return (
    <Column
      sx={{
        width: { xs: "100%", sm: "400px" },
        alignSelf:
          inboxContext.messageSource === MESSAGE_SOURCES.agent
            ? "flex-start"
            : "flex-end",
      }}
    >
      <RoundedImageCardHeader
        sx={{
          backgroundImage: `url(${ProposalCardBackgroundImage})`,
        }}
      />
      <RoundedCardContents>
        <Typography variant="h6">{proposal.name}</Typography>
        <Typography variant="body1" whiteSpace="break-spaces" sx={{ mt: 2 }}>
          {proposal.introduction}
        </Typography>
        <SendProposalStatsPerks
          commissionSplit={proposal.brokerage_commission_ratio}
          signingBonus={proposal.signing_bonus}
          perks={proposal.perks}
          perkLengthCap={15}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4, alignSelf: "flex-end" }}
          onClick={handleReviewProposalButtonClicked}
        >
          Review proposal
        </Button>
      </RoundedCardContents>
    </Column>
  )
}
