import { AnimatePresence, motion } from "framer-motion"
import { createContext, useContext, useEffect, useRef } from "react"
import { Wizard, useWizard } from "react-use-wizard"

export default function AnimatedWizard({ children, wizardProps }) {
  const previousStep = useRef(0)
  return (
    <AnimationStepContext.Provider value={previousStep}>
      <Wizard
        wrapper={<AnimatePresence initial={false} mode="wait" />}
        {...wizardProps}
      >
        {children}
      </Wizard>
    </AnimationStepContext.Provider>
  )
}

export function AnimatedStepWrapper({ children }) {
  const { activeStep } = useWizard()
  const previousStepIndex = useContext(AnimationStepContext)

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 200 : -200,
        opacity: 0,
        height: 200,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      height: "100%",
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 200 : -200,
        opacity: 0,
        height: 200,
      }
    },
  }

  useEffect(() => {
    return () => {
      previousStepIndex.current = activeStep
    }
  }, [previousStepIndex, activeStep])

  return (
    <motion.div
      style={{ width: "100%" }}
      custom={activeStep - previousStepIndex.current}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30,
      }}
    >
      {children}
    </motion.div>
  )
}

export const AnimationStepContext = createContext(0)
