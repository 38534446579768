import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export default function CheckableChip({
  label,
  checked,
  onChecked,
  onUnchecked,
}) {
  return checked ? (
    <CheckedChip label={label} onClick={onUnchecked} />
  ) : (
    <PerkChip label={label} onClick={onChecked} />
  )
}

const PerkChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.units.borderRadius,
  backgroundColor: theme.palette.otherwise.lightBackground,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}))

const ActivePerkChip = styled(PerkChip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  "& .MuiChip-label": { display: "flex", alignItems: "center" },
}))

export function CheckedChip({ label, onClick }) {
  return (
    <ActivePerkChip
      label={
        <>
          {label}{" "}
          <CheckOutlinedIcon
            color="primary"
            sx={{ ml: 1, fontSize: "1.25em" }}
          />
        </>
      }
      onClick={onClick}
    />
  )
}
