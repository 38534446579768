import CheckIcon from "@mui/icons-material/Check"
import Divider from "@mui/material/Divider"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

export default function CheckMenu({
  choices,
  anchorEl,
  onClose,
  value,
  onChange,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {choices.map(({ label, value: choiceValue }, index) => [
        <CheckedMenuItem
          key={choiceValue}
          onClick={() => onChange(choiceValue)}
          label={label}
          checked={choiceValue === value}
        />,
        index < choices.length - 1 && <Divider />,
      ])}
    </Menu>
  )
}

function CheckedMenuItem({ onClick, label, checked }) {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        minWidth: "200px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {label} {checked && <CheckIcon color="primary" />}
    </MenuItem>
  )
}
