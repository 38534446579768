import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const Row = styled(Box)(() => ({
  display: "flex",
}))

export const RowButColumnOnMobile = styled(Row)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  })
)

export default Row
