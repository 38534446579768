import Popover from "@mui/material/Popover"
import TextField from "@mui/material/TextField"
import { useState } from "react"

import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import FilterTitleDescription from "./FilterTitleDescription"

export const ANCHOR_TOP = 120

export default function ZipcodeFilterDialog({
  open,
  onClose,
  initialZipcode,
  anchorEl,
}) {
  const {
    zipcode,
    zipcodeError,
    handleZipcodeChanged,
    isValidatingZipcode,
    validateZipcode,
  } = useZipcodeFilterState(initialZipcode, onClose)

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: ANCHOR_TOP,
        left: 520,
      }}
    >
      <Column
        sx={{
          alignItems: "center",
          p: 2,
          maxWidth: "250px",
        }}
      >
        <ZipcodeFilterTitleDescription />
        <ZipcodeTextField
          zipcode={zipcode}
          zipcodeError={zipcodeError}
          handleZipcodeChanged={handleZipcodeChanged}
          sx={{ mt: 2 }}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!!zipcodeError}
          isLoading={isValidatingZipcode}
          onClick={validateZipcode}
          fullWidth
          sx={{ mt: 2 }}
        >
          Apply
        </LoadingButton>
      </Column>
    </Popover>
  )
}

export function ZipcodeFilterTitleDescription() {
  return (
    <FilterTitleDescription
      title="Find brokerages based on your location"
      description="You'll be recommended brokerages within this area."
    />
  )
}

export function useZipcodeFilterState(initialZipcode, onZipcodeValid) {
  const [zipcode, setZipcode] = useState(initialZipcode)
  const [zipcodeError, setZipcodeError] = useState(null)
  const [isValidatingZipcode, setIsValidatingZipcode] = useState(false)

  const axios = useAuthorizedAxiosClient()

  function handleZipcodeChanged(e) {
    setZipcode(e.target.value)
    setZipcodeError(null)
  }

  function validateZipcode() {
    setIsValidatingZipcode(true)
    axios
      .get("/agents/api/validate-zip-code-filter/", {
        params: { zip_code: zipcode },
      })
      .then(({ data }) => {
        setZipcodeError(null)
        onZipcodeValid({
          zipCode: zipcode,
          ...data,
        })
      })
      .catch(({ response: { data } }) => {
        setZipcodeError(data.zip_code)
      })
      .finally(() => setIsValidatingZipcode(false))
  }

  return {
    zipcode,
    setZipcode,
    zipcodeError,
    isValidatingZipcode,
    handleZipcodeChanged,
    validateZipcode,
  }
}

export function ZipcodeTextField({
  zipcode,
  zipcodeError,
  handleZipcodeChanged,
  sx,
}) {
  return (
    <TextField
      fullWidth
      placeholder="Zip code"
      value={zipcode}
      onChange={handleZipcodeChanged}
      error={!!zipcodeError}
      helperText={zipcodeError}
      sx={sx}
      focused
    />
  )
}
