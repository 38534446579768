import Button from "@mui/material/Button"
import { Wizard, useWizard } from "react-use-wizard"

import ActionButtonsContainer from "../../../../common/components/ActionButtonsContainer"
import { BuildingVerticalSplitLayout } from "../../../../common/components/VerticalSplitLayout"
import { useTrackSignupWizards } from "../../utils"
import { HeaderSection as VerificationRequestSentHeaderSection } from "../verification-request-sent/Common"
import {
  HeaderSection as VerifyOwnerhipHeaderSection,
  ActionButtons as VerifyOwnershipActionButtons,
  ContentSection as VerifyOwnershipContentSection,
  useCommonVerifyOwnershipState,
} from "../verify-ownership/Common"
import {
  REPORT_REASONS,
  ActionButtons as ReportReasonActionButtons,
  ContentSection as ReportReasonContentSection,
  HeaderSection as ReportReasonHeaderSection,
  useCommonReportReasonState,
  useReportBrokerage,
} from "./Common"

export default function ReportBrokerageWizard({
  brokerage,
  onReturn,
  onRestartWizard,
}) {
  const { reportReason, setReportReason, note, setNote } =
    useCommonReportReasonState()
  return (
    <Wizard>
      <SelectReportReasonStep
        brokerage={brokerage}
        reportReason={reportReason}
        setReportReason={setReportReason}
        note={note}
        setNote={setNote}
        onPreviousButtonClicked={onReturn}
      />
      <VerifyOwnershipStep
        brokerage={brokerage}
        reportReason={reportReason}
        note={note}
      />
      <VerificationRequestSentStep onRestartWizard={onRestartWizard} />
    </Wizard>
  )
}

function SelectReportReasonStep({
  brokerage,
  reportReason,
  setReportReason,
  note,
  setNote,
  onPreviousButtonClicked,
}) {
  const { isReporting, reportBrokerage } = useReportBrokerage()
  const { nextStep, goToStep, activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Report an issue")

  const LAST_STEP_INDEX = 2

  function handleReportButtonClicked() {
    if (reportReason === REPORT_REASONS.claim) {
      nextStep()
    } else {
      reportBrokerage(reportReason, note, null, brokerage).then(() =>
        goToStep(LAST_STEP_INDEX)
      )
    }
  }

  return (
    <BuildingVerticalSplitLayout>
      <>
        <ReportReasonHeaderSection brokerage={brokerage} />
        <ReportReasonContentSection
          brokerage={brokerage}
          reportReason={reportReason}
          setReportReason={setReportReason}
          note={note}
          setNote={setNote}
        />
        <ActionButtonsContainer>
          <ReportReasonActionButtons
            reportReason={reportReason}
            isReporting={isReporting}
            onPreviousButtonClicked={onPreviousButtonClicked}
            onReportButtonClicked={handleReportButtonClicked}
          />
        </ActionButtonsContainer>
      </>
    </BuildingVerticalSplitLayout>
  )
}

function VerifyOwnershipStep({ reportReason, note, brokerage }) {
  const { verificationDocument, setVerificationDocument } =
    useCommonVerifyOwnershipState()
  const { isReporting, reportBrokerage } = useReportBrokerage()
  const { previousStep, nextStep, activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Verify your ownership")

  function handleNextButtonClicked() {
    reportBrokerage(reportReason, note, verificationDocument, brokerage).then(
      () => {
        nextStep()
      }
    )
  }

  return (
    <BuildingVerticalSplitLayout>
      <>
        <VerifyOwnerhipHeaderSection />
        <VerifyOwnershipContentSection
          verificationDocument={verificationDocument}
          setVerificationDocument={setVerificationDocument}
        />
        <ActionButtonsContainer>
          <VerifyOwnershipActionButtons
            verificationDocument={verificationDocument}
            isReporting={isReporting}
            onPreviousButtonClicked={() => previousStep()}
            onNextButtonClicked={handleNextButtonClicked}
          />
        </ActionButtonsContainer>
      </>
    </BuildingVerticalSplitLayout>
  )
}

function VerificationRequestSentStep({ onRestartWizard }) {
  const { activeStep } = useWizard()
  useTrackSignupWizards(activeStep + 1, "Verification request sent")

  return (
    <BuildingVerticalSplitLayout>
      <>
        <VerificationRequestSentHeaderSection />
        <ActionButtonsContainer>
          <Button variant="contained" color="primary" onClick={onRestartWizard}>
            Continue to connect your brokerage
          </Button>
        </ActionButtonsContainer>
      </>
    </BuildingVerticalSplitLayout>
  )
}
