import AddIcon from "@mui/icons-material/AddOutlined"
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined"
import CheckIcon from "@mui/icons-material/Check"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import LogoutIcon from "@mui/icons-material/LogoutOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popover from "@mui/material/Popover"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"

import AuthenticatedUserLayout from "../../common/components/AuthenticatedUserLayout"
import BrandAvatar from "../../common/components/BrandAvatar"
import Column from "../../common/components/Column"
import CommonTourProvider, {
  TOUR_STATUS,
  useNavLinkTourPopoverStyles,
  usePopoverBelowStyles,
} from "../../common/components/CommonTourProvider"
import { finishTourDialogStep } from "../../common/components/FinishTourDialog"
import NavbarAvatar from "../../common/components/NavbarAvatar"
import NavbarDivider from "../../common/components/NavbarDivider"
import {
  NOTIFICATION_ICON_SIZE,
  NotificationLayout,
  NotificationsMenu,
} from "../../common/components/Notifications"
import NotificationsBellButton, {
  useNotificationsBellButtonState,
} from "../../common/components/NotificationsBellButton"
import Row from "../../common/components/Row"
import {
  ImageSidebarLinkData,
  MuiIconSidebarLinkData,
} from "../../common/components/SidebarLink"
import UserAvatar from "../../common/components/UserAvatar"
import { AgentAvatar } from "../../common/components/UserAvatar"
import InboxActiveIcon from "../../common/resources/icons/inbox-active.svg"
import InboxIcon from "../../common/resources/icons/inbox.svg"
import { getFullName, useShowBottomNav } from "../../common/utils"
import { hideNotification, retrieveNotifications } from "../notifications/slice"
import { CUSTOMER_HOME_URLS, useLogoutUser } from "../users/utils"
import SelectBrokerageDialog, {
  useSelectBrokerageDialogState,
} from "./claim-office/select-brokerage/Dialog"
import UpgradeToProDialog from "./profile/UpgradeProDialog"
import {
  logoutBrokerage,
  profileLoading,
  retrieveBrokerageProfile,
  selectBrokerages,
  selectCurrentBrokerage,
  selectProfile,
  updateCurrentBrokerage,
} from "./slice"
import { NOTIFICATION_TYPES, useUpdateBrokerageTourStatus } from "./utils"

export default function BrokerageLayout() {
  const dispatch = useDispatch()
  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const [profileMenuAnchorElem, setProfileMenuAnchorElem] = useState()
  const {
    notificationsMenuAnchorElem,
    handleBellButtonClicked,
    handleNotificationsMenuClosed,
  } = useNotificationsBellButtonState()

  const {
    isOpen: isSelectBrokerageDialogOpen,
    open: openSelectBrokerageDialog,
    onClosed: handleSelectBrokerageDialogClosed,
    onSelectAnotherBrokerage,
  } = useSelectBrokerageDialogState()

  const navigate = useNavigate()
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const brokerages = useSelector(selectBrokerages)
  const showMobileNavbar = useShowBottomNav()

  const NotificationTypeToComponentMap = {
    [NOTIFICATION_TYPES.proposalAccepted]: ProposalAcceptedNotification,
  }

  const { updateStatus: updateBrokerageTourStatus } =
    useUpdateBrokerageTourStatus()
  const navLinkTourPopoverStyles = useNavLinkTourPopoverStyles()

  const popoverBelowStyles = usePopoverBelowStyles()

  const logoutUser = useLogoutUser("/customers/api/logout/", () => {
    setProfileMenuAnchorElem(null)
    dispatch(logoutBrokerage())
  })

  function handleUserAvatarClicked(e) {
    setProfileMenuAnchorElem(e.currentTarget)
  }

  function handleProfileMenuClosed() {
    setProfileMenuAnchorElem(null)
  }

  function handleEditProfileButtonClicked() {
    setProfileMenuAnchorElem(null)
    navigate("/brokerage/profile/")
  }

  function handleSettingsMenuItemClicked() {
    setProfileMenuAnchorElem(null)
    navigate("/brokerage/settings/")
  }

  function handleLogoutMenuItemClicked() {
    logoutUser()
  }

  useEffect(
    () => {
      dispatch(retrieveBrokerageProfile())
      dispatch(retrieveNotifications())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      if (brokerages.length === 0) {
        navigate(CUSTOMER_HOME_URLS.claimOffice)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileLoading, brokerages]
  )

  return (
    <CommonTourProvider
      steps={[
        {
          selector: "#dashboard",
          content:
            "This area is your dashboard.  It's your home base when working in HighRise.",
          position: [240, 74],
        },
        {
          selector: "#candidate-cards",
          content: `
            This is where you'll find your new candidates.
            Agents who are interested in learning more about your office will soon appear here.
            We will notify you as agents start arriving.
          `,
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        {
          selector: "#candidate-cards",
          content: (
            <>
              When agents start requesting hiring information from your office,
              they will appear on cards like this.
              <br />
              <br />
              You can review the agent's profile card and decide if you want to
              engage in conversation with them or not.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        {
          selector: "#candidates-nav-link",
          content: (
            <>
              Once you've started to approve agents, they can all be found
              listed here under your Candidates tab.
            </>
          ),
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#conversations-nav-link",
          content: (
            <>
              This is your inbox, where you'll manage conversations with agents.
              <br />
              <br />
              You can chat, negotiate, set meetings, exchange files and more in
              here.
            </>
          ),
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#proposals-nav-link",
          content: (
            <>
              Here is where you can send offers and hiring proposals to agents
              for them to consider.
              <br />
              <br />
              Using HighRise proposals helps you gain valuable insights and
              analytics as to what strategies, plans, and perks are succeeding
              in your market.
            </>
          ),
          position: "right",
          styles: {
            popover: navLinkTourPopoverStyles,
          },
        },
        {
          selector: "#brokerage-selector",
          content: (
            <>
              This is where you can build and manage your company Brokerage
              Page(s).
              <br />
              <br />
              A Brokerage Page represents your brand, and is what agents will
              see when considering offices they may want to want for.
              <br />
              <br />
              Building a great Page is the first step in preparing your HighRise
              account.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        {
          selector: "#profile-button",
          content: (
            <>
              This is where your personal settings can be found. Use your
              Settings to manage important security features, privacy settings,
              contact information, and more.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
        },
        finishTourDialogStep,
        {
          selector: "#brokerage-selector",
          content: (
            <>
              Ok, no worries!
              <br />
              <br />
              Setting up your Page is extremely important to your success in
              HighRise.
              <br />
              <br />
              Remember to return to the dropdown here to set up your page when
              you're ready.
            </>
          ),
          position: "bottom",
          styles: {
            popover: popoverBelowStyles,
          },
          actionAfter: () => {
            updateBrokerageTourStatus(TOUR_STATUS.finished)
          },
        },
      ]}
    >
      <AuthenticatedUserLayout
        sidebarLinksData={[
          new MuiIconSidebarLinkData(
            "Dashboard",
            undefined,
            "/brokerage/dashboard",
            GridViewOutlinedIcon
          ),
          new MuiIconSidebarLinkData(
            "Candidates",
            undefined,
            "/brokerage/candidates",
            PersonOutlinedIcon,
            "candidates"
          ),
          new ImageSidebarLinkData(
            "Conversations",
            profile.unread_conversations_count > 0
              ? profile.unread_conversations_count
              : undefined,
            "/brokerage/conversations",
            InboxIcon,
            InboxActiveIcon,
            "conversations"
          ),
          new MuiIconSidebarLinkData(
            "Proposals",
            undefined,
            "/brokerage/proposals",
            BusinessCenterOutlinedIcon,
            "proposals"
          ),
        ]}
        navbar={
          currentBrokerage ? (
            <>
              {!showMobileNavbar && <PageTitle />}
              <Row
                sx={{
                  alignItems: "center",
                }}
              >
                <Box sx={{ mr: { xs: 0, sm: 2 } }}>
                  <NotificationsBellButton onClick={handleBellButtonClicked} />
                  <NotificationsMenu
                    anchorEl={notificationsMenuAnchorElem}
                    onClose={handleNotificationsMenuClosed}
                    NotificationTypeToComponentMap={
                      NotificationTypeToComponentMap
                    }
                  />
                </Box>
                {!isProfileLoading &&
                  (showMobileNavbar ? (
                    <SelectBrokerageButton
                      onAddNewBrokerage={openSelectBrokerageDialog}
                    />
                  ) : (
                    <BrokerageAutocomplete
                      onAddNewBrokerage={openSelectBrokerageDialog}
                    />
                  ))}
                <SelectBrokerageDialog
                  open={isSelectBrokerageDialogOpen}
                  onClose={handleSelectBrokerageDialogClosed}
                  onSelectAnotherBrokerage={onSelectAnotherBrokerage}
                />
                <NavbarDivider />
                <Box
                  sx={{
                    ml: { xs: 1, sm: 2 },
                  }}
                >
                  <NavbarAvatar
                    profile={profile}
                    isProfileLoading={isProfileLoading}
                    onClick={handleUserAvatarClicked}
                  />
                </Box>
                <Popover
                  open={Boolean(profileMenuAnchorElem)}
                  anchorEl={profileMenuAnchorElem}
                  onClose={handleProfileMenuClosed}
                  anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                  }}
                  transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Column
                    sx={{
                      alignItems: "center",
                      p: 2,
                    }}
                  >
                    <UserAvatar
                      isProfileLoading={isProfileLoading}
                      profileData={profile}
                      size={48}
                    />
                    <Column
                      sx={{
                        maxWidth: "12em",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="caption" sx={{ mt: 2 }}>
                        {getFullName(profile)}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ mt: 1, fontSize: "0.78em" }}
                      >
                        {currentBrokerage?.company}
                      </Typography>
                    </Column>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2 }}
                      size="small"
                      onClick={handleEditProfileButtonClicked}
                    >
                      View or edit profile
                    </Button>
                  </Column>
                  <Divider />
                  <MenuList>
                    <MenuItem onClick={handleSettingsMenuItemClicked}>
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Account settings</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogoutMenuItemClicked}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText>Log out</ListItemText>
                    </MenuItem>
                  </MenuList>
                </Popover>
              </Row>
              <UpgradeToProDialog />
            </>
          ) : null
        }
      />
    </CommonTourProvider>
  )
}

const AUTOCOMPLETE_OPTION_TYPES = {
  brokerage: "brokerage",
  addBrokerage: "add-brokerage",
}

function BrokerageAutocomplete({ onAddNewBrokerage }) {
  const [brokerageSelectValue, setBrokerageSelectValue] = useState(null)
  const [brokerageSelectInputValue, setBrokerageSelectInputValue] = useState("")
  const autocompleteRef = useRef()

  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const brokerageOptions = useBrokerageOptions()
  const handleBrokerageSelected =
    useHandleBrokerageOptionSelected(onAddNewBrokerage)

  const BROKERAGE_SELECT_WIDTH = "300px"

  function handleBrokerageSelectValueChanged(e, newValue) {
    handleBrokerageSelected(newValue)
    if (newValue.type !== AUTOCOMPLETE_OPTION_TYPES.addBrokerage) {
      setBrokerageSelectValue(newValue)
    }
  }

  function handleBrokerageSelectInputValueChanged(e, newValue) {
    setBrokerageSelectInputValue(newValue)
  }

  useEffect(() => {
    if (!isProfileLoading) {
      setBrokerageSelectValue(currentBrokerage)
      setBrokerageSelectInputValue(currentBrokerage.company)
    }
  }, [isProfileLoading, currentBrokerage])

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.id = "brokerage-selector"
    }
  }, [autocompleteRef])

  return (
    <Autocomplete
      size="small"
      options={brokerageOptions}
      getOptionLabel={(option) => option.company}
      disableClearable
      value={brokerageSelectValue}
      onChange={handleBrokerageSelectValueChanged}
      inputValue={brokerageSelectInputValue}
      onInputChange={handleBrokerageSelectInputValueChanged}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <NavbarBrandAvatar />,
          }}
          sx={{ "& .MuiInputBase-root": { border: "none" } }}
        />
      )}
      renderOption={(props, option) => (
        <BrokerageOption option={option} {...props} />
      )}
      PaperComponent={({ children, ...props }) => (
        <Paper
          sx={{
            "& .MuiAutocomplete-listbox": { padding: 0 },
            "& .MuiAutocomplete-option": {
              padding: (theme) => `${theme.spacing(2)}!important`,
            },
          }}
          {...props}
        >
          {children}
        </Paper>
      )}
      sx={{
        border: (theme) => `1px solid ${theme.palette.otherwise.border}`,
        borderRadius: "20px",
        backgroundColor: "white",
        width: BROKERAGE_SELECT_WIDTH,
        "& .MuiOutlinedInput-root": {
          borderRadius: "20px",
          "&:hover, &.Mui-focused": {
            border: "none!important",
          },
        },
      }}
      ref={autocompleteRef}
    />
  )
}

function SelectBrokerageButton({ onAddNewBrokerage }) {
  const [buttonRef, setButtonRef] = useState(null)
  const brokerageOptions = useBrokerageOptions()
  const handleBrokerageSelected =
    useHandleBrokerageOptionSelected(onAddNewBrokerage)

  function handleOptionClickedWrapper(option) {
    return () => {
      handleBrokerageSelected(option)
      setButtonRef(null)
    }
  }

  return (
    <>
      <IconButton
        onClick={(e) => setButtonRef(e.currentTarget)}
        id="brokerage-selector"
      >
        <NavbarBrandAvatar />
      </IconButton>
      <Popover
        anchorEl={buttonRef}
        open={!!buttonRef}
        onClose={() => setButtonRef(null)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Column sx={{ maxWidth: "300px", "& > .MuiBox-root": { p: 2 } }}>
          {brokerageOptions.map((option) => (
            <BrokerageOption
              key={option.id}
              option={option}
              onClick={handleOptionClickedWrapper(option)}
            />
          ))}
        </Column>
      </Popover>
    </>
  )
}

function NavbarBrandAvatar() {
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  return (
    <BrandAvatar
      brand={{ icon: currentBrokerage.image }}
      size={32}
      brokerageIconPadding="8px"
    />
  )
}

function BrokerageOption({ option, onClick, ...props }) {
  const currentBrokerage = useSelector(selectCurrentBrokerage)

  return option.type === AUTOCOMPLETE_OPTION_TYPES.addBrokerage ? (
    <Row
      component="li"
      sx={{
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
      {...props}
    >
      <AddIcon color="primary" fontSize="small" />
      <Typography variant="body1" sx={{ ml: 2 }}>
        Add a brokerage
      </Typography>
    </Row>
  ) : (
    <Row
      component="li"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={onClick}
      {...props}
    >
      <Row
        sx={{
          alignItems: "center",
        }}
      >
        <BrandAvatar
          brand={{ icon: option.image }}
          size={32}
          brokerageIconPadding="4px"
        />
        <Column
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{option.company}</Typography>
          <Link
            variant="subtitle2"
            fontSize="0.8em"
            underline="always"
            sx={{ mt: 1 }}
            component={RouterLink}
            to={`${option.id}/edit/`}
          >
            Review profile
          </Link>
        </Column>
      </Row>
      {option.id === currentBrokerage.id && (
        <CheckIcon color="primary" sx={{ ml: 1 }} />
      )}
    </Row>
  )
}

function useBrokerageOptions() {
  const profile = useSelector(selectProfile)

  return [
    ...profile.brokerages,
    {
      id: "add-brokerage",
      type: AUTOCOMPLETE_OPTION_TYPES.addBrokerage,
      company: "Add a brokerage",
    },
  ]
}

function useHandleBrokerageOptionSelected(onAddNewBrokerage) {
  const dispatch = useDispatch()

  return (option) => {
    if (option.type === AUTOCOMPLETE_OPTION_TYPES.addBrokerage) {
      onAddNewBrokerage()
    } else {
      dispatch(updateCurrentBrokerage(option))
    }
  }
}

function PageTitle() {
  const currentURL = useLocation().pathname
  let title
  if (currentURL === "/brokerage/dashboard") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">
          Welcome to your recruiting dashboard!
        </Typography>
        <Typography variant="body2" color="text.secondary2">
          We're happy you're here, now let's get growing!
        </Typography>
      </Box>
    )
  } else if (currentURL === "/brokerage/candidates") {
    title = "Candidates"
  } else if (currentURL === "/brokerage/conversations") {
    title = "Conversations"
  } else if (currentURL.startsWith("/brokerage/proposals")) {
    title = "Proposals"
  } else if (currentURL.startsWith("/brokerage/invoices")) {
    title = "Invoices"
  }
  return <Typography variant="h5">{title}</Typography>
}

function ProposalAcceptedNotification({ notification, closeMenu }) {
  const agentRequest = notification.proposal.agent_request
  const dispatch = useDispatch()

  function handleButtonClickedWrapper(hired) {
    return () => {
      dispatch(hideNotification({ id: notification.id, data: { hired } }))
      closeMenu()
    }
  }

  return (
    <NotificationLayout
      show={!!notification}
      leftIcon={
        <AgentAvatar
          agent={{
            profile_image: agentRequest.profile_image,
            first_name: agentRequest.final_name,
          }}
          size={NOTIFICATION_ICON_SIZE}
        />
      }
      title={`${agentRequest.final_name} marked your proposal as accepted. Did you hire them?`}
      actionWidget={
        <Row>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleButtonClickedWrapper(true)}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleButtonClickedWrapper(false)}
            sx={{ ml: 1 }}
          >
            No
          </Button>
        </Row>
      }
      date={new Date(notification.created)}
    />
  )
}
