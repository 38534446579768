import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"

import { AgentAvatar } from "../../../common/components/UserAvatar"
import TopProducerIcon from "../../../common/resources/icons/top-producer-star.png"

export default function TopProducerAgentAvatar({ agentRequest, avatarSize }) {
  const starSize = avatarSize / 3
  return (
    <Box sx={{ position: "relative" }}>
      <AgentAvatar agent={agentRequest} size={avatarSize} />
      {agentRequest.is_top_producer ? (
        <Tooltip title="Agent has higher annual production than market average">
          <img
            src={TopProducerIcon}
            style={{
              position: "absolute",
              bottom: -4,
              right: -4,
              width: `${starSize}px`,
              height: `${starSize}px`,
              border: "1px solid white",
              borderRadius: "50%",
            }}
            alt="Yellow star"
          />
        </Tooltip>
      ) : null}
    </Box>
  )
}
