import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { useEffect, useState } from "react"

import CheckableChip from "../../../common/components/CheckableChip"
import ChipContainer from "../../../common/components/ChipContainer"
import IconValue from "../../../common/components/IconValue"
import {
  PERKS_CATEGORIES,
  getIconForPerkCategory,
} from "../../../common/components/PerksColumns"
import { axiosClient } from "../../../common/utils"

export default function PerksEditor({ selectedPerks, setSelectedPerks }) {
  const [perksExpandedSection, setPerksExpandedSection] = useState(
    PERKS_CATEGORIES.compensation
  )
  const [allPerks, setAllPerks] = useState([])

  useEffect(
    () => {
      axiosClient.get("/customers/api/perks/").then(({ data }) => {
        setAllPerks(data)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <PerksAccordionSection
        category={PERKS_CATEGORIES.compensation}
        expandedSection={perksExpandedSection}
        setExpandedSection={setPerksExpandedSection}
        allPerks={allPerks}
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
      <PerksAccordionSection
        category={PERKS_CATEGORIES.admin}
        expandedSection={perksExpandedSection}
        setExpandedSection={setPerksExpandedSection}
        allPerks={allPerks}
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
      <PerksAccordionSection
        category={PERKS_CATEGORIES.office}
        expandedSection={perksExpandedSection}
        setExpandedSection={setPerksExpandedSection}
        allPerks={allPerks}
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
      <PerksAccordionSection
        category={PERKS_CATEGORIES.marketing}
        expandedSection={perksExpandedSection}
        setExpandedSection={setPerksExpandedSection}
        allPerks={allPerks}
        selectedPerks={selectedPerks}
        setSelectedPerks={setSelectedPerks}
      />
    </>
  )
}

export function useSelectedPerks(initialPerks = []) {
  const [selectedPerks, setSelectedPerks] = useState(initialPerks)
  return { selectedPerks, setSelectedPerks }
}

function PerksAccordionSection({
  category,
  expandedSection,
  setExpandedSection,
  allPerks,
  selectedPerks,
  setSelectedPerks,
}) {
  const SECTION_TITLES = {
    [PERKS_CATEGORIES.compensation]: "Compensation",
    [PERKS_CATEGORIES.admin]: "Admin support",
    [PERKS_CATEGORIES.office]: "Office",
    [PERKS_CATEGORIES.marketing]: "Marketing & Leads",
  }

  const Icon = getIconForPerkCategory(category)

  function filterPerksByCategory(category) {
    return allPerks.filter((perk) => perk.category === category)
  }

  function handlePerksSectionExpanded(e, expanded) {
    setExpandedSection(expanded ? category : null)
  }

  function handlePerkChipSelectedWrapper(perk) {
    return function () {
      setSelectedPerks([...selectedPerks, perk])
    }
  }

  function handlePerkChipDeselectedWrapper(perkID) {
    return function () {
      setSelectedPerks(selectedPerks.filter(({ id }) => id !== perkID))
    }
  }

  return (
    <Accordion
      elevation={0}
      expanded={category === expandedSection}
      onChange={handlePerksSectionExpanded}
      sx={{
        "&.MuiPaper-root": { border: "none", px: "0!important" },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
        <IconValue Icon={Icon} value={SECTION_TITLES[category]} />
      </AccordionSummary>
      <AccordionDetails>
        <ChipContainer>
          {filterPerksByCategory(PERKS_CATEGORIES[category]).map((perk) => (
            <CheckableChip
              key={perk.id}
              label={perk.title}
              checked={selectedPerks.findIndex((p) => p.id === perk.id) !== -1}
              onChecked={handlePerkChipSelectedWrapper(perk)}
              onUnchecked={handlePerkChipDeselectedWrapper(perk.id)}
            />
          ))}
        </ChipContainer>
      </AccordionDetails>
    </Accordion>
  )
}
