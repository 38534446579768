import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import LoadingButton from "../../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../../common/utils"

export function HeaderSection() {
  return (
    <>
      <Typography variant="h4">
        Add contact information to your brokerage
      </Typography>
      <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
        Let agents know how to contact your business
      </Typography>
    </>
  )
}

export function ContentSection({
  website,
  setWebsite,
  email,
  setEmail,
  phone,
  setPhone,
}) {
  return (
    <>
      <TextField
        label="Website"
        placeholder="http://www.example.com"
        type="url"
        required
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        fullWidth
        sx={{
          mt: 4,
        }}
      />
      <TextField
        label="Email"
        placeholder="john@gmail.com"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        sx={{
          mt: 4,
        }}
      />
      <TextField
        label="Phone"
        placeholder="+1 123456789"
        type="tel"
        required
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        sx={{
          mt: 4,
        }}
      />
    </>
  )
}

export function ActionButtons({
  website,
  email,
  phone,
  company,
  zipcode,
  addressInput,
  geoLocation,
  image,
  imageName,
  images,
  selectedPerks,
  yearFounded,
  description,
  onPreviousButtonClicked,
  onCreatingBrokerageFinished,
}) {
  const [isCreatingBrokerage, setIsCreatingBrokerage] = useState(false)
  const axios = useAuthorizedAxiosClient()

  function handleFinishButtonClicked() {
    setIsCreatingBrokerage(true)
    const formData = new FormData()
    formData.append("website", website)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("company", company)
    formData.append("zip_code", zipcode)
    formData.append("address", addressInput)
    formData.append("latitude", geoLocation.lat)
    formData.append("longitude", geoLocation.lng)
    if (image && imageName) {
      formData.append("custom_brand_image", image, imageName)
    }
    for (const mediaImage of images) {
      formData.append("images[]", mediaImage.file, mediaImage.file.name)
    }
    for (const perk of selectedPerks) {
      formData.append("perks[]", perk.id)
    }
    formData.append("year_founded", yearFounded)
    formData.append("description", description)
    axios
      .post("/customers/api/brokerage/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        onCreatingBrokerageFinished(data)
      })
      .finally(() => setIsCreatingBrokerage(false))
  }
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={onPreviousButtonClicked}
        fullWidth
      >
        Previous
      </Button>
      <LoadingButton
        isLoading={isCreatingBrokerage}
        onClick={handleFinishButtonClicked}
        fullWidth
      >
        Finish
      </LoadingButton>
    </>
  )
}
