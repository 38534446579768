import { differenceInDays } from "date-fns"

import {
  BlueBorderedChip,
  GreenBorderedChip,
  PinkBorderedChip,
  WarningBorderedChip,
} from "../../../common/components/BorderedChips"

export default function ProposalStatusChip({ proposal }) {
  switch (proposal.status) {
    case PROPOSAL_STATUSES.accepted:
      return <GreenBorderedChip label="Accepted" size="small" />
    case PROPOSAL_STATUSES.hired:
      return <PinkBorderedChip label="Hired" size="small" />
    case PROPOSAL_STATUSES.rejected:
      return <WarningBorderedChip label="Rejected" size="small" />
    default:
      break
  }
  const createdDate = new Date(proposal.created)
  if (differenceInDays(new Date(), createdDate) < 7) {
    return <BlueBorderedChip label="New" size="small" />
  }
  return null
}

export const PROPOSAL_STATUSES = {
  sent: "sent",
  accepted: "accepted",
  hired: "hired",
  rejected: "rejected",
}
